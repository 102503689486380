import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import QRCode from 'qrcode';
import { routes } from 'src/routes';

import { faQrcode, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { SButton, SCard, SModal, SText, STitle } from '@smartrenting/smartomic';

import CONFIG from '@config';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';

import './KeyInfoCard.scss';

export default function KeyInfoCard({
  processType,
}: {
  processType: 'setup' | 'unsetup';
}) {
  const { t } = useTranslation();
  const { id } = useApartment();
  const {
    state: { token },
    sendSmsDirectives,
  } = useAppContext();

  const [sentSms, setSentSms] = useState(false);
  const [displayQRCode, setDisplayQRCode] = useState(false);
  const [foUrl, setFoUrl] = useState<string>();

  const handleSendSms = useCallback(async () => {
    try {
      setSentSms(true);

      await sendSmsDirectives(id, processType);

      toast.success(t('dashboard.keys.smsSent'), {
        autoClose: 60000,
      });

      setTimeout(() => {
        setSentSms(false);
      }, 60000);
    } catch (error) {
      toast.error(t('dashboard.keys.smsSentError'), {
        onClose: () => setSentSms(false),
        autoClose: 10000,
      });
    }
  }, []);

  const handleOpenModal = useCallback(async () => {
    const url = `${CONFIG.FO_URL}${routes.dashboard}?token=${token}`;
    const qrCodeUrl = await QRCode.toDataURL(url, {
      width: 180,
      margin: 5,
    });

    setFoUrl(qrCodeUrl);

    setDisplayQRCode(true);
  }, []);

  return (
    <SCard className="KeyInfoCard">
      <STitle variant="body" title={t('dashboard.keys.getInstruction')} />
      <div className="KeyInfoCard__button">
        <SButton
          variant="secondary"
          outline
          icon={faQrcode}
          onClick={handleOpenModal}
        >
          {t('common.scanQRCode')}
        </SButton>
        <SButton
          disabled={sentSms}
          variant="secondary"
          icon={faEnvelope}
          onClick={handleSendSms}
        >
          {t('common.sendSMS')}
        </SButton>
      </div>
      <SModal
        className="KeyInfoCard__modal"
        isOpen={displayQRCode}
        onClose={setDisplayQRCode.bind(null, false)}
      >
        <STitle variant="body" title={t('common.modal.QRCode.title')} />
        <SText>{t('common.modal.QRCode.text')}</SText>
        <div className="KeyInfoCard__modal__qr-code">
          <img src={foUrl} alt="qr-code" />
        </div>
      </SModal>
    </SCard>
  );
}

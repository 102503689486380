import { Alert, AlertTitle, Box } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useLeaving } from '@hooks/useLeaving/useLeaving';

import outValidationScheduledSvg from './OutValidationScheduled.svg';

import './OutValidationScheduled.scss';

export default function OutValidationScheduled() {
  const { t } = useTranslation();
  const leaving = useLeaving();

  return (
    <div className="OutValidationScheduled">
      <Alert
        severity="success"
        icon={
          <Box
            component={FontAwesomeIcon}
            icon={faCircleCheck as any}
            size="1x"
            my="auto"
          />
        }
        sx={{ width: '100%' }}
      >
        <AlertTitle color="success.main">
          {t('dashboard.inventory.outValidationScheduled.success..title')}
        </AlertTitle>
        {t('dashboard.inventory.outValidationScheduled.success..subtitle', {
          date: leaving.expectedInventoryOutValidationDate?.format('LL [à] LT'),
        })}
      </Alert>
      <img src={outValidationScheduledSvg} alt="fireworks" />
    </div>
  );
}

import React, { useEffect } from 'react';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';

import { useKeywordsContext } from '@scopes/Gamification/context/KeywordsContext';

import { useGamification } from '../../contexts/GamificationContext';
import Keywords from '../Keywords/Keywords';

import './NeighborhoodStep.scss';

export default function NeighborhoodStep() {
  const { enable, handleNext, disable } = useGamification();
  const { postApartmentKeywords } = useAppContext();
  const { selectedIds, validSteps } = useKeywordsContext();
  const apartment = useApartment();

  const validationCondition = validSteps.includes('neighborhood');

  const sendData = async () => {
    if (
      JSON.stringify(apartment.keywords.map((keyword) => keyword.id).sort()) !==
      JSON.stringify([...selectedIds].sort())
    )
      await postApartmentKeywords(apartment.id, selectedIds);
  };

  useEffect(() => {
    if (validationCondition) {
      enable();
      handleNext(() => sendData() as any);
    } else {
      disable();
    }
  }, [validationCondition, selectedIds]);

  return (
    <main className="NeighborhoodStep">
      <Keywords
        categories={['neighborhood', 'surrounding']}
        step="neighborhood"
      />
    </main>
  );
}

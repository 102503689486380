import { Box, Typography } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDownload } from '@hooks/useDownload/useDownload';

import fileDownload from '../../assets/fileDownload.svg';

export interface DocumentDownloaderProps {
  document: {
    documentId: number;
    name: string;
  };
}
export default function DocumentDownloader({
  document,
}: DocumentDownloaderProps) {
  const { t } = useTranslation();

  const handleDownload = useDownload(document.documentId, document.name);

  return (
    <Box
      sx={{
        display: 'flex',
        marginBottom: 2,
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={handleDownload}
    >
      <Box sx={{ marginRight: 2 }}>
        <img src={fileDownload} />
      </Box>
      <Box>
        <Typography
          sx={{
            color: 'text.primary',
          }}
        >
          {document.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
            marginTop: -0.5,
          }}
        >
          {t('documents.trip.download')}
        </Typography>
      </Box>
    </Box>
  );
}

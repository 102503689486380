import { RentEstimationType } from 'src/types/RentEstimation';

import Apartment from '@models/Apartment';

import { fetchApi } from './fetchApi';

export async function getApartment(token: string, id: number) {
  return fetchApi<Apartment>('GET', `/apartments/${id}`, token);
}

export async function patchApartment(
  token: string,
  id: number,
  data: Partial<Apartment>,
) {
  return fetchApi<Apartment>('PATCH', `/apartments/${id}`, token, {
    body: data,
  });
}

export async function postKeywords(
  token: string,
  apartmentId: number,
  keywords: number[],
) {
  return fetchApi<Apartment>(
    'POST',
    `/apartments/${apartmentId}/keywords`,
    token,
    {
      body: { keywords },
    },
  );
}

export async function postApartmentPictures(
  token: string,
  id: number,
  files: FormData,
) {
  return fetchApi<Apartment>('POST', `/apartments/${id}/pictures`, token, {
    body: files,
  });
}

export async function deleteApartmentPicture(
  token: string,
  id: number,
  documentId: number,
) {
  return fetchApi<Apartment>(
    'DELETE',
    `/apartments/${id}/pictures/${documentId}`,
    token,
  );
}

export async function getRentEstimation(token: string, id: number) {
  return fetchApi<RentEstimationType>(
    'GET',
    `/apartments/${id}/rent-estimation`,
    token,
  );
}

export async function postApartmentAccess(
  token: string,
  apartmentId: number,
  accessType: string,
  steps: Array<{
    position: number;
    type: string;
    value: string;
  }>,
) {
  return fetchApi('POST', `/apartments/${apartmentId}/accesses`, token, {
    body: {
      type: accessType,
      steps,
    },
  });
}

import React from 'react';

import InReviewScheduled from './InReviewScheduled/InReviewScheduled';
import InReviewToSign from './InReviewToSign/InReviewToSign';
import InventoryInReview from './InventoryInReview/InventoryInReview';
import InventoryWaitingOut from './InventoryWaitingOut/InventoryWaitingOut';
import OutDone from './OutDone/OutDone';
import OutToSign from './OutToSign/OutToSign';
import OutValidationScheduled from './OutValidationScheduled/OutValidationScheduled';
import { OutValidationToSchedule } from './OutValidationToSchedule/OutValidationToSchedule';
import OutValidationToSign from './OutValidationToSign/OutValidationToSign';
import { useInventoryStep, InventoryStep } from './useInventoryStep';

import './Inventory.scss';

export default function Inventory() {
  const step = useInventoryStep();

  return (
    <div className="Inventory">
      {step === InventoryStep.inReview && <InventoryInReview />}
      {step === InventoryStep.inReviewScheduled && <InReviewScheduled />}
      {step === InventoryStep.inReviewToSign && <InReviewToSign />}
      {step === InventoryStep.outToSign && <OutToSign />}
      {step === InventoryStep.waitingOut && <InventoryWaitingOut />}
      {step === InventoryStep.outDone && <OutDone />}
      {step === InventoryStep.outValidationToSchedule && (
        <OutValidationToSchedule />
      )}
      {step === InventoryStep.outValidationScheduled && (
        <OutValidationScheduled />
      )}
      {step === InventoryStep.outValidationToSign && <OutValidationToSign />}
    </div>
  );
}

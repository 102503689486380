import React, { useEffect, useState } from 'react';

import { t } from 'i18next';

import {
  AEquipmentName,
  SEquipmentList,
  STitle,
} from '@smartrenting/smartomic';

import Apartment from '@models/Apartment';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';

import { useGamification } from '../../contexts/GamificationContext';

import './EquipmentsStep.scss';

export const equipmentsDefault: Array<AEquipmentName> = [
  'mop',
  'washer',
  'microwave',
  'hoover',
  'oven',
  'shower',
  'coffee-machine',
  'television',
  'fridge',
  'yard-sight',
  'street-sight',
  'elevator',
];

export const equipmentsOther: Array<AEquipmentName> = [
  'handicap-accessible',
  'car-park',
  'garden',
  'barbecue',
  'jacuzzi',
  'shared-pool',
  'private-pool',
  'blender',
  'balcony',
  'dryer',
  'phone',
  'chimney',
  'wc',
  'bath',
  'dishwasher',
  'kettle',
  'toaster',
  'hair-dryer',
  'iron',
  'curtain',
  'shutter',
  'air-conditioner',
  'freezer',
  'towel-dryer',
  'drying-rack',
  'ironing-board',
  'terrace',
  'smoke-alarm',
  'fan',
];

export default function EquipmentsStep() {
  const { id, equipments = [] } = useApartment();
  const { updateApartment } = useAppContext();
  const { enable, disable, disabled, handleNext } = useGamification();
  const [selected, setSelected] = useState<string[]>(
    equipments.filter((e: string) => !equipmentsOther.includes(e as any)),
  );

  const [otherSelected, setOtherSelected] = useState<string[]>(
    equipments.filter((e: string) => !equipmentsDefault.includes(e as any)),
  );

  const handleChangeDefault = ({ target }: any) => {
    setSelected(target.value);
  };
  const handleChangeOther = ({ target }: any) => {
    setOtherSelected(target.value);
  };

  useEffect(() => {
    const selectedEquipments = [...selected, ...otherSelected];

    if (selectedEquipments.length) {
      enable();

      if (
        JSON.stringify([...selectedEquipments].sort()) !==
        JSON.stringify([...equipments].sort())
      ) {
        handleNext(
          () =>
            updateApartment(id, {
              equipments: selectedEquipments,
            } as Partial<Apartment>) as any,
        );
      } else {
        handleNext(() => Promise.resolve());
      }
    } else if (!disabled) {
      disable();
    }
  }, [selected, otherSelected, equipments]);

  return (
    <div className="EquipmentsStep">
      <div className="EquipmentsStep__list">
        <STitle variant="page" title={t('apartment.info.equipmentsDefault')} />
        <SEquipmentList
          name={'equipementDefault'}
          equipments={equipmentsDefault}
          onChange={handleChangeDefault}
          value={selected}
        />
      </div>

      <div className="EquipmentsStep__list">
        <STitle variant="page" title={t('common.other')} />
        <SEquipmentList
          name={'equipementOther'}
          equipments={equipmentsOther}
          onChange={handleChangeOther}
          value={otherSelected}
        />
      </div>
    </div>
  );
}

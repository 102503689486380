import React from 'react';

import { t } from 'i18next';

import { AIcon, AIconProps, AInput } from '@smartrenting/smartomic';

import { ROOM_ITEM_TYPE } from '@enum';

export type RoomInputProps = {
  item: (typeof ROOM_ITEM_TYPE)[keyof typeof ROOM_ITEM_TYPE];
  number: number;
  icon: AIconProps['icon'];
  onChange: (
    item: (typeof ROOM_ITEM_TYPE)[keyof typeof ROOM_ITEM_TYPE],
    number: number,
  ) => void;
};

const RoomInput: React.FC<RoomInputProps> = ({
  item,
  number,
  icon,
  onChange,
}) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange(item, Number(e.target.value));
  };

  return (
    <div className="room-edit-row">
      <AIcon icon={icon} />
      <div>{t(`room-item-input.${item}`, { count: number })}</div>
      <AInput type="number" value={number} onChange={handleChange} />
    </div>
  );
};

export default RoomInput;

import KeySetup from '@models/KeySetup';

import { fetchApi } from './fetchApi';

export async function postReceivedKeySetup(token: string, id: number) {
  return fetchApi<KeySetup>('POST', `/key-setups/${id}/received`, token);
}

export async function postNotReceivedKeySetup(token: string, id: number) {
  return fetchApi<KeySetup>('POST', `/key-setups/${id}/not-received`, token);
}

import { TableRow, TableCell, Box } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Leaving from '@models/Leaving';

import { TabCellDownloader } from './FileDownloader';

export const AccountabilityTableRow = ({ leaving }: { leaving: Leaving }) => {
  const { t } = useTranslation();

  return (
    <TableRow
      key={leaving.accountabilityId}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      hover
    >
      <TableCell width="50%" scope="row">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            marginRight={1}
            component={FontAwesomeIcon}
            height={20}
            icon={faFilePdf as IconProp}
            color="primary.main"
          />
          {t('documents.trip.name.accountability')}
        </Box>
      </TableCell>
      <TableCell width="30%" align="center">
        -
      </TableCell>
      <TabCellDownloader
        documentId={leaving.accountabilityId}
        name={t('documents.trip.name.accountability')}
      />
    </TableRow>
  );
};

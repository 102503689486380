import moment from 'moment-timezone';

export default class TermPricing {
  id!: number;
  type!: string;
  value!: number;
  isPercent!: boolean;
  active!: boolean;
  createdAt!: moment.Moment;
  updatedAt!: moment.Moment;

  constructor(data: Record<string, any>) {
    Object.assign(this, data);

    this.value = Number(data.value);
    this.createdAt = moment(data.createdAt);
    this.updatedAt = moment(data.updatedAt);
  }
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { AIcon, SCard, SText } from '@smartrenting/smartomic';

import SchedulerLogo from './components/SchedulerLogo/SchedulerLogo';

import './Scheduler.scss';

export enum SchedulerLogoType {
  phone,
  visit,
  photo,
  inventory,
}

export default function Scheduler({
  duration,
  type,
  onChange,
  children,
}: {
  duration: number;
  type: (typeof SchedulerLogoType)[SchedulerLogoType];
  onChange: any;
  children?: React.ReactNode;
}) {
  const { t } = useTranslation();
  void onChange;

  return (
    <SCard className="Scheduler">
      <div className="Scheduler__info">
        <div className="Scheduler__info__title">
          <SchedulerLogo type={type} />
          <SText variant="body-title">
            {t(`dashboard.scheduler.${type}.title`)}
          </SText>
        </div>
        <div className="Scheduler__info__extra">
          <AIcon icon={faClock} />
          <SText variant="body-subtitle">
            {t('dashboard.scheduler.duration', { duration })}
          </SText>
        </div>
      </div>
      <div className="Scheduler__action">
        <div className="Scheduler__action__calendar">{children}</div>
      </div>
    </SCard>
  );
}

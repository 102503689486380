import React, { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { routes } from 'src/routes';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useContact } from '@hooks/useContact/useContact';
import { useLeaving } from '@hooks/useLeaving/useLeaving';
import { useProspect } from '@hooks/useProspect/useProspect';

import { AppLoader } from '@components/AppLoader/AppLoader';

import Apartments from '@scopes/Apartments/Apartments';
import Dashboard from '@scopes/Dashboard/Dashboard';
import Documents from '@scopes/Documents/Documents';
import Estimation from '@scopes/Estimation/Estimation';
import Gamification from '@scopes/Gamification/Gamification';
import { KeywordsContextProvider } from '@scopes/Gamification/context/KeywordsContext';
import { GamificationProvider } from '@scopes/Gamification/contexts/GamificationContext';
import InventoryRedirect from '@scopes/InventoryRedirect/InventoryRedirect';
import Leavings from '@scopes/Leavings/Leavings';
import Payments from '@scopes/Payments/Payments';
import Referral from '@scopes/Referral/Referral';
import Settings from '@scopes/Settings/Settings';

export function AppRoutes() {
  const prospect = useProspect();
  const {
    state: { connected },
  } = useAppContext();

  const apartment = useApartment();
  const leaving = useLeaving();
  const contact = useContact();

  const loaded = useMemo(
    () => connected && contact && prospect && apartment && leaving,
    [connected, contact, prospect, apartment, leaving],
  );

  if (!loaded) {
    return <AppLoader />;
  }

  return (
    <Routes>
      <Route path={routes.dashboard + '/*'} element={<Dashboard />} />
      <Route
        path={routes.gamification}
        element={
          <GamificationProvider>
            <KeywordsContextProvider>
              <Gamification />
            </KeywordsContextProvider>
          </GamificationProvider>
        }
      />
      <Route path={routes.estimation} element={<Estimation />} />
      <Route path={routes.leavings} element={<Leavings />} />
      <Route path={routes.apartments} element={<Apartments />} />
      <Route path={routes.documents} element={<Documents />} />
      <Route path={routes.settings + '/*'} element={<Settings />} />
      <Route path={routes.inventoryRedirect} element={<InventoryRedirect />} />
      <Route path={routes.referral} element={<Referral />} />
      <Route path={routes.payment} element={<Payments />} />
      <Route
        path={'*'}
        element={
          <Navigate
            to={!prospect.gamification ? routes.gamification : routes.dashboard}
          />
        }
      />
    </Routes>
  );
}

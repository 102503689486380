import React from 'react';

import { SInput, SInputNumber, SSelect } from '@smartrenting/smartomic';

import './GamificationInput.scss';

interface Props {
  label: string;
  type: 'number' | 'text' | 'select' | 'SInputNumber';
  options?: Array<{ value: string; label: string }>;
  onChange?: any;
  name: string;
  required?: boolean;
}

export default function GamificationInput({
  label,
  type,
  options,
  ...rest
}: Props) {
  if (type === 'select' && options) {
    return (
      <div className="GamificationInput">
        <div className="GamificationInput__label">{label}</div>
        <SSelect options={options} {...rest} required placeholder={' '} />
      </div>
    );
  }

  if (type === 'SInputNumber') {
    return (
      <div className="GamificationInput">
        <div className="GamificationInput__label">{label}</div>
        <SInputNumber {...rest} />
      </div>
    );
  }

  return (
    <div className="GamificationInput">
      <div className="GamificationInput__label">{label}</div>
      <SInput
        {...rest}
        step={1}
        pattern={type === 'number' ? '\\d*' : 'text'}
        type={type as any}
      />
    </div>
  );
}

import React, { useEffect, useState } from 'react';

import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { AIcon } from '@smartrenting/smartomic';

import Document from '@models/Document';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';

import Image from '@components/Image/Image';

import { useGamification } from '../../contexts/GamificationContext';

import FileUploader from './components/FileUploader/FileUploader';

import './PhotoStep.scss';

export default function PhotoStep() {
  const { addApartmentPictures, removeApartmentPicture } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const currentApartment = useApartment();

  const [pictures, setPictures] = useState<Document[]>(
    currentApartment.pictures,
  );

  const handlePicturePost = async (acceptedFiles: File[]) => {
    const formData = new FormData();

    for (const file of Array.from(acceptedFiles))
      formData.append('pictures', file);

    return addApartmentPictures(currentApartment.id, formData);
  };

  const handleUpload = async (newFiles: File[]) => {
    setIsLoading(true);
    const updatedApartment = await handlePicturePost(newFiles);
    setPictures(updatedApartment.pictures);
    setIsLoading(false);
  };

  const deletePicture = async (id: number) => {
    const updatedApartment = await removeApartmentPicture(
      currentApartment.id,
      id,
    );
    setPictures(updatedApartment.pictures);
  };

  const { enable, handleNext } = useGamification();

  useEffect(() => {
    handleNext(() => Promise.resolve());
    enable();
  }, []);

  return (
    <div className="PhotoStep">
      <FileUploader
        onUpload={handleUpload}
        nbFilesLeft={15 - pictures.length}
        disabled={isLoading || pictures.length >= 15}
      />
      <div className="PhotoStep__files">
        {pictures.map((picture) => (
          <div className="PhotoStep__files__imageHolder" key={picture.id}>
            <AIcon
              icon={faTrash}
              onClick={deletePicture.bind(null, picture.id)}
            />
            <Image document={picture} parameters={{ w: 500 }} />
          </div>
        ))}
      </div>
    </div>
  );
}

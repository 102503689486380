import moment from 'moment-timezone';

import { PhotoContract } from '@models/PhotoContract';

import { fetchApi } from './fetchApi';

export async function getPhotoContract(token: string, id: number) {
  return fetchApi<PhotoContract>('GET', `/photo-contracts/${id}`, token);
}

export async function postPicthouseOrder(
  token: string,
  id: number,
  date: moment.Moment,
) {
  return fetchApi('POST', `/photo-contracts/${id}/picthouse/order`, token, {
    body: {
      date: moment(date).format('YYYY-MM-DD'),
      time: moment(date).format('HH:mm'),
    },
  });
}

export const getPicthouseAvailabilities = (
  token: string,
  id: number | string,
) => {
  return fetchApi(
    'GET',
    `/photo-contracts/${id}/picthouse/availabilities`,
    token,
  );
};

export const getPicthouseSlots = (
  token: string,
  id: number,
  date: moment.Moment,
  cityId: number,
  packId: number,
): Promise<any> => {
  return fetchApi('GET', `/photo-contracts/${id}/picthouse/slots`, token, {
    query: {
      date: date.toISOString(),
      cityId,
      packId,
    },
  });
};

import {
  Alert,
  AlertTitle,
  Box,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment-timezone';

import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { INVENTORY_TYPE, LEAVING_STATUS } from '@enum';

import Leaving from '@models/Leaving';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import CanceledLeaving from '@components/CanceledLeaving/CanceledLeaving';
import Page from '@components/Page/Page';

import DashboardSetup from './components/DashboardSetup/DashboardSetup';
import DashboardUnSetup from './components/DashboardUnSetup/DashboardUnSetup';
import DoneLeaving from './components/DoneLeaving/DoneLeaving';
import OngoingLeaving from './components/OngoingLeaving/OngoingLeaving';

import './Dashboard.scss';

export default function Dashboard() {
  const { setCurrentLeaving } = useAppContext();
  const { t } = useTranslation();
  const apartment = useApartment();
  const leaving = useLeaving();

  const isPhotoShootScheduled =
    !!apartment.photoContracts.find((photoContract) =>
      photoContract.isScheduled(),
    ) ||
    (apartment.isRenewal(leaving.id) && !apartment.photoContracts.length);

  const inventoryInReview = leaving.findInventoryByType(
    INVENTORY_TYPE.IN_REVIEW,
  );

  const isComplete =
    leaving.isCheckinReady ||
    (leaving.isSigned &&
      leaving.isKeySetupStepCompleted &&
      isPhotoShootScheduled &&
      inventoryInReview?.isDone());

  const isHostReturnComing = moment()
    .startOf('day')
    .isSameOrAfter(moment(leaving.endDate).subtract(7, 'days').startOf('day'));

  const leavingsOptions = apartment.leavings
    .filter((leaving) => leaving.status !== LEAVING_STATUS.ARCHIVED)
    .map((leaving: Leaving) => {
      if (leaving.isFakeDate) {
        return {
          value: leaving.id,
          label: t('dashboard.leavings.isFakeDate'),
        };
      }

      return {
        value: leaving.id,
        label: `du ${leaving.startDate?.format(
          'L',
        )} au ${leaving.endDate?.format('L')}`,
      };
    });

  const handleChangeLeaving = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCurrentLeaving(parseInt(value));
  };

  if (leaving.status === LEAVING_STATUS.CANCELED) {
    return (
      <Page>
        <div className="Dashboard__period">
          <Box>
            <Typography variant="h5">{t('dashboard.title')}</Typography>
            <Typography mb={2.5}>{t('dashboard.subtitle')}</Typography>
          </Box>
          <Box>
            <TextField
              select
              label={t('dashboard.leavings.period')}
              defaultValue={leaving.id}
              onChange={handleChangeLeaving}
              size="small"
              focused={false}
              InputProps={{
                sx: {
                  color: 'secondary.main',
                },
              }}
            >
              {leavingsOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  color="secondary.main"
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </div>
        <Box
          className="Dashboard"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 1,
          }}
        >
          <CanceledLeaving />
        </Box>
      </Page>
    );
  }

  return (
    <div className="Dashboard">
      <Page>
        <div className="Dashboard__period">
          <Box>
            <Typography variant="h5">{t('dashboard.title')}</Typography>
            <Typography mb={2.5}>{t('dashboard.subtitle')}</Typography>
          </Box>
          <Box>
            <TextField
              select
              label={t('dashboard.leavings.period')}
              defaultValue={leaving.id}
              onChange={handleChangeLeaving}
              size="small"
              focused={false}
              InputProps={{
                sx: {
                  color: 'secondary.main',
                },
              }}
            >
              {leavingsOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  color="secondary.main"
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </div>
        {leaving.isFakeDate && (
          <Alert
            severity="warning"
            icon={
              <Box
                component={FontAwesomeIcon}
                icon={faExclamationCircle as any}
                size="1x"
                my="auto"
              />
            }
            sx={{ mb: 2, width: '100%' }}
          >
            <AlertTitle color="warning.main">
              {t('dashboard.leavings.alert.title')}
            </AlertTitle>
            {t('dashboard.leavings.alert.subtitle')}
          </Alert>
        )}
        {!isComplete && <DashboardSetup />}
        {isComplete && !isHostReturnComing && <OngoingLeaving />}
        {isComplete && isHostReturnComing && !leaving.isCheckoutReady && (
          <DashboardUnSetup />
        )}
        {isComplete && isHostReturnComing && leaving.isCheckoutReady && (
          <DoneLeaving />
        )}
      </Page>
    </div>
  );
}

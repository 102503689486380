import {
  Alert,
  AlertTitle,
  Box,
  Card,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faShieldHalved,
  faBuildingShield,
  faExclamationCircle,
  faInfoCircle,
  faXmark,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import config from '@config';

import { TERM_PRICING_TYPE } from '@enum';

import Modality from '@models/Modality';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useTranslationFormat } from '@hooks/useTranslationFormat/useTranslationFormat';

import StepChoice from '../ModalityChoice';
import { computeInsuranceAmount } from '../hooks/useModalityMapper';
import { useMultipleTermPricingByType } from '../hooks/useTermPricing';

import styled from '@emotion/styled';

interface StepInsuranceProps {
  onChoiceChange: (data: Record<string, any>) => void;
  modality: Modality | null;
}

const insuranceList = [
  { key: 'thirdPartyDamage', infoHelper: true },
  { key: 'personalProperty', infoHelper: true },
  { key: 'glassBreakage', infoHelper: true },
  { key: 'lockSmith', infoHelper: false },
  { key: 'globalAssistance', infoHelper: false },
  { key: 'sav', infoHelper: false },
];

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: (theme as any).shadows[1],
  },
}));

export default function StepInsurance({
  onChoiceChange,
  modality,
}: StepInsuranceProps) {
  const { t } = useTranslation();
  const apartment = useApartment();

  const { t: tF } = useTranslationFormat();
  const [currentChoice, setCurrentChoice] = useState<boolean>(
    !!modality?.paidInsurance,
  );

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(
    (choice: boolean) => () => {
      setCurrentChoice(choice);
      onChoiceChange({ paidInsurance: choice });
    },
    [],
  );

  return (
    <Box>
      <Typography
        variant="h6"
        align="center"
        fontWeight={500}
        sx={{ mb: { xs: '1rem', sm: '2rem' } }}
      >
        {t('boardOffice.steps.insurance.title')}
      </Typography>
      <Grid sx={{ flexGrow: 1 }} container>
        <Grid container justifyContent="center" spacing={{ xs: 2, sm: 4 }}>
          <Grid key="insurance-essential" item onClick={handleClick(false)}>
            <StepChoice
              title={t('boardOffice.steps.insurance.essential.title')}
              subtitle={t('boardOffice.steps.insurance.essential.subtitle')}
              header={t('boardOffice.steps.insurance.essential.header')}
              icon={faShieldHalved as IconProp}
              active={!currentChoice}
            />
          </Grid>
          <Grid key="express" item onClick={handleClick(true)}>
            <StepChoice
              title={t('boardOffice.steps.insurance.paid.title')}
              subtitle={t('boardOffice.steps.insurance.paid.subtitle')}
              header={t('boardOffice.steps.insurance.paid.header', {
                amount: computeInsuranceAmount(
                  useMultipleTermPricingByType(
                    TERM_PRICING_TYPE.PAID_INSURANCE,
                  ),
                  apartment,
                ).toFixed(2),
              })}
              icon={faBuildingShield as IconProp}
              active={currentChoice}
            />
          </Grid>
        </Grid>
      </Grid>
      <Link
        component={Typography}
        color="secondary"
        onClick={() => setOpen(true)}
        sx={{ cursor: 'pointer' }}
        textAlign="center"
        pt={1}
        fontWeight={600}
        fontSize={{ xs: 14, sm: 16 }}
      >
        {t('boardOffice.steps.insurance.seeMore')}
      </Link>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Card
          sx={{
            height: { xs: '95%', sm: '90%' },
            overflowY: 'scroll',
            position: 'absolute',
            top: { xs: '5%', sm: '50%' },
            left: { xs: 0, sm: '50%' },
            transform: { sm: 'translate(-50%, -50%)' },
            width: { xs: '100%', sm: '90%' },
            maxWidth: '1110px',
            p: { xs: 3, sm: 8 },
            pt: { xs: 4, sm: 8 },
            borderRadius: { xs: 0, sm: 3 },
          }}
        >
          <Box
            component={FontAwesomeIcon}
            icon={faXmark as IconProp}
            position="absolute"
            right={0}
            top={0}
            height={20}
            mt={{ xs: 0.5, sm: 2 }}
            mr={{ xs: 0.5, sm: 2 }}
            onClick={() => setOpen(false)}
            sx={{ cursor: 'pointer' }}
          />
          <Typography
            variant="h5"
            gutterBottom
            textAlign="center"
            color="black.light"
          >
            {t('insurance.title')}
          </Typography>
          <Typography
            variant="subtitle1"
            textAlign="center"
            color="grey"
            px={{ xs: 0, sm: 5 }}
          >
            {t('insurance.subtitle')}
          </Typography>
          <Alert
            icon={
              <Box
                component={FontAwesomeIcon}
                icon={faExclamationCircle as any}
                size="1x"
                my="auto"
              />
            }
            sx={{ my: 4 }}
            severity="info"
          >
            <AlertTitle color="sea.main">
              {t('insurance.alert.title')}
            </AlertTitle>
            {t('insurance.alert.text')}
          </Alert>
          <Divider />
          <List sx={{ listStyleType: 'disc', pt: 3 }}>
            {['1', '2', '3', '4', '5'].map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  display: 'list-item',
                  py: 0,
                  fontSize: { xs: 10, sm: 14 },
                }}
              >
                {tF(`insurance.list.${item}`, { deposit: apartment.risk })}
              </ListItem>
            ))}
          </List>
          <Card sx={{ borderRadius: 8, my: 3, mx: -2 }}>
            <Grid container pl={4}>
              <Grid item xs={4} sm={6} py={4}>
                <Typography
                  fontSize={{ xs: 14, sm: 18 }}
                  fontWeight={600}
                  color="grey.800"
                >
                  {t('insurance.details.guarantees')}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={6} component={Stack}>
                <Box
                  width="50%"
                  py={4}
                  sx={{
                    ':hover': currentChoice
                      ? {
                          bgcolor: 'sea.light',
                          cursor: 'pointer',
                          '.MuiTypography-root': {
                            color: 'secondary.lighter',
                          },
                        }
                      : {},
                  }}
                  bgcolor={currentChoice ? 'transparent' : 'snow.main'}
                  onClick={handleClick(false)}
                >
                  <Typography
                    textAlign="center"
                    fontSize={{ xs: 14, sm: 18 }}
                    fontWeight={600}
                    color={currentChoice ? 'grey.500' : 'sea.main'}
                  >
                    {t('insurance.details.free')}
                  </Typography>
                </Box>
                <Box
                  py={4}
                  pr={0}
                  width="50%"
                  height="100%"
                  sx={{
                    ':hover': currentChoice
                      ? {}
                      : {
                          bgcolor: 'sea.light',
                          cursor: 'pointer',
                          '.MuiTypography-root': {
                            color: 'secondary.lighter',
                          },
                        },
                  }}
                  bgcolor={currentChoice ? 'snow.main' : 'transparent'}
                  onClick={handleClick(true)}
                >
                  <Typography
                    textAlign="center"
                    fontSize={{ xs: 14, sm: 18 }}
                    fontWeight={600}
                    color={currentChoice ? 'sea.main' : 'grey.500'}
                  >
                    {t('insurance.details.premium')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box bgcolor="grey.50" py={2}>
              <Typography
                textAlign="center"
                fontSize={{ xs: 14, sm: 20 }}
                fontWeight={600}
                color="grey.800"
              >
                {t('insurance.details.covered')}
              </Typography>
            </Box>
            <CardElement
              currentChoice={currentChoice}
              free="50€"
              premium="300€"
              details={{
                title: t('insurance.details.excessRefunded.title'),
                text: t('insurance.details.excessRefunded.text'),
              }}
            />
            <Box bgcolor="grey.50" py={2}>
              <Typography
                textAlign="center"
                fontSize={{ xs: 14, sm: 20 }}
                fontWeight={600}
                color="grey.800"
              >
                {t('insurance.details.notCovered')}
              </Typography>
            </Box>
            {insuranceList.map((item, index) => (
              <CardElement
                key={index}
                currentChoice={currentChoice}
                free={t(`insurance.details.${item.key}.free`)}
                premium={t(`insurance.details.${item.key}.premium`)}
                details={{
                  title: t(`insurance.details.${item.key}.title`),
                  subtitle: t(`insurance.details.${item.key}.subtitle`),
                  text: t(`insurance.details.${item.key}.text`),
                }}
                bg={index % 2 === 1}
                infoHelper={item.infoHelper}
              />
            ))}
          </Card>
          <Box>
            <Typography
              fontSize={{ xs: '8px !important', sm: '12px !important' }}
              color="grey.800"
            >
              {t('insurance.endInfo.steal')}
            </Typography>
            <Typography
              fontSize={{ xs: '8px !important', sm: '12px !important' }}
              color="grey.800"
            >
              {t('insurance.endInfo.dammage')}
            </Typography>
            <Typography
              fontSize={{ xs: '8px !important', sm: '12px !important' }}
              color="grey.800"
            >
              {t('insurance.endInfo.cgu.text')}
              <Link
                component="a"
                href={`${config.WEBSITE_URL}/conditions-generales-dutilisation`}
                target="_blank"
                fontWeight={600}
                color="grey.900"
              >
                {t('insurance.endInfo.cgu.link')}
              </Link>
            </Typography>
          </Box>
        </Card>
      </Modal>
    </Box>
  );
}

const CardElement: React.FC<{
  bg?: boolean;
  currentChoice: boolean;
  free: string;
  premium: string;
  details: {
    title: string;
    subtitle?: string;
    text: string;
  };
  infoHelper?: boolean;
}> = ({
  bg = false,
  currentChoice,
  free,
  premium,
  details,
  infoHelper = false,
}) => {
  const { t } = useTranslationFormat();
  const apartment = useApartment();

  return (
    <Grid container pl={4} bgcolor={bg ? 'grey.50' : 'white'}>
      <Grid
        item
        xs={4}
        sm={6}
        py={3}
        component={Stack}
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
      >
        <Typography
          fontSize={{ xs: 10, sm: 16 }}
          fontWeight={400}
          color="grey.800"
          display="inline-flex"
        >
          {details.title}
          {details.subtitle && (
            <Box
              ml={0.5}
              alignSelf="center"
              fontSize="12px !important"
              color="grey.600"
              display={{ xs: 'none', sm: 'inline' }}
            >
              {details.subtitle}
            </Box>
          )}
        </Typography>
        <Typography
          fontSize="12px !important"
          fontWeight={300}
          color="grey.600"
          whiteSpace="pre-line"
          display={{ xs: 'none', sm: 'inline' }}
        >
          {details.text}
        </Typography>
      </Grid>
      <Grid item xs={8} sm={6} py={4} component={Stack} alignItems="center">
        <Box width="50%">
          <Typography
            mx={2}
            textAlign="center"
            fontSize={{ xs: 10, sm: 18 }}
            fontWeight={600}
            color={currentChoice ? 'grey.500' : 'sea.main'}
          >
            {free}
            {infoHelper && (
              <LightTooltip
                title={
                  <Typography
                    whiteSpace="pre-line"
                    fontSize="10px !important"
                    color="grey.900"
                  >
                    {t('insurance.details.infoHelper', {
                      deposit: apartment.risk,
                      coveredAmount: free,
                      sum:
                        Number(apartment.risk) + Number(free.replace('€', '')),
                    })}
                  </Typography>
                }
              >
                <Box
                  component={FontAwesomeIcon}
                  icon={faInfoCircle as IconProp}
                  ml={1}
                  display={{ xs: 'none', sm: 'initial' }}
                />
              </LightTooltip>
            )}
          </Typography>
        </Box>
        <Box pr={0} width="50%">
          <Typography
            mx={3}
            textAlign="center"
            fontSize={{ xs: 10, sm: 18 }}
            fontWeight={600}
            color={currentChoice ? 'sea.main' : 'grey.500'}
          >
            {premium}
            {infoHelper && (
              <LightTooltip
                title={
                  <Typography
                    whiteSpace="pre-line"
                    fontSize="10px !important"
                    color="grey.900"
                  >
                    {t('insurance.details.infoHelper', {
                      deposit: apartment.risk,
                      coveredAmount: premium,
                      sum:
                        Number(apartment.risk) +
                        Number(premium.replace('€', '')),
                    })}
                  </Typography>
                }
              >
                <Box
                  component={FontAwesomeIcon}
                  icon={faInfoCircle as IconProp}
                  ml={1}
                  display={{ xs: 'none', sm: 'initial' }}
                />
              </LightTooltip>
            )}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

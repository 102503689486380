import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  faPhoneIntercom,
  faBroom,
  faTrashCan,
  faFaucetDrip,
  faBolt,
  faHouseSignal,
  faWifi,
  faLock,
} from '@fortawesome/pro-solid-svg-icons';
import { SButton } from '@smartrenting/smartomic';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';

import PracticalInfoRow from './components/PracticalInfoRow';

import './PracticalInfo.scss';

const PracticalInfo: React.FC<{
  edit: boolean;
  setEdit?: (edit: boolean) => void;
}> = ({ edit, setEdit }) => {
  const { t } = useTranslation();
  const { updateApartment } = useAppContext();
  const {
    ring,
    wifi,
    wifiPassword,
    electricitySupplyLocalization,
    wasteInstructions,
    waterSupplyLocalization,
    cleaningDirective,
    wifiInstructions,
    id,
  } = useApartment();

  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infos, setInfos] = useState({
    ring,
    wifi,
    wifiPassword,
    electricitySupplyLocalization,
    wasteInstructions,
    waterSupplyLocalization,
    cleaningDirective,
    wifiInstructions,
  });

  const onSubmit = async () => {
    setLoading(true);
    try {
      await updateApartment(id, infos);
    } catch (error) {
      toast.error(t('common.unhandledError'), {
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
      setUpdated(false);
      if (setEdit) setEdit(false);
    }
  };

  const onChange = (change: typeof infos) => {
    setInfos(change);
    setUpdated(true);
  };

  useEffect(() => {
    setUpdated(false);
    setInfos({
      ring,
      wifi,
      wifiPassword,
      electricitySupplyLocalization,
      wasteInstructions,
      waterSupplyLocalization,
      cleaningDirective,
      wifiInstructions,
    });
  }, [edit]);

  return (
    <div className="PraticalInfo">
      <PracticalInfoRow
        edit={edit}
        icon={faPhoneIntercom}
        title={t('apartment.practical.intercom')}
        description={infos.ring}
        onChange={(updated) => onChange({ ...infos, ring: updated })}
        name="intercom"
      />
      <PracticalInfoRow
        edit={edit}
        icon={faHouseSignal}
        title={t('apartment.practical.wifi-place')}
        description={infos.wifiInstructions}
        onChange={(updated) =>
          onChange({ ...infos, wifiInstructions: updated })
        }
        name="wifi-place"
      />
      <PracticalInfoRow
        edit={edit}
        icon={faWifi}
        title={t('apartment.practical.wifi-name')}
        description={infos.wifi}
        onChange={(updated) => onChange({ ...infos, wifi: updated })}
        name="wifi-name"
      />
      <PracticalInfoRow
        edit={edit}
        icon={faLock}
        title={t('apartment.practical.wifi-password')}
        description={infos.wifiPassword}
        onChange={(updated) => onChange({ ...infos, wifiPassword: updated })}
        name="wifi-password"
      />
      <PracticalInfoRow
        edit={edit}
        icon={faBolt}
        title={t('apartment.practical.circuit-breaker')}
        description={infos.electricitySupplyLocalization}
        onChange={(updated) =>
          onChange({ ...infos, electricitySupplyLocalization: updated })
        }
        name="electricity"
      />
      <PracticalInfoRow
        edit={edit}
        icon={faFaucetDrip}
        title={t('apartment.practical.water-supply')}
        description={infos.waterSupplyLocalization}
        onChange={(updated) =>
          onChange({ ...infos, waterSupplyLocalization: updated })
        }
        name="water"
      />
      <PracticalInfoRow
        edit={edit}
        icon={faBroom}
        title={t('apartment.practical.cleaning')}
        description={infos.cleaningDirective}
        onChange={(updated) =>
          onChange({ ...infos, cleaningDirective: updated })
        }
        name="cleaning"
      />
      <PracticalInfoRow
        edit={edit}
        icon={faTrashCan}
        title={t('apartment.practical.trash')}
        description={infos.wasteInstructions}
        onChange={(updated) =>
          onChange({ ...infos, wasteInstructions: updated })
        }
        name="trash"
      />
      {edit && (
        <SButton
          className="validate-form-button"
          disabled={loading || !updated}
          variant="secondary"
          label={t('settings.submit')}
          onClick={onSubmit}
        />
      )}
    </div>
  );
};

export default PracticalInfo;

import { apartment } from '@schemas';

import { useMemo } from 'react';

import { denormalize } from 'normalizr';

import Apartment from '@models/Apartment';

import { useAppContext } from '@contexts/AppContext/AppContext';
import { useEntities } from '@contexts/EntitiesContext/EntitiesContext';

export function useApartment(): Apartment {
  const entities = useEntities();
  const {
    state: { currentApartment },
  } = useAppContext();

  const selectedApartment = useMemo(
    () => denormalize(currentApartment, apartment, entities),
    [currentApartment, entities.apartments],
  );

  return selectedApartment && new Apartment(selectedApartment);
}

export const STATUS = {
  PENDING: 1,
  ACTIVE: 2,
  ARCHIVED: 3,
};

export const REFERRAL_STATUS = {
  PAYED: 'payed',
  TO_PAY: 'to-pay',
  PENDING: 'pending',
} as const;

export type RefferalStatus =
  (typeof REFERRAL_STATUS)[keyof typeof REFERRAL_STATUS];

export const ROLE = {
  ADMIN: 'admin',
  PROSPECT: 'prospect',
  SALE: 'sale',
  OPERATIONAL: 'operational',
  CONTRACTOR: 'contractor',
  CONTRACTOR_ADMIN: 'contractor-admin',
  TECHNICAL: 'technical',
};

export const PROSPECT_STATUS = {
  NEW: 'new',
  WAIT_FIRST_CALL: 'wait-first-call',
  PHONE_CALL: 'phone-call',
  WAIT_VISIT: 'wait-visit',
  VISIT_SCHEDULED: 'visit-scheduled',
  WAIT_DOCUMENTS: 'wait-documents',
  MANDATE_TO_SENT: 'mandate-to-sent',
  SALE_TO_VALIDATE: 'sale-to-validate',
  CLIENT_TO_VALIDATE: 'client-to-validate',
  TO_SIGN: 'to-sign',
  SIGNED: 'signed',
  EXPIRED: 'expired',
  REJECTED: 'rejected',
  TO_RECONTACT: 'to-recontact',
};

export const PROSPECT_STATUS_BEFORE_VISITE = [
  'new',
  'phone-call',
  'visit-scheduled',
  'wait-first-call',
  'wait-visit',
];

export const LEAVING_STATUS = {
  INIT: 'init',
  SIGNED: 'signed',
  ARCHIVED: 'archived',
  CLOSED: 'closed',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
};

export const CHECKOUT_STATUS = {
  NO_PROBLEM: 'no-problem',
  MINOR_PROBLEM: 'minor-problem',
  MAJOR_PROBLEM: 'major-problem',
};

export const DOCUMENT = {
  ID: 'identityDocuments',
  RIB: 'rib',
  PROOF: 'proofDocument',
  AUTHORIZATION: 'authorizationDocument',
  DEPARTURE: 'departureDocument',
  RECEIPTS: 'receipts',
  USAGE: 'usageDocument',
} as const;

export const EVENT_TYPE = {
  CREATE: 'create',
  UPDATE: 'update',
  CHANGE_SALE: 'change-sale',
  CHANGE_STATUS: 'change-status',
  COMMENT: 'comment',
  ADD_DOCUMENT: 'add-document',
  REMOVE_DOCUMENT: 'remove-document',
  GENERATE_MANDATE: 'generate-mandate',
  GENERATE_MANDATE_MANUAL: 'generate-mandate-manual',
};

export const OWNERSHIP = {
  OWNER: 'owner',
  TENANT: 'tenant',
};

export const RESIDENCE_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const FORMAT_TYPE = {
  JSON: 'application/json',
  CSV: 'text/csv',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const HOUSING_TYPE = {
  HOUSE: 'house',
  APARTMENT: 'apartment',
};

export const DELIVERY_CONTRACTORS = {
  LA_POSTE: 'la-poste',
  STUART: 'stuart',
};

export const KEY_SETUP_STATUS = {
  INIT: 'init',
  READY: 'ready',
  DEPOSITED: 'deposited',
} as const;

export const KEY_UN_SETUP_STATUS = {
  INIT: 'init',
  READY: 'ready',
  DONE: 'done',
};

export const APARTMENT_KEY_DENOMINATION = {
  GUEST: 'guest',
  AGENCY: 'agency',
  CLEANING: 'cleaning',
} as const;

export const APARTMENT_KEY_TYPE = {
  KEYCAFE: 'keycafe',
  KEYNEST: 'keynest',
  OTHERS: 'others',
} as const;

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
};

export const PHOTO_CONTRACT_STATUS = {
  TO_SCHEDULE: 'to-schedule',
  SCHEDULED: 'scheduled',
  DELIVERED: 'delivered',
  CANCELED: 'canceled',
};

export const INVENTORY_TYPE = {
  OUT: 'out',
  IN: 'in',
  OUT_REVIEW: 'out-review',
  IN_REVIEW: 'in-review',
  OUT_VALIDATION: 'out-validation',
};

export const INVENTORY_STATUS = {
  INIT: 'init',
  TO_SIGN: 'to-sign',
  DONE: 'done',
};

export const ROOM_TYPE = {
  LIVINGROOM: 'livingroom',
  BEDROOM: 'bedroom',
  BATHROOM: 'bathroom',
} as const;

export const KEY_PROCESS_TYPE = {
  SETUP: 'setup',
  UNSETUP: 'unsetup',
};

export const APARTMENT_KEY_STATUS = {
  WAITING_DROPOFF: 'waiting-dropoff',
  IN_USE: 'in-use',
  STORED: 'stored',
} as const;

export const BED_ROOM_ITEM_TYPE = {
  BED_140: 'bed-140',
  BED_160: 'bed-160',
  BED_180: 'bed-180',
  BED_BUNK: 'bed-bunk',
  BED_COUCH: 'bed-couch',
  BED_SOLO: 'bed-solo',
} as const;

export const LIVING_ROOM_ITEM_TYPE = {
  BED_140: 'bed-140',
  BED_160: 'bed-160',
  BED_180: 'bed-180',
  BED_BUNK: 'bed-bunk',
  BED_COUCH: 'bed-couch',
  BED_SOLO: 'bed-solo',
} as const;

export const BATH_ROOM_ITEM_TYPE = {
  SHOWER: 'shower',
  TOILETS: 'toilets',
  BATH: 'bath',
} as const;

export const ROOM_ITEM_TYPE = {
  ...BATH_ROOM_ITEM_TYPE,
  ...LIVING_ROOM_ITEM_TYPE,
  ...BATH_ROOM_ITEM_TYPE,
} as const;

export const ROOM_ITEM_BY_ROOM = {
  [ROOM_TYPE.BEDROOM]: [
    ROOM_ITEM_TYPE.BED_140,
    ROOM_ITEM_TYPE.BED_160,
    ROOM_ITEM_TYPE.BED_180,
    ROOM_ITEM_TYPE.BED_BUNK,
    ROOM_ITEM_TYPE.BED_SOLO,
    ROOM_ITEM_TYPE.BED_COUCH,
  ],
  [ROOM_TYPE.BATHROOM]: [
    ROOM_ITEM_TYPE.BATH,
    ROOM_ITEM_TYPE.SHOWER,
    ROOM_ITEM_TYPE.TOILETS,
  ],
  [ROOM_TYPE.LIVINGROOM]: [
    ROOM_ITEM_TYPE.BED_140,
    ROOM_ITEM_TYPE.BED_160,
    ROOM_ITEM_TYPE.BED_180,
    ROOM_ITEM_TYPE.BED_BUNK,
    ROOM_ITEM_TYPE.BED_SOLO,
    ROOM_ITEM_TYPE.BED_COUCH,
  ],
};

export const ACCESS_TYPE = {
  FOOT: 'foot',
  CAR: 'car',
} as const;

export const ACCESS_STEP_TYPE = {
  ACCESS: 'access',
  ACTION: 'action',
  CUSTOM: 'custom',
  FIXED: 'fixed',
  LOCATION: 'location',
} as const;

export const LANGUAGES = {
  FR: 'FR',
  EN: 'EN',
  IT: 'IT',
  DE: 'DE',
  ES: 'ES',
} as const;

export const TERM_PRICING_TYPE = {
  EARLY_PAYMENT: 'early-payment',
  PAID_INSURANCE: 'paid-insurance',
  PAID_CLEANING: 'paid-cleaning',
  PAID_INVENTORY: 'paid-inventory',
  PAID_KEY: 'paid-key',
  SETUP_FEE: 'setup-fee',
} as const;

import { useCallback } from 'react';

import CONFIG from '@config';

import { useAppContext } from '@contexts/AppContext/AppContext';

export function useDownload(documentId: number, name: string) {
  const {
    state: { token },
  } = useAppContext();
  const myHeaders = new Headers({ authorization: token });

  return useCallback(() => {
    fetch(`${CONFIG.API_V2_URL}/documents/${documentId}`, {
      headers: myHeaders,
    }).then(async (res) => {
      let filename = name;
      const a = document.createElement('a');
      a.href = URL.createObjectURL(await res.blob());

      const contentDisposition = res.headers.get(
        'Content-Disposition',
      ) as string;

      if (contentDisposition) {
        const match = contentDisposition.match(
          /filename="(.+)"/,
        ) as RegExpMatchArray;

        if (match[1]) {
          filename = match[1];
        }
      }

      a.download = filename;
      a.click();
      a.remove();
    });
  }, [documentId, name]);
}

import { Box } from '@mui/material';

import React, { useEffect } from 'react';

import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';

import config from '@config';

interface MapContainerProps {
  google?: any;
  initialCenter?: {
    lat: number;
    lng: number;
  };
  zoom?: number;
  markerAddress?: string;
}

const MapContainer: React.FC<MapContainerProps> = ({
  google,
  zoom = 10,
  initialCenter = {
    lat: 46.227638,
    lng: 2.213749,
  },
  markerAddress,
}) => {
  const geocoder = React.useMemo(
    () => google && new google.maps.Geocoder(),
    [google],
  );

  const [center, setCenter] = React.useState(null);

  useEffect(() => {
    if (geocoder && markerAddress) {
      geocoder.geocode(
        { address: markerAddress },
        (results: any, status: string) => {
          if (status === 'OK') {
            const { lat, lng } = results[0].geometry.location;
            const coordinates = { lat: lat(), lng: lng() };
            setCenter(coordinates as any);
          }
        },
      );
    }
  }, [markerAddress]);

  return (
    <Box
      sx={{
        '> div': {
          '> div': {
            borderRadius: 4,
          },
        },
      }}
    >
      <Map
        google={google}
        initialCenter={initialCenter}
        containerStyle={{
          position: 'relative',
          paddingTop: '56.25%',
        }}
        center={center}
        {...({ zoom } as any)} // zoom not reconized as a props ...
      >
        <Marker
          {...({ position: center } as any)} // position not reconized as a props ... google HALF Typescript friendly
        />
      </Map>
    </Box>
  );
};

const LoadingContainer: React.FC = () => <div>Fancy loading container!</div>;

export default GoogleApiWrapper((props: MapContainerProps) => ({
  ...props,
  apiKey: config.GOOGLE_API_KEY,
  LoadingContainer,
}))(MapContainer) as React.ComponentType<MapContainerProps>;

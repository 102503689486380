import {
  apartment,
  contact,
  leavings,
  prospect,
  neighborhood,
  keySetup,
  photoContract,
  eventActivity,
  inventory,
  user,
  access,
  hostRent,
  termPricing,
} from '@schemas';

import React, { useCallback, useMemo } from 'react';

import { RentEstimationType } from 'src/types/RentEstimation';

import { DOCUMENT } from '@enum';

import { Actions, defaultFunctionParameter } from '@utils/helpers';

import { AccessStep } from '@models/AccessStep';
import Apartment from '@models/Apartment';
import { CleaningDuration } from '@models/CleaningDuration';
import Contact from '@models/Contact';
import { Inventory } from '@models/Inventory';
import Leaving, { getDefaultCurrentLeaving } from '@models/Leaving';
import Modality from '@models/Modality';
import { PhotoContract } from '@models/PhotoContract';
import { RoomCreation } from '@models/Room';
import User from '@models/User';

import { getApartmentAccessSteps, patchApartmentAccess } from '@api/access';
import { sendSmsDirectives } from '@api/apartmentKeys';
import {
  patchApartment,
  postKeywords,
  deleteApartmentPicture,
  postApartmentPictures,
  getApartment,
  getRentEstimation,
  postApartmentAccess,
} from '@api/apartments';
import { getCleaningDurationByArea } from '@api/cleaningDurations';
import { patchContact, getContact } from '@api/contacts';
import { getHostRentsWithBills } from '@api/hostRent';
import { getInventoryUrl } from '@api/inventory';
import { postNotReceivedKeySetup, postReceivedKeySetup } from '@api/keySetups';
import {
  generateAuthorizationTenantDocument,
  patchLeaving,
  postLeavingInventory,
  generateMandateForLeaving,
} from '@api/leavings';
import { getModality, patchModality } from '@api/modality';
import { getNeighborhoods } from '@api/neighborhoods';
import { sendNotificationRenewal } from '@api/notifications';
import {
  getPhotoContract,
  getPicthouseAvailabilities,
  getPicthouseSlots,
  postPicthouseOrder,
} from '@api/photoContracts';
import {
  deleteDocument,
  getAuthenticate,
  patchProfilePicture,
  postDocument,
  postLogin,
  postLogout,
  postNewPassword,
  forgotPassword,
} from '@api/profile';
import {
  getEvents,
  patchProspectStatus,
  postGamification,
} from '@api/prospects';
import { getReferences } from '@api/references';
import { postRooms } from '@api/rooms';
import { getTermsPricing } from '@api/terms-pricing';

import { useEntitiesContext } from '@contexts/EntitiesContext/EntitiesContext';

import { ActionTypes } from './actions';

export enum LocalStorageKey {
  TOKEN = 'token',
  APARTMENT = 'apartment',
  LEAVING = 'leaving',
}

export interface StateInterface {
  connected: boolean;
  token: string;
  contact: number | null;
  currentApartment: number | null;
  currentLeaving: number | null;
}

export const AppReducer = (
  state: StateInterface,
  action: Actions<(typeof ActionTypes)[keyof typeof ActionTypes]>,
) => {
  switch (action.type) {
    case ActionTypes.SET_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case ActionTypes.SET_CONNECTED: {
      return {
        ...state,
        connected: action.payload,
      };
    }
    case ActionTypes.SET_CURRENT_APARTMENT: {
      return {
        ...state,
        currentApartment: action.payload,
      };
    }
    case ActionTypes.SET_CURRENT_LEAVING: {
      return {
        ...state,
        currentLeaving: action.payload,
      };
    }
    case ActionTypes.SET_CONTACT: {
      return {
        ...state,
        contact: action.payload,
      };
    }
    case ActionTypes.RESET_STATE: {
      return { ...initialState(), token: '' };
    }
    default:
      return state;
  }
};

export const initialState = (): StateInterface => ({
  token: localStorage.getItem(LocalStorageKey.TOKEN) || '',
  connected: false,
  contact: null,
  currentApartment:
    parseInt(localStorage.getItem(LocalStorageKey.APARTMENT) || '', 10) || null,
  currentLeaving:
    parseInt(localStorage.getItem(LocalStorageKey.LEAVING) || '', 10) || null,
});

export interface AppContextInterface {
  state: StateInterface;
  resetState: () => void;
  setToken: (token: string) => void;
  authenticate: () => void;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  createNewPassword: (password: string) => Promise<User>;
  forgotPassword: (email: string) => Promise<void>;
  fetchReferences: () => void;
  fetchContact: (apartmentId?: number) => void;
  fetchApartment: (id: number) => Promise<void>;
  setCurrentApartment: (id: number) => void;
  setCurrentLeaving: (id: number) => void;
  updateLeaving: (id: number, data: Partial<Leaving>) => Promise<void>;
  updateApartment: (id: number, data: Partial<Apartment>) => Promise<void>;
  sendSmsDirectives: (
    apartmentId: number,
    processType: 'setup' | 'unsetup',
  ) => Promise<void>;
  updateContact: (id: number, data: Partial<Contact>) => Promise<Contact>;
  createDocument: (name: string, formDate: FormData) => Promise<void>;
  removeDocument: (name: string, documentId: number) => void;
  updateProspectStatus: (
    id: number,
    status: string,
    data?: Record<string, any>,
  ) => void;
  fetchNeighborhoods: (agencyId?: number) => void;
  receivedKeySetup: (keySetupId: number) => void;
  notReceivedKeySetup: (keySetupId: number) => void;
  updateProfilePicture: (file: FormData) => void;
  createPicthouseOrder: (
    id: number,
    date: moment.Moment,
  ) => Promise<PhotoContract>;
  fetchPhotoContractDates: (id: number) => Promise<any>;
  fetchPhotoContractSlots: (
    id: number,
    date: moment.Moment,
    cityId: number,
    packId: number,
  ) => Promise<Record<string, { available: boolean }>>;
  endGamification: (id: number) => void;
  fetchEvents: (id: number) => void;
  postApartmentKeywords: (
    apartmentId: number,
    keywordsIds: number[],
  ) => Promise<void>;
  addApartmentPictures: (id: number, files: FormData) => Promise<Apartment>;
  removeApartmentPicture: (
    id: number,
    documentId: number,
  ) => Promise<Apartment>;
  createRooms: (apartmentId: number, rooms: RoomCreation[]) => Promise<void>;
  fetchRentEstimation: (apartmentId: number) => Promise<RentEstimationType>;
  createLeavingInventory: (
    leavingId: number,
    type: string,
  ) => Promise<Inventory>;
  fetchInventoryUrl: (leavingId: number) => Promise<string>;
  fetchApartmentAccessSteps: (accessId: number) => Promise<AccessStep[]>;
  createApartmentAccess: (
    apartmentId: number,
    accessType: string,
    steps: Array<{
      position: number;
      type: string;
      value: string;
    }>,
  ) => Promise<void>;
  updateApartmentAccess: (
    accessId: number,
    accessType: string,
    steps: Array<{
      position: number;
      type: string;
      value: string;
    }>,
  ) => Promise<void>;
  sendRenewalEmail: (
    startDate: moment.Moment,
    endDate: moment.Moment,
    apartmentId: number,
  ) => Promise<void>;
  fetchHostRentsWithBills: (apartmentId: number) => Promise<void>;
  generateAuthorizationDocument: (leavingId: number) => Promise<Leaving>;
  generateMandate: (leavingId: number) => Promise<Leaving>;
  fetchModalityByLeavingId: (leavingId: number) => Promise<Modality>;
  updateModality: (id: number, data: Partial<Modality>) => Promise<Modality>;
  fetchTermsPricing: () => void;
  fetchCleaningDurationByArea: (area: number) => Promise<CleaningDuration>;
}

export const AppContext = React.createContext<AppContextInterface>({
  state: initialState(),
  resetState: defaultFunctionParameter,
  setToken: defaultFunctionParameter,
  authenticate: defaultFunctionParameter,
  login: defaultFunctionParameter,
  logout: defaultFunctionParameter,
  createNewPassword: Promise.reject,
  fetchReferences: defaultFunctionParameter,
  fetchContact: defaultFunctionParameter,
  fetchApartment: defaultFunctionParameter,
  setCurrentApartment: defaultFunctionParameter,
  setCurrentLeaving: defaultFunctionParameter,
  updateLeaving: Promise.reject,
  updateApartment: Promise.reject,
  updateContact: Promise.reject,
  createDocument: Promise.reject,
  removeDocument: defaultFunctionParameter,
  updateProspectStatus: defaultFunctionParameter,
  fetchNeighborhoods: defaultFunctionParameter,
  receivedKeySetup: defaultFunctionParameter,
  notReceivedKeySetup: defaultFunctionParameter,
  updateProfilePicture: defaultFunctionParameter,
  createPicthouseOrder: Promise.reject,
  fetchPhotoContractDates: Promise.reject,
  fetchPhotoContractSlots: Promise.reject,
  endGamification: defaultFunctionParameter,
  fetchEvents: defaultFunctionParameter,
  postApartmentKeywords: Promise.reject,
  removeApartmentPicture: Promise.reject,
  addApartmentPictures: Promise.reject,
  forgotPassword: Promise.reject,
  createRooms: defaultFunctionParameter,
  fetchRentEstimation: Promise.reject,
  sendSmsDirectives: Promise.reject,
  createLeavingInventory: Promise.reject,
  fetchInventoryUrl: Promise.reject,
  fetchApartmentAccessSteps: Promise.reject,
  createApartmentAccess: Promise.reject,
  updateApartmentAccess: Promise.reject,
  sendRenewalEmail: Promise.reject,
  fetchHostRentsWithBills: Promise.reject,
  generateAuthorizationDocument: Promise.reject,
  generateMandate: Promise.reject,
  fetchModalityByLeavingId: Promise.reject,
  updateModality: Promise.reject,
  fetchTermsPricing: defaultFunctionParameter,
  fetchCleaningDurationByArea: Promise.reject,
});

export const AppProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = React.useReducer(AppReducer, initialState());
  const { addEntities, addReferences, resetState } = useEntitiesContext();

  const setConnected = useCallback((connected: boolean) => {
    dispatch({ type: ActionTypes.SET_CONNECTED, payload: connected });
  }, []);

  const setToken = useCallback((value: string) => {
    localStorage.setItem(LocalStorageKey.TOKEN, value);
    dispatch({ type: ActionTypes.SET_TOKEN, payload: value });
  }, []);

  const authenticate = useCallback(async () => {
    try {
      await getAuthenticate(state.token);

      setConnected(true);
    } catch (error) {
      setConnected(false);
      setToken('');
    }
  }, [state.token]);

  const login = useCallback(async (email: string, password: string) => {
    const loggedContact = await postLogin(email, password);
    setToken(loggedContact.token);
    addEntities(contact, loggedContact);
  }, []);

  const logout = useCallback(async () => {
    setToken('');
    setConnected(false);
    await postLogout(state.token);
    memoResetState();
    resetState();
  }, [state.token]);

  const createNewPassword = useCallback(
    async (password: string): Promise<any> => {
      const loggedContact = await postNewPassword(password, state.token);
      addEntities(contact, loggedContact);
    },
    [state.token],
  );

  const setCurrentApartment = useCallback((value: number) => {
    localStorage.setItem(LocalStorageKey.APARTMENT, String(value));

    dispatch({ type: ActionTypes.SET_CURRENT_APARTMENT, payload: value });
  }, []);

  const setCurrentLeaving = useCallback((value: number) => {
    localStorage.setItem(LocalStorageKey.LEAVING, String(value));

    dispatch({ type: ActionTypes.SET_CURRENT_LEAVING, payload: value });
  }, []);

  const fetchReferences = useCallback(async () => {
    const references = await getReferences(state.token);
    addReferences(references);
  }, [state.token]);

  const fetchContact = useCallback(
    async (apartmentId?: number) => {
      const profile = await getContact(state.token);

      const currentLeaving = state.currentLeaving;

      addEntities(contact, profile);

      if (apartmentId || state.currentApartment) {
        await fetchApartment(apartmentId || state.currentApartment);
        await fetchHostRentsWithBills(apartmentId || state.currentApartment);

        if (currentLeaving) {
          setCurrentLeaving(currentLeaving);
        }
      } else {
        await fetchApartment(profile.apartments[0].id);
        await fetchHostRentsWithBills(profile.apartments[0].id);
      }

      dispatch({ type: ActionTypes.SET_CONTACT, payload: profile.id });
    },
    [state.token, state.currentApartment, state.currentLeaving],
  );

  const fetchApartment = useCallback(
    async (id: number) => {
      const fetchedApartment = await getApartment(state.token, id);

      const currentLeaving = getDefaultCurrentLeaving(
        fetchedApartment.leavings,
      );

      addEntities(apartment, fetchedApartment);
      setCurrentApartment(fetchedApartment.id);
      setCurrentLeaving(currentLeaving.id);
    },
    [state.token],
  );

  const createDocument = useCallback(
    async (name: string, formData: FormData) => {
      const uploadedEntity = await postDocument(state.token, name, formData, {
        apartment: state.currentApartment as number,
        contact: state.contact,
        leaving: state.currentLeaving,
      });

      switch (name) {
        case DOCUMENT.AUTHORIZATION:
        case DOCUMENT.DEPARTURE:
          addEntities(leavings, uploadedEntity);
          break;
        case DOCUMENT.PROOF:
        case DOCUMENT.RECEIPTS:
        case DOCUMENT.USAGE:
          addEntities(apartment, uploadedEntity);
          break;
        case DOCUMENT.ID:
        case DOCUMENT.RIB:
          addEntities(contact, uploadedEntity);
          break;
      }
    },
    [state.token, state.currentApartment, state.currentLeaving, state.contact],
  );

  const removeDocument = useCallback(
    async (name: string, documentId: number) => {
      const deletedEntity = await deleteDocument(
        state.token,
        name,
        documentId,
        {
          apartment: state.currentApartment as number,
          contact: state.contact,
          leaving: state.currentLeaving,
        },
      );

      switch (name) {
        case DOCUMENT.AUTHORIZATION:
        case DOCUMENT.DEPARTURE:
          addEntities(leavings, deletedEntity);
          break;
        case DOCUMENT.PROOF:
        case DOCUMENT.RECEIPTS:
        case DOCUMENT.USAGE:
          addEntities(apartment, deletedEntity);
          break;
        case DOCUMENT.ID:
        case DOCUMENT.RIB:
          addEntities(contact, deletedEntity);
          break;
      }
    },
    [state.token, state.currentApartment, state.currentLeaving, state.contact],
  );

  const updateProspectStatus = useCallback(
    async (id: number, status: string, data?: Record<string, any>) => {
      const patchedProspect = await patchProspectStatus(
        state.token,
        id,
        status,
        data,
      );
      addEntities(prospect, patchedProspect);
    },
    [state.token],
  );

  const fetchNeighborhoods = useCallback(
    async (agencyId?: number) => {
      const neighborhoods = await getNeighborhoods(state.token, agencyId);
      addEntities([neighborhood], neighborhoods);
    },
    [state.token],
  );

  const updateLeaving = useCallback(
    async (id: number, data: Partial<Leaving>) => {
      const patchedLeaving = await patchLeaving(state.token, id, data);
      addEntities(leavings, patchedLeaving);
    },
    [state.token],
  );

  const updateApartment = useCallback(
    async (id: number, data: Partial<Apartment>) => {
      const patchedApartment = await patchApartment(state.token, id, data);

      return addEntities(apartment, patchedApartment);
    },
    [state.token],
  );

  const updateContact = useCallback(
    async (id: number, data: Partial<Contact>): Promise<any> => {
      const patchedContact = await patchContact(state.token, id, data);

      return addEntities(contact, patchedContact);
    },
    [state.token],
  );

  const receivedKeySetup = useCallback(
    async (id: number) => {
      const postKeySetup = await postReceivedKeySetup(state.token, id);
      addEntities(keySetup, postKeySetup);
    },
    [state.token],
  );

  const notReceivedKeySetup = useCallback(
    async (id: number) => {
      const postKeySetup = await postNotReceivedKeySetup(state.token, id);
      addEntities(keySetup, postKeySetup);
    },
    [state.token],
  );

  const updateProfilePicture = useCallback(
    async (file: FormData) => {
      const postPicture = await patchProfilePicture(state.token, file);

      addEntities(user, postPicture);
    },
    [state.token],
  );

  const createPicthouseOrder = useCallback(
    async (id: number, date: moment.Moment) => {
      await postPicthouseOrder(state.token, id, date);

      const patchedPhotoContract = await getPhotoContract(state.token, id);

      addEntities(photoContract, patchedPhotoContract);

      return patchedPhotoContract;
    },
    [state.token],
  );

  const fetchPhotoContractDates = useCallback(
    async (id: number) => {
      const avalabilities = await getPicthouseAvailabilities(state.token, id);

      return avalabilities;
    },
    [state.token],
  );

  const fetchPhotoContractSlots = useCallback(
    async (id: number, date: moment.Moment, cityId: number, packId: number) => {
      const avalabilities = await getPicthouseSlots(
        state.token,
        id,
        date,
        cityId,
        packId,
      );

      return avalabilities;
    },
    [state.token],
  );

  const endGamification = useCallback(
    async (id: number) => {
      const patchedProspect = await postGamification(state.token, id);

      addEntities(prospect, patchedProspect);
    },
    [state.token],
  );

  const fetchEvents = useCallback(
    async (id: number) => {
      const eventActivities = await getEvents(state.token, id);

      addEntities([eventActivity], eventActivities);
    },
    [state.token],
  );

  const postApartmentKeywords = useCallback(
    async (id: number, data: number[]) => {
      const patchedApartment = await postKeywords(state.token, id, data);
      addEntities(apartment, patchedApartment);
    },
    [state.token],
  );

  const addApartmentPictures = useCallback(
    async (id: number, files: FormData) => {
      const patchedApartment = await postApartmentPictures(
        state.token,
        id,
        files,
      );
      addEntities(apartment, patchedApartment);

      return patchedApartment;
    },
    [state.token],
  );

  const removeApartmentPicture = useCallback(
    async (id: number, documentId: number) => {
      const patchedApartment = await deleteApartmentPicture(
        state.token,
        id,
        documentId,
      );

      addEntities(apartment, patchedApartment);

      return patchedApartment;
    },
    [state.token],
  );

  const createRooms = useCallback(
    async (id: number, rooms: RoomCreation[]) => {
      const patchedApartment = await postRooms(state.token, id, rooms);
      if (patchedApartment.equipments) {
        patchedApartment.equipments = [];
      }
      addEntities(apartment, patchedApartment);
    },
    [state.token],
  );

  const memoForgotPassword = async (email: string) => {
    await forgotPassword(email);
  };

  const memoResetState = useCallback(() => {
    localStorage.clear();
    dispatch({ type: ActionTypes.RESET_STATE });
  }, []);

  const fetchRentEstimation = useCallback(
    (apartmentId: number) => {
      return getRentEstimation(state.token, apartmentId);
    },
    [state.token],
  );

  const createLeavingInventory = useCallback(
    async (leavingId: number, type: string) => {
      const postInventory = await postLeavingInventory(
        state.token,
        leavingId,
        type,
      );

      addEntities(inventory, postInventory);

      return postInventory;
    },
    [state.token],
  );

  const fetchInventoryUrl = useCallback(
    async (leavingId: number) => {
      return getInventoryUrl(state.token, leavingId);
    },
    [state.token],
  );

  const memoSendSmsDirectives = useCallback(
    async (apartmentId: number, processType: 'setup' | 'unsetup') => {
      await sendSmsDirectives(state.token, { apartmentId, processType });
    },
    [],
  );

  const fetchApartmentAccessSteps = useCallback(
    async (accessId: number) => {
      return getApartmentAccessSteps(state.token, accessId);
    },
    [state.token],
  );

  const createApartmentAccess = useCallback(
    async (
      apartmentId: number,
      accessType: string,
      steps: Array<{
        position: number;
        type: string;
        value: string;
      }>,
    ) => {
      const patchedApartment = await postApartmentAccess(
        state.token,
        apartmentId,
        accessType,
        steps,
      );

      addEntities(apartment, patchedApartment);
    },
    [state.token],
  );

  const updateApartmentAccess = useCallback(
    async (
      accessId: number,
      accessType: string,
      steps: Array<{
        position: number;
        type: string;
        value: string;
      }>,
    ) => {
      const updatedAccess = await patchApartmentAccess(
        state.token,
        accessId,
        accessType,
        steps,
      );

      addEntities(access, updatedAccess);
    },
    [state.token],
  );

  const fetchHostRentsWithBills = useCallback(
    async (apartmentId: number) => {
      const hostRents = await getHostRentsWithBills(state.token, apartmentId);

      addEntities([hostRent], hostRents);
    },
    [state.token],
  );

  const memoSendRenewalEmail = async (
    startDate: moment.Moment,
    endDate: moment.Moment,
    apartmentId: number,
  ) => {
    return sendNotificationRenewal(state.token, {
      startDate,
      endDate,
      apartmentId,
    });
  };

  const generateAuthorizationDocument = useCallback(
    async (leavingId: number) => {
      const leaving = await generateAuthorizationTenantDocument(
        state.token,
        leavingId,
      );

      addEntities(leavings, leaving);

      return leaving;
    },
    [state.token],
  );

  const generateMandate = useCallback(
    async (leavingId: number) => {
      const leaving = await generateMandateForLeaving(state.token, leavingId);

      addEntities(leavings, leaving);

      await fetchApartment(leaving.apartmentId);

      return leaving;
    },
    [state.token],
  );

  const fetchModalityByLeavingId = useCallback(
    async (leavingId: number) => {
      return getModality(state.token, leavingId);
    },
    [state.token],
  );

  const updateModality = useCallback(
    async (id: number, data: Partial<Modality>) => {
      return patchModality(state.token, id, data);
    },
    [state.token],
  );

  const fetchTermsPricing = useCallback(async () => {
    const terms = await getTermsPricing(state.token);
    addEntities([termPricing], terms);
  }, [state.token]);

  const fetchCleaningDurationByArea = useCallback(
    async (area: number) => {
      return getCleaningDurationByArea(state.token, area);
    },
    [state.token],
  );

  const value = useMemo(() => {
    return {
      state,
      setToken,
      authenticate,
      login,
      logout,
      createNewPassword,
      fetchReferences,
      fetchContact,
      fetchApartment,
      setCurrentApartment,
      setCurrentLeaving,
      updateLeaving,
      updateApartment,
      updateContact,
      createDocument,
      removeDocument,
      updateProspectStatus,
      fetchNeighborhoods,
      receivedKeySetup,
      notReceivedKeySetup,
      updateProfilePicture,
      createPicthouseOrder,
      endGamification,
      fetchEvents,
      postApartmentKeywords,
      addApartmentPictures,
      removeApartmentPicture,
      createRooms,
      forgotPassword: memoForgotPassword,
      fetchPhotoContractDates,
      fetchPhotoContractSlots,
      resetState: memoResetState,
      fetchRentEstimation,
      sendSmsDirectives: memoSendSmsDirectives,
      createLeavingInventory,
      fetchInventoryUrl,
      fetchApartmentAccessSteps,
      createApartmentAccess,
      updateApartmentAccess,
      sendRenewalEmail: memoSendRenewalEmail,
      fetchHostRentsWithBills,
      generateAuthorizationDocument,
      generateMandate,
      fetchModalityByLeavingId,
      updateModality,
      fetchTermsPricing,
      fetchCleaningDurationByArea,
    };
  }, [state]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  return React.useContext(AppContext);
};

export default AppContext;

import { Box, Button, Grid, Paper, Typography } from '@mui/material';

import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleXmark,
  faEnvelope,
  faPhone,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslationFormat } from '@hooks/useTranslationFormat/useTranslationFormat';

export default function CanceledLeaving() {
  const { t } = useTranslationFormat();

  return (
    <Box
      component={Paper}
      elevation={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding: 5,
        m: 1,
      }}
    >
      <Box
        component={FontAwesomeIcon}
        icon={faCircleXmark as IconProp}
        color="primary.main"
        size="4x"
      />
      <Typography
        color="black.lighter"
        variant="subtitle1"
        fontWeight="700"
        mt={1}
      >
        {t('canceledLeaving.title')}
      </Typography>
      <Typography
        variant="subtitle2"
        fontSize={{ xs: 14 }}
        color="black.light"
        pb={4}
        align="center"
        px={5}
      >
        {t('canceledLeaving.subtitle')}
      </Typography>
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid item xs={6}>
          <Button
            size="small"
            variant="contained"
            fullWidth
            color="secondary"
            startIcon={
              <Box component={FontAwesomeIcon} icon={faEnvelope as IconProp} />
            }
            href="mailto:customer@smart-renting.com"
          >
            {t('dashboard.sidebar.contact.message')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            size="small"
            fullWidth
            variant="outlined"
            color="secondary"
            startIcon={
              <Box component={FontAwesomeIcon} icon={faPhone as IconProp} />
            }
            href="tel:+33755536436"
          >
            <Typography variant="caption">
              07&nbsp;55&nbsp;53&nbsp;64&nbsp;36
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

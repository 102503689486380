import React from 'react';
import { useTranslation } from 'react-i18next';

import { SCard, SText } from '@smartrenting/smartomic';

import toRecontactSvg from '../../../assets/toRecontact.svg';

import './ToRecontact.scss';

export default function ToRecontact() {
  const { t } = useTranslation();

  return (
    <div className="ToRecontact__content">
      <SCard>
        <SText align="center" variant="subtitle">
          <strong>{t('dashboard.to-recontact.title')}</strong>
        </SText>
        <SText align="center" variant="body-subtitle">
          {t('dashboard.to-recontact.subtitle')}
        </SText>
        <img src={toRecontactSvg} alt="toRecontact-svg" />
      </SCard>
    </div>
  );
}

const GoogleTagManager = {
  tags: function (gtmId: string) {
    if (!gtmId) console.warn('[react-gtm]', 'GTM Id is required');

    const iframe = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
        height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;

    const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');`;

    return {
      iframe,
      script,
    };
  },

  gtm: function (gtmId: string) {
    const snippets = this.tags(gtmId);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;

      return noscript;
    };

    const script = () => {
      const script = document.createElement('script');
      script.innerHTML = snippets.script;

      return script;
    };

    return {
      noScript,
      script,
    };
  },
  initialize: function (gtmId: string) {
    const gtm = this.gtm(gtmId);
    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
};

export default GoogleTagManager;

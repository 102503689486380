import { Alert, AlertTitle, Box } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useLeaving } from '@hooks/useLeaving/useLeaving';

import InReviewScheduledSvg from './InReviewScheduled.svg';

import './InReviewScheduled.scss';

export default function InReviewScheduled() {
  const { t } = useTranslation();
  const leaving = useLeaving();

  return (
    <div className="InReviewScheduled">
      <Alert
        severity="success"
        icon={
          <Box
            component={FontAwesomeIcon}
            icon={faCircleCheck as any}
            size="1x"
            my="auto"
          />
        }
        sx={{ width: '100%' }}
      >
        <AlertTitle color="success.main">
          {t('dashboard.inventory.inReviewScheduled.success.title')}
        </AlertTitle>
        {t('dashboard.inventory.inReviewScheduled.success.subtitle', {
          date: leaving.expectedInventoryInReviewDate?.format('LL [à] k[h]mm'),
        })}
      </Alert>
      <img src={InReviewScheduledSvg} alt="in-review-scheduled" />
    </div>
  );
}

import { useMemo } from 'react';

import moment from 'moment-timezone';

import {
  INVENTORY_TYPE,
  LEAVING_STATUS,
  PROSPECT_STATUS,
  RESIDENCE_TYPE,
} from '@enum';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';
import { useProspect } from '@hooks/useProspect/useProspect';

export enum ProspectSetupStep {
  phone,
  hidePhone,
  visit,
  documents,
  mandate,
  photoShoot,
  inventory,
  keys,
  toRecontact,
  rejected,
}

export const useSetupStep = () => {
  const prospect = useProspect();
  const apartment = useApartment();
  const leaving = useLeaving();

  const displayPhoneCalendly = useMemo(() => {
    if (leaving.startDate.isBefore(moment('2024-03-31'))) {
      return false;
    }

    if (apartment.residenceType === RESIDENCE_TYPE.SECONDARY) {
      return false;
    }

    return true;
  }, [leaving]);

  switch (prospect.status) {
    case PROSPECT_STATUS.WAIT_FIRST_CALL:
    case PROSPECT_STATUS.NEW: {
      return displayPhoneCalendly
        ? ProspectSetupStep.phone
        : ProspectSetupStep.hidePhone;
    }
    case PROSPECT_STATUS.PHONE_CALL:
      return ProspectSetupStep.phone;
    case PROSPECT_STATUS.WAIT_VISIT: {
      return ProspectSetupStep.visit;
    }
    case PROSPECT_STATUS.WAIT_DOCUMENTS:
    case PROSPECT_STATUS.MANDATE_TO_SENT:
    case PROSPECT_STATUS.SALE_TO_VALIDATE:
    case PROSPECT_STATUS.VISIT_SCHEDULED: {
      return ProspectSetupStep.documents;
    }
    case PROSPECT_STATUS.CLIENT_TO_VALIDATE:
    case PROSPECT_STATUS.EXPIRED:
    case PROSPECT_STATUS.TO_SIGN: {
      return ProspectSetupStep.mandate;
    }
    case PROSPECT_STATUS.TO_RECONTACT: {
      return ProspectSetupStep.toRecontact;
    }
    case PROSPECT_STATUS.REJECTED: {
      return ProspectSetupStep.rejected;
    }

    case PROSPECT_STATUS.SIGNED: {
      if (leaving.status === LEAVING_STATUS.INIT || leaving.isExpired()) {
        return ProspectSetupStep.mandate;
      }

      if (
        !apartment.photoContracts.find((photoContract) =>
          photoContract.isScheduled(),
        ) &&
        !apartment.isRenewal(leaving.id)
      ) {
        return ProspectSetupStep.photoShoot;
      }

      const inventoryInReview = leaving.findInventoryByType(
        INVENTORY_TYPE.IN_REVIEW,
      );

      if (!inventoryInReview?.isDone()) {
        return ProspectSetupStep.inventory;
      }

      return ProspectSetupStep.keys;
    }
  }
};

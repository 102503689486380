import TermPricing from '@models/TermPricing';

import { fetchApi } from './fetchApi';

export async function getTermsPricing(token: string) {
  return fetchApi<Array<TermPricing>>(
    'GET',
    '/modalities/terms-pricing',
    token,
  );
}

import { LoadingButton } from '@mui/lab';
import { Box, Button, MobileStepper } from '@mui/material';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimer } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modality from '@models/Modality';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useLeaving } from '@hooks/useLeaving/useLeaving';

import background from '../../background.svg';

import StepCleaning from './Steps/StepCleaning';
import StepInfo from './Steps/StepInfo';
import StepInsurance from './Steps/StepInsurance';
import StepKeys from './Steps/StepKeys';
import StepPayment from './Steps/StepPayment';
import StepRecap from './Steps/StepRecap';

export default function BoardOffice() {
  const { t } = useTranslation();
  const { fetchModalityByLeavingId, updateModality, generateMandate } =
    useAppContext();

  const leaving = useLeaving();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [displayStepper, setDisplayStepper] = useState(false);

  const [modality, setModality] = useState<Modality | null>(null);
  const [choices, setChoices] = useState({});

  const handleNext = useCallback(async () => {
    if (modality) {
      setModality(await updateModality(modality.id, choices));
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [modality, choices]);

  const onChoiceChange = (data: Record<string, any>) => {
    setChoices(data);
  };

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const handleReset = useCallback(() => {
    setDisplayStepper(false);
  }, []);

  const handleValidation = useCallback(async () => {
    setLoading(true);
    await generateMandate(leaving.id);
    setLoading(false);
  }, [leaving.id]);

  const steps = useMemo(() => {
    const tempSteps = [];

    if (!leaving.commission || !leaving?.commission.fullterm) {
      tempSteps.push(StepPayment);
    }

    tempSteps.push(StepInsurance, StepCleaning, StepKeys, StepRecap);

    return tempSteps;
  }, [leaving]);

  useEffect(() => {
    fetchModalityByLeavingId(leaving.id).then((modality: Modality) => {
      if (modality) {
        setModality(modality);
      }
    });
  }, [leaving.id]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: { xs: '100%', sm: '90%', md: '70%' },
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      {displayStepper ? (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: { xs: '1rem', sm: '2rem' },
              pt: {
                xs: 0,
                sm: '1rem',
              },
              backgrounSize: 'contain',
            }}
          >
            {React.createElement(steps[activeStep], {
              onChoiceChange,
              modality,
            })}
          </Box>
          <Box>
            <MobileStepper
              variant="progress"
              steps={steps.length}
              position="static"
              activeStep={activeStep}
              backButton={null}
              nextButton={null}
              sx={{ flexGrow: 1, padding: 0 }}
              LinearProgressProps={{
                sx: {
                  width: '100%',
                },
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: '1rem',
              }}
            >
              <Button
                id={`back-${steps[activeStep].name}`}
                size="small"
                variant="outlined"
                onClick={activeStep === 0 ? handleReset : handleBack}
              >
                {t('boardOffice.back')}
              </Button>
              <LoadingButton
                id={`next-${steps[activeStep].name}`}
                size="small"
                variant="contained"
                loading={loading}
                loadingPosition="start"
                startIcon={
                  // loadingIcon take the place of startIcon, without it will render hover the text so we set a start icon only on loading
                  loading ? (
                    <Box
                      component={FontAwesomeIcon}
                      icon={faTimer as IconProp}
                    />
                  ) : null
                }
                onClick={
                  activeStep === steps.length - 1
                    ? handleValidation
                    : handleNext
                }
              >
                <span>
                  {activeStep === steps.length - 1
                    ? t('boardOffice.validate')
                    : t('boardOffice.next')}
                </span>
              </LoadingButton>
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundImage: `url(${background})`,
            backgrounSize: 'contain',
          }}
        >
          <StepInfo modality={modality} />
          <Button
            id="start-board-office"
            variant="contained"
            size="small"
            onClick={() => setDisplayStepper(true)}
            sx={{
              width: 'fit-content',
            }}
          >
            {t('boardOffice.steps.info.action')}
          </Button>
        </Box>
      )}
    </Box>
  );
}

export const hexToRgba = (hex: string, opacity: number) => {
  let array: string[];
  let src: number;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    array = hex.substring(1).split('');
    if (array.length == 3) {
      array = [array[0], array[0], array[1], array[1], array[2], array[2]];
    }
    src = ('0x' + array.join('')) as unknown as number;

    return (
      'rgba(' +
      [(src >> 16) & 255, (src >> 8) & 255, src & 255].join(',') +
      ',' +
      opacity / 100 +
      ')'
    );
  }
  throw new Error('Bad Hex');
};

import { Box, SxProps } from '@mui/material';
import { MyTheme } from '@styles/theme';

import * as React from 'react';

export const PriceSvg: React.FC<{
  sx?: SxProps<MyTheme>;
  height?: number | string | object;
  width: number | string | { xs: number | string; sm: number | string };
}> = ({ sx, width }) => {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      // height="88"
      viewBox="0 0 77 88"
      fill="none"
    >
      <Box
        component="path"
        d="M77 0H0V56.2242C0 58.1164 0.892648 59.8978 2.40845 61.0305L34.9085 85.3162C37.0383 86.9078 39.9617 86.9078 42.0915 85.3162L74.5915 61.0305C76.1074 59.8978 77 58.1164 77 56.2242V0Z"
        sx={sx ? sx : { fill: '#4090F2' }}
      />
    </Box>
  );
};

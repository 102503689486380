import { hostRent } from '@schemas';

import { denormalize } from 'normalizr';

import { HostRent } from '@models/HostRent';

import { useEntities } from '@contexts/EntitiesContext/EntitiesContext';

export function useHostRents(): HostRent[] {
  const entities = useEntities();

  const hostRents = denormalize(
    Object.keys(entities.hostRents),
    [hostRent],
    entities,
  );

  return hostRents.map((hostRent: HostRent) => new HostRent(hostRent));
}

import { Box, Stack, Typography } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

import NoPaymentSvg from './assests/noPayment.svg';

export function NoPaymentView() {
  const { t } = useTranslation();

  return (
    <Stack
      width="100%"
      sx={{ overflowX: { xs: 'auto', sm: 'hidden' } }}
      padding={2}
      pl={{ lg: 34 }}
      maxHeight={1}
    >
      <Typography variant="h5">{t('payments.title')}</Typography>
      <Typography mb={2.5}>{t('payments.subtitle')}</Typography>
      <Stack
        p={{ xs: 2.5, sm: 3 }}
        mr={{ xs: 0, sm: 10 }}
        width="100%"
        boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.15)"
        overflow={{ xs: 'visible', sm: 'auto' }}
        sx={{
          position: 'relative',
          borderRadius: 5,
          bgcolor: 'white',
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            component="img"
            sx={{
              zoom: { xs: 1, sm: 1.5 },
            }}
            py={2}
            src={NoPaymentSvg}
            alt="noPayment"
          />
          <Stack sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" pb={1} align="center">
              {t('payments.noPayment.title')}
            </Typography>
            <Typography align="center" maxWidth={{ sm: 0.5, xs: 0.8 }}>
              {t('payments.noPayment.subtitle')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

import React from 'react';

import outValidationToScheduleSvg from './OutValidationToSchedule.svg';

export const OutValidationToSchedule = () => {
  return (
    <div>
      <img src={outValidationToScheduleSvg} alt="fireworks" />
    </div>
  );
};

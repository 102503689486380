import Contact from '@models/Contact';

import { fetchApi } from './fetchApi';

export async function patchContact(
  token: string,
  id: number,
  data: Partial<Contact>,
) {
  return fetchApi<Contact>('PATCH', `/contacts/${id}`, token, {
    body: data,
  });
}

export async function getContact(token: string) {
  return fetchApi<Contact>('GET', '/contacts/profile', token);
}

import moment from 'moment-timezone';

export default class Prospect {
  id!: number;
  status!: string;
  gamification = false;

  phoneCallDate!: moment.Moment;
  visitDate!: moment.Moment;
  visitType!: moment.Moment;

  constructor(data: Record<string, any>) {
    Object.assign(this, data);

    this.phoneCallDate = data.phoneCallDate && moment(data.phoneCallDate);
    this.visitDate = data.visitDate && moment(data.visitDate);
  }
}

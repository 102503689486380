import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFolder } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LEAVING_STATUS } from '@enum';

import Leaving from '@models/Leaving';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useHostRents } from '@hooks/useHostRents/useHostRents';

import { NoPaymentView } from './NoPaymentView';
import { AccountabilityTableRow } from './components/AccountabilityTableRow';
import { BillTableRow } from './components/BillTableRow';
import { FolderTableRow } from './components/FolderTableRow';

export default function Payments() {
  const { t } = useTranslation();
  const apartment = useApartment();
  const leavings = useMemo(() => {
    return apartment.leavings
      .filter((l) => l.status === LEAVING_STATUS.SIGNED)
      .sort((leavingA, leavingB) =>
        leavingA.startDate.isBefore(leavingB.startDate) ? 1 : -1,
      );
  }, [apartment.leavings]);

  const [leavingSelected, setLeavingSelected] = useState<Leaving | null>(null);
  const [parentsIds, setParentsIds] = useState<number[]>([]);
  const hostRents = useHostRents();

  if (!hostRents.length) {
    return <NoPaymentView />;
  }

  const findParentLeaving = (leaving: Leaving): any => {
    if (leaving.parentId) {
      setParentsIds((prevState) => [...prevState, leaving.parentId]);

      return findParentLeaving(
        apartment.leavings.find((l) => l.id === leaving.parentId) as any,
      );
    }
  };

  const title = leavingSelected
    ? t('payments.leavingDates', {
        startdate: leavingSelected.startDate.format('L'),
        enddate: leavingSelected.endDate.format('L'),
      })
    : t('payments.details');

  const handlePrev = () => {
    if (leavingSelected) {
      setLeavingSelected(null);
      setParentsIds([]);
    }
  };

  useEffect(() => {
    if (leavingSelected) {
      findParentLeaving(leavingSelected);
    }
  }, [leavingSelected]);

  return (
    <Stack
      width="100%"
      sx={{
        overflowX: { xs: 'auto', sm: 'hidden' },
        '& .MuiTableCell-root': {
          '@media (max-width: 600px)': {
            paddingX: 1,
            paddingY: 1.5,
          },
        },
      }}
      padding={2}
      pl={{ lg: 34 }}
      height="100%"
    >
      <Typography variant="h5">{t('payments.title')}</Typography>
      <Typography mb={2.5}>{t('payments.subtitle')}</Typography>
      <Stack
        p={{ xs: 2.5, sm: 3 }}
        mr={{ xs: 0, sm: 10 }}
        width={'100%'}
        boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.15)"
        overflow={{ xs: 'visible', sm: 'auto' }}
        sx={{
          position: 'relative',
          borderRadius: 5,
          bgcolor: 'white',
        }}
      >
        <Box
          component="div"
          display="flex"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={handlePrev}
        >
          <Box
            marginRight={1.5}
            component={FontAwesomeIcon}
            height={18}
            icon={(leavingSelected ? faChevronLeft : faFolder) as IconProp}
            color={leavingSelected ? '' : '#8fbdf6'}
          />

          <Typography
            color="grey.700"
            fontWeight={{ xs: 400, sm: 500 }}
            fontSize={16}
          >
            {title}
          </Typography>
        </Box>

        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {leavingSelected
                    ? t('payments.filename')
                    : t('dashboard.leavings.period')}
                </TableCell>
                {leavingSelected && (
                  <>
                    <TableCell align="center">
                      {t('payments.paymentDate')}
                    </TableCell>
                    <TableCell />
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!leavingSelected &&
                leavings.map((leaving) => (
                  <FolderTableRow
                    key={leaving.id}
                    leaving={leaving}
                    setLeaving={setLeavingSelected}
                  />
                ))}
              {leavingSelected && (
                <>
                  {leavingSelected.accountabilityId && (
                    <AccountabilityTableRow leaving={leavingSelected} />
                  )}
                  {hostRents
                    .filter((h) =>
                      [...parentsIds, leavingSelected.id].includes(h.leavingId),
                    )
                    .map((hostRent) => (
                      <BillTableRow key={hostRent.id} hostRent={hostRent} />
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
}

import maisonRoseBleu from './assets/maisons-rose-bleu.png';
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Stack,
  Typography,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { routes } from 'src/routes';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';

import {
  Waiting1,
  Waiting2,
  Waiting3,
  Waiting4,
  Waiting5,
  WaitingLeft,
  WaitingRight,
} from './components/WaitingSvg';
import WaveAnimation from './components/WaveAnimation';

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{ height: '10px', borderRadius: '66px' }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="subtitle2">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const getImageIndex = (progress: number) => {
  const ranges = [0, 20, 50, 80];
  for (let i = 0; i < ranges.length; i++) {
    if (progress >= ranges[i] && progress < ranges[i + 1]) {
      return i;
    }
  }

  return ranges.length - 1;
};

export default function WaitingSignature({
  type,
}: {
  type: 'mandate' | 'inventory';
}) {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const selectedApartment = useApartment();
  const { fetchApartment } = useAppContext();

  const pictures = [
    <Waiting1 key={1} />,
    <Waiting2 key={2} />,
    <Waiting3 key={3} />,
    <Waiting4 key={4} />,
  ];

  const imageIndex = getImageIndex(progress);

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress === 100) {
        fetchApartment(selectedApartment.id);
      }

      if (progress < 135) {
        setProgress((prevProgress) => prevProgress + 1);
      } else {
        clearInterval(timer);
        setProgress(0);
        navigate(routes.dashboard);
      }
    }, 120);

    return () => clearInterval(timer);
  }, [progress]);

  return (
    <>
      <Stack
        width="100%"
        direction="row"
        justifyContent="center"
        alignItems="center"
        height="100%"
        flexDirection="column"
      >
        {progress > 100 ? <Waiting5 /> : pictures[imageIndex]}
        {progress > 100 ? (
          <Typography marginTop="48px" variant="h5" marginRight="4px">
            {t(`waiting-signature.${type}.complete`)}
          </Typography>
        ) : (
          <>
            <Stack flexDirection="row" alignItems="flex-end">
              <Typography
                marginTop="48px"
                variant="subtitle1"
                marginRight="4px"
              >
                {t(`waiting-signature.${type}.waiting`)}
              </Typography>
              <div style={{ marginBottom: '2px' }}>
                <WaveAnimation />
              </div>
            </Stack>
            <Box sx={{ width: '50%', marginTop: '16px' }}>
              <LinearProgressWithLabel
                value={progress}
                valueBuffer={progress}
              />
            </Box>
          </>
        )}
      </Stack>
      <Box
        maxWidth="20%"
        component="img"
        src={maisonRoseBleu}
        position="absolute"
        top={0}
        right={0}
        sx={{
          zIndex: -1,
        }}
      />
      <Box
        position="absolute"
        height="100vh"
        width="100vw"
        top={0}
        left={0}
        zIndex={-1}
      >
        <Box position="relative" overflow="hidden" width="100%" height="100%">
          <Box
            display={{ xs: 'none', sm: 'initial' }}
            position="absolute"
            left={0}
            bottom={-5}
          >
            <WaitingLeft />
          </Box>
          <Box
            display={{ xs: 'none', sm: 'initial' }}
            position="absolute"
            right={0}
            bottom={-5}
          >
            <WaitingRight />
          </Box>
        </Box>
      </Box>
    </>
  );
}

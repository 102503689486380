import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

import { t } from 'i18next';
import moment from 'moment-timezone';

import { SFormValidator } from '@smartrenting/smartomic';

export type ApartmentKeyLocation = {
  address: string;
  id: number;
  name: string;
  distance: number;
  direction: string;
  openingHours: Array<{
    dayOfWeek: number;
    startMinuteOfDay: number;
    endMinuteOfDay: number;
  }>;
};
type GroupOpeningTime = {
  startMinuteOfDay: number;
  endMinuteOfDay: number;
  dayOfWeek: Array<number>;
};

export class Tools {
  static apartmentKeyLocationOpeningHoursToHumanFormat = (
    openingHours: ApartmentKeyLocation['openingHours'],
  ) => {
    const groupBySameOpeningTime = openingHours.reduce<any>(
      (acc: Array<GroupOpeningTime>, openingHour: any) => {
        const elemIndexWithSameSchedule = acc.findIndex(
          (element) =>
            element.endMinuteOfDay === openingHour.endMinuteOfDay &&
            element.startMinuteOfDay === openingHour.startMinuteOfDay,
        );

        if (elemIndexWithSameSchedule !== -1) {
          return acc.map<GroupOpeningTime>((elem, index) => {
            if (index === elemIndexWithSameSchedule) {
              return {
                ...elem,
                dayOfWeek: [...elem.dayOfWeek, openingHour.dayOfWeek].sort(),
              };
            }

            return elem;
          });
        }

        return [...acc, { ...openingHour, dayOfWeek: [openingHour.dayOfWeek] }];
      },
      [],
    );

    groupBySameOpeningTime.sort(
      (
        elemA: { dayOfWeek: Array<number> },
        elemB: { dayOfWeek: Array<number> },
      ) => {
        if (elemA.dayOfWeek.length > elemB.dayOfWeek.length) return -1;

        return 1;
      },
    );

    const areFollowingDays = (groupOpeningTime: GroupOpeningTime): boolean => {
      return (
        groupOpeningTime.dayOfWeek.length > 1 &&
        groupOpeningTime.dayOfWeek.reduce<boolean>(
          (acc: boolean, day: number, index: number) => {
            if (acc === false) return false;
            if (index === 0) return true;

            return (
              groupOpeningTime.dayOfWeek[index] ===
              groupOpeningTime.dayOfWeek[index - 1] + 1
            );
          },
          true,
        )
      );
    };

    const minutesToHumanFormat = (minutes: number) =>
      moment().startOf('day').add(minutes, 'minutes').format('HH:mm');

    const daysToHumanFormat = (days: number) =>
      moment().endOf('week').add(days, 'days').format('ddd').slice(0, -1);

    return groupBySameOpeningTime.map((groupOpeningTime: GroupOpeningTime) => {
      const sortedDayOfWeek = [...groupOpeningTime.dayOfWeek].sort(
        (dayOfWeek1, dayOfWeek2) => {
          if (dayOfWeek1 === 0 || dayOfWeek2 === 0) return -1;

          return 1;
        },
      );
      const openingHour = minutesToHumanFormat(
        groupOpeningTime.startMinuteOfDay,
      );
      const closingHour = minutesToHumanFormat(groupOpeningTime.endMinuteOfDay);
      const days = areFollowingDays(groupOpeningTime)
        ? `${daysToHumanFormat(sortedDayOfWeek[0])} - ${daysToHumanFormat(
            sortedDayOfWeek[sortedDayOfWeek.length - 1],
          )}`
        : sortedDayOfWeek
            .map((day: number) => daysToHumanFormat(day))
            .join(', ');

      return `${days} : ${openingHour} - ${closingHour} `;
    });
  };

  cleanString = (
    txt: string,
    removeSpaces = false,
    removeDashes = false,
    removeAmpersands = false,
  ) => {
    /*  eslint-disable */
    const accent = [
      /[\300-\306]/g,
      /[\340-\346]/g, // A, a
      /[\310-\313]/g,
      /[\350-\353]/g, // E, e
      /[\314-\317]/g,
      /[\354-\357]/g, // I, i
      /[\322-\330]/g,
      /[\362-\370]/g, // O, o
      /[\331-\334]/g,
      /[\371-\374]/g, // U, u
      /[\321]/g,
      /[\361]/g, // N, n
      /[\307]/g,
      /[\347]/g, // C, c
    ];

    const noaccent = [
      'A',
      'a',
      'E',
      'e',
      'I',
      'i',
      'O',
      'o',
      'U',
      'u',
      'N',
      'n',
      'C',
      'c',
    ];

    for (let i = 0; i < accent.length; i++)
      txt = txt.replace(accent[i], noaccent[i]);

    if (removeSpaces) txt = txt.replace(/\s+/g, '');
    if (removeDashes) txt = txt.replace(/-/g, '');
    if (removeAmpersands) txt = txt.replace(/&/g, '');
    /* eslint-enable */

    return txt.toLowerCase().trim();
  };
}

export const usePreventDefault = () => {
  return React.useCallback((e: Event) => e.preventDefault(), []);
};

export const toastPromise = (promise: Promise<any>, options?: ToastOptions) => {
  return toast
    .promise(
      promise,
      {
        pending: `${t('common.loading')}`,
        success: `${t('common.success')}`,
        error: {
          /* istanbul ignore next */
          render({ data }: { data?: any }) {
            return `${t(data.message)}`;
          },
        },
      },
      options,
    )
    .catch(() => null);
  // On promise throw toast promise will now throw
  // making our app to throw...
};

export function validatePasswordConfirmation(password: string, t: any): any {
  return (confirmation: string, _t: any): SFormValidator => {
    const errors = [];
    if (password !== confirmation) {
      errors.push(t('auth.errors.passwordsMismatch'));
    }

    return errors as any;
  };
}

export function mobileAndTabletCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substring(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);

  return check;
}

import {
  MenuList,
  MenuItem,
  ListItemIcon,
  Stack,
  styled,
  Typography,
  Divider,
  Box,
  Popover,
} from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { routes } from 'src/routes';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faTable,
  faHouseBlank,
  faFileLines,
  faGear,
  faGift,
  faEuroSign,
  faMessageLines,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { classNames } from '@utils/helpers';

import ContactCard from '@components/Layout/ContactCard/ContactCard';
import Logo from '@components/Logo/Logo';

import ApartmentSubMenu from './components/ApartmentSubMenu/ApartmentSubMenu';

const StyledMenuItem = styled(MenuItem)`
  margin: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledIcon = styled(FontAwesomeIcon)<{ selected?: boolean }>`
  color: ${({ theme, selected }) =>
    selected ? theme.palette.primary.main : theme.palette.grey[700]};
`;

export default function Navigation({
  className,
  location,
}: {
  className?: string;
  location: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLLIElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classNames('Navigation', className)}>
      <Stack
        justifyContent="space-between"
        bgcolor="white"
        height="100%"
        sx={{
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
        }}
      >
        <Box
          display={{ xs: 'none', lg: 'flex' }}
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo />
          <Divider variant="middle" />
        </Box>
        <Stack justifyContent="space-between" width={235} height="100%">
          <MenuList>
            <StyledMenuItem
              onClick={() => navigate(routes.dashboard)}
              selected={location.includes(routes.dashboard)}
            >
              <ListItemIcon>
                <StyledIcon
                  icon={faTable as IconProp}
                  selected={location.includes(routes.dashboard)}
                />
              </ListItemIcon>
              <Typography
                fontWeight={500}
                color={
                  location.includes(routes.dashboard) ? 'primary' : 'grey.700'
                }
              >
                {t(`navigation.routes.dashboard`)}
              </Typography>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => navigate(routes.apartments)}
              selected={location.includes(routes.apartments)}
            >
              <ListItemIcon>
                <StyledIcon
                  icon={faHouseBlank as IconProp}
                  selected={location.includes(routes.apartments)}
                />
              </ListItemIcon>
              <Typography
                fontWeight={500}
                color={
                  location.includes(routes.apartments) ? 'primary' : 'grey.700'
                }
              >
                {t(`navigation.routes.apartments`)}
              </Typography>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => navigate(routes.documents)}
              selected={location.includes(routes.documents)}
            >
              <ListItemIcon>
                <StyledIcon
                  icon={faFileLines as IconProp}
                  selected={location.includes(routes.documents)}
                />
              </ListItemIcon>
              <Typography
                fontWeight={500}
                color={
                  location.includes(routes.documents) ? 'primary' : 'grey.700'
                }
              >
                {t(`navigation.routes.documents`)}
              </Typography>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => navigate(routes.payment)}
              selected={location.includes(routes.payment)}
            >
              <ListItemIcon>
                <StyledIcon
                  icon={faEuroSign as IconProp}
                  selected={location.includes(routes.payment)}
                />
              </ListItemIcon>
              <Typography
                fontWeight={500}
                color={
                  location.includes(routes.payment) ? 'primary' : 'grey.700'
                }
              >
                {t(`navigation.routes.payment`)}
              </Typography>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => navigate(routes.referral)}
              selected={location.includes(routes.referral)}
            >
              <ListItemIcon>
                <StyledIcon
                  icon={faGift as IconProp}
                  selected={location.includes(routes.referral)}
                />
              </ListItemIcon>
              <Typography
                fontWeight={500}
                color={
                  location.includes(routes.referral) ? 'primary' : 'grey.700'
                }
              >
                {t(`navigation.routes.referral`)}
              </Typography>
            </StyledMenuItem>
          </MenuList>
          <MenuList>
            <StyledMenuItem onClick={handleClick}>
              <ListItemIcon>
                <StyledIcon icon={faMessageLines as IconProp} />
              </ListItemIcon>
              <Typography fontWeight={500} color="grey.700">
                {t(`navigation.routes.contactUs`)}
              </Typography>
            </StyledMenuItem>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <ContactCard />
            </Popover>
            <StyledMenuItem
              onClick={() => navigate(routes.settings)}
              selected={location.includes(routes.settings)}
            >
              <ListItemIcon>
                <StyledIcon
                  icon={faGear as IconProp}
                  selected={location.includes(routes.settings)}
                />
              </ListItemIcon>
              <Typography
                fontWeight={500}
                color={
                  location.includes(routes.settings) ? 'primary' : 'grey.700'
                }
              >
                {t(`navigation.routes.settings`)}
              </Typography>
            </StyledMenuItem>
          </MenuList>
        </Stack>
        <Divider variant="middle" />
        <ApartmentSubMenu />
      </Stack>
    </div>
  );
}

import React from 'react';

import svg from '../inventory.svg';

export default function InventoryWaitingOut() {
  return (
    <div>
      <img src={svg} alt="fireworks" />
    </div>
  );
}

import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { faImages } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { AIcon, AText } from '@smartrenting/smartomic';

import './FileUploader.scss';

export interface FileUploaderProps {
  disabled?: boolean;
  onUpload: (files: File[]) => void;
  nbFilesLeft: number;
}

export default function FileUploader({
  disabled,
  onUpload,
  nbFilesLeft,
}: FileUploaderProps) {
  const { t } = useTranslation();
  const onDrop = React.useCallback(
    (files: Array<File>) => {
      if (disabled) {
        return toast(t('gamification.photoStep.maxPictures'), {
          className: 'Toastify__toast--error',
          progressClassName: 'Toastify__progress-bar--error ',
        });
      }

      if (files.length > nbFilesLeft) {
        return toast(
          t('gamification.photoStep.maxPicturesError', { count: 15 }),
          {
            className: 'Toastify__toast--error',
            progressClassName: 'Toastify__progress-bar--error ',
          },
        );
      }

      onUpload(files);
    },
    [disabled, nbFilesLeft],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
      'application/pdf': [],
    },
  });

  return (
    <div
      {...(!disabled ? getRootProps() : {})}
      className={`FileUploader ${disabled ? 'FileUploader--disabled' : ''}`}
    >
      <div
        className={`FileUploader__drag-overlay FileUploader__drag-overlay${
          isDragActive ? '--active' : ''
        }`}
      >
        <input {...getInputProps()} className="FileUploader__input" />
        <AIcon className="FileUploader__icon" icon={faImages} height={40} />
        <div className="FileUploader__content">
          <AText variant="body-title" align="center">
            {t('gamification.photoStep.instructions.title', { count: 15 })}
          </AText>
          <AText align="center" className="FileUploader__content__instruction">
            {t('gamification.photoStep.instructions.subtitle')}
          </AText>
          <AText align="center" className="FileUploader__content__optional">
            {t('gamification.photoStep.instructions.optional')}
          </AText>
          <div className="FileUploader__content__info">
            <AIcon
              className="FileUploader__content__info__icon"
              icon={faInfoCircle}
              height={20}
            />
            <AText>{t('gamification.photoStep.instructions.info')}</AText>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Box, TableCell, TableRow } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFolder } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Leaving from '@models/Leaving';

export const FolderTableRow = ({
  leaving,
  setLeaving,
}: {
  leaving: Leaving;
  setLeaving: React.Dispatch<React.SetStateAction<Leaving | null>>;
}) => {
  const { t } = useTranslation();

  return (
    <TableRow
      key={leaving.id}
      sx={{
        cursor: 'pointer',
        '&:last-child td, &:last-child th': { border: 0 },
      }}
      hover
      onClick={() => setLeaving(leaving)}
    >
      <TableCell width="50%" scope="row">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            marginRight={1}
            component={FontAwesomeIcon}
            height={20}
            icon={faFolder as IconProp}
            color="#8fbdf6"
          />
          {t('payments.leavingDates', {
            startdate: leaving.startDate.format('L'),
            enddate: leaving.endDate.format('L'),
          })}
        </Box>
      </TableCell>
    </TableRow>
  );
};

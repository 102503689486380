import { DOCUMENT } from '@enum';

import Contact from '@models/Contact';
import User from '@models/User';

import { fetchApi } from './fetchApi';

export async function postLogin(
  email: string,
  password: string,
): Promise<Contact & { token: string }> {
  return fetchApi('POST', '/users/login', undefined, {
    body: {
      email,
      password,
    },
  });
}

export async function postLogout(
  token: string,
): Promise<Contact & { token: string }> {
  return fetchApi('POST', '/users/logout', token);
}

export async function getAuthenticate(
  token: string,
): Promise<Contact & { token: string }> {
  return fetchApi('GET', '/users/authenticated', token);
}

export async function postNewPassword(password: string, token: string) {
  return fetchApi('POST', '/users/new-password', token, {
    body: {
      password,
    },
  });
}

export const forgotPassword = (email: string): any => {
  return fetchApi('POST', '/users/forgot-password', undefined, {
    body: {
      email,
    },
  });
};

export async function postDocument(
  token: string,
  name: string,
  files: FormData,
  profile: {
    apartment: number;
    contact: number;
    leaving: number;
  },
) {
  const handlers = {
    [DOCUMENT.AUTHORIZATION]: `/leavings/${profile.leaving}/authorizationdocument`,
    [DOCUMENT.DEPARTURE]: `/leavings/${profile.leaving}/departure-document`,
    [DOCUMENT.ID]: `/contacts/${profile.contact}/idcard`,
    [DOCUMENT.PROOF]: `/apartments/${profile.apartment}/proofdocument`,
    [DOCUMENT.RECEIPTS]: `/apartments/${profile.apartment}/receipts`,
    [DOCUMENT.RIB]: `/contacts/${profile.contact}/rib`,
    [DOCUMENT.USAGE]: `/apartments/${profile.apartment}/usage-document`,
  };

  if (!(handlers as any)[name]) {
    throw new Error(`document ${name} has no handler`);
  }

  if ([DOCUMENT.ID, DOCUMENT.RIB].includes(name as any)) {
    files.append('apartmentId', String(profile.apartment));
  }

  return fetchApi('POST', handlers[name as keyof typeof handlers], token, {
    body: files,
  });
}

export async function deleteDocument(
  token: string,
  name: string,
  documentId: number,
  profile: {
    apartment: number;
    contact: number;
    leaving: number;
  },
) {
  const handlers = {
    [DOCUMENT.AUTHORIZATION]: `/leavings/${profile.leaving}/authorizationdocument`,
    [DOCUMENT.DEPARTURE]: `/leavings/${profile.leaving}/departure-document`,
    [DOCUMENT.ID]: `/contacts/${profile.contact}/idcard/${documentId}`,
    [DOCUMENT.PROOF]: `/apartments/${profile.apartment}/proofdocument`,
    [DOCUMENT.RECEIPTS]: `/apartments/${profile.apartment}/receipts/${documentId}`,
    [DOCUMENT.RIB]: `/contacts/${profile.contact}/rib`,
    [DOCUMENT.USAGE]: `/apartments/${profile.apartment}/usage-document`,
  };

  if (!(handlers as any)[name]) {
    throw new Error(`document ${name} has no handler`);
  }

  return fetchApi<any>(
    'DELETE',
    handlers[name as keyof typeof handlers],
    token,
  );
}

export async function patchProfilePicture(token: string, file: FormData) {
  return fetchApi('PATCH', '/users/me/profile-picture', token, {
    body: file,
  });
}

export async function patchMe(token: string, data: Partial<User>) {
  return fetchApi('PATCH', '/users/me/', token, {
    body: data,
  });
}

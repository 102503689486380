import { Box, TableCell } from '@mui/material';

import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCloudArrowDown } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDownload } from '@hooks/useDownload/useDownload';

interface TabCellDownloaderProps {
  documentId?: number;
  name?: string;
}

export function TabCellDownloader({
  documentId,
  name,
}: TabCellDownloaderProps) {
  const handleDownload =
    documentId && name ? useDownload(documentId, name) : () => null;

  return (
    <TableCell
      width="10%"
      align="center"
      onClick={handleDownload}
      sx={{ cursor: 'pointer' }}
    >
      <Box
        marginRight={1}
        component={FontAwesomeIcon}
        height={20}
        color="primary.main"
        icon={faCloudArrowDown as IconProp}
      />
    </TableCell>
  );
}

import { prospect } from '@schemas';

import { denormalize } from 'normalizr';

import Prospect from '@models/Prospect';

import { useEntities } from '@contexts/EntitiesContext/EntitiesContext';

import { useApartment } from '@hooks/useApartment/useApartment';

export function useProspect(): Prospect {
  const entities = useEntities();
  const apartment = useApartment();

  const selectedProspect = denormalize(
    apartment?.prospect?.id,
    prospect,
    entities,
  );

  return (
    apartment && selectedProspect && new Prospect(selectedProspect as Prospect)
  );
}

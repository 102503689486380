import React from 'react';
import { useTranslation } from 'react-i18next';

import { STitle } from '@smartrenting/smartomic';

import initSvg from '../../KeySetups/assets/init.svg';

import './KeysNotReady.scss';

export default function KeysNotReady({
  processType,
}: {
  processType: 'setup' | 'unsetup';
}) {
  const { t } = useTranslation();

  return (
    <div className="KeyNotReady">
      <img src={initSvg} alt="fireworks" />
      <STitle
        title={t(`${processType}.init.title`)}
        subtitle={t(`${processType}.init.subtitle`)}
        variant="body"
      />
    </div>
  );
}

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { INVENTORY_TYPE } from '@enum';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useLeaving } from '@hooks/useLeaving/useLeaving';

import { AppLoader } from '@components/AppLoader/AppLoader';

const InventoryRedirect = () => {
  const { type } = useParams<Record<string, any>>();
  const leaving = useLeaving();
  const { createLeavingInventory, fetchInventoryUrl } = useAppContext();

  useEffect(() => {
    const loadInventoryUrl = async () => {
      const inventory = leaving.findInventoryByType(type);
      try {
        const { id } =
          inventory || (await createLeavingInventory(leaving.id, type));
        const url = await fetchInventoryUrl(id);
        window.open(url, '_self');
      } catch (err: any) {
        toast.error(err?.message);
      }
    };

    if ([INVENTORY_TYPE.IN, INVENTORY_TYPE.OUT_REVIEW].includes(type)) {
      loadInventoryUrl();
    }
  }, []);

  return <AppLoader />;
};

export default InventoryRedirect;

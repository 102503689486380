import React, { useCallback } from 'react';

import { normalize } from 'normalizr';
import { Schemas } from 'src/schemas';

import { KeywordsType } from '@smartrenting/smartomic';

import { Actions, defaultFunctionParameter, mergeDeep } from '@utils/helpers';

import Apartment from '@models/Apartment';
import Contact from '@models/Contact';
import Document from '@models/Document';
import EventActivity from '@models/EventActivity';
import { HostRent } from '@models/HostRent';
import { Inventory } from '@models/Inventory';
import Leaving from '@models/Leaving';
import Neighborhood from '@models/Neighborhood';
import { PhotoContract } from '@models/PhotoContract';
import Prospect from '@models/Prospect';
import TermPricing from '@models/TermPricing';
import User from '@models/User';

import { ActionTypes } from './actions';

type Collection<T> = Record<number | string, T>;

export interface StateInterface {
  entities: {
    users: Collection<User>;
    apartments: Collection<Apartment>;
    leavings: Collection<Leaving>;
    contacts: Collection<Contact>;
    prospects: Collection<Prospect>;
    documents: Collection<Document>;
    neighborhoods: Collection<Neighborhood>;
    photoContracts: Collection<PhotoContract>;
    eventActivities: Collection<EventActivity>;
    inventories: Collection<Inventory>;
    hostRents: Collection<HostRent>;
    termsPricing: Collection<TermPricing>;
  };
  references: Partial<{
    apartmentEquipments: string[];
    apartmentCategories: string[];
    apartmentHousingTypes: string[];
    keywords: KeywordsType;
    keywordTypes: string[];
    accessStepData: { code: string; accessType: string; stepType: string }[];
  }>;
}

export const EntitiesReducer = (
  state: StateInterface,
  action: Actions<(typeof ActionTypes)[keyof typeof ActionTypes]>,
) => {
  switch (action.type) {
    case ActionTypes.ADD_ENTITIES: {
      return {
        ...state,
        entities: mergeDeep(
          {},
          state.entities,
          action.payload && action.schema
            ? normalize(action.payload, action.schema).entities
            : {},
        ) as StateInterface['entities'],
      };
    }
    case ActionTypes.ADD_REFERENCES: {
      return {
        ...state,
        references: action.payload,
      };
    }
    case ActionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export const initialState: StateInterface = {
  entities: {
    users: {},
    apartments: {},
    leavings: {},
    contacts: {},
    prospects: {},
    documents: {},
    neighborhoods: {},
    photoContracts: {},
    eventActivities: {},
    inventories: {},
    hostRents: {},
    termsPricing: {},
  },
  references: {},
};

export interface EntitiesContextInterface {
  state: StateInterface;
  addEntities: (schema: Schemas, data: any) => void;
  addReferences: (value: StateInterface['references']) => void;
  resetState: () => void;
}

export const EntitiesContext = React.createContext<EntitiesContextInterface>({
  state: initialState,
  addEntities: defaultFunctionParameter,
  addReferences: defaultFunctionParameter,
  resetState: defaultFunctionParameter,
});

export const EntitiesProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = React.useReducer(EntitiesReducer, initialState);

  const resetState = useCallback(() => {
    dispatch({ type: ActionTypes.RESET_STATE });
  }, []);

  const addEntities = useCallback((schema: Schemas, payload: any) => {
    dispatch({
      type: ActionTypes.ADD_ENTITIES,
      payload,
      schema,
    });
  }, []);

  const addReferences = useCallback((value: StateInterface['references']) => {
    dispatch({
      type: ActionTypes.ADD_REFERENCES,
      payload: value,
    });
  }, []);

  return (
    <EntitiesContext.Provider
      value={{
        state,
        addEntities,
        addReferences,
        resetState,
      }}
    >
      {children}
    </EntitiesContext.Provider>
  );
};

export const useEntitiesContext = () => {
  return React.useContext(EntitiesContext);
};

export const useEntities = () => useEntitiesContext().state.entities;
export const useReferences = () => useEntitiesContext().state.references;

export default EntitiesContext;

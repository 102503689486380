import { Shadows, createTheme } from '@mui/material';

const themeMUI = createTheme({
  palette: {
    primary: {
      light: '#FFE0E9',
      main: '#FF3D67',
      contrastText: '#fff',
    },
    secondary: {
      light: '#CBDDF1',
      lighter: '#F9FBFD',
      main: '#001C64',
      contrastText: '#fff',
    },
    gray: {
      dark: '#C5C5C5',
      main: '#838383',
      lighter: '#F7F8FA',
      light: '#AFB9C2',
    },
    black: {
      dark: '#000',
      main: '#212529',
      lighter: '#343a40',
      light: '#495057',
    },
    coconut: {
      main: '#FAF7F4',
    },
    mint: {
      main: '#D4F2D9',
    },
    grass: {
      main: '#28A745',
      contrastText: '#fff',
    },
    forest: {
      main: '#114755',
    },
    snow: {
      main: '#E8EFF7',
    },
    sea: {
      main: '#4090F2',
      light: '#97C3F0',
    },
    gum: {
      main: '#FFE0F7',
    },
    guava: {
      main: '#FF9DE6',
    },
    pomegranate: {
      main: '#97006E',
    },
    toro: {
      main: '#FCE3DE',
    },
    papaya: {
      main: '#FF7447',
    },
    cinamon: {
      main: '#B82F37',
    },
    sand: {
      main: '#F6F1DA',
    },
    sun: {
      main: '#FFD369',
    },
    ananas: {
      main: '#DAA30B',
    },
  },
  typography: {
    htmlFontSize: 16,
    button: { textTransform: 'none' },
    allVariants: {
      color: '#001C64',
      lineHeight: 1.4,
    },
    h1: {
      '@media (min-width:1200px)': {
        fontSize: '4rem',
      },
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h2: {
      '@media (min-width:1200px)': {
        fontSize: '3rem',
      },
      fontSize: '2rem',
      fontWeight: 700,
    },
    h3: {
      '@media (min-width:1200px)': {
        fontSize: '2.5rem',
      },
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h4: {
      '@media (min-width:1200px)': {
        fontSize: '2rem',
      },
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h5: {
      '@media (min-width:1200px)': {
        fontSize: '1.75rem',
      },
      fontSize: '1.125rem',
      fontWeight: 600,
    },
    h6: {
      '@media (min-width:1200px)': {
        fontSize: '1.5rem',
      },
      fontSize: '1rem',
      fontWeight: 600,
    },
    overline: {
      '@media (min-width:1200px)': {
        fontSize: '1rem',
      },
      fontSize: '1rem',
      fontWeight: 600,
    },
    subtitle1: {
      '@media (min-width:1200px)': {
        fontSize: '1.25rem',
      },
      fontSize: '1rem',
      fontWeight: 400,
    },
    subtitle2: {
      '@media (min-width:1200px)': {
        fontSize: '1.125rem',
      },
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    body1: {
      '@media (min-width:1200px)': {
        fontSize: '1rem',
      },
      fontSize: '0.75rem',
      fontWeight: 100,
    },
    body2: {
      '@media (min-width:1200px)': {
        fontSize: '0.75rem',
      },
      fontSize: '0.625rem',
      fontWeight: 100,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0 24px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          height: 'fit-content',
        },
        sizeSmall: {
          fontWeight: 500,
          fontSize: '0.75em',
          padding: '12px 18px',
          borderRadius: 6,
          '@media (min-width:1200px)': {
            borderRadius: 8,
            padding: '14px 20px',
            fontSize: '1rem',
          },
        },
        sizeMedium: {
          fontWeight: 500,
          fontSize: '0.875em',
          padding: '12px 18px',
          borderRadius: 6,
          '@media (min-width:1200px)': {
            borderRadius: 8,
            padding: '16px 22px',
            fontSize: '1.125rem',
          },
        },
        sizeLarge: {
          fontWeight: 500,
          fontSize: '0.75em',
          padding: '12px 18px',
          borderRadius: 6,
          '@media (min-width:1200px)': {
            borderRadius: 8,
            padding: '16px 22px',
            fontSize: '1.125rem',
          },
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          '@media (min-width:1200px)': {
            fontSize: '0.85rem',
          },
          fontSize: '0.70rem',
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          '@media (min-width:1200px)': {
            fontSize: '0.95rem',
          },
          fontSize: '0.75rem',
        },
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface PaletteOptions {
    gray: PaletteOptions['primary'];
    black: PaletteOptions['primary'];
    coconut: PaletteOptions['primary'];
    mint: PaletteOptions['primary'];
    grass: PaletteOptions['primary'];
    forest: PaletteOptions['primary'];
    snow: PaletteOptions['primary'];
    sea: PaletteOptions['primary'];
    gum: PaletteOptions['primary'];
    guava: PaletteOptions['primary'];
    pomegranate: PaletteOptions['primary'];
    toro: PaletteOptions['primary'];
    papaya: PaletteOptions['primary'];
    cinamon: PaletteOptions['primary'];
    sand: PaletteOptions['primary'];
    sun: PaletteOptions['primary'];
    ananas: PaletteOptions['primary'];
  }
  interface Palette {
    gray: Palette['primary'];
    black: Palette['primary'];
    coconut: Palette['primary'];
    mint: Palette['primary'];
    grass: Palette['primary'];
    forest: Palette['primary'];
    snow: Palette['primary'];
    sea: Palette['primary'];
    gum: Palette['primary'];
    guava: Palette['primary'];
    pomegranate: Palette['primary'];
    toro: Palette['primary'];
    papaya: Palette['primary'];
    cinamon: Palette['primary'];
    sand: Palette['primary'];
    sun: Palette['primary'];
    ananas: Palette['primary'];
  }

  interface PaletteColor {
    lighter?: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gray: true;
    black: true;
    coconut: true;
    mint: true;
    grass: true;
    forest: true;
    snow: true;
    sea: true;
    gum: true;
    guava: true;
    pomegranate: true;
    toro: true;
    papaya: true;
    cinamon: true;
    sand: true;
    sun: true;
    ananas: true;
  }
}

// Shadow theme overide
// Default shadow should be "elevation={3}" for all of our shadows :)
themeMUI.shadows = Array.from(Array(25).keys()).reduce(
  (prev, number) => ({
    ...prev,
    [number]:
      number === 0
        ? 'none'
        : `0px 0px ${number * 3}px ${number}px rgba(0,0,0,0.1)`,
  }),
  {} as Shadows,
);

export type MyTheme = typeof themeMUI;

export default themeMUI;

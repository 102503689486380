import React, { useEffect, useState } from 'react';

import Apartment from '@models/Apartment';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useContact } from '@hooks/useContact/useContact';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import './Calendly.scss';

export enum CalendlyType {
  phone,
  visit,
  inventory,
}

const params =
  '?' +
  [
    'hide_landing_page_details=1',
    'hide_event_type_details=1',
    'hide_gdpr_banner=1',
  ].join('&');

const calendlyUrlType = {
  [CalendlyType.phone]: (apartment: Apartment) =>
    apartment.agency?.calendlyUrlForFirstCall,
  [CalendlyType.visit]: (apartment: Apartment) =>
    apartment.agency?.calendlyUrlForVisit,
  [CalendlyType.inventory]: (apartment: Apartment) => {
    const { area } = apartment;

    if (!area) return `${apartment.agency?.calendlyUrlForInventory}-2`;

    const areaNumber = Number(area);

    // 1h00
    if (areaNumber <= 30) {
      return apartment.agency?.calendlyUrlForInventory;
    }

    // 1h30
    if (areaNumber > 30 && areaNumber <= 60) {
      return `${apartment.agency?.calendlyUrlForInventory}-2`;
    }

    // 2h00
    if (areaNumber > 61 && areaNumber <= 110) {
      return `${apartment.agency?.calendlyUrlForInventory}-3`;
    }

    // 2h30
    if (areaNumber > 110) {
      return `${apartment.agency?.calendlyUrlForInventory}-4`;
    }
  },
};

export default function Calendly({
  type,
  onChange,
}: {
  type: CalendlyType;
  onChange?: any;
}) {
  const apartment = useApartment();
  const leaving = useLeaving();
  const contact = useContact();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const { Calendly } = global as any;
    const element = document.querySelector(
      `.Calendly__calendar#calendar-${CalendlyType[type]}`,
    );

    if (
      Calendly &&
      apartment.agency &&
      element &&
      !loaded &&
      calendlyUrlType[type](apartment)
    ) {
      Calendly.initInlineWidget({
        url: calendlyUrlType[type](apartment) + params,
        prefill: {
          name: contact.fullName,
          email: contact.user.email,
          customAnswers:
            type === CalendlyType.inventory
              ? {
                  a1: contact.user.phone,
                  a2: apartment.getFullAddress(),
                }
              : {},
        },
        utm: {
          utmContent: leaving.id,
        },
        parentElement: element,
      });
      setLoaded(true);

      window.addEventListener(
        'message',
        (message: { data?: { event?: string } }) => {
          if (message.data?.event === 'calendly.event_scheduled') {
            onChange();
          }
        },
      );
    }
  }, [(global as any).Calendly, apartment, loaded]);

  return (
    <div className="Calendly">
      <div
        id={`calendar-${CalendlyType[type]}`}
        className="Calendly__calendar"
      ></div>
    </div>
  );
}

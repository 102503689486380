import logo from './logo_leazly.png';

import React from 'react';
import { Link, useMatch } from 'react-router-dom';

import { classNames } from '@utils/helpers';

import './Logo.scss';

export default function Logo({ className }: { className?: string }) {
  const match = useMatch('/auth/:link');

  if (match)
    return <img className={classNames('Logo', className)} src={logo} />;

  return (
    <Link to="/">
      <img
        className={classNames('Logo', className)}
        src={logo}
        data-testid="navigation-logo"
      />
    </Link>
  );
}

import { Alert, AlertTitle, Box } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoomLloyd, Rooms } from '@smartrenting/smartomic';

import { ROOM_TYPE } from '@enum';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';

import { useGamification } from '@scopes/Gamification/contexts/GamificationContext';

import './RoomsStep.scss';

export default function RoomsStep() {
  const apartment = useApartment();

  const { t } = useTranslation();

  const { createRooms } = useAppContext();
  const { enable, disable, handleNext } = useGamification();
  const [rooms, setRooms] = useState<RoomLloyd[]>(apartment.rooms);

  const handleChange = (room: RoomLloyd[], roomType: string) => {
    const newRooms = rooms.filter((room) => room.type !== roomType);
    if (!room.length) {
      setRooms(newRooms);
    } else {
      setRooms([...newRooms, ...room]);
    }
  };

  const livingrooms = rooms.filter(
    (room) => room.type === ROOM_TYPE.LIVINGROOM,
  );

  const bathrooms = rooms.filter((room) => room.type === ROOM_TYPE.BATHROOM);

  const bedrooms = rooms.filter((room) => room.type === ROOM_TYPE.BEDROOM);

  const addRoom = (roomType: string) => () => {
    const newRoom = {
      items: [],
      type: roomType,
    };
    setRooms([...rooms, newRoom]);
  };

  const deleteRoom = (roomType: string) => () => {
    const tmp = rooms.map((room) => room.type);
    setRooms([
      ...rooms.reduce<RoomLloyd[]>((acc, room, index) => {
        if (index === tmp.lastIndexOf(roomType)) {
          return acc;
        }

        return [...acc, room];
      }, []),
    ]);
  };

  useEffect(() => {
    if (rooms.length && rooms.every((room) => room.items?.length > 0)) {
      enable();
      handleNext(async () => {
        return createRooms(
          apartment.id,
          rooms.map((room) => ({
            apartmentId: apartment.id,
            items: room.items.map((item) => item.type || item),
            type: room.type,
          })) as any, // need change on smartomic type
        );
      });
    } else {
      disable();
    }
  }, [rooms]);

  return (
    <div className="RoomsStep">
      <div className="RoomsStep__complete">
        <Alert
          severity="info"
          icon={
            <Box
              component={FontAwesomeIcon}
              icon={faCircleQuestion as any}
              size="1x"
              my="auto"
            />
          }
          sx={{ width: '100%' }}
        >
          <AlertTitle>{t('room.title')}</AlertTitle>
          {t('room.subtitle')}
        </Alert>
      </div>
      <Rooms
        title={t('room.livingroom_plural')}
        addRoom={addRoom(ROOM_TYPE.LIVINGROOM)}
        deleteRoom={deleteRoom(ROOM_TYPE.LIVINGROOM)}
        roomsTitle={t('room.livingroom')}
        roomType={ROOM_TYPE.LIVINGROOM}
        rooms={livingrooms}
        onChange={handleChange}
      />
      <Rooms
        title={t('room.bedroom_plural')}
        addRoom={addRoom(ROOM_TYPE.BEDROOM)}
        deleteRoom={deleteRoom(ROOM_TYPE.BEDROOM)}
        roomsTitle={t('room.bedroom')}
        roomType={ROOM_TYPE.BEDROOM}
        rooms={bedrooms}
        onChange={handleChange}
      />
      <Rooms
        title={t('room.bathroom_plural')}
        addRoom={addRoom(ROOM_TYPE.BATHROOM)}
        deleteRoom={deleteRoom(ROOM_TYPE.BATHROOM)}
        roomsTitle={t('room.bathroom')}
        roomType={ROOM_TYPE.BATHROOM}
        rooms={bathrooms}
        onChange={handleChange}
      />
    </div>
  );
}

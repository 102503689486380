import React from 'react';

import {
  faPhone,
  faHome,
  faImage,
  faTag,
} from '@fortawesome/pro-solid-svg-icons';
import { AIcon, AIconProps } from '@smartrenting/smartomic';

import { classNames } from '@utils/helpers';

import { SchedulerLogoType } from '@components/Scheduler/Scheduler';

import './SchedulerLogo.scss';

const schedulerIconType: Record<string, AIconProps['icon']> = {
  phone: faPhone,
  visit: faHome,
  photo: faImage,
  inventory: faTag,
};

export default function SchedulerLogo({
  type,
}: {
  type: (typeof SchedulerLogoType)[SchedulerLogoType];
}) {
  return (
    <div
      className={classNames(
        'SchedulerLogo',
        type === SchedulerLogoType[SchedulerLogoType.phone] &&
          'SchedulerLogo--phone',
        type === SchedulerLogoType[SchedulerLogoType.visit] &&
          'SchedulerLogo--visit',
        type === SchedulerLogoType[SchedulerLogoType.photo] &&
          'SchedulerLogo--photo',
        type === SchedulerLogoType[SchedulerLogoType.inventory] &&
          'SchedulerLogo--inventory',
      )}
    >
      <AIcon icon={schedulerIconType[type]} />
    </div>
  );
}

import { Button, styled } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MessengerBox = styled(FontAwesomeIcon)`
  height: 24px;
  margin: auto;
  color: transparent;
  background: -webkit-linear-gradient(225deg, #ff6968, #a334fa, #0695ff);
  background: linear-gradient(225deg, #ff6968, #a334fa, #0695ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -moz-transition: height 0.5s ease;
  -webkit-transition: height 0.5s ease;
  -o-transition: height 0.5s ease;
  transition: height 0.5s ease;
  * {
    fill: url(#rg);
  }
`;
export const SemiButton = styled(Button)`
  height: 50px;
  border-radius: 0 8px 8px 0px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
`;

import { Typography } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { routes } from 'src/routes';

import { faUser, faLock } from '@fortawesome/pro-solid-svg-icons';
import { ASeparator, SNavItem, STitle } from '@smartrenting/smartomic';

import './DesktopHeader.scss';

export default function DesktopHeader() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentPath = pathname.replace(routes.settings + '/', '');

  return (
    <div className="DesktopHeader">
      <div className="DesktopHeader__background">
        <Typography variant="h5" ml={{ xs: 0, md: 4 }} mt={1}>
          {t('navigation.routes.settings')}
        </Typography>
        <div className="DesktopHeader__title">
          <SNavItem
            label={t('navigation.routes.user.title')}
            icon={faUser}
            href={routes.user}
            onClick={navigate}
          />
          <SNavItem
            label={t('navigation.routes.rib.title')}
            icon={faUser}
            href={routes.rib}
            onClick={navigate}
          />
          <SNavItem
            label={t('navigation.routes.security.title')}
            icon={faLock}
            href={routes.security}
            onClick={navigate}
          />
        </div>
      </div>
      <ASeparator />
      {currentPath !== routes.settings && (
        <STitle
          className="DesktopHeader__subtitle"
          variant="body"
          title={t(`navigation.routes.${currentPath}.title`)}
          subtitle={t(`navigation.routes.${currentPath}.subtitle`)}
        />
      )}
    </div>
  );
}

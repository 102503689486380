import { Alert, AlertTitle, Box } from '@mui/material';

import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SButton,
  SEditRow,
  SForm,
  SFormSubmitProps,
  SInput,
} from '@smartrenting/smartomic';

import { toastPromise } from '@utils/tools';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useContact } from '@hooks/useContact/useContact';

import paymentSVG from '../assets/payment.svg';

import './Payment.scss';

export default function Payment() {
  const contact = useContact();
  const { t } = useTranslation();
  const { updateContact } = useAppContext();

  const handleSubmit = async (
    event: FormEvent,
    formProps: SFormSubmitProps,
  ) => {
    const { touched = [], values, reset } = formProps;

    event.preventDefault();
    event.stopPropagation();

    const modifications = touched.reduce((acc: Record<string, any>, name) => {
      acc[name] = values[name];

      return acc;
    }, {});

    if (Object.values(modifications).length === 0) return;

    return toastPromise(updateContact(contact.id, modifications)).then(reset);
  };

  return (
    <div className="Payment">
      <div className="Payment__row">
        <Alert
          severity="warning"
          icon={
            <Box
              component={FontAwesomeIcon}
              icon={faTriangleExclamation as any}
              size="1x"
              my="auto"
            />
          }
          sx={{ width: '100%' }}
        >
          <AlertTitle color="warning.main">
            {t('settings.payment.warning.title')}
          </AlertTitle>
          {t('settings.payment.warning.text')}
        </Alert>
      </div>
      <SForm
        onSubmit={handleSubmit}
        initialValues={{
          iban: contact.iban || '',
          bic: contact.bic || '',
        }}
      >
        <div className="Payment__row">
          <SEditRow label={t('settings.payment.iban')} name="iban">
            <SInput placeholder={t('settings.payment.iban')} />
          </SEditRow>
          <SEditRow label={t('settings.payment.bic')} name="bic">
            <SInput placeholder={t('settings.payment.bic')} />
          </SEditRow>
          <SButton
            variant="secondary"
            type="submit"
            label={t('settings.submit')}
          />
        </div>
      </SForm>
      <div className="Payment__image">
        <img src={paymentSVG} />
      </div>
    </div>
  );
}

import moment from 'moment-timezone';

export default class Commission {
  id!: number;
  fullterm!: boolean;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  percent!: number;

  constructor(data: Partial<Commission>) {
    Object.assign(this, data);

    this.startDate = data.startDate && moment(data.startDate);
    this.endDate = data.endDate && moment(data.endDate);
  }
}

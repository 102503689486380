import { keyframes } from '@emotion/react';

import React from 'react';

import styled from '@emotion/styled';

const waveAnimation = keyframes`
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-15px);
  }
`;

const Dot = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 3px;
  background: #001c64;
  animation: ${waveAnimation} 1s linear infinite;

  &:nth-child(2) {
    animation-delay: -1.1s;
  }

  &:nth-child(3) {
    animation-delay: -1.2s;
  }
`;

function WaveAnimation() {
  return (
    <div>
      <Dot />
      <Dot />
      <Dot />
    </div>
  );
}

export default WaveAnimation;

import React from 'react';

import { KeywordsCategory, KeywordsSelector } from '@smartrenting/smartomic';

import { useReferences } from '@contexts/EntitiesContext/EntitiesContext';

import { useKeywordsContext } from '@scopes/Gamification/context/KeywordsContext';

import './Keywords.scss';

export interface KeywordsProps {
  categories: KeywordsCategory[];
  step: 'keywords' | 'neighborhood';
}

export default function Keywords({ categories, step }: KeywordsProps) {
  const references = useReferences();

  const { setSelectedIds, setValidSteps, validSteps, selectedIds } =
    useKeywordsContext();

  const handleChange = (selectedIds: number[], isValid: boolean) => {
    setSelectedIds(selectedIds);

    if (isValid) {
      setValidSteps([...validSteps, step]);
    } else {
      setValidSteps(validSteps.filter((validStep) => validStep !== step));
    }
  };

  return (
    <div className="Keywords">
      {references.keywords ? (
        <KeywordsSelector
          categories={categories}
          onChange={handleChange}
          selectedIds={selectedIds}
          keywords={references.keywords}
          editable
        />
      ) : null}
    </div>
  );
}

import React, { useEffect, useState } from 'react';

import { t } from 'i18next';

import { faBuilding, faHome } from '@fortawesome/pro-solid-svg-icons';
import { AIcon, SText } from '@smartrenting/smartomic';

import { HOUSING_TYPE, OWNERSHIP, RESIDENCE_TYPE } from '@enum';

import { classNames } from '@utils/helpers';

import Apartment from '@models/Apartment';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';

import { useGamification } from '../../contexts/GamificationContext';

import './TypeStep.scss';

export default function TypeStep() {
  const {
    id,
    ownership,
    housingType: apartmentHousingType,
    residenceType: apartmentResidenceType,
  } = useApartment();
  const { enable, disable, disabled, handleNext } = useGamification();
  const { updateApartment } = useAppContext();
  const isOwner = ownership === OWNERSHIP.OWNER;
  const [housingType, setHousingType] = useState<
    (typeof HOUSING_TYPE)[keyof typeof HOUSING_TYPE]
  >(apartmentHousingType as (typeof HOUSING_TYPE)[keyof typeof HOUSING_TYPE]);
  const [residenceType, setResidenceType] = useState<
    (typeof RESIDENCE_TYPE)[keyof typeof RESIDENCE_TYPE]
  >(
    apartmentResidenceType as (typeof RESIDENCE_TYPE)[keyof typeof RESIDENCE_TYPE],
  );

  useEffect(() => {
    const toUpdate: Partial<Apartment> = {};
    if (housingType && housingType !== apartmentHousingType) {
      toUpdate.housingType = housingType;
    }

    if (residenceType && residenceType !== apartmentResidenceType) {
      toUpdate.residenceType = residenceType;
    }
    handleNext(() => updateApartment(id, toUpdate) as any);
  }, [
    residenceType,
    housingType,
    apartmentHousingType,
    apartmentResidenceType,
  ]);

  useEffect(() => {
    if (
      !!housingType &&
      ((!!housingType && isOwner && !!residenceType) || !isOwner)
    ) {
      enable();
    } else if (!disabled) {
      disable();
    }
  }, [housingType, residenceType]);

  return (
    <div className="TypeStep">
      {isOwner && (
        <SText className="TypeStep__housingType__label" variant="title">
          {t('gamification.housingType')}
        </SText>
      )}
      <div className="TypeStep__housingType">
        <div
          className={classNames(
            'TypeStep__housingType__item',
            housingType === HOUSING_TYPE.APARTMENT
              ? 'TypeStep__housingType__item--selected'
              : '',
          )}
          onClick={() => setHousingType(HOUSING_TYPE.APARTMENT)}
          data-testid="gamification-housing-type-house"
        >
          <AIcon icon={faBuilding} />
          <SText variant="body-subtitle">
            {t('common.housingTypes.apartment')}
          </SText>
        </div>
        <div
          className={classNames(
            'TypeStep__housingType__item',
            housingType === HOUSING_TYPE.HOUSE
              ? 'TypeStep__housingType__item--selected'
              : '',
          )}
          onClick={() => setHousingType(HOUSING_TYPE.HOUSE)}
        >
          <AIcon icon={faHome} />
          <SText variant="body-subtitle">
            {t('common.housingTypes.house')}
          </SText>
        </div>
      </div>
      {isOwner && (
        <>
          <SText className="TypeStep__residenceType__label" variant="title">
            {t('gamification.residenceType')}
          </SText>
          <div className="TypeStep__residenceType">
            <div
              className={classNames(
                'TypeStep__residenceType__item',
                residenceType === RESIDENCE_TYPE.PRIMARY
                  ? 'TypeStep__residenceType__item--selected'
                  : '',
              )}
              onClick={() => setResidenceType(RESIDENCE_TYPE.PRIMARY)}
            >
              <SText variant="body-subtitle">
                {t('common.residenceTypes.primary')}
              </SText>
            </div>
            <div
              className={classNames(
                'TypeStep__residenceType__item',
                residenceType === RESIDENCE_TYPE.SECONDARY
                  ? 'TypeStep__residenceType__item--selected'
                  : '',
              )}
              onClick={() => setResidenceType(RESIDENCE_TYPE.SECONDARY)}
            >
              <SText variant="body-subtitle">
                {t('common.residenceTypes.secondary')}
              </SText>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

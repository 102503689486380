import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { faImage, faUser, faHouse } from '@fortawesome/pro-duotone-svg-icons';
import { AIcon, AText } from '@smartrenting/smartomic';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import Image from '@components/Image/Image';

import './ApartmentDetailsInfo.scss';

export const ApartmentDetailsInfo: React.FC = () => {
  const { t } = useTranslation();
  const {
    professionalPictures,
    pictures,
    ownership,
    residenceType,
    address,
    category,
    area,
    floor,
    door,
  } = useApartment();
  const { rent, allowance, monthlyCharges } = useLeaving();

  const displayedPictures = useMemo(() => {
    if (professionalPictures.length > 0 || pictures.length > 0)
      return (
        <div className="pictures-info-container">
          {(professionalPictures.length > 0
            ? professionalPictures
            : pictures
          ).map((picture, index) => {
            return (
              <div key={`${picture.id}-${index}`}>
                <Image document={picture} parameters={{ w: 500 }} />
              </div>
            );
          })}
        </div>
      );

    return (
      <div className="ApartmentDetailsInfo__defaultImage">
        <AIcon icon={faImage} height={40} />
      </div>
    );
  }, [pictures, professionalPictures]);

  return (
    <>
      {displayedPictures}
      <div className="is-flex align-items-baseline is-flex-wrap mobile-padding">
        <div>
          <div className="is-flex mt-2">
            <AText className="mr-3" variant="title">
              {address}
            </AText>
          </div>
          <div className="is-flex mt-2">
            <AText className="mr-3">
              {t('apartment.info.prices.rent', { rent: rent || '- ' })}
            </AText>
            <AText className="mr-3">
              {t('apartment.info.prices.allowance', {
                allowance: allowance || '- ',
              })}
            </AText>
            <AText className="mr-3">
              {t('apartment.info.prices.monthlyCharges', {
                monthlyCharges: monthlyCharges || '- ',
              })}
            </AText>
          </div>
          <div className="is-flex mt-2">
            <AIcon className="mr-1 default-cursor" icon={faUser} height={12} />
            <AText className="mr-3">
              {t(`apartment.info.ownership.${ownership}`)}
            </AText>
            <AIcon className="mr-1 default-cursor" icon={faHouse} height={12} />
            <AText className="mr-3">
              {t(`apartment.info.residenceType.${residenceType}`)}
            </AText>
          </div>
        </div>
        <div className="is-flex align-items-center box-common-info ">
          <AText variant={'body-subtitle'}>
            {t(`apartment.categories.${category}`)}
          </AText>
          <span className="vertical-line mx-3" />
          <AText variant={'body-subtitle'}>
            {t('apartment.info.common.area', { area: area })}
          </AText>
          <span className="vertical-line mx-3" />
          <AText variant={'body-subtitle'}>
            {t('apartment.info.common.floor', {
              floor: floor || '-',
            })}
          </AText>
          <span className="vertical-line mx-3" />
          <AText variant={'body-subtitle'}>
            {t('apartment.info.common.door', {
              door: door || '-',
            })}
          </AText>
        </div>
      </div>
    </>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  SButton,
  SEditRow,
  SForm,
  SFormChangeProps,
  SFormSubmitProps,
  SInput,
} from '@smartrenting/smartomic';

import { validatePasswordConfirmation } from '@utils/tools';

import { useAppContext } from '@contexts/AppContext/AppContext';

import AuthPage from '../components/AuthPage/AuthPage';
import useRedirectToLogin from '../useRedirectToLogin/useRedirectToLogin';

import './Reset.scss';

export default function Reset() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { createNewPassword, logout } = useAppContext();

  const redirectToLogin = useRedirectToLogin();

  const onSubmit = async (event: FormEvent, { values }: SFormSubmitProps) => {
    try {
      setLoading(true);
      await createNewPassword(values.password);
      logout();

      return toast.success(t('auth.reset.success'), {
        onClose: redirectToLogin,
        autoClose: 1500,
      });
    } catch (error) {
      setLoading(false);

      return toast.error(t('common.unhandledError'), {
        autoClose: 1500,
      });
    }
  };

  return (
    <AuthPage
      className="Reset"
      title={t('auth.reset.title')}
      subtitle={t('auth.reset.subtitle')}
      form={
        <SForm
          onSubmit={onSubmit}
          validate={{
            validatePasswordConfirmation,
          }}
          initialValues={{
            passwordConfirmation: '',
            password: '',
          }}
        >
          {({ validateRules, values }: SFormChangeProps) => (
            <>
              <SEditRow
                required
                name="password"
                type="password"
                label={t('auth.reset.password')}
              >
                <SInput />
              </SEditRow>
              <SEditRow
                required
                name="passwordConfirmation"
                type="password"
                label={t('auth.reset.confirmation')}
                validate={
                  validateRules.validatePasswordConfirmation(
                    values.password,
                    t,
                  ) as any
                }
              >
                <SInput />
              </SEditRow>
              <SButton variant="secondary" type="submit" disabled={loading}>
                {t('auth.reset.submit')}
              </SButton>
            </>
          )}
        </SForm>
      }
    />
  );
}

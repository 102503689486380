import User from './User';

export default class Employee {
  id!: number;
  user: User;

  constructor(data: Record<string, any>) {
    Object.assign(this, data);
    this.user = data.user && new User(data.user);
  }

  get fullName() {
    return this.user.fullName;
  }
}

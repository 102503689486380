import { Box, Paper, Typography } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSackDollar } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modality from '@models/Modality';

import { useLeaving } from '@hooks/useLeaving/useLeaving';

interface StepInfoProps {
  modality: Modality | null;
}

export default function StepInfo({ modality }: StepInfoProps) {
  const { t } = useTranslation();
  const leaving = useLeaving();

  return (
    <Box>
      <Paper
        elevation={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '1rem',
          mb: { xs: '1rem', sm: '2rem' },
          mt: { xs: '1rem', sm: '2rem' },
          pt: 5,
          px: 6,
          borderRadius: 4,
          backgroundColor: (theme) => theme.palette.secondary.lighter,
        }}
      >
        <Box
          component={FontAwesomeIcon}
          icon={faSackDollar as IconProp}
          size="2x"
          sx={{ color: (theme) => theme.palette.secondary.main }}
        />
        <Typography variant="h1" align="center" fontSize={{ xs: 50, sm: 60 }}>
          {(leaving.rent || 0) +
            (leaving.monthlyCharges || 0) -
            (leaving.allowance || 0)}{' '}
          €
        </Typography>
        <Typography
          variant="h6"
          align="center"
          sx={{ mb: '2rem', fontWeight: '400' }}
        >
          {t('boardOffice.steps.info.guaranted')}
        </Typography>
        {!modality?.setupFee && (
          <>
            <Typography variant="body1" align="center">
              {t('boardOffice.steps.info.setupOffered-1')}
            </Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{
                mb: '2rem',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
            >
              {t('boardOffice.steps.info.setupOffered-2')}
            </Typography>
          </>
        )}
      </Paper>
    </Box>
  );
}

import { useTranslation } from 'react-i18next';

import {
  faMoneyBillTransfer,
  faFaceTongueMoney,
  faShieldHalved,
  faBuildingShield,
  faLockKeyholeOpen,
  faKeySkeletonLeftRight,
  faSparkles,
  faBroom,
  faUserShield,
  faMobileScreenButton,
  faFolders,
} from '@fortawesome/pro-duotone-svg-icons';

import { TERM_PRICING_TYPE } from '@enum';

import Apartment from '@models/Apartment';
import Modality from '@models/Modality';
import TermPricing from '@models/TermPricing';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import { useCleaningDuration } from './useCleaningDurations';
import {
  useMultipleTermPricingByType,
  useUniqueTermPricingByType,
} from './useTermPricing';

export function useModalityMapper(modality: Modality | null) {
  const leaving = useLeaving();
  const apartment = useApartment();
  const { t } = useTranslation();

  const dataPerModality = {
    [TERM_PRICING_TYPE.EARLY_PAYMENT]: modality?.earlyPayment
      ? {
          label: t('boardOffice.steps.payment.express.title'),
          amount:
            ((leaving.rent || 0) +
              (leaving.monthlyCharges || 0) -
              (leaving.allowance || 0)) *
            useUniqueTermPricingByType(TERM_PRICING_TYPE.EARLY_PAYMENT).value,
          icon: faMoneyBillTransfer,
          tooltipContent: t('boardOffice.steps.recap.percentOfRevenu', {
            value:
              useUniqueTermPricingByType(TERM_PRICING_TYPE.EARLY_PAYMENT)
                ?.value * 100,
          }),
        }
      : {
          label: t('boardOffice.steps.payment.classic.title'),
          amount: 0,
          icon: faFaceTongueMoney,
          tooltipContent: undefined,
        },
    [TERM_PRICING_TYPE.PAID_INSURANCE]: modality?.paidInsurance
      ? {
          label: t('boardOffice.steps.insurance.paid.title'),
          amount: computeInsuranceAmount(
            useMultipleTermPricingByType(TERM_PRICING_TYPE.PAID_INSURANCE),
            apartment,
          ),
          icon: faBuildingShield,
          tooltipContent: t('boardOffice.steps.recap.insuranceTooltip'),
        }
      : {
          label: t('boardOffice.steps.insurance.essential.title'),
          amount: 0,
          icon: faShieldHalved,
          tooltipContent: undefined,
        },
    [TERM_PRICING_TYPE.PAID_KEY]: modality?.paidKey
      ? {
          label: t('boardOffice.steps.keys.delegated.title'),
          amount: useUniqueTermPricingByType(TERM_PRICING_TYPE.PAID_KEY)?.value,
          icon: faKeySkeletonLeftRight,
          tooltipContent: undefined,
        }
      : {
          label: t('boardOffice.steps.keys.autonomous.title'),
          amount: 0,
          icon: faLockKeyholeOpen,
          tooltipContent: undefined,
        },
    [TERM_PRICING_TYPE.PAID_CLEANING]: modality?.paidCleaning
      ? {
          label: t('boardOffice.steps.cleaning.hotel.title'),
          amount:
            computeCleaningAmount(
              useUniqueTermPricingByType(TERM_PRICING_TYPE.PAID_CLEANING),
              Number(apartment.area),
            ) || 0,
          icon: faSparkles,
          tooltipContent: undefined,
        }
      : {
          label: t('boardOffice.steps.cleaning.autonomous.title'),
          amount: 0,
          icon: faBroom,
          tooltipContent: undefined,
        },
    [TERM_PRICING_TYPE.PAID_INVENTORY]: modality?.paidInventory
      ? {
          label: t('boardOffice.steps.inventory.assisted.title'),
          amount: useUniqueTermPricingByType(TERM_PRICING_TYPE.PAID_INVENTORY)
            ?.value,
          icon: faUserShield,
          tooltipContent: undefined,
        }
      : {
          label: t('boardOffice.steps.inventory.autonomous.title'),
          amount: 0,
          icon: faMobileScreenButton,
          tooltipContent: undefined,
        },
    [TERM_PRICING_TYPE.SETUP_FEE]: modality?.setupFee
      ? {
          label: t('boardOffice.steps.recap.setupFees'),
          amount:
            ((leaving.rent || 0) +
              (leaving.monthlyCharges || 0) -
              (leaving.allowance || 0)) *
            useUniqueTermPricingByType(TERM_PRICING_TYPE.SETUP_FEE).value,
          icon: faFolders,
          tooltipContent: undefined,
        }
      : {
          label: t('boardOffice.steps.recap.setupFees'),
          amount: 0,
          icon: faFolders,
          tooltipContent: undefined,
        },
  };

  return dataPerModality;
}

export function computeInsuranceAmount(
  termsPricing: TermPricing[],
  apartment: Apartment,
) {
  const nbDaysPerMonth = 31;

  const fixedPart =
    (termsPricing.find(
      (tp) => tp.type === TERM_PRICING_TYPE.PAID_INSURANCE && !tp.isPercent,
    )?.value || 0) * nbDaysPerMonth;
  const dynamicPart =
    (termsPricing.find(
      (tp) => tp.type === TERM_PRICING_TYPE.PAID_INSURANCE && tp.isPercent,
    )?.value || 0) *
    Number(apartment.area) *
    nbDaysPerMonth;

  return fixedPart + dynamicPart;
}

export function computeCleaningAmount(termPricing: TermPricing, area: number) {
  if (!area) return termPricing.value;

  const cleaningDuration = useCleaningDuration(area);

  return termPricing.value * ((cleaningDuration?.duration || 1) + 0.5); // We want to add 0.5 to each coefficient, it's not an mistake
}

import React, { useCallback, useEffect, useState } from 'react';

import { ASeparator } from '@smartrenting/smartomic';

import { INVENTORY_STATUS, INVENTORY_TYPE } from '@enum';

import { useLeaving } from '@hooks/useLeaving/useLeaving';

import HostReturn from '@scopes/Keys/HostReturn/HostReturn';
import KeyUnSetups from '@scopes/Keys/KeyUnSetups/KeyUnSetups';

import Inventory from '../Steps/Inventory/Inventory';

import UnSetupStepContainer from './components/UnSetupStepContainer/UnSetupStepContainer';
import {
  ProspectUnSetupStep,
  useUnSetupStep,
} from './components/UnSetupStepContainer/useUnSetupStep';

import './DashboardUnSetup.scss';

const steps: Exclude<ProspectUnSetupStep, ProspectUnSetupStep.finalStep>[] = [
  ProspectUnSetupStep.hostReturn,
  ProspectUnSetupStep.keyUnSetup,
  ProspectUnSetupStep.inventory,
];

export default function DashboardUnSetup() {
  const initialStep = useUnSetupStep() as ProspectUnSetupStep;
  const [selectedStep, setSelectedStep] = useState(initialStep);
  const leaving = useLeaving();

  const inventoryOut = leaving.findInventoryByType(INVENTORY_TYPE.OUT);
  const inventoryOutValidation = leaving.findInventoryByType(
    INVENTORY_TYPE.OUT_VALIDATION,
  );

  const handleClickStep = useCallback((step: ProspectUnSetupStep) => {
    if (
      inventoryOut?.status === INVENTORY_STATUS.TO_SIGN &&
      inventoryOutValidation?.status !== INVENTORY_STATUS.DONE &&
      selectedStep >= ProspectUnSetupStep.keyUnSetup &&
      [ProspectUnSetupStep.inventory, ProspectUnSetupStep.keyUnSetup].includes(
        step,
      )
    ) {
      setSelectedStep(step);
    }
  }, []);

  useEffect(() => {
    setSelectedStep(initialStep);
  }, [initialStep]);

  return (
    <>
      <ASeparator className="DashboardUnSetup__ASeparator" />
      <UnSetupStepContainer
        steps={steps}
        initialStep={initialStep}
        selectedStep={selectedStep}
        onChange={handleClickStep}
      >
        <>{selectedStep === ProspectUnSetupStep.hostReturn && <HostReturn />}</>
        <>
          {selectedStep === ProspectUnSetupStep.keyUnSetup && <KeyUnSetups />}
        </>
        <>{selectedStep === ProspectUnSetupStep.inventory && <Inventory />}</>
      </UnSetupStepContainer>
    </>
  );
}

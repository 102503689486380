import { DOCUMENT, OWNERSHIP, RESIDENCE_TYPE } from '@enum';

import { useApartment } from '@hooks/useApartment/useApartment';

export function useDocumentsList(): (typeof DOCUMENT)[keyof typeof DOCUMENT][] {
  const apartment = useApartment();
  const requiredDocuments = [
    DOCUMENT.ID,
    DOCUMENT.PROOF,
    DOCUMENT.RIB,
  ] as (typeof DOCUMENT)[keyof typeof DOCUMENT][];

  if (apartment.ownership === OWNERSHIP.OWNER) {
    if (apartment.residenceType === RESIDENCE_TYPE.PRIMARY)
      requiredDocuments.push(DOCUMENT.DEPARTURE);
    else requiredDocuments.push(DOCUMENT.USAGE);
  } else {
    requiredDocuments.push(DOCUMENT.AUTHORIZATION, DOCUMENT.DEPARTURE);
  }

  const optionalDocuments =
    apartment.ownership === OWNERSHIP.TENANT ? [DOCUMENT.RECEIPTS] : [];

  return [...requiredDocuments, ...optionalDocuments];
}

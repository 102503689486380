import React, { useMemo } from 'react';

import moment from 'moment-timezone';

import { useKeys } from '@hooks/useKeys/useKeys';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import KeysMultiLocation from '../component/KeysMultiLocation/KeysMultiLocation';
import KeysNotReady from '../component/KeysNotReady/KeysNotReady';
import KeysWithSameLocation from '../component/KeysWithSameLocation/KeysWithSameLocation';

import './KeyUnSetups.scss';

export default function KeyUnSetups() {
  const { agencyKey, guestKey, cleaningKey } = useKeys();
  const leaving = useLeaving();

  const keySetupWithSameType: boolean = useMemo(() => {
    const keySetupWithDefinedType = [guestKey, cleaningKey, agencyKey].filter(
      (e) => !!e?.type,
    );

    return keySetupWithDefinedType.every(
      (key) =>
        key?.type === keySetupWithDefinedType[0]?.type &&
        key?.locationName === keySetupWithDefinedType[0]?.locationName,
    );
  }, [guestKey, cleaningKey, agencyKey]);

  const isSevenDayBefore = moment()
    .startOf('day')
    .isSameOrAfter(moment(leaving.endDate).subtract(7, 'days').startOf('day'));

  if (!isSevenDayBefore) {
    return <KeysNotReady processType="unsetup" />;
  }

  if (keySetupWithSameType) {
    return (
      <KeysWithSameLocation
        guestKey={guestKey}
        agencyKey={agencyKey}
        cleaningKey={cleaningKey}
        processType="unsetup"
      />
    );
  }

  return (
    <KeysMultiLocation
      processType="unsetup"
      guestKey={guestKey}
      cleaningKey={cleaningKey}
      agencyKey={agencyKey}
    />
  );
}

import { Alert, AlertTitle, Box } from '@mui/material';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SCard, STitle } from '@smartrenting/smartomic';

import {
  APARTMENT_KEY_STATUS,
  APARTMENT_KEY_TYPE,
  KEY_SETUP_STATUS,
} from '@enum';

import { ApartmentKeyLocation, Tools } from '@utils/tools';

import { ApartmentKey } from '@models/ApartmentKey';
import KeySetup from '@models/KeySetup';

import KeyInfoCard from '../KeyInfoCard/KeyInfoCard';
import KeysHelp from '../KeysHelp/KeysHelp';

import './KeysMultiLocation.scss';

interface KeysMultiLocationSetup {
  processType: 'setup';
  guestKey: KeySetup | null | undefined;
  cleaningKey: KeySetup | null | undefined;
  agencyKey: KeySetup | null | undefined;
}

interface KeysMultiLocationUnsetup {
  processType: 'unsetup';
  guestKey: ApartmentKey | null | undefined;
  cleaningKey: ApartmentKey | null | undefined;
  agencyKey: ApartmentKey | null | undefined;
}

export type KeysMultiLocationProps =
  | KeysMultiLocationSetup
  | KeysMultiLocationUnsetup;

export default function KeysMultiLocation({
  guestKey,
  cleaningKey,
  agencyKey,
  processType,
}: KeysMultiLocationProps) {
  const { t } = useTranslation();

  const sameType: boolean = useMemo(() => {
    const keySetupWithDefinedType = [guestKey, cleaningKey, agencyKey].filter(
      (e) => !!e?.type,
    );

    return keySetupWithDefinedType.every(
      (key) => key?.type === keySetupWithDefinedType[0]?.type,
    );
  }, [guestKey, cleaningKey, agencyKey]);

  const defaultKey = useMemo(() => {
    const keySetupWithDefinedType = [guestKey, cleaningKey, agencyKey].filter(
      (e) => !!e?.type,
    );

    return keySetupWithDefinedType[0];
  }, []);

  const keysToDisplay = useMemo(() => {
    // In case of setup, we would key setup with ready or deposited status
    if (processType === 'setup') {
      return [guestKey, cleaningKey, agencyKey].filter(
        (e) => !!e?.type && e.status !== KEY_SETUP_STATUS.INIT,
      );
    }

    // In case of unsetup, we would stored apartment key
    return [guestKey, cleaningKey, agencyKey].filter(
      (e) => !!e?.type && e.status === APARTMENT_KEY_STATUS.STORED,
    );
  }, []);

  if (!defaultKey) return null;

  return (
    <div className="KeysMultiLocation">
      <Alert
        severity="warning"
        icon={
          <Box
            component={FontAwesomeIcon}
            icon={faExclamationCircle as any}
            size="1x"
            my="auto"
          />
        }
        sx={{ width: '100%' }}
      >
        <AlertTitle color="warning.main">
          {t(`${processType}.ready.alert.title`)}
        </AlertTitle>
        {t(`${processType}.ready.alert.subtitle`)}
      </Alert>
      {[guestKey, cleaningKey, agencyKey].map((key) => {
        if (
          !key ||
          !key.locationTimeSchedule ||
          (processType === 'unsetup' && key.status !== 'stored')
        ) {
          return undefined;
        }

        return (
          <SCard
            key={key.denomination}
            className={`KeyCard ${
              key.status === 'deposited' ? 'KeyCard--done' : ''
            }`}
          >
            <div className={`KeyCard__bar KeyCard__bar--${key.denomination}`} />
            <div className="KeyCard__container">
              <STitle
                title={t(`dashboard.keys.multi-location.${key.denomination}`)}
                variant="body"
              />
              {key.status === 'deposited' && (
                <div>{t('dashboard.keys.done')}</div>
              )}
              {key.status !== 'deposited' && (
                <>
                  <div className="KeyCard__row">
                    <div className="KeyCard__row--prefix">
                      {t('dashboard.keys.multi-location.card.relais.prefix')}
                    </div>
                    <div className="KeyCard__row--text">
                      {t('dashboard.keys.multi-location.card.relais.text', {
                        type: t(`common.${key.type}`),
                        locationName: key.locationName,
                        locationAddress: key.locationAddress,
                      })}
                    </div>
                  </div>
                  <div className="KeyCard__row">
                    <div className="KeyCard__row--prefix">
                      {t('dashboard.keys.multi-location.card.hourly.prefix')}
                    </div>
                    <div className="KeyCard__row--text">
                      {Tools.apartmentKeyLocationOpeningHoursToHumanFormat(
                        key.locationTimeSchedule as ApartmentKeyLocation['openingHours'],
                      ).map((schedule: any) => (
                        <div
                          className="KeysPickupDetails__keys__content__days"
                          key={String(schedule)}
                        >
                          {schedule}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="KeyCard__row">
                    <div className="KeyCard__row--prefix">
                      {t('dashboard.keys.multi-location.card.code.prefix')}
                    </div>
                    <div
                      className={`KeyCard__row--text KeyCard__row--text-code KeyCard__row--text KeyCard__row--text-code-${key.denomination}`}
                    >
                      {t('dashboard.keys.multi-location.card.code.text', {
                        code:
                          processType === 'unsetup' &&
                          key.type === APARTMENT_KEY_TYPE.KEYNEST
                            ? (key as ApartmentKey)?.collectionCode
                            : key.dropOffCode,
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </SCard>
        );
      })}
      {keysToDisplay.length > 0 && (
        <>
          <KeyInfoCard processType={processType} />
          <KeysHelp
            processType={processType}
            sameTypeDifferentLocation
            differentType={!sameType}
            keysInfos={defaultKey}
          />
        </>
      )}
    </div>
  );
}

import { Room, RoomType } from '@models/Room';

export const selectRoomByType = (rooms: RoomUpdate[], roomType: RoomType) =>
  rooms.filter((room) => room.type === roomType);

export const roomsToUpdatedRooms = (rooms: Room[]) => {
  return rooms.map((room) => ({
    type: room.type,
    items: room.items.map((item) => item.type),
  }));
};

export type RoomUpdate = ReturnType<typeof roomsToUpdatedRooms>[number];

export type RoomCardProps = {
  rooms: RoomUpdate[];
  type: RoomType;
  edit: boolean;
  onChange: (rooms: RoomUpdate[], type: RoomType) => void;
};

export type RoomEditProps = RoomUpdate & {
  index: number;
  onChange: (room: RoomUpdate, index: number) => void;
  handleRemove: (index: number) => void;
};

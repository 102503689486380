import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { faTrashSlash, faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  AIcon,
  ASeparator,
  SButton,
  SEditRow,
  SForm,
  SFormSubmitProps,
  SInput,
  SSelect,
} from '@smartrenting/smartomic';

import { useAppContext } from '@contexts/AppContext/AppContext';
import { useReferences } from '@contexts/EntitiesContext/EntitiesContext';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import Image from '@components/Image/Image';

import DropGalery from './components/DropGalery';

import './ApartmentDetailsEdit.scss';

export interface AcceptedFiles extends File {
  path: string;
  data: any;
}
const leavingDetailsKeys = [
  'rent',
  'allowance',
  'monthlyCharges',
  'paymentDay',
];

const apartmentDetailsKeys = [
  'professionalPictures',
  'pictures',
  'ownership',
  'residenceType',
  'address',
  'city',
  'postalCode',
  'neighborhood',
  'category',
  'area',
  'floor',
  'door',
];

export const ApartmentDetailsEdit: React.FC<{
  setEdit: (edit: boolean) => void;
}> = ({ setEdit }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    rent,
    allowance,
    monthlyCharges,
    paymentDay,
    id: leavingId,
  } = useLeaving();
  const {
    professionalPictures,
    pictures,
    ownership,
    residenceType,
    address,
    city,
    postalCode,
    neighborhood,
    category,
    area,
    floor,
    door,
    id: apartmentId,
  } = useApartment();

  const {
    addApartmentPictures,
    removeApartmentPicture,
    updateApartment,
    updateLeaving,
  } = useAppContext();

  const { apartmentCategories = [] } = useReferences();
  const handleDeletePicture = (deletedPictures: number[], id: number) => {
    return deletedPictures.includes(id)
      ? [...deletedPictures.filter((i) => i !== id)]
      : [...deletedPictures, id];
  };

  const handleDeleteNewPicture = (
    newPictures: AcceptedFiles[],
    index: number,
  ) => [...newPictures.filter((v, i) => i !== index)];

  const handleApartmentLeavingUpdate = async (submit: SFormSubmitProps) => {
    const updatedLeaving: Record<string, any> = {};
    const updatedApartment: Record<string, any> = {};
    submit.dirty.forEach((dirty) => {
      if (leavingDetailsKeys.includes(dirty)) {
        updatedLeaving[dirty] = submit.values[dirty];
      }

      if (apartmentDetailsKeys.includes(dirty)) {
        updatedApartment[dirty] = submit.values[dirty];
      }
    });

    return Promise.all([
      await (Object.keys(updatedApartment).length
        ? updateApartment(apartmentId, updatedApartment)
        : Promise.resolve()),
      await (Object.keys(updatedLeaving).length
        ? updateLeaving(leavingId, updatedLeaving)
        : Promise.resolve()),
    ]);
  };
  const handlePicturePost = async (acceptedFiles: File[]) => {
    const formData = new FormData();

    for (const file of Array.from(acceptedFiles))
      formData.append('pictures', file);

    return addApartmentPictures(apartmentId, formData);
  };

  const handleSubmit = async (event: FormEvent, submit: SFormSubmitProps) => {
    const { deletedPictures, newPictures } = submit.values;
    setLoading(true);

    try {
      await handleApartmentLeavingUpdate(submit);

      await Promise.all(
        deletedPictures.map((deletedPicture: number) =>
          removeApartmentPicture(apartmentId, deletedPicture),
        ),
      );

      if (submit.dirty.includes('newPictures') && newPictures.length)
        await handlePicturePost(newPictures);
    } catch (error) {
      toast.error(t('common.unhandledError'), {
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
      setEdit(false);
    }
  };

  return (
    <>
      <SForm
        initialValues={{
          ownership: t(`apartment.info.ownership.${ownership}`),
          residenceType,
          address,
          city,
          postalCode,
          neighborhood: neighborhood?.name,
          category,
          area,
          floor,
          door: door || '',
          rent,
          allowance,
          monthlyCharges,
          paymentDay: paymentDay || '-',
          deletedPictures: [] as number[],
          newPictures: [] as AcceptedFiles[],
        }}
        onSubmit={handleSubmit}
      >
        {({ values, setValue }: SFormSubmitProps) => (
          <>
            <div className="pictures-edit-container">
              {(professionalPictures.length
                ? professionalPictures
                : pictures
              ).map((picture, index) => {
                const isDeleted = values.deletedPictures.includes(picture.id);

                return (
                  <div key={`${picture.id}-${index}`}>
                    <Image document={picture} parameters={{ w: 500 }} />
                    <div className={isDeleted ? 'deleted' : ''} />
                    <AIcon
                      icon={isDeleted ? faTrashSlash : faTrash}
                      onClick={() =>
                        setValue(
                          'deletedPictures',
                          handleDeletePicture(
                            values.deletedPictures,
                            picture.id,
                          ),
                        )
                      }
                    />
                  </div>
                );
              })}
              {values.newPictures.map(
                (picture: AcceptedFiles, index: number) => (
                  <div className="new-picture" key={index}>
                    <img className="Image" src={URL.createObjectURL(picture)} />
                    <AIcon
                      icon={faTrash}
                      onClick={() =>
                        setValue(
                          'newPictures',
                          handleDeleteNewPicture(values.newPictures, index),
                        )
                      }
                    />
                  </div>
                ),
              )}
              <DropGalery values={values} setValue={setValue} />
            </div>

            <div>
              <div>
                <SEditRow name="ownership" label={t('apartment.form.status')}>
                  <SInput disabled />
                </SEditRow>
                <SEditRow
                  name="residenceType"
                  label={t('apartment.form.residenceType')}
                >
                  <SSelect
                    options={[
                      {
                        label: t('common.residenceTypes.primary'),
                        value: 'primary',
                      },
                      {
                        label: t('common.residenceTypes.secondary'),
                        value: 'secondary',
                      },
                    ]}
                  />
                </SEditRow>
                <SEditRow name="address" label={t('apartment.form.address')}>
                  <SInput disabled />
                </SEditRow>
                <SEditRow name="city" label={t('apartment.form.city')}>
                  <SInput disabled />
                </SEditRow>
                <SEditRow
                  name="postalCode"
                  label={t('apartment.form.postalCode')}
                >
                  <SInput disabled />
                </SEditRow>
              </div>
              <div>
                <SEditRow
                  name="neighborhood"
                  label={t('apartment.form.neighborhood')}
                >
                  <SInput disabled />
                </SEditRow>
                <SEditRow name="category" label={t('apartment.form.category')}>
                  <SSelect
                    options={apartmentCategories.map((el: string) => ({
                      label: t(`apartment.categories.${el}`),
                      value: el,
                    }))}
                  />
                </SEditRow>
                <SEditRow name="area" label={t('apartment.form.area')}>
                  <SInput pattern="\\d*" type="number" />
                </SEditRow>
                <SEditRow name="floor" label={t('apartment.form.floor')}>
                  <SInput pattern="\\d*" type="number" />
                </SEditRow>
                <SEditRow name="door" label={t('apartment.form.door')}>
                  <SInput pattern="\\d*" type="number" placeholder="-" />
                </SEditRow>
              </div>
            </div>
            <ASeparator />
            <div>
              <div>
                <SEditRow name="rent" label={t('apartment.form.rent')}>
                  <SInput disabled />
                </SEditRow>
                <SEditRow
                  name="paymentDay"
                  label={t('apartment.form.paymentDay')}
                >
                  <SInput disabled placeholder="-" />
                </SEditRow>
              </div>
              <div>
                <SEditRow
                  name="allowance"
                  label={t('apartment.form.allowance')}
                >
                  <SInput pattern="\\d*" type="number" />
                </SEditRow>
                <SEditRow
                  name="monthlyCharges"
                  label={t('apartment.form.monthlyCharges')}
                >
                  <SInput pattern="\\d*" type="number" />
                </SEditRow>
              </div>
            </div>
            <SButton
              className="validate-form-button"
              disabled={loading}
              type="submit"
              variant="secondary"
              label={t('settings.submit')}
            />
          </>
        )}
      </SForm>
    </>
  );
};

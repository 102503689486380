import React from 'react';

import './Step.scss';

export default function Step({
  children,
  img,
}: {
  children: React.ReactNode;
  img?: string;
}) {
  return (
    <div className="Step">
      {children}
      {img && (
        <div className="Step__background">
          <img src={img} />
        </div>
      )}
    </div>
  );
}

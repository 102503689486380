export default class Agency {
  id!: number;
  calendlyUrlForFirstCall?: string;
  calendlyUrlForVisit?: string;
  calendlyUrlForInventory?: string;

  constructor(data: Record<string, any>) {
    Object.assign(this, data);
  }
}

import React, { useState } from 'react';

import { faXmark, faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { AIcon, AIconProps, STitle } from '@smartrenting/smartomic';

import './EditableBlock.scss';

interface EditToggleBlock {
  editable?: boolean;
  title?: string;
  icon?: AIconProps['icon'];
  isEditDefault?: boolean;
  Edit?: (setEdit: (editStatus: boolean) => any, edit: boolean) => JSX.Element;
  Visualisation: () => JSX.Element;
}

export const EditableBlock: React.FC<EditToggleBlock> = ({
  editable = true,
  isEditDefault = false,
  title,
  icon,
  Edit,
  Visualisation,
}) => {
  const [isEdit, setEdit] = useState(isEditDefault);

  return (
    <div className="EditableBlock">
      <div
        className={`EditableBlock__header${
          !editable && !title ? '--unvisible' : ''
        }`}
      >
        <div className="EditableBlock__header__title">
          {icon && (
            <AIcon icon={icon} height={20} className="mr-3 default-cursor" />
          )}
          <STitle title={title} variant="body" />
        </div>
        <div className="EditableBlock__header__button">
          {editable && (
            <AIcon
              icon={isEdit ? faXmark : faPenToSquare}
              height={20}
              onClick={setEdit.bind(null, !isEdit)}
            />
          )}
        </div>
      </div>
      <div>
        {isEdit && editable && Edit ? Edit(setEdit, isEdit) : Visualisation()}
      </div>
    </div>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  faBedFront,
  faCouch,
  faShower,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { AIcon, ASeparator } from '@smartrenting/smartomic';

import { ROOM_ITEM_TYPE } from '@enum';

import RoomEdit from '../RoomEdit/RoomEdit';
import { RoomCardProps, RoomUpdate } from '../Rooms/RoomsHelper';

import './RoomCard.scss';

const RoomCard: React.FC<RoomCardProps> = ({ rooms, type, edit, onChange }) => {
  const { t } = useTranslation();
  const iconMap = {
    bedroom: faBedFront,
    livingroom: faCouch,
    bathroom: faShower,
  } as const;
  const icon = iconMap[type];

  const itemsCounter = rooms
    .reduce<(typeof ROOM_ITEM_TYPE)[keyof typeof ROOM_ITEM_TYPE][]>(
      (items, room) => [...items, ...room.items],
      [],
    )
    .reduce<
      Partial<
        Record<(typeof ROOM_ITEM_TYPE)[keyof typeof ROOM_ITEM_TYPE], number>
      >
    >((counter, type) => {
      const a = counter[type];
      counter[type] = a ? a + 1 : 1;

      return counter;
    }, {});

  const subtitle = (
    Object.entries(itemsCounter) as Array<
      [(typeof ROOM_ITEM_TYPE)[keyof typeof ROOM_ITEM_TYPE], number]
    >
  ).map(([type, count]) => t(`room-item.${type}`, { count }));

  const HandleChange = (room: RoomUpdate, index: number) => {
    onChange([...rooms.slice(0, index - 1), room, ...rooms.slice(index)], type);
  };

  const handleRemove = (index: number) => {
    onChange([...rooms.slice(0, index - 1), ...rooms.slice(index)], type);
  };

  const handleAdd = () => {
    onChange([...rooms, { type: type, items: [] }], type);
  };

  return (
    <div className="room-card">
      <div className="room-card-title">
        <AIcon icon={icon} type="regular" />
        <div>
          {t(`room.${type}`)} :{' '}
          {rooms.filter((room) => room.type === type).length}
        </div>
        {edit && (
          <div className="add-icon">
            <AIcon icon={faPlus} onClick={handleAdd} height={15} />
          </div>
        )}
      </div>
      {!edit && (
        <div className="room-card-info mt-1">{subtitle.join(', ')}</div>
      )}
      {edit &&
        rooms.map((room, index) => (
          <div key={index}>
            <ASeparator className="my-s" />
            <RoomEdit
              {...room}
              index={index + 1}
              onChange={HandleChange}
              handleRemove={handleRemove}
            />
          </div>
        ))}
    </div>
  );
};

export default RoomCard;

import React from 'react';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import moment from 'moment-timezone';

import { SModalPortal } from '@smartrenting/smartomic';

import { ReactToastifyPosition } from '@utils/constants';

import { AppProvider } from '@contexts/AppContext/AppContext';
import { EntitiesProvider } from '@contexts/EntitiesContext/EntitiesContext';

import { useInit } from '@hooks/useInit/useInit';

import Layout from '@components/Layout/Layout';

import Auth from '@scopes/Auth/Auth';
import WaitingSignature from '@scopes/Dashboard/components/WaitingSignature/WaitingSignature';

import { AppRoutes } from './AppRoutes';
import { routes } from './routes';

import './App.scss';

moment.locale('fr');

export function App() {
  useInit();

  return (
    <>
      <Routes>
        <Route path="/auth/*" element={<Auth />} />
        <Route
          path={routes.waitingMandate}
          element={<WaitingSignature type="mandate" />}
        />
        <Route
          path={routes.waintingInventory}
          element={<WaitingSignature type="inventory" />}
        />
        <Route path="/*" element={<Layout routes={<AppRoutes />} />} />
      </Routes>
    </>
  );
}

export default () => (
  <EntitiesProvider>
    <AppProvider>
      <BrowserRouter>
        <SModalPortal>
          <div className="App">
            <ToastContainer position={ReactToastifyPosition} theme="colored" />
            <App />
          </div>
        </SModalPortal>
      </BrowserRouter>
    </AppProvider>
  </EntitiesProvider>
);

import { Box, Typography } from '@mui/material';

import React from 'react';

import Apartment from '@models/Apartment';

import Image from '@components/Image/Image';

import './ApartmentCard.scss';

export default function ApartmentCard({
  apartment,
  isCurrent,
}: {
  apartment: Apartment;
  isCurrent?: boolean;
}) {
  return (
    <div className="ApartmentCard">
      <div className="ApartmentCard__picture">
        <Image
          document={apartment.getPicture()}
          parameters={{ h: 200, w: 200, crop: 'auto' }}
        />
      </div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="button"
          sx={{
            color: (theme) =>
              isCurrent ? theme.palette.primary.main : theme.palette.gray.main,
            textOverflow: 'ellipsis',
            fontWeight: 600,
          }}
        >
          {apartment.address}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            color: (theme) =>
              isCurrent ? theme.palette.primary.main : theme.palette.gray.main,
            textOverflow: 'ellipsis',
          }}
        >{`${apartment.postalCode} ${apartment.city}`}</Typography>
      </Box>
    </div>
  );
}

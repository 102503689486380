import { Card } from '@mui/material';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { AAvatar, SButton, SText, STitle } from '@smartrenting/smartomic';

import { PROSPECT_STATUS, ROLE } from '@enum';

import { useApartment } from '@hooks/useApartment/useApartment';
import { usePicture } from '@hooks/usePicture/usePicture';

import './ContactCard.scss';

export default function ContactCard() {
  const { t } = useTranslation();
  const apartment = useApartment();

  const contact =
    apartment.prospect?.status === PROSPECT_STATUS.SIGNED &&
    apartment?.customerExperience
      ? apartment?.customerExperience
      : apartment?.sale;

  const picture = usePicture(contact?.user?.profilePictureId);

  const redirectPhone = useCallback(() => {
    const a = document.createElement('a');

    a.href = `tel:+33624207541`;
    a.click();
    a.remove();
  }, []);

  const redirectMail = useCallback(() => {
    const a = document.createElement('a');

    a.href = `mailto:contact@leazly.fr`;
    a.click();
    a.remove();
  }, []);

  return (
    <Card className="ContactCard">
      {contact && (
        <STitle
          variant="body"
          title={t(
            `dashboard.sidebar.${
              apartment.prospect?.status !== PROSPECT_STATUS.SIGNED
                ? ROLE.SALE
                : 'customerExperience'
            }`,
          )}
        />
      )}
      <div className="ContactCard__top">
        <AAvatar picture={picture} initials={contact?.user?.initials || 'SR'} />
        <SText variant="body-title">{contact?.fullName || 'Leazly'}</SText>
      </div>
      <SText variant="body-subtitle">
        {t('dashboard.sidebar.contact.question', {
          firstname: contact?.user?.firstname || '',
        })}
      </SText>
      <div className="ContactCard__bottom">
        <SButton
          variant="secondary"
          icon={faEnvelope}
          label={t('dashboard.sidebar.contact.message')}
          onClick={redirectMail}
        />
        <SButton
          variant="secondary"
          icon={faPhone}
          outline
          label={'07 55 53 64 36'}
          onClick={redirectPhone}
        />
      </div>
    </Card>
  );
}

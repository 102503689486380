import { useEffect, useState } from 'react';

export function useScreenSize(): string {
  const screenSize = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  };
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return Object.entries(screenSize).reduce((acc, [key, value]) => {
    if (windowSize.width >= value) {
      return key;
    }

    return acc;
  }, 'xs');
}

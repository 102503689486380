import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';

import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { SButton, SSmartLoader } from '@smartrenting/smartomic';

import CONFIG from '@config';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useContact } from '@hooks/useContact/useContact';
import { useLeaving } from '@hooks/useLeaving/useLeaving';
import { useMobile } from '@hooks/useMobile/useMobile';

import './MandateSignature.scss';

export default function MandateSignature() {
  const { t } = useTranslation();
  const {
    state: { token },
  } = useAppContext();
  const leaving = useLeaving();
  const contact = useContact();

  const [error, setError] = useState(false);
  const [src, setSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [file, setFile] = useState(null);
  const isManual = !leaving?.mandateExternalId;
  const mobile = useMobile();

  const handlePreview = useCallback(async () => {
    const myHeaders = new Headers({ Authorization: token });

    setLoading(true);

    const result = await fetch(
      `${CONFIG.API_V2_URL}/documents/${leaving.mandateId}`,
      { headers: myHeaders },
    );

    if (result && result.ok) {
      const file = await result.blob();
      setFile(file as any);
    } else {
      setError(true);
    }

    setLoading(false);
  }, [leaving]);

  const handleDownload = useCallback(() => {
    const a = document.createElement('a');

    const mandateName = `MGL${
      leaving.id
    }${contact.user.lastname.toUpperCase()}.pdf`;

    a.href = URL.createObjectURL(file as any);
    a.download = mandateName;
    a.click();
    a.remove();
  }, [leaving, contact, file]);

  const handleActionClick = useCallback(() => {
    if (!isManual && src) window.open(src);
    else handleDownload();
  }, [isManual, src, handleDownload]);

  const handleFetchSignUrl = useCallback(async () => {
    const myHeaders = new Headers({ Authorization: token });

    const result = await (
      await fetch(
        `${CONFIG.API_V2_URL}/mandates/sign?leavingId=${leaving.id}`,
        {
          headers: myHeaders,
        },
      )
    ).json();

    if (result.url) return setSrc(result.url);

    return setError(true);
  }, [token]);

  useEffect(() => {
    if (token && !leaving.isSigned) {
      if (!isManual) handleFetchSignUrl();

      handlePreview();
    }
  }, [token, isManual, leaving.isSigned]);

  return (
    <>
      {!leaving.isSigned && (
        <div className="MandateSignature__container">
          {error && (
            <div className="MandateSignature__error">
              <h2 className="MandateSignature__title">
                {t('mandate.error.title')}
              </h2>
              <p className="MandateSignature__content">
                {t('mandate.error.content1')}
                <br />
                {t('mandate.error.content2')}
              </p>
              <div className="MandateSignature__box">
                <p>{t('mandate.error.boxContent')}</p>
                <a
                  className="MandateSignature__box__link"
                  href={CONFIG.WEBSITE_URL}
                >
                  <span className="mr-2">{t('mandate.error.boxLink')}</span>
                </a>
              </div>
            </div>
          )}
          {loading ? (
            <SSmartLoader />
          ) : (
            <>
              <div className="MandateSignature__container__pdfViewer">
                {file && (
                  <Document
                    file={file}
                    loading={t('common.loading')}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        width={mobile ? 280 : 600}
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        loading=""
                      />
                    ))}
                  </Document>
                )}
              </div>
              <div className="MandateSignature__container__buttonContainer">
                <SButton
                  variant="secondary"
                  disabled={!file}
                  label={
                    isManual
                      ? t('dashboard.mandate.download')
                      : t('dashboard.mandate.sign')
                  }
                  onClick={handleActionClick}
                />
                {!isManual && (
                  <SButton
                    variant="secondary"
                    icon={faDownload}
                    disabled={!file}
                    outline
                    onClick={handleDownload}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { routes } from 'src/routes';

import { SButton } from '@smartrenting/smartomic';

import { useProspect } from '@hooks/useProspect/useProspect';

import CapacityStep from './components/CapacityStep/CapacityStep';
import EquipmentsStep from './components/EquipmentsStep/EquipmentsStep';
import { GamificationPage } from './components/GamificationPage/GamificationPage';
import KeywordsStep from './components/KeywordsStep/KeywordsStep';
import NeighborhoodStep from './components/NeighborhoodStep/NeighborhoodStep';
import PhotoStep from './components/PhotoStep/PhotoStep';
import RoomsStep from './components/RoomsStep/RoomsStep';
import TypeStep from './components/TypeStep/TypeStep';
import {
  GamificationStep,
  useGamification,
} from './contexts/GamificationContext';

const GamificationComponent = {
  [GamificationStep.type]: TypeStep,
  [GamificationStep.capacity]: CapacityStep,
  [GamificationStep.rooms]: RoomsStep,
  [GamificationStep.equipments]: EquipmentsStep,
  [GamificationStep.keywords]: KeywordsStep,
  [GamificationStep.neighborhood]: NeighborhoodStep,
  [GamificationStep.photo]: PhotoStep,
};

export default function Gamification() {
  const prospect = useProspect();
  const { t } = useTranslation();
  const { step, onNext, handlePrev, disabled, loading } = useGamification();
  const navigate = useNavigate();

  if (prospect.gamification) {
    navigate(routes.dashboard);
  }

  return (
    <GamificationPage
      step={step}
      controls={
        <>
          <SButton
            outline
            onClick={handlePrev as any}
            disabled={loading || step === GamificationStep.type}
            variant="secondary"
            label={t('gamification.actions.previous')}
            data-testid="gamification-previous"
          />
          <SButton
            disabled={disabled || loading}
            onClick={onNext as any}
            label={t(
              `gamification.actions.${
                step === GamificationStep.photo ? 'finish' : 'next'
              }`,
            )}
            data-testid="gamification-next"
          />
        </>
      }
    >
      {Object.entries(GamificationStep).map(([key, value]) => {
        return step === value
          ? React.createElement(GamificationComponent[value], {
              key,
            })
          : null;
      })}
    </GamificationPage>
  );
}

import {
  Box,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPiggyBank } from '@fortawesome/pro-duotone-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TERM_PRICING_TYPE } from '@enum';

import Modality from '@models/Modality';

import { useLeaving } from '@hooks/useLeaving/useLeaving';
import { useTranslationFormat } from '@hooks/useTranslationFormat/useTranslationFormat';

import { useModalityMapper } from '../hooks/useModalityMapper';

interface StepRecapProps {
  onChoiceChange: (data: Record<string, any>) => void;
  modality: Modality | null;
}

export default function StepRecap({ modality }: StepRecapProps) {
  const { t } = useTranslation();
  const { t: translationFormat } = useTranslationFormat();

  const leaving = useLeaving();

  const dataPerModality = useModalityMapper(modality);

  const renderRent = () => {
    return (
      <Grid
        container
        sx={{
          alignItems: 'center',
          mb: '0.5rem',
          mt: '0.5rem',
        }}
      >
        <Grid item xs={2} sm={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '30px',
              height: '30px',

              borderRadius: '0.3rem',
              backgroundColor: (theme) => theme.palette.snow.main,
            }}
          >
            <Box
              component={FontAwesomeIcon}
              icon={faPiggyBank as IconProp}
              sx={{
                color: (theme) => theme.palette.sea.main,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Typography variant="body2" color="black.lighter">
            {t('boardOffice.steps.recap.referenceRent')}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3} display="flex" justifyContent="flex-end">
          <Chip
            sx={{
              color: (theme) => theme.palette.grass.main,
              backgroundColor: (theme) => theme.palette.mint.main,
              '.MuiChip-labelSmall': {
                textOverflow: 'unset',
              },
            }}
            size="small"
            label={`${computedMonthlyRevenu}€`}
          />
        </Grid>
      </Grid>
    );
  };

  const renderFee = (
    type: (typeof TERM_PRICING_TYPE)[keyof typeof TERM_PRICING_TYPE],
  ) => {
    return (
      <Grid
        container
        sx={{
          alignItems: 'center',
          mb: '0.5rem',
          mt: '0.5rem',
        }}
      >
        <Grid item xs={2} sm={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '30px',
              height: '30px',
              borderRadius: '0.3rem',
              backgroundColor: (theme) => theme.palette.snow.main,
            }}
          >
            <Box
              component={FontAwesomeIcon}
              icon={dataPerModality[type].icon as IconProp}
              sx={{
                color: (theme) => theme.palette.sea.main,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} display="flex">
          <Typography variant="body2" noWrap>
            {dataPerModality[type].label}
          </Typography>
          {dataPerModality[type].tooltipContent && (
            <Tooltip
              title={dataPerModality[type].tooltipContent}
              placement="right"
            >
              <Box
                component={FontAwesomeIcon}
                icon={faCircleInfo as IconProp}
                sx={{
                  color: (theme) => theme.palette.sea.main,
                  ml: 1,
                  mb: 1,
                }}
                size="xs"
              />
            </Tooltip>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          sm={3}
          display="flex"
          justifyContent="flex-end"
          sx={{
            '.MuiChip-labelSmall': {
              textOverflow: 'unset',
            },
          }}
        >
          {dataPerModality[type].amount === 0 ? (
            <Chip
              sx={{
                color: (theme) => theme.palette.grass.main,
                backgroundColor: (theme) => theme.palette.mint.main,
              }}
              size="small"
              label={t(
                type === TERM_PRICING_TYPE.SETUP_FEE
                  ? 'boardOffice.steps.recap.offered'
                  : 'boardOffice.free',
              )}
            />
          ) : (
            <Chip
              sx={{
                color: (theme) => theme.palette.primary.main,
                backgroundColor: (theme) => theme.palette.primary.light,
              }}
              size="small"
              label={`-${dataPerModality[type].amount}€`}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  const computedMonthlyFees = useMemo(
    () =>
      -(
        dataPerModality[TERM_PRICING_TYPE.EARLY_PAYMENT].amount +
        dataPerModality[TERM_PRICING_TYPE.PAID_INSURANCE]?.amount
      ),
    [leaving, dataPerModality],
  );

  const computedMonthlyRevenu = useMemo(
    () =>
      (leaving.rent || 0) +
      (leaving.monthlyCharges || 0) -
      (leaving.allowance || 0),
    [leaving, dataPerModality],
  );

  const computedFixedFees = useMemo(
    () =>
      -dataPerModality[TERM_PRICING_TYPE.PAID_CLEANING].amount -
      dataPerModality[TERM_PRICING_TYPE.PAID_KEY].amount -
      dataPerModality[TERM_PRICING_TYPE.SETUP_FEE].amount,
    [dataPerModality],
  );

  return (
    <Box width={{ xs: '100%', md: '90%' }}>
      <Paper
        elevation={1}
        sx={{
          padding: '1rem',
          borderRadius: 4,
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '1rem',
            backgroundColor: (theme) => theme.palette.secondary.lighter,
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" align="center" fontWeight={500}>
            {t('boardOffice.steps.recap.revenue')}
          </Typography>
          <Typography variant="h3" align="center">
            {computedMonthlyRevenu + computedMonthlyFees} €
          </Typography>
          <Typography
            variant="subtitle2"
            align="center"
            fontSize={{ xs: 12, sm: 14 }}
          >
            {translationFormat('boardOffice.steps.recap.period', {
              from: leaving.startDate.format('DD/MM/YYYY'),
              to: leaving.endDate.format('DD/MM/YYYY'),
            } as any)}
          </Typography>
        </Paper>
        <Grid
          container
          columnSpacing={1}
          sx={{ display: 'flex', mt: '1rem' }}
          rowGap={1}
        >
          <Grid item xs={12} sm={6}>
            <Typography
              variant="subtitle2"
              fontSize={{ xs: 12, sm: 14 }}
              sx={{
                fontWeight: '600',
                color: 'grey.700',
              }}
            >
              {t('boardOffice.steps.recap.monthlyFees')}
            </Typography>
            {renderRent()}
            {renderFee(TERM_PRICING_TYPE.EARLY_PAYMENT)}
            {renderFee(TERM_PRICING_TYPE.PAID_INSURANCE)}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="subtitle2"
              fontSize={{ xs: 12, sm: 14 }}
              sx={{
                fontWeight: '600',
                color: 'grey.700',
              }}
            >
              {t('boardOffice.steps.recap.punctualFees')}
            </Typography>
            {renderFee(TERM_PRICING_TYPE.SETUP_FEE)}
            {renderFee(TERM_PRICING_TYPE.PAID_CLEANING)}
            {renderFee(TERM_PRICING_TYPE.PAID_KEY)}
          </Grid>
        </Grid>
        <Divider sx={{ my: 1 }} />
        <Typography
          variant="subtitle2"
          fontSize={{ xs: 12, sm: 14 }}
          color="grey.700"
          fontWeight={600}
          pt={1}
        >
          {t('boardOffice.steps.recap.details')}
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            pl: 2,
            py: 0,
            '& .MuiListItem-root': {
              display: 'list-item',
              p: 0,
            },
          }}
        >
          <ListItem>
            <Stack direction="row" justifyContent="space-between">
              <Typography color="black.lighter" variant="body2">
                {t('boardOffice.steps.recap.fee')}
              </Typography>
              <Typography
                color="black.lighter"
                variant="body2"
                fontWeight={500}
              >
                {computedFixedFees}€
              </Typography>
            </Stack>
          </ListItem>
          <ListItem>
            <Stack direction="row" justifyContent="space-between">
              <Typography color="black.lighter" variant="body2">
                {t('boardOffice.steps.recap.receivedRent')}
              </Typography>
              <Typography
                color="black.lighter"
                variant="body2"
                fontWeight={500}
              >
                {(
                  computedMonthlyRevenu +
                  computedMonthlyFees +
                  computedFixedFees
                ).toFixed(2)}
                €
              </Typography>
            </Stack>
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
}

import React from 'react';

import './BurgerMenu.scss';

export default function BurgerMenu() {
  return (
    <div className="BurgerMenu">
      <div className="BurgerMenu__bar" />
      <div className="BurgerMenu__bar" />
      <div className="BurgerMenu__bar" />
    </div>
  );
}

import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AFieldErrors,
  ASeparator,
  SButton,
  SEditRow,
  SForm,
  SFormSubmitProps,
  SInput,
  STitle,
} from '@smartrenting/smartomic';

import { toastPromise } from '@utils/tools';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useContact } from '@hooks/useContact/useContact';

import securitySVG from '../assets/security.svg';

import './Security.scss';

export default function Security() {
  const { t } = useTranslation();
  const contact = useContact();
  const [invalidPassword, setInvalidPassword] = useState(false);
  const { createNewPassword } = useAppContext();

  const onSubmit = async (
    event: FormEvent,
    { values, reset }: SFormSubmitProps,
  ) => {
    if (values.password !== values.confirmation) {
      setInvalidPassword(true);

      return;
    }

    toastPromise(createNewPassword(values.password))
      .then(reset)
      .finally(() => {
        setInvalidPassword(false);
      });
  };

  return (
    <div className="Security">
      <SForm
        onSubmit={onSubmit}
        initialValues={{
          password: '',
          confirmation: '',
        }}
      >
        <div className="Security__container">
          <SEditRow label={t('settings.security.email')}>
            <SInput value={contact.user.email} disabled />
          </SEditRow>
        </div>
        <ASeparator />
        <STitle
          className="Security__title"
          variant="body"
          title={t(`settings.security.title`)}
          subtitle={t(`settings.security.subtitle`)}
        />
        <div className="Security__container">
          <SEditRow
            name="password"
            type="password"
            label={t('settings.security.new-password')}
            required
          >
            <SInput />
          </SEditRow>
        </div>
        <div className="Security__container">
          <SEditRow
            name="confirmation"
            type="password"
            label={t('settings.security.confirm-password')}
            required
          >
            <>
              <SInput />
              {invalidPassword && (
                <AFieldErrors
                  errors={[
                    t('auth.error.error'),
                    t('auth.error.passwordInvalid'),
                  ]}
                />
              )}
            </>
          </SEditRow>
        </div>
        <div className="Security__row">
          <SButton
            variant="secondary"
            type="submit"
            label={t('settings.submit')}
          />
        </div>
      </SForm>
      <div className="Security__image">
        <img src={securitySVG} />
      </div>
    </div>
  );
}

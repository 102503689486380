import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { OWNERSHIP } from '@enum';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';
import { useProspect } from '@hooks/useProspect/useProspect';

import Calendly, { CalendlyType } from '@components/Calendly/Calendly';
import Scheduler, { SchedulerLogoType } from '@components/Scheduler/Scheduler';

import AuthorizationTenant from '../../AuthorizationTenant/AuthorizationTenant';
import LinkToStep from '../components/LinkToStep/LinkToStep';
import Step from '../components/Step/Step';

import visitSvg from './visit.svg';

function Visit() {
  const { t } = useTranslation();
  const prospect = useProspect();
  const { updateProspectStatus } = useAppContext();
  const leaving = useLeaving();
  const apartment = useApartment();

  const navigate = useNavigate();
  const handleClickLink = useCallback(() => navigate('/documents'), []);

  return (
    <Step img={visitSvg}>
      <Scheduler
        duration={30}
        type={SchedulerLogoType[SchedulerLogoType.visit]}
        onChange={null}
      >
        <Calendly
          type={CalendlyType.visit}
          onChange={updateProspectStatus.bind(
            null,
            prospect.id,
            'visit-scheduled',
          )}
        />
      </Scheduler>
      {apartment.ownership === OWNERSHIP.TENANT &&
      !leaving.authorizationDocument ? (
        <AuthorizationTenant callScheduled withFooter />
      ) : (
        <>
          <LinkToStep
            text={t('dashboard.documentsLink')}
            onClick={handleClickLink}
          />
        </>
      )}
    </Step>
  );
}

export default Visit;

import React from 'react';

import { SText } from '@smartrenting/smartomic';

import { classNames } from '@utils/helpers';

import './AuthPage.scss';

interface AuthPageProps {
  title: string;

  subtitle: string;
  form: React.ReactNode;
  className?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >['className'];
  children?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >['children'];
}

export default function AuthPage({
  title,
  subtitle,
  form,
  className = '',
  children = null,
}: AuthPageProps) {
  return (
    <div className={classNames('AuthPage', className)}>
      <div className="AuthPage__title-content">
        <SText variant="title" className="AuthPage__title">
          {title}
        </SText>
        <SText variant="body-title" className="AuthPage__subtitle">
          {subtitle}
        </SText>
      </div>
      <div className="AuthPage__form">{form}</div>
      {children}
    </div>
  );
}

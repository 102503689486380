import CONFIG from '@config';

const queryToSearchParams = (query?: Record<string, any>) => {
  if (!query || Object.keys(query).length === 0) {
    return '';
  }

  const params = Object.entries(query).reduce(
    (acc, [key, value], index, arr) => {
      let queryPart: string;

      if (Array.isArray(value)) {
        queryPart = value.map((val: string) => `${key}=${val}`).join('&');
      } else {
        queryPart = `${key}=${value}`;
      }

      if (index !== arr.length - 1) return acc + queryPart + '&';

      return acc + queryPart;
    },
    '',
  );

  return '?' + params;
};

export async function fetchApi<T>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  path: string,
  token?: string,
  params: {
    query?: Record<string, any>;
    body?: Record<string, any>;
    headers?: Record<string, any>;
  } = {},
  signal?: AbortSignal,
): Promise<T> {
  const headers = new Headers();

  if (token) {
    headers.append('Authorization', token);
  }

  if (params.body && !(params.body instanceof FormData)) {
    headers.append('Content-Type', 'application/json');
  }

  Object.entries(params.headers || {}).forEach(([key, value]) => {
    headers.append(key, value);
  });

  const response = await fetch(
    `${CONFIG.API_V2_URL}${path}${queryToSearchParams(params.query)}`,
    {
      method,
      headers: headers,
      signal,
      body:
        params.body && !(params.body instanceof FormData)
          ? JSON.stringify(params.body)
          : params.body,
    },
  );

  if (!response.ok) {
    throw (await response.json()) as {
      statusCode: number;
      error: string;
      message: string;
    };
  }

  if (response.headers.get('Content-Type')?.includes('application/json')) {
    return response.json();
  }

  if (response.headers.get('Content-Type')?.startsWith('image/')) {
    return response.blob() as any as T;
  }

  return response.text() as any;
}

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { routes } from 'src/routes';

import {
  SButton,
  SEditRow,
  SForm,
  SFormChangeProps,
  SFormSubmitProps,
  SInput,
  SText,
} from '@smartrenting/smartomic';

import { useAppContext } from '@contexts/AppContext/AppContext';

import AuthPage from '../components/AuthPage/AuthPage';
import useRedirectToLogin from '../useRedirectToLogin/useRedirectToLogin';

import './Forgotten.scss';

export default function Forgotten() {
  const { t } = useTranslation();
  const { forgotPassword } = useAppContext();
  const [mailSent, setMailSent] = useState(false);
  const redirectToLogin = useRedirectToLogin();

  const onSubmit = useCallback(
    async (event: React.FormEvent, { values }: SFormSubmitProps) => {
      try {
        await forgotPassword(values.email);
      } catch {
        void 0;
      }
      setMailSent(true);
      toast.success(t('auth.forgotten.mailSentSuccesfully'), {
        onClose: redirectToLogin,
        autoClose: 4000,
      });
    },
    [forgotPassword],
  );

  return (
    <AuthPage
      className="Forgotten"
      title={t('auth.forgotten.title')}
      subtitle={
        mailSent ? t('auth.forgotten.mailSent') : t('auth.forgotten.subtitle')
      }
      form={
        <SForm
          onSubmit={onSubmit}
          initialValues={{
            email: '',
          }}
        >
          {({ validateRules }: SFormChangeProps) => (
            <>
              {!mailSent && (
                <>
                  <SEditRow
                    name="email"
                    label={t('auth.forgotten.email')}
                    validate={validateRules.validateEmail}
                  >
                    <SInput />
                  </SEditRow>
                  <SButton variant="secondary" type="submit">
                    {t('auth.forgotten.submit')}
                  </SButton>
                </>
              )}
            </>
          )}
        </SForm>
      }
    >
      {!mailSent && (
        <Link to={routes.login} className="ALink">
          <SText variant="body-title">{t('auth.forgotten.login')}</SText>
        </Link>
      )}
    </AuthPage>
  );
}

import { useEffect, useState } from 'react';

import CONFIG from '@config';

import { useAppContext } from '@contexts/AppContext/AppContext';

export const usePicture = (documentId: number) => {
  const {
    state: { token },
  } = useAppContext();
  const [picture, setPicture] = useState<string>();
  const myHeaders = new Headers({ authorization: token });

  useEffect(() => {
    if (documentId && !picture) {
      fetch(
        `${CONFIG.API_V2_URL}/documents/${documentId}/image?w=500&crop=auto`,
        {
          headers: myHeaders,
        },
      ).then(async (res) => {
        setPicture(URL.createObjectURL(await res.blob()));
      });
    }
  }, [documentId]);

  return picture;
};

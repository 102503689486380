import React from 'react';

import { SText } from '@smartrenting/smartomic';

import { ApartmentKey } from '@models/ApartmentKey';
import KeySetup from '@models/KeySetup';

import { useTranslationFormat } from '@hooks/useTranslationFormat/useTranslationFormat';

export default function KeyNestHelp({
  keyInfos,
  processType,
  sameTypeDifferentLocation,
}: {
  processType: 'setup' | 'unsetup';
  keyInfos: ApartmentKey | KeySetup;
  sameTypeDifferentLocation: boolean;
}) {
  const { t } = useTranslationFormat();

  return (
    <>
      <SText className="KeysHelp__content__keys__title" variant="body-title">
        {t(
          `${processType}.help.keynest.${
            sameTypeDifferentLocation ? 'different' : 'same'
          }.title_1`,
        )}
      </SText>
      <SText
        className="KeysHelp__content__keys__subtitle"
        variant="body-subtitle"
      >
        {t(
          `${processType}.help.keynest.${
            sameTypeDifferentLocation ? 'different' : 'same'
          }.text_1`,
          {
            address: keyInfos.locationAddress,
          } as any,
        )}
      </SText>
      <SText className="KeysHelp__content__keys__title" variant="body-title">
        {t(
          `${processType}.help.keynest.${
            sameTypeDifferentLocation ? 'different' : 'same'
          }.title_2`,
        )}
      </SText>
      <SText
        className="KeysHelp__content__keys__subtitle"
        variant="body-subtitle"
      >
        {t(
          `${processType}.help.keynest.${
            sameTypeDifferentLocation ? 'different' : 'same'
          }.text_2`,
          {
            name: keyInfos.locationName,
          } as any,
        )}
      </SText>
      <SText className="KeysHelp__content__keys__title" variant="body-title">
        {t(
          `${processType}.help.keynest.${
            sameTypeDifferentLocation ? 'different' : 'same'
          }.title_3`,
        )}
      </SText>
      <SText
        className="KeysHelp__content__keys__subtitle"
        variant="body-subtitle"
      >
        {t(
          `${processType}.help.keynest.${
            sameTypeDifferentLocation ? 'different' : 'same'
          }.text_3`,
        )}
      </SText>
      <SText
        className="KeysHelp__content__keys__subtitle"
        variant="body-subtitle"
      >
        {t(
          `${processType}.help.keynest.${
            sameTypeDifferentLocation ? 'different' : 'same'
          }.infos`,
        )}
      </SText>
    </>
  );
}

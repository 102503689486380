import { LoadingButton } from '@mui/lab';
import { Box, Typography, Stack, Button, Modal, useTheme } from '@mui/material';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import {
  faThumbsUp,
  faPhone,
  faHandshake,
  faCircleInfo,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CONFIG from '@config';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useLeaving } from '@hooks/useLeaving/useLeaving';

import { AuthorizationSvg } from './AuthorizationSvg';
import { NeedHelpSvg } from './NeedHelpSvg';

export default function AuthorizationTenant({
  callScheduled,
  withFooter,
}: {
  callScheduled?: boolean;
  withFooter?: boolean;
}) {
  const { t } = useTranslation();
  const leaving = useLeaving();
  const theme = useTheme();
  const {
    state: { token },
    generateAuthorizationDocument,
  } = useAppContext();

  const alreadyOpened = localStorage.getItem('tenant-modal') ? false : true;

  const [open, setOpen] = useState(alreadyOpened);
  const [loading, setLoading] = useState(false);
  const [needHelp, setNeedHelp] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    const leavingWithDocument = await generateAuthorizationDocument(leaving.id);

    const myHeaders = new Headers({ authorization: token });

    await fetch(
      `${CONFIG.API_V2_URL}/documents/${leavingWithDocument.authorizationDocument.id}`,
      {
        headers: myHeaders,
      },
    ).then(async (res) => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(await res.blob());
      a.download = `authorization-document-${leaving.id}.pdf`;
      a.click();
      a.remove();
      setLoading(false);
    });
  };
  const handleHelp = () => {
    setOpen(true);
    setNeedHelp(true);
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('tenant-modal', 'true');
  };

  const handleOpenTips = () => {
    const url =
      'https://leazly.fr/blog/articles/sous-location-convaincre-proprietaire';
    window.open(url, '_blank');
  };

  const AuthRentModal = () => (
    <>
      <AuthorizationSvg />
      <Stack spacing="4px">
        <Typography sx={{ textAlign: 'center' }} variant="h6" color="black">
          {t('authorization-tenant.modal1.title')}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ textAlign: 'center' }}
          fontWeight="300"
          color={theme.palette.gray.main}
        >
          {t('authorization-tenant.modal1.subtitle')}
        </Typography>
      </Stack>
      <Stack spacing="16px">
        <LoadingButton
          variant="contained"
          style={{ padding: '10px 60px' }}
          loading={loading}
          loadingPosition="start"
          startIcon={<></>}
          onClick={handleDownload}
        >
          {t('authorization-tenant.modal1.button1')}
        </LoadingButton>
        <Button
          variant="outlined"
          style={{ padding: '10px 60px' }}
          color="gray"
          onClick={handleHelp}
        >
          {t('authorization-tenant.modal1.button2')}
        </Button>
      </Stack>
    </>
  );

  const NeedHelpModal = () => (
    <>
      <NeedHelpSvg />
      <Stack spacing="4px">
        <Typography sx={{ textAlign: 'center' }} variant="h6" color="black">
          {t('authorization-tenant.modal2.title')}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ textAlign: 'center' }}
          fontWeight="300"
          color={theme.palette.gray.main}
        >
          {t('authorization-tenant.modal2.subtitle')}
        </Typography>
      </Stack>
      <Stack spacing="16px">
        <LoadingButton
          variant="contained"
          loading={loading}
          loadingPosition="start"
          style={{ padding: '10px 60px' }}
          onClick={handleDownload}
          startIcon={<FontAwesomeIcon icon={faThumbsUp as IconProp} />}
        >
          {t('authorization-tenant.modal2.button1')}
        </LoadingButton>
        {!callScheduled && (
          <Button
            variant="outlined"
            style={{ padding: '10px 40px' }}
            color="gray"
            onClick={handleClose}
            startIcon={<FontAwesomeIcon icon={faPhone as IconProp} />}
          >
            {t('authorization-tenant.modal2.button2')}
          </Button>
        )}
        <Button
          variant="outlined"
          style={{ padding: '10px 50px' }}
          color="gray"
          onClick={handleOpenTips}
          startIcon={<FontAwesomeIcon icon={faHandshake as IconProp} />}
        >
          {t('authorization-tenant.modal2.button3')}
        </Button>
      </Stack>
    </>
  );

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            height: { xs: '100%', sm: 'auto' },
            width: { xs: '100%', sm: 'auto' },
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: { xs: 0, sm: 4 },
            boxShadow: 24,
            py: 12,
            px: { xs: 4, sm: 12 },
          }}
        >
          <Stack>
            <FontAwesomeIcon
              style={{
                position: 'absolute',
                top: 30,
                right: 30,
                cursor: 'pointer',
              }}
              onClick={handleClose}
              icon={faXmark as IconProp}
              size="2x"
              color="#D7D7D7"
            />
            <Stack
              spacing="36px"
              color="black"
              justifyContent="center"
              alignItems="center"
            >
              {needHelp ? NeedHelpModal() : AuthRentModal()}
            </Stack>
          </Stack>
        </Box>
      </Modal>
      {withFooter && (
        <Stack
          marginTop={4}
          flexDirection="row"
          sx={{
            backgroundColor: theme.palette.gray.lighter,
            borderRadius: '6px',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
            p: { xs: '10px', sm: '11px 16px' },
          }}
          width="100%"
        >
          <Box
            component={FontAwesomeIcon}
            icon={faCircleInfo as IconProp}
            height={{ xs: '11px', sm: '20px' }}
            color={theme.palette.gray.dark}
            mr="19px"
          />
          <Stack mt="4px">
            <Typography
              color={theme.palette.gray.dark}
              fontSize="14px"
              fontStyle="normal"
              lineHeight="14px"
              fontWeight="600"
              mb="8px"
            >
              {t('authorization-tenant.footer.title')}
            </Typography>
            <Typography
              color={theme.palette.gray.light}
              fontSize="12px"
              fontStyle="normal"
              lineHeight="14px"
              fontWeight="400"
            >
              {t('authorization-tenant.footer.subtitle')}
            </Typography>
            <Stack flexDirection="row">
              <LoadingButton
                variant="contained"
                style={{
                  backgroundColor: theme.palette.gray.dark,
                  border: `0.5px solid ${theme.palette.gray.dark}`,
                  marginRight: '16px',
                  marginTop: '16px',
                  padding: '8px 40px',
                }}
                loading={loading}
                loadingPosition="start"
                startIcon={<></>}
                onClick={handleDownload}
              >
                <Typography
                  color={theme.palette.primary.contrastText}
                  fontSize="12px"
                  fontStyle="normal"
                  lineHeight="normal"
                  fontWeight="500"
                >
                  {t('authorization-tenant.modal1.button1')}
                </Typography>
              </LoadingButton>
              <Button
                variant="outlined"
                style={{
                  backgroundColor: theme.palette.gray.lighter,
                  border: `0.5px solid ${theme.palette.gray.dark}`,
                  marginRight: '16px',
                  marginTop: '16px',
                  padding: '8px 40px',
                }}
                onClick={handleHelp}
              >
                <Typography
                  color={theme.palette.gray.dark}
                  fontSize="12px"
                  fontStyle="normal"
                  lineHeight="normal"
                  fontWeight="500"
                >
                  {t('authorization-tenant.modal1.button2')}
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
}

import React from 'react';

import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { AIcon, SText } from '@smartrenting/smartomic';

import './LinkToStep.scss';

interface LinkToStepProps {
  text: string;
  onClick: () => void;
}

export default function LinkToStep({ text, onClick }: LinkToStepProps) {
  return (
    <div className="LinkToStep" onClick={onClick}>
      <div className="LinkToStep__logo">
        <AIcon icon={faClock} />
      </div>
      <div className="LinkToStep__text">
        <SText variant="body-subtitle">{text}</SText>
      </div>
    </div>
  );
}

import { Paper, Box, Typography } from '@mui/material';

import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PriceSvg } from './svg';

interface ModalityChoiceProps {
  title: string;
  subtitle: string;
  header: string;
  icon: IconProp;
  active: boolean;
}

export default function ModalityChoice({
  title,
  subtitle,
  header,
  icon,
  active,
}: ModalityChoiceProps) {
  return (
    <Paper
      variant="outlined"
      sx={{
        height: { xs: 150, sm: 220 },
        width: { xs: 270, sm: 310 },
        padding: '1.5rem',
        borderRadius: 4,
        ...(active
          ? {
              border: 'solid',
              borderColor: (theme) => theme.palette.sea.main,
              backgroundColor: (theme) => theme.palette.snow.main,
            }
          : {
              backgroundColor: (theme) => theme.palette.gray.lighter,
            }),

        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box
          component={FontAwesomeIcon}
          icon={icon}
          sx={{
            fontSize: { xs: '1rem', sm: '2rem' },
            color: (theme) =>
              active ? theme.palette.sea.main : theme.palette.black.lighter,
            pb: { xs: 0, sm: '1rem' },
          }}
        />
        <Box mt={-3} position="relative" textAlign="center">
          <PriceSvg
            width={{ xs: 50, sm: 70 }}
            sx={(theme) => ({
              fill: active ? theme.palette.sea.main : theme.palette.gray.dark,
            })}
          />
          <Typography
            variant="body2"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -70%)',
              fontWeight: 'bold',
              fontSize: '0.5rem',
              lineHeight: 1,
              color: (theme) => theme.palette.coconut.main,
            }}
          >
            {header}
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          fontWeight: 'bold',
          color: (theme) =>
            active ? theme.palette.sea.main : theme.palette.black.lighter,
          pb: { xs: 0, sm: '1rem' },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: (theme) => theme.palette.black.lighter,
        }}
      >
        {subtitle}
      </Typography>
    </Paper>
  );
}

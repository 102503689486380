import React, { useState } from 'react';
import { useLocation } from 'react-router';

import { routes as routesList } from 'src/routes';

import { classNames } from '@utils/helpers';

import BurgerMenu from '@components/BurgerMenu/BurgerMenu';
import Logo from '@components/Logo/Logo';
import Navigation from '@components/Navigation/Navigation';

import './Layout.scss';

const noNavigationRoutes = [
  routesList.root,
  routesList.gamification,
  routesList.estimation,
];

export default function Layout({ routes = null }: { routes: React.ReactNode }) {
  const [navigation, setNavigation] = useState(false);
  const location = useLocation();

  const noNavigation = noNavigationRoutes.includes(location.pathname);

  return (
    <div className="Layout">
      <div className="Layout__header">
        <div
          className={classNames(
            'Layout__header__menu',
            noNavigation && 'Layout__header__menu--hidden',
          )}
          onClick={() => setNavigation(!navigation)}
        >
          <BurgerMenu />
        </div>
        <div className="Layout__header__logo">
          <Logo />
        </div>
      </div>
      <div className="Layout__body">
        <div
          className={classNames(
            'Layout__body__shadow',
            navigation ? 'Layout__body__shadow--open' : '',
          )}
          onClick={() => setNavigation(false)}
        />
        <Navigation
          className={classNames(
            navigation ? 'Navigation--open' : '',
            noNavigation && 'Navigation--hidden',
          )}
          location={location.pathname}
        />
        {routes}
      </div>
    </div>
  );
}

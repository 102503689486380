import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SText } from '@smartrenting/smartomic';

import { classNames } from '@utils/helpers';

import './GamificationPage.scss';

export function GamificationPage({
  step,
  controls,
  children,
}: {
  step: number;
  controls: React.ReactNode;
  children: React.ReactNode;
}) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(
    /* istanbul ignore next */
    () => {
      if (ref.current && ref.current.scrollTo) {
        ref.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    [step],
  );

  return (
    <div className="GamificationPage">
      <div
        className="GamificationPage__question"
        data-testid="gamification-step-title"
      >
        <SText variant="title" align="left">
          {t(`gamification.questions.step${step}`)}
        </SText>
      </div>
      <div className="GamificationPage__content">
        <div className="GamificationPage__content__children" ref={ref}>
          {children}
        </div>
        <div className="GamificationPage__content__controls">
          <div
            className={classNames(
              'GamificationPage__content__controls__bar',
              `GamificationPage__content__controls__bar--${step}`,
            )}
          ></div>
          <div className="GamificationPage__content__controls__button">
            {controls}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import moment from 'moment-timezone';

import {
  AButtonGroup,
  SButton,
  SDateTimePicker,
  SEditRow,
  SForm,
  SInput,
  STitle,
} from '@smartrenting/smartomic';
import type { SFormSubmitProps } from '@smartrenting/smartomic';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useLeaving } from '@hooks/useLeaving/useLeaving';

import hostReturnSVG from '../assets/hostReturn.svg';

import './HostReturn.scss';

export default function HostReturn() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const leaving = useLeaving();
  const { updateLeaving } = useAppContext();

  const formattedTime = moment(leaving.hostReturnTime, 'HH:mm').format('HH:mm');

  const handleSubmit = React.useCallback(
    async (event: FormEvent, formProps: SFormSubmitProps) => {
      const { touched = [], values, reset } = formProps;

      event.preventDefault();
      event.stopPropagation();

      const modifications = touched.reduce((acc: Record<string, any>, name) => {
        acc[name] = values[name];

        return acc;
      }, {});

      if (Object.values(modifications).length === 0) return;

      await updateLeaving(leaving.id, modifications);

      reset();
      navigate('/dashboard');
    },
    [updateLeaving],
  );

  return (
    <div className="HostReturn">
      <SForm
        onSubmit={handleSubmit}
        initialValues={{
          hostReturnDate: leaving.hostReturnDate,
          hostReturnTime: leaving.hostReturnTime ? formattedTime : '',
          hostHasKey: leaving.hostHasKey,
        }}
      >
        {({ dirty }) => {
          return (
            <>
              <STitle
                variant="body"
                title={t('hostReturn.question.returnDate')}
              />
              <div className="HostReturn__returnDateTime">
                <SEditRow
                  className="HostReturn__returnDateTime__date"
                  name="hostReturnDate"
                  label={t('hostReturn.form.hostReturnDate')}
                >
                  <SDateTimePicker min={leaving.endDate} />
                </SEditRow>
                <SEditRow
                  className="HostReturn__returnDateTime__time"
                  name="hostReturnTime"
                  type="time"
                  label={t('hostReturn.form.hostReturnTime')}
                >
                  <SInput />
                </SEditRow>
              </div>
              <STitle
                variant="body"
                title={t('hostReturn.question.hostHasKey')}
              />
              <SEditRow className="HostReturn__hasKey" name="hostHasKey">
                <AButtonGroup />
              </SEditRow>
              <SButton
                disabled={dirty.length === 0}
                label={t('common.finish')}
                type="submit"
              />
            </>
          );
        }}
      </SForm>
      <div className="HostReturn__image">
        <img src={hostReturnSVG} />
      </div>
    </div>
  );
}

import { Alert, AlertTitle, Box } from '@mui/material';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SText } from '@smartrenting/smartomic';

import { OWNERSHIP, PROSPECT_STATUS } from '@enum';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';
import { useProspect } from '@hooks/useProspect/useProspect';

import Calendly, { CalendlyType } from '@components/Calendly/Calendly';
import Scheduler, { SchedulerLogoType } from '@components/Scheduler/Scheduler';

import AuthorizationTenant from '../../AuthorizationTenant/AuthorizationTenant';
import LinkToStep from '../components/LinkToStep/LinkToStep';
import Step from '../components/Step/Step';

import phoneSvg from './phone.svg';

import './Phone.scss';

export default function Phone() {
  const prospect = useProspect();
  const { updateProspectStatus } = useAppContext();
  const { t } = useTranslation();
  const leaving = useLeaving();
  const apartment = useApartment();

  const navigate = useNavigate();
  const handleClickLink = useCallback(() => navigate('/documents'), []);

  if (prospect.status === PROSPECT_STATUS.PHONE_CALL) {
    return (
      <div className="Phone">
        <Alert
          severity="success"
          icon={
            <Box
              component={FontAwesomeIcon}
              icon={faCheck as any}
              size="1x"
              my="auto"
            />
          }
          sx={{ width: '100%' }}
        >
          <AlertTitle color="success.main">
            {t('dashboard.phone-call.complete.title')}
          </AlertTitle>
          {t('dashboard.phone-call.complete.subtitle')}
        </Alert>
        {prospect.phoneCallDate && (
          <div className="Phone__content">
            <SText variant="body-title" align="center">
              {t('dashboard.phone-call.schedule')}
            </SText>
            <SText variant="title" className="Phone__content__subtitle">
              {prospect.phoneCallDate.format('LL à LT')}
            </SText>
          </div>
        )}
        {apartment.ownership === OWNERSHIP.TENANT &&
        !leaving.authorizationDocument ? (
          <AuthorizationTenant callScheduled withFooter />
        ) : (
          <div className="Phone__footer">
            <LinkToStep
              text={t('dashboard.documentsLink')}
              onClick={handleClickLink}
            />
            <img src={phoneSvg} />
          </div>
        )}
      </div>
    );
  }

  return (
    <Step>
      <Scheduler
        duration={15}
        type={SchedulerLogoType[SchedulerLogoType.phone]}
        onChange={null}
      >
        <Calendly
          type={CalendlyType.phone}
          onChange={updateProspectStatus.bind(null, prospect.id, 'phone-call')}
        />
      </Scheduler>
      {apartment.ownership === OWNERSHIP.TENANT &&
      !leaving.authorizationDocument ? (
        <AuthorizationTenant withFooter />
      ) : (
        <>
          <LinkToStep
            text={t('dashboard.documentsLink')}
            onClick={handleClickLink}
          />
        </>
      )}
    </Step>
  );
}

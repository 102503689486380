import { Alert, AlertTitle, Box } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment-timezone';

import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useLeaving } from '@hooks/useLeaving/useLeaving';
import { useTranslationFormat } from '@hooks/useTranslationFormat/useTranslationFormat';

import Calendly, { CalendlyType } from '@components/Calendly/Calendly';
import Scheduler, { SchedulerLogoType } from '@components/Scheduler/Scheduler';

import Step from '../../components/Step/Step';
import inventorySvg from '../inventory.svg';

import './InventoryInReview.scss';

function InventoryInReview() {
  const { t } = useTranslation();
  const leaving = useLeaving();
  const { t: translationFormat } = useTranslationFormat();

  return (
    <Step img={inventorySvg}>
      <div className="InventoryInReview">
        {leaving.startDate.isAfter(moment().add(14, 'days').startOf('day')) ? (
          <Alert
            severity="warning"
            icon={
              <Box
                component={FontAwesomeIcon}
                icon={faExclamationCircle as any}
                size="1x"
                my="auto"
              />
            }
            sx={{ mb: 2, width: '100%' }}
          >
            <AlertTitle color="warning.main">
              {t('dashboard.inventory.in.alertEarly.title')}
            </AlertTitle>
            {t('dashboard.inventory.in.alertEarly.subtitle')}
          </Alert>
        ) : (
          <>
            <Alert
              severity="warning"
              icon={
                <Box
                  component={FontAwesomeIcon}
                  icon={faExclamationCircle as any}
                  size="1x"
                  my="auto"
                />
              }
              sx={{ mb: 2, width: '100%' }}
            >
              <AlertTitle color="warning.main">
                {t('dashboard.inventory.in.alert.title')}
              </AlertTitle>
              {
                translationFormat(
                  'dashboard.inventory.in.alert.subtitle',
                ) as any
              }
            </Alert>
            <Scheduler
              type={SchedulerLogoType[SchedulerLogoType.inventory]}
              duration={60}
              onChange={null}
            >
              <Calendly type={CalendlyType.inventory} onChange={null} />
            </Scheduler>
          </>
        )}
      </div>
    </Step>
  );
}

export default InventoryInReview;

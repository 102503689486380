import React from 'react';

import KeySetups from '@scopes/Keys/KeySetups/KeySetups';

import Step from '../components/Step/Step';

import './Keys.scss';

export default function KeysStep() {
  return (
    <Step>
      <div className="Keys">
        <KeySetups />
      </div>
    </Step>
  );
}

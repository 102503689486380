import { schema } from 'normalizr';

import { DOCUMENT } from '@enum';

const leaving = new schema.Entity('leavings');
export const eventActivity = new schema.Entity('eventActivities');
export const apartmentKey = new schema.Entity('apartmentKeys');
export const keySetup = new schema.Entity('keySetups');
export const document = new schema.Entity('documents');
export const prospect = new schema.Entity('prospects');
export const user = new schema.Entity('users');
export const access = new schema.Entity('accesses');
export const employee = new schema.Entity('employees', { user });
export const inventory = new schema.Entity('inventories', { leaving });
export const leavings = new schema.Entity('leavings', {
  keySetups: [keySetup],
  inventories: [inventory],
  [DOCUMENT.AUTHORIZATION]: document,
  [DOCUMENT.DEPARTURE]: document,
});
export const photoContract = new schema.Entity('photoContracts');
export const contact = new schema.Entity('contacts', {
  [DOCUMENT.ID]: [document],
  [DOCUMENT.RIB]: document,
  user,
});
export const apartment = new schema.Entity('apartments', {
  prospect,
  contact,
  leavings: [leavings],
  sale: employee,
  customerExperience: employee,
  photoContracts: [photoContract],
  accesses: [access],
  [DOCUMENT.RECEIPTS]: [document],
  [DOCUMENT.USAGE]: document,
  [DOCUMENT.PROOF]: document,
});

export const neighborhood = new schema.Entity('neighborhoods');
export const hostRent = new schema.Entity('hostRents');
export const room = new schema.Entity('rooms');
export const termPricing = new schema.Entity('termsPricing');

const schemas = [
  apartment,
  leavings,
  apartmentKey,
  document,
  neighborhood,
  prospect,
  keySetup,
  user,
  contact,
  eventActivity,
  room,
  hostRent,
  termPricing,
] as const;

export type Schemas =
  | (typeof schemas)[number]
  | Array<(typeof schemas)[number]>
  | Record<string, (typeof schemas)[number] | Array<(typeof schemas)[number]>>;

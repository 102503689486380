import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';

import React, { MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { routes } from 'src/routes';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowUpBigSmall,
  faRightToBracket,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Apartment from '@models/Apartment';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useContact } from '@hooks/useContact/useContact';
import { usePicture } from '@hooks/usePicture/usePicture';

import ApartmentCard from '@components/ApartmentsSelector/components/ApartmentCard/ApartmentCard';

const StyledIcon = styled(FontAwesomeIcon)<{ selected?: boolean }>`
  color: ${({ theme, selected }) =>
    selected ? theme.palette.primary.main : theme.palette.grey[700]};
`;

export default function ApartmentSubMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedApartment = useApartment();
  const contact = useContact();
  const picture = usePicture(contact?.user?.profilePictureId);
  const {
    logout,
    fetchApartment,
    setCurrentApartment,
    fetchHostRentsWithBills,
  } = useAppContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const allApartments = contact?.apartments;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectApartment = useCallback(
    (apartment: Apartment): MouseEventHandler =>
      async () => {
        if (apartment.id !== selectedApartment?.id) {
          await fetchApartment(apartment.id);
          setCurrentApartment(apartment.id);
          fetchHostRentsWithBills(apartment.id);
          handleClose();
          navigate(routes.root);
        }
      },
    [selectedApartment],
  );

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          maxWidth: '250px',
        }}
      >
        <Tooltip title={t('navigation.menu.apartments')}>
          <Button
            onClick={handleClick}
            size="small"
            sx={{
              m: 1,
              width: '100%',
              px: {
                xs: '0.5rem',
              },
            }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Stack
              component={Typography}
              color="white"
              variant="body2"
              fontWeight={500}
              lineHeight={1}
              alignItems="center"
              justifyContent="center"
              borderRadius={50}
              height={30}
              width={30}
              bgcolor="primary.main"
              overflow="hidden"
            >
              {picture ? (
                <Box
                  component="img"
                  src={picture}
                  width="100%"
                  height="100%"
                  sx={{ objectFit: 'cover' }}
                />
              ) : (
                <Box component="span">{contact?.initials}</Box>
              )}
            </Stack>
            <Stack
              alignItems="flex-start"
              sx={{
                maxWidth: '70%',
                mr: 1,
                pl: 1.5,
              }}
            >
              <Typography
                variant="body2"
                fontWeight={600}
                color="grey.700"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                }}
              >
                {contact?.fullName}
              </Typography>
              <Typography
                variant="body2"
                color="grey.700"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                  fontSize: { xs: '0.7rem' },
                }}
              >
                {selectedApartment?.address}
              </Typography>
            </Stack>
            <StyledIcon
              sx={{
                ml: 'auto',
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              icon={faArrowUpBigSmall as IconProp}
            />
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              px: 1,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {(allApartments || []).map((apartment) => (
          <MenuItem
            key={apartment.id}
            onClick={handleSelectApartment(apartment)}
            sx={{
              borderRadius: '12px',
              mb: 1,
            }}
            selected={apartment.id === selectedApartment?.id}
          >
            <ApartmentCard
              apartment={apartment}
              isCurrent={apartment.id === selectedApartment?.id}
            />
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          onClick={logout}
          sx={{ display: 'flex', justifyContent: 'space-between', pr: 0 }}
        >
          <Typography color="grey.700">{t('common.logout')}</Typography>
          <ListItemIcon>
            <FontAwesomeIcon icon={faRightToBracket as IconProp} />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

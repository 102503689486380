import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { SButton, SSmartLoader } from '@smartrenting/smartomic';

import CONFIG from '@config';

import { INVENTORY_TYPE } from '@enum';

import { mobileAndTabletCheck } from '@utils/tools';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import PdfViewer from '../../components/PdfViewer/PdfViewer';

import './InReviewToSign.scss';

export default function InReviewToSign() {
  const { t } = useTranslation();
  const {
    state: { token },
  } = useAppContext();
  const leaving = useLeaving();
  const apartment = useApartment();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<Blob>();
  const inventoryInReview = leaving.findInventoryByType(
    INVENTORY_TYPE.IN_REVIEW,
  );
  const isMobileOrTablet = mobileAndTabletCheck();

  const handlePreview = useCallback(async () => {
    if (inventoryInReview?.documentId) {
      setLoading(true);
      const myHeaders = new Headers({ Authorization: token });
      const result = await fetch(
        `${CONFIG.API_V2_URL}/documents/${inventoryInReview.documentId}`,
        { headers: myHeaders },
      );

      if (result && result.ok) {
        const file = await result.blob();

        setFile(file);
        setError(false);
      } else {
        setError(true);
      }

      setLoading(false);
    }
  }, [leaving]);

  const handleDownload = useCallback(() => {
    const fileName = `edl-${apartment.code.replace(
      / /g,
      '-',
    )}-${inventoryInReview?.typeCode}-${inventoryInReview?.id}.pdf`;
    const filePdf = new Blob([file as Blob], {
      type: 'application/pdf',
    });
    const newWindow = window.open() as Window;
    newWindow.location.href = URL.createObjectURL(filePdf);

    if (!isMobileOrTablet) {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(filePdf);
      a.download = fileName;
      a.click();
      a.remove();
    }
  }, [apartment, inventoryInReview]);

  const handleRedirectToOodriveSign = useCallback(() => {
    if (inventoryInReview?.oodriveSignUrl) {
      const newWindow = window.open() as Window;
      newWindow.location.href = inventoryInReview?.oodriveSignUrl;
    }
  }, []);

  useEffect(() => {
    if (token) {
      handlePreview();
    }
  }, [token, leaving.isSigned]);

  return (
    <div className="InReviewToSign">
      {error && (
        <div>
          <h2>{t('dashboard.inventory.error.title')}</h2>
          <p>
            {t('dashboard.inventory.error.content1')}
            <br />
            {t('dashboard.inventory.error.content2')}
          </p>
          <div>
            <p>{t('dashboard.inventory.error.boxContent')}</p>
            <a className="InReviewToSign__box__link" href={CONFIG.WEBSITE_URL}>
              <span>{CONFIG.WEBSITE_URL}</span>
            </a>
          </div>
        </div>
      )}
      {loading ? (
        <SSmartLoader />
      ) : (
        <div className="InReviewToSign__pdf">
          {file && <PdfViewer file={file} />}
          <div className="InReviewToSign__pdf__actions">
            <SButton
              variant="secondary"
              disabled={!inventoryInReview?.oodriveSignUrl}
              label={t('dashboard.inventory.sign')}
              onClick={handleRedirectToOodriveSign}
            />
            <SButton
              variant="secondary"
              icon={faDownload}
              disabled={!file}
              outline
              onClick={handleDownload}
            />
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useEffect } from 'react';

import { KeywordsCategory, KeywordType } from '@smartrenting/smartomic';

import { defaultFunctionParameter } from '@utils/helpers';

import { useApartment } from '@hooks/useApartment/useApartment';

const apartmentString = 'apartment';
const buildingString = 'building';
const neighborhoodString = 'neighborhood';
const surroundingString = 'surrounding';

export const keywordCategoryMinMax = {
  [apartmentString]: { min: 3, max: 4 },
  [buildingString]: { min: 1, max: 1 },
  [neighborhoodString]: { min: 1, max: 1 },
  [surroundingString]: { min: 0 },
} as any;
export interface KeywordsContextInterface {
  selectedIds: number[];
  setSelectedIds: CallableFunction;
  validSteps: string[];
  setValidSteps: CallableFunction;
}

export const KeywordsContext = React.createContext<KeywordsContextInterface>({
  selectedIds: [],
  setSelectedIds: defaultFunctionParameter,
  validSteps: [],
  setValidSteps: defaultFunctionParameter,
});

export const KeywordsContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const apartment = useApartment();
  const categories = ['apartment', 'building', 'surrounding', 'neighborhood'];

  const keywordByType = apartment?.keywords.reduce(
    (acc: any, keyword) => {
      return { ...acc, [keyword.type]: [...acc[keyword.type], keyword] };
    },
    categories.reduce((acc, type) => ({ ...acc, [type]: [] }), {}),
  );

  const getNbKeywordSelectedByCategory = (
    selectedIds: number[],
    category: string,
  ) => {
    const keywordsFromCategoryIds = keywordByType[category]
      .filter((keyword: KeywordType) => keyword.type === category)
      .map((keyword: KeywordType) => keyword.id);

    return selectedIds.reduce(
      (acc, id) => acc + (keywordsFromCategoryIds.includes(id) ? 1 : 0),
      0,
    );
  };

  const isValid = (
    selectedIds: number[],
    categoriesToCheck: KeywordsCategory[],
  ) => {
    const validCategories = [];
    categoriesToCheck.forEach((category) => {
      const { min, max } = keywordCategoryMinMax[category];
      const nbSelected = getNbKeywordSelectedByCategory(selectedIds, category);

      if (nbSelected >= min && (!max || nbSelected <= max)) {
        validCategories.push(category);
      }
    });

    return validCategories.length === categoriesToCheck.length;
  };

  const [selectedIds, setSelectedIds] = React.useState<number[]>(
    apartment.keywords.map(({ id }) => id),
  );
  const [validSteps, setValidSteps] = React.useState<string[]>([]);

  const keywordsStepValid = isValid(selectedIds, ['apartment', 'building']);

  const neighborhoodStepValid = isValid(selectedIds, [
    'neighborhood',
    'surrounding',
  ]);

  useEffect(() => {
    if (keywordsStepValid && neighborhoodStepValid) {
      setValidSteps(['keywords', 'neighborhood']);
    } else if (keywordsStepValid) {
      setValidSteps(['keywords']);
    } else if (neighborhoodStepValid) {
      setValidSteps(['neighborhood']);
    }
  }, [keywordsStepValid, neighborhoodStepValid]);

  return (
    <KeywordsContext.Provider
      value={{
        selectedIds,
        setSelectedIds,
        validSteps,
        setValidSteps,
      }}
    >
      {children}
    </KeywordsContext.Provider>
  );
};

export const useKeywordsContext = () => {
  return React.useContext(KeywordsContext);
};

export default KeywordsContext;

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { SSmartLoader, SText, SButton } from '@smartrenting/smartomic';

import CONFIG from '@config';

import { INVENTORY_TYPE } from '@enum';

import { mobileAndTabletCheck } from '@utils/tools';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import PdfViewer from '../../components/PdfViewer/PdfViewer';

export default function OutValidationToSign() {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<Blob>();

  const leaving = useLeaving();
  const apartment = useApartment();
  const { t } = useTranslation();

  const {
    state: { token },
  } = useAppContext();

  const inventoryOutValidation = leaving.findInventoryByType(
    INVENTORY_TYPE.OUT_VALIDATION,
  );

  const isMobileOrTablet = mobileAndTabletCheck();

  const handlePreview = useCallback(async () => {
    if (inventoryOutValidation?.documentId) {
      setLoading(true);
      const myHeaders = new Headers({ Authorization: token });
      const result = await fetch(
        `${CONFIG.API_V2_URL}/documents/${inventoryOutValidation.documentId}`,
        { headers: myHeaders },
      );

      if (result && result.ok) {
        const file = await result.blob();

        setFile(file);
        setError(false);
      } else {
        setError(true);
      }

      setLoading(false);
    }
  }, [leaving]);

  const handleDownload = useCallback(() => {
    const fileName = `edl-${apartment.code.replace(
      / /g,
      '-',
    )}-${inventoryOutValidation?.typeCode}-${inventoryOutValidation?.id}.pdf`;
    const filePdf = new Blob([file as Blob], {
      type: 'application/pdf',
    });
    const newWindow = window.open() as Window;
    newWindow.location.href = URL.createObjectURL(filePdf);

    if (!isMobileOrTablet) {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(filePdf);
      a.download = fileName;
      a.click();
      a.remove();
    }
  }, [apartment, inventoryOutValidation]);

  const handleRedirectToOodriveSign = useCallback(() => {
    if (inventoryOutValidation?.oodriveSignUrl) {
      const newWindow = window.open() as Window;
      newWindow.location.href = inventoryOutValidation?.oodriveSignUrl;
    }
  }, []);

  useEffect(() => {
    if (token) {
      handlePreview();
    }
  }, [token, leaving.isSigned]);

  return (
    <div className="OutToSign__content">
      {error && (
        <div>
          <h2>{t('dashboard.inventory.error.title')}</h2>
          <p>
            {t('dashboard.inventory.error.content1')}
            <br />
            {t('dashboard.inventory.error.content2')}
          </p>
          <div>
            <p>{t('dashboard.inventory.error.boxContent')}</p>
            <a className="InReviewToSign__box__link" href={CONFIG.WEBSITE_URL}>
              <span>{CONFIG.WEBSITE_URL}</span>
            </a>
          </div>
        </div>
      )}
      {loading && <SSmartLoader />}
      {!loading && !error && (
        <div className="OutToSign__content__left">
          <div className="OutToSign__content__left__title">
            <SText variant="body-subtitle" align="center">
              <strong>
                {t('dashboard.inventory.outToSign.signature.title')}
              </strong>
            </SText>
          </div>
          <div className="OutToSign__content__left__content">
            {file && <PdfViewer file={file} scale={0.7} />}
            <div className="OutToSign__content__left__content__actions">
              <SButton
                variant="secondary"
                disabled={!inventoryOutValidation?.oodriveSignUrl}
                label={t('dashboard.inventory.sign')}
                onClick={handleRedirectToOodriveSign}
              />
              <SButton
                variant="secondary"
                icon={faDownload}
                disabled={!file}
                outline
                onClick={handleDownload}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

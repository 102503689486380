import { fetchApi } from './fetchApi';

export async function sendSmsDirectives(
  token: string,
  data: { apartmentId: number; processType: string },
) {
  return fetchApi('POST', `/apartment-keys/sms-directives`, token, {
    body: data,
  });
}

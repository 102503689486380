import React from 'react';
import { useDropzone } from 'react-dropzone';

import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { AIcon } from '@smartrenting/smartomic';

interface DropGaleryProps {
  setValue: (key: string, value: any) => void;
  values: {
    [key: string]: any;
  };
}

const DropGalery: React.FC<DropGaleryProps> = ({ setValue, values }) => {
  const onDrop = (files: any) => {
    setValue('newPictures', [...values.newPictures, ...files]);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="dropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      <AIcon icon={faPlus} />
    </div>
  );
};

export default DropGalery;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { SCard, SText } from '@smartrenting/smartomic';

import rejectedSvg from '../../../assets/rejected.svg';

import './Rejected.scss';

export default function Rejected() {
  const { t } = useTranslation();

  return (
    <div className="Rejected__content">
      <SCard>
        <SText align="center" variant="subtitle">
          <strong>{t('dashboard.rejected.title')}</strong>
        </SText>
        <SText align="center" variant="body-subtitle">
          {t('dashboard.rejected.subtitle')}
        </SText>
        <img src={rejectedSvg} alt="rejected-svg" />
      </SCard>
    </div>
  );
}

import Modality from '@models/Modality';

import { fetchApi } from './fetchApi';

export async function getModality(token: string, leavingId: number) {
  return fetchApi<Modality>('GET', `/leavings/${leavingId}/modality`, token);
}

export async function patchModality(
  token: string,
  id: number,
  data: Partial<Modality>,
) {
  return fetchApi<Modality>('PATCH', `/modalities/${id}`, token, {
    body: data,
  });
}

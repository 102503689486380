import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router';

import { routes } from 'src/routes';

import { useMobile } from '@hooks/useMobile/useMobile';

import GoBackHeader from '@components/GoBackHeader/GoBackHeader';

import Payment from './Payment/Payment';
import Security from './Security/Security';
import SettingsNavigation from './SettingsNavigation/SettingsNavigation';
import User from './User/User';
import DesktopHeader from './components/DesktopHeader/DesktopHeader';

import './Settings.scss';

export default function Settings() {
  const isMobile = useMobile();
  const { pathname } = useLocation();
  const currentPath = pathname.replace(routes.settings + '/', '');
  const { t } = useTranslation();

  return (
    <div className="Settings">
      {!isMobile && <DesktopHeader />}
      {isMobile && pathname !== routes.settings && (
        <GoBackHeader
          title={t(`navigation.routes.${currentPath}.title`)}
          subtitle={t(`navigation.routes.${currentPath}.subtitle`)}
        />
      )}
      <Routes>
        <Route path="/rib" element={<Payment />} />
        <Route path="/user" element={<User />} />
        <Route path="/security" element={<Security />} />
        {isMobile && <Route path="/" element={<SettingsNavigation />} />}
        {isMobile && (
          <Route path="*" element={<Navigate replace to={routes.settings} />} />
        )}
        {!isMobile && (
          <Route path="*" element={<Navigate replace to={routes.user} />} />
        )}
      </Routes>
    </div>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import { SText } from '@smartrenting/smartomic';

import '../OutToSign.scss';

export default function Instructions() {
  const { t } = useTranslation();

  return (
    <div className="OutToSign__content__right__instructions">
      <div className="OutToSign__content__right__instructions__row">
        <div className="OutToSign__content__right__instructions__row__icon">
          <span>
            <strong>1</strong>
          </span>
        </div>
        <SText variant="body-subtitle">
          {t('dashboard.inventory.outToSign.qr-code.instruction_1')}
        </SText>
      </div>
      <div className="OutToSign__content__right__instructions__row">
        <div className="OutToSign__content__right__instructions__row__icon">
          <span>
            <strong>2</strong>
          </span>
        </div>
        <SText variant="body-subtitle">
          {t('dashboard.inventory.outToSign.qr-code.instruction_2')}
        </SText>
      </div>
      <div className="OutToSign__content__right__instructions__row">
        <div className="OutToSign__content__right__instructions__row__icon">
          <span>
            <strong>3</strong>
          </span>
        </div>
        <SText variant="body-subtitle">
          {t('dashboard.inventory.outToSign.qr-code.instruction_3')}
        </SText>
      </div>
      <div className="OutToSign__content__right__instructions__row">
        <div className="OutToSign__content__right__instructions__row__icon">
          <span>
            <strong>4</strong>
          </span>
        </div>
        <SText variant="body-subtitle">
          {t('dashboard.inventory.outToSign.qr-code.instruction_4')}
        </SText>
      </div>
      <div className="OutToSign__content__right__instructions__row">
        <div className="OutToSign__content__right__instructions__row__icon">
          <span>
            <strong>5</strong>
          </span>
        </div>
        <SText variant="body-subtitle">
          {t('dashboard.inventory.outToSign.qr-code.instruction_5')}
        </SText>
      </div>
    </div>
  );
}

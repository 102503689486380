import React from 'react';

import { OWNERSHIP } from '@enum';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import AuthorizationTenant from '../../AuthorizationTenant/AuthorizationTenant';

import HidePhoneSvg from './HidePhoneStep.svg';

import './HidePhoneStep.scss';

export default function HidePhoneStep() {
  const leaving = useLeaving();
  const apartment = useApartment();

  return (
    <div className="HidePhoneStep">
      <img src={HidePhoneSvg} alt="in-review-scheduled" />
      {apartment.ownership === OWNERSHIP.TENANT &&
        !leaving.authorizationDocument && <AuthorizationTenant callScheduled />}
    </div>
  );
}

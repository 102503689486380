import React from 'react';

export const AuthorizationSvg = () => (
  <svg
    width="286"
    height="245"
    viewBox="0 0 286 245"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4857_1672)">
      <path
        d="M21.6841 224.834C23.3293 225.413 25.0957 225.557 26.8132 225.255C28.5306 224.952 30.1412 224.213 31.4897 223.107C34.9242 220.224 36.0012 215.476 36.8771 211.079L39.4685 198.072L34.0431 201.808C30.1411 204.495 26.1516 207.267 23.4501 211.159C20.7487 215.05 19.5702 220.362 21.7401 224.573"
        fill="#E6E6E6"
      />
      <path
        d="M22.5206 241.487C21.8377 236.512 21.1351 231.472 21.6153 226.444C22.0408 221.978 23.4033 217.617 26.1771 214.04C27.6495 212.145 29.4411 210.521 31.4713 209.242C32.0006 208.908 32.4879 209.747 31.9609 210.08C28.4479 212.3 25.7306 215.578 24.2009 219.442C22.5115 223.739 22.2403 228.423 22.5313 232.987C22.7072 235.747 23.0809 238.49 23.4563 241.229C23.486 241.352 23.4674 241.483 23.4045 241.594C23.3416 241.704 23.239 241.787 23.1175 241.825C22.9935 241.859 22.8613 241.842 22.7496 241.778C22.6379 241.715 22.5558 241.61 22.5209 241.486L22.5206 241.487Z"
        fill="#F2F2F2"
      />
      <path
        d="M27.4375 233.504C28.143 234.577 29.1128 235.45 30.2536 236.039C31.3944 236.628 32.6676 236.913 33.9507 236.867C37.2486 236.71 39.9969 234.409 42.4707 232.224L49.7893 225.763L44.9458 225.531C41.4626 225.364 37.8895 225.207 34.5718 226.283C31.2541 227.358 28.1944 229.942 27.5878 233.376"
        fill="#E6E6E6"
      />
      <path
        d="M20.5911 244.347C23.8788 238.53 27.6918 232.065 34.5056 229.998C36.4001 229.426 38.3865 229.221 40.3579 229.394C40.9793 229.448 40.8241 230.406 40.204 230.352C36.8993 230.078 33.601 230.95 30.8643 232.823C28.2308 234.616 26.1802 237.108 24.4449 239.75C23.3818 241.369 22.4296 243.057 21.4774 244.742C21.1731 245.281 20.2833 244.892 20.5911 244.347Z"
        fill="#F2F2F2"
      />
      <path
        d="M283.651 172.536H102.4C101.841 172.535 101.306 172.313 100.911 171.918C100.516 171.523 100.294 170.988 100.293 170.429V2.10666C100.294 1.54817 100.516 1.01275 100.911 0.617833C101.306 0.222916 101.841 0.000730095 102.4 0H283.651C284.209 0.000730095 284.745 0.222916 285.139 0.617833C285.534 1.01275 285.757 1.54817 285.757 2.10666V170.429C285.757 170.987 285.535 171.523 285.14 171.918C284.745 172.313 284.209 172.535 283.651 172.536Z"
        fill="white"
      />
      <path
        d="M283.651 172.536H102.4C101.841 172.535 101.306 172.313 100.911 171.918C100.516 171.523 100.294 170.988 100.293 170.429V2.10666C100.294 1.54817 100.516 1.01275 100.911 0.617833C101.306 0.222916 101.841 0.000730095 102.4 0H283.651C284.209 0.000730095 284.745 0.222916 285.139 0.617833C285.534 1.01275 285.757 1.54817 285.757 2.10666V170.429C285.757 170.987 285.535 171.523 285.14 171.918C284.745 172.313 284.209 172.535 283.651 172.536ZM102.4 0.841005C102.065 0.841732 101.744 0.975022 101.507 1.2117C101.271 1.44838 101.137 1.76918 101.137 2.1039V170.429C101.137 170.764 101.271 171.084 101.507 171.321C101.744 171.558 102.065 171.691 102.4 171.692H283.651C283.985 171.691 284.306 171.558 284.543 171.321C284.78 171.084 284.913 170.764 284.913 170.429V2.10666C284.913 1.77195 284.779 1.45115 284.543 1.21447C284.306 0.977787 283.985 0.844496 283.651 0.843769L102.4 0.841005Z"
        fill="#CACACA"
      />
      <path
        d="M188.043 43.7393C187.664 43.7399 187.301 43.8909 187.033 44.159C186.766 44.4272 186.615 44.7906 186.615 45.1695C186.615 45.5484 186.766 45.9118 187.033 46.1799C187.301 46.4481 187.664 46.599 188.043 46.5997H266.522C266.901 46.599 267.264 46.4481 267.532 46.1799C267.799 45.9118 267.95 45.5484 267.95 45.1695C267.95 44.7906 267.799 44.4272 267.532 44.159C267.264 43.8909 266.901 43.7399 266.522 43.7393H188.043Z"
        fill="#E4E4E4"
      />
      <path
        d="M188.044 52.3213C187.665 52.3222 187.302 52.4733 187.035 52.7414C186.767 53.0095 186.617 53.3728 186.617 53.7515C186.617 54.1302 186.767 54.4935 187.035 54.7616C187.302 55.0297 187.665 55.1808 188.044 55.1817H228.109C228.488 55.1808 228.851 55.0297 229.118 54.7616C229.386 54.4935 229.536 54.1302 229.536 53.7515C229.536 53.3728 229.386 53.0095 229.118 52.7414C228.851 52.4733 228.488 52.3222 228.109 52.3213H188.044Z"
        fill="#E4E4E4"
      />
      <path
        d="M119.45 98.4731C119.071 98.4741 118.709 98.6251 118.441 98.8933C118.174 99.1614 118.023 99.5247 118.023 99.9034C118.023 100.282 118.174 100.645 118.441 100.913C118.709 101.182 119.071 101.333 119.45 101.334H266.599C266.978 101.333 267.34 101.182 267.608 100.913C267.875 100.645 268.026 100.282 268.026 99.9034C268.026 99.5247 267.875 99.1614 267.608 98.8933C267.34 98.6251 266.978 98.4741 266.599 98.4731H119.45Z"
        fill="#E4E4E4"
      />
      <path
        d="M119.45 107.055C119.071 107.056 118.709 107.207 118.441 107.475C118.174 107.743 118.023 108.107 118.023 108.485C118.023 108.864 118.174 109.227 118.441 109.495C118.709 109.764 119.071 109.915 119.45 109.916H228.185C228.564 109.915 228.927 109.764 229.194 109.495C229.461 109.227 229.612 108.864 229.612 108.485C229.612 108.107 229.461 107.743 229.194 107.475C228.927 107.207 228.564 107.056 228.185 107.055H119.45Z"
        fill="#E4E4E4"
      />
      <path
        d="M119.45 115.443C119.071 115.444 118.709 115.595 118.441 115.863C118.174 116.131 118.023 116.494 118.023 116.873C118.023 117.252 118.174 117.615 118.441 117.883C118.709 118.151 119.071 118.302 119.45 118.303H266.599C266.978 118.302 267.34 118.151 267.608 117.883C267.875 117.615 268.026 117.252 268.026 116.873C268.026 116.494 267.875 116.131 267.608 115.863C267.34 115.595 266.978 115.444 266.599 115.443H119.45Z"
        fill="#E4E4E4"
      />
      <path
        d="M119.45 124.025C119.071 124.026 118.709 124.177 118.441 124.445C118.174 124.713 118.023 125.076 118.023 125.455C118.023 125.834 118.174 126.197 118.441 126.465C118.709 126.733 119.071 126.884 119.45 126.885H228.185C228.564 126.884 228.927 126.733 229.194 126.465C229.461 126.197 229.612 125.834 229.612 125.455C229.612 125.076 229.461 124.713 229.194 124.445C228.927 124.177 228.564 124.026 228.185 124.025H119.45Z"
        fill="#E4E4E4"
      />
      <path
        d="M119.45 132.637C119.071 132.638 118.709 132.789 118.441 133.057C118.174 133.325 118.023 133.688 118.023 134.067C118.023 134.446 118.174 134.809 118.441 135.077C118.709 135.345 119.071 135.496 119.45 135.497H266.599C266.978 135.496 267.34 135.345 267.608 135.077C267.875 134.809 268.026 134.446 268.026 134.067C268.026 133.688 267.875 133.325 267.608 133.057C267.34 132.789 266.978 132.638 266.599 132.637H119.45Z"
        fill="#E4E4E4"
      />
      <path
        d="M119.45 141.219C119.071 141.22 118.709 141.371 118.441 141.639C118.174 141.907 118.023 142.27 118.023 142.649C118.023 143.028 118.174 143.391 118.441 143.659C118.709 143.927 119.071 144.078 119.45 144.079H228.185C228.564 144.078 228.927 143.927 229.194 143.659C229.461 143.391 229.612 143.028 229.612 142.649C229.612 142.27 229.461 141.907 229.194 141.639C228.927 141.371 228.564 141.22 228.185 141.219H119.45Z"
        fill="#E4E4E4"
      />
      <path
        d="M142.758 70.0088C138.693 70.0088 134.721 68.8037 131.341 66.5459C127.962 64.288 125.328 61.0788 123.773 57.3241C122.218 53.5694 121.811 49.4378 122.604 45.4518C123.397 41.4658 125.354 37.8044 128.227 34.9307C131.101 32.0569 134.762 30.0998 138.748 29.307C142.734 28.5141 146.866 28.921 150.621 30.4762C154.376 32.0315 157.585 34.6652 159.843 38.0444C162.101 41.4236 163.306 45.3964 163.306 49.4605C163.299 54.9083 161.133 60.1312 157.28 63.9834C153.428 67.8357 148.205 70.0026 142.758 70.0088Z"
        fill="#6C63FF"
      />
      <path
        d="M80.3822 147.703C80.3467 146.953 80.1471 146.22 79.7976 145.556C79.4481 144.891 78.9571 144.311 78.3593 143.857C77.7615 143.403 77.0713 143.085 76.3374 142.926C75.6035 142.768 74.8437 142.772 74.1116 142.939L67.3196 133.367L60.4961 136.075L70.2776 149.458C70.6392 150.669 71.4325 151.706 72.5074 152.372C73.5822 153.037 74.8638 153.285 76.1093 153.069C77.3548 152.853 78.4777 152.187 79.2652 151.198C80.0528 150.209 80.4502 148.966 80.3822 147.703Z"
        fill="#A0616A"
      />
      <path
        d="M67.1434 146.994L46.5918 120.332L54.2923 96.1309C54.8566 90.0463 58.6641 88.3473 58.8261 88.2779L59.0732 88.1719L65.7741 106.041L60.8541 119.161L72.9301 139.471L67.1434 146.994Z"
        fill="#3F3D56"
      />
      <path
        d="M138.516 64.8051C137.771 64.8986 137.056 65.1542 136.42 65.554C135.785 65.9539 135.245 66.4882 134.838 67.1194C134.431 67.7506 134.168 68.4632 134.066 69.2072C133.965 69.9512 134.028 70.7083 134.25 71.4254L125.232 78.9371L128.461 85.5307L141.047 74.7438C142.227 74.2897 143.199 73.4185 143.779 72.2955C144.36 71.1724 144.508 69.8755 144.196 68.6504C143.884 67.4253 143.134 66.3573 142.087 65.6486C141.04 64.9398 139.77 64.6397 138.516 64.8051Z"
        fill="#A0616A"
      />
      <path
        d="M138.833 78.0579L113.84 100.609L89.1154 94.8031C83.0054 94.7109 81.0171 91.0462 80.9353 90.89L80.8105 90.6518L98.1084 82.5894L111.57 86.4803L130.885 72.8701L138.833 78.0579Z"
        fill="#3F3D56"
      />
      <path
        d="M95.5423 238.376L102.257 238.376L105.452 212.475L95.541 212.476L95.5423 238.376Z"
        fill="#A0616A"
      />
      <path
        d="M115.207 244.611L94.1026 244.612L94.1022 236.458L109.286 236.457C110.856 236.457 112.362 237.081 113.473 238.191C114.583 239.301 115.207 240.807 115.207 242.378L115.207 244.611Z"
        fill="#2F2E41"
      />
      <path
        d="M68.702 238.376L75.4171 238.376L78.6118 212.475L68.7012 212.476L68.702 238.376Z"
        fill="#A0616A"
      />
      <path
        d="M88.3691 244.611L67.2647 244.612L67.2643 236.458L82.4479 236.457C83.2254 236.457 83.9954 236.61 84.7137 236.908C85.4321 237.205 86.0848 237.641 86.6347 238.191C87.1845 238.741 87.6207 239.394 87.9182 240.112C88.2158 240.83 88.369 241.6 88.369 242.378L88.3691 244.611Z"
        fill="#2F2E41"
      />
      <path
        d="M66.3145 143.834L66.8621 182.176L67.4102 232.567L79.4599 231.471L85.4852 161.91L93.1535 231.471H105.594L106.847 161.362L102.465 146.026L66.3145 143.834Z"
        fill="#2F2E41"
      />
      <path
        d="M89.8991 148.984C76.7879 148.985 64.7191 143.05 64.5562 142.969L64.4209 142.901L63.3206 116.496C63.0016 115.563 56.7189 97.1509 55.6548 91.2987C54.5767 85.3695 70.2016 80.1659 72.099 79.5574L72.5295 74.7876L90.0391 72.9009L92.2583 79.004L98.54 81.3594C99.2521 81.6267 99.8415 82.1458 100.196 82.8186C100.551 83.4913 100.647 84.2709 100.466 85.0096L96.9744 99.2073L105.501 146.18L103.666 146.26C99.2653 148.247 94.5162 148.983 89.8991 148.984Z"
        fill="#3F3D56"
      />
      <path
        d="M88.0174 68.3347C94.1268 64.995 96.3721 57.335 93.0324 51.2257C89.6927 45.1163 82.0327 42.8711 75.9233 46.2108C69.8139 49.5505 67.5687 57.2104 70.9084 63.3198C74.2481 69.4292 81.9081 71.6744 88.0174 68.3347Z"
        fill="#A0616A"
      />
      <path
        d="M71.7596 69.4776C74.1622 72.0369 78.6241 70.663 78.9372 67.1667C78.9617 66.8952 78.96 66.622 78.9319 66.3509C78.7702 64.8022 77.8755 63.3962 78.0898 61.7609C78.1383 61.3539 78.2899 60.9661 78.5303 60.6341C80.4449 58.0704 84.9391 61.7808 86.746 59.4599C87.854 58.0368 86.5516 55.7962 87.4018 54.2056C88.524 52.1063 91.8477 53.1419 93.9321 51.9922C96.2512 50.7131 96.1125 47.1549 94.5859 44.9906C92.7241 42.3512 89.4599 40.9429 86.2364 40.7399C83.0128 40.5369 79.8115 41.4083 76.802 42.5811C73.3826 43.9137 69.9918 45.7553 67.8876 48.762C65.3286 52.4184 65.0824 57.3342 66.3621 61.6097C67.1407 64.2105 69.7976 67.3877 71.7596 69.4776Z"
        fill="#2F2E41"
      />
      <path
        d="M160.437 244.936H0.663495C0.552275 244.936 0.445604 244.892 0.36696 244.813C0.288316 244.734 0.244141 244.628 0.244141 244.517C0.244141 244.405 0.288316 244.299 0.36696 244.22C0.445604 244.141 0.552275 244.097 0.663495 244.097H160.437C160.549 244.097 160.655 244.141 160.734 244.22C160.813 244.299 160.857 244.405 160.857 244.517C160.857 244.628 160.813 244.734 160.734 244.813C160.655 244.892 160.549 244.936 160.437 244.936Z"
        fill="#CACACA"
      />
      <path
        d="M141.57 57.2213C141.2 57.2218 140.843 57.0815 140.572 56.829L133.796 50.5356C133.511 50.2706 133.343 49.9034 133.329 49.5146C133.315 49.1259 133.456 48.7474 133.72 48.4624C133.985 48.1773 134.352 48.0091 134.741 47.9945C135.13 47.98 135.508 48.1203 135.794 48.3848L141.533 53.7156L152.887 42.3611C153.023 42.2239 153.185 42.115 153.364 42.0406C153.542 41.9661 153.733 41.9276 153.926 41.9272C154.12 41.9269 154.311 41.9647 154.49 42.0385C154.668 42.1123 154.83 42.2206 154.967 42.3572C155.104 42.4939 155.212 42.6562 155.286 42.8348C155.36 43.0134 155.397 43.2048 155.397 43.398C155.396 43.5912 155.358 43.7825 155.283 43.9607C155.209 44.139 155.1 44.3009 154.963 44.437L142.608 56.7915C142.472 56.928 142.31 57.0362 142.132 57.1099C141.954 57.1837 141.763 57.2215 141.57 57.2213Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4857_1672">
        <rect
          width="285.512"
          height="245"
          fill="white"
          transform="translate(0.244141)"
        />
      </clipPath>
    </defs>
  </svg>
);

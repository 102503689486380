import EventActivity from '@models/EventActivity';
import Prospect from '@models/Prospect';

import { fetchApi } from './fetchApi';

export async function patchProspectStatus(
  token: string,
  id: number,
  status: Prospect['status'],
  data: Record<string, any> = { date: null },
) {
  return fetchApi<Prospect>('PATCH', `/prospects/${id}/status/update`, token, {
    body: { ...data, status },
  });
}

export async function postGamification(token: string, id: number) {
  return fetchApi<Prospect>('POST', `/prospects/${id}/gamification`, token);
}

export async function getEvents(token: string, id: number) {
  return fetchApi<EventActivity[]>('GET', `/prospects/${id}/events`, token);
}

import moment from 'moment-timezone';

import { INVENTORY_STATUS } from '@enum';

export class Inventory {
  id!: number;
  type!: string;
  status!: string;
  documentId?: number;
  typeCode!: string;
  oodriveSignUrl?: string;
  leavingId!: number;
  createdAt!: moment.Moment;

  constructor(data: Partial<Inventory>) {
    Object.assign(this, data);
  }

  isDone() {
    return this.status === INVENTORY_STATUS.DONE;
  }
}

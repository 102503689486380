import React from 'react';

import { AIcon, STitle, SInput, AIconProps } from '@smartrenting/smartomic';

import '../PracticalInfo.scss';

const PracticalInfoRow: React.FC<{
  edit?: boolean;
  icon: AIconProps['icon'];
  title: string;
  description?: string;
  onChange: (description: string) => void;
  name?: string;
}> = ({ icon, title, description, edit, onChange, name }) => {
  return (
    <div className="PracticalInfo__row">
      <div className="PracticalInfo__row__icon">
        <AIcon icon={icon} type="regular" height={18} />
      </div>
      <div className="PracticalInfo__row__description">
        <STitle
          key={title}
          title={title}
          subtitle={edit ? undefined : description || '-'}
          variant="body"
        />
        {edit && (
          <SInput
            value={description || ''}
            onChange={(e) => onChange(e.target.value)}
            name={name}
          />
        )}
      </div>
    </div>
  );
};

export default PracticalInfoRow;

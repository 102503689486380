import React from 'react';

import { SText } from '@smartrenting/smartomic';

import { KEY_PROCESS_TYPE } from '@enum';

import { ApartmentKey } from '@models/ApartmentKey';
import KeySetup from '@models/KeySetup';

import { useTranslationFormat } from '@hooks/useTranslationFormat/useTranslationFormat';

export default function KeyCafeHelp({
  processType,
  keyInfos,
  sameTypeDifferentLocation,
}: {
  processType: 'setup' | 'unsetup';
  keyInfos: ApartmentKey | KeySetup;
  sameTypeDifferentLocation: boolean;
}) {
  const { t } = useTranslationFormat();

  return (
    <>
      <SText className="KeysHelp__content__keys__title" variant="body-title">
        {t(
          `${processType}.help.keycafe.${
            sameTypeDifferentLocation ? 'different' : 'same'
          }.title_1`,
        )}
      </SText>
      <SText
        className="KeysHelp__content__keys__subtitle"
        variant="body-subtitle"
      >
        {t(
          `${processType}.help.keycafe.${
            sameTypeDifferentLocation ? 'different' : 'same'
          }.text_1`,
          {
            address: keyInfos.locationAddress,
          } as any,
        )}
      </SText>
      <SText className="KeysHelp__content__keys__title" variant="body-title">
        {t(
          `${processType}.help.keycafe.${
            sameTypeDifferentLocation ? 'different' : 'same'
          }.title_2`,
        )}
      </SText>
      <SText
        className="KeysHelp__content__keys__subtitle"
        variant="body-subtitle"
      >
        {t(
          `${processType}.help.keycafe.${
            sameTypeDifferentLocation ? 'different' : 'same'
          }.text_2`,
        )}
      </SText>
      {processType === KEY_PROCESS_TYPE.SETUP && (
        <>
          <SText
            className="KeysHelp__content__keys__title"
            variant="body-title"
          >
            {t(
              `${processType}.help.keycafe.${
                sameTypeDifferentLocation ? 'different' : 'same'
              }.title_4`,
            )}
          </SText>
          <SText
            className="KeysHelp__content__keys__subtitle"
            variant="body-subtitle"
          >
            {t(
              `${processType}.help.keycafe.${
                sameTypeDifferentLocation ? 'different' : 'same'
              }.text_4`,
            )}
          </SText>
        </>
      )}
      <SText className="KeysHelp__content__keys__title" variant="body-title">
        {t(
          `${processType}.help.keycafe.${
            sameTypeDifferentLocation ? 'different' : 'same'
          }.title_3`,
        )}
      </SText>
      <SText
        variant="body-subtitle"
        className="KeysHelp__content__keys__subtitle"
      >
        {t(
          `${processType}.help.keycafe.${
            sameTypeDifferentLocation ? 'different' : 'same'
          }.text_3`,
        )}
      </SText>
    </>
  );
}

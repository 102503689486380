import moment from 'moment-timezone';

import { PHOTO_CONTRACT_STATUS } from '@enum';

export class PhotoContract {
  id!: number;
  status?: string;
  date?: moment.Moment;

  constructor(data: Partial<PhotoContract>) {
    Object.assign(this, data);

    this.date = data.date && moment(data.date);
  }

  isScheduled() {
    return this.date || this.status === PHOTO_CONTRACT_STATUS.SCHEDULED;
  }
}

import { Typography } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  faMemoCircleInfo,
  faHouse,
  faTv,
  faThumbtack,
  faRoute,
} from '@fortawesome/pro-solid-svg-icons';
import { ASeparator, SCard } from '@smartrenting/smartomic';

import { PROSPECT_STATUS_BEFORE_VISITE } from '@enum';

import { useProspect } from '@hooks/useProspect/useProspect';

import { EditableBlock } from '@components/EditableBlock/EditableBlock';
import Page from '@components/Page/Page';

import AccessCreation from './components/AccessCreation/AccessCreation';
import ApartmentAccess from './components/ApartmentAccess/ApartmentAccess';
import { ApartmentDetailsEdit } from './components/ApartmentDetailsEdit/ApartmentDetailsEdit';
import { ApartmentDetailsInfo } from './components/ApartmentDetailsInfo/ApartmentDetailsInfo';
import Equipment from './components/Equipment/Equipment';
import LodgingInfo from './components/LodgingInfo/LodgingInfo';
import PracticalInfo from './components/PracticalInfo/PracticalInfo';

import './Apartments.scss';

export default function Apartments() {
  const { t } = useTranslation();
  const { status } = useProspect();

  const editable = PROSPECT_STATUS_BEFORE_VISITE.includes(status);

  return (
    <div className="Page__container">
      <Page className="Apartments">
        <Typography variant="h5">{t('apartment.title')}</Typography>
        <Typography mb={2.5}>{t('apartment.subtitle')}</Typography>
        <SCard>
          <EditableBlock
            title={t('apartment.info.title')}
            icon={faMemoCircleInfo}
            editable={editable}
            Edit={(setEdit) => <ApartmentDetailsEdit setEdit={setEdit} />}
            Visualisation={() => <ApartmentDetailsInfo />}
          />
        </SCard>
        <ASeparator variant="bold" className="Card__separator" />
        <SCard className="mt-3">
          <EditableBlock
            title={t('apartment.lodging.title')}
            icon={faHouse}
            editable={editable}
            Edit={(setEdit, edit) => (
              <LodgingInfo edit={edit} setEdit={setEdit} />
            )}
            Visualisation={() => <LodgingInfo edit={false} />}
          />
        </SCard>
        <ASeparator variant="bold" className="Card__separator" />
        <SCard className="mt-3">
          <EditableBlock
            title={t('apartment.equipment.title')}
            icon={faTv}
            editable={editable}
            Edit={(setEdit, edit) => (
              <Equipment edit={edit} setEdit={setEdit} />
            )}
            Visualisation={() => <Equipment edit={false} />}
          />
        </SCard>
        <SCard className="mt-3">
          <EditableBlock
            title={t('apartment.practical.title')}
            icon={faThumbtack}
            editable={editable}
            Edit={(setEdit, edit) => (
              <PracticalInfo edit={edit} setEdit={setEdit} />
            )}
            Visualisation={() => <PracticalInfo edit={false} />}
          />
        </SCard>
        <SCard className="mt-3">
          <EditableBlock
            title={t('apartment.accesses.title')}
            icon={faRoute}
            editable={editable}
            Edit={(setEdit) => <AccessCreation setEdit={setEdit} />}
            Visualisation={() => <ApartmentAccess />}
          />
        </SCard>
      </Page>
    </div>
  );
}

import { fetchApi } from './fetchApi';

export async function sendNotificationRenewal(
  token: string,
  body: {
    startDate: moment.Moment;
    endDate: moment.Moment;
    apartmentId: number;
  },
) {
  return fetchApi<any>('POST', `/communications/renewal`, token, {
    body,
  });
}

import { AlertTitle, Box, Alert } from '@mui/material';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { faHourglass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PROSPECT_STATUS } from '@enum';

import { useLeaving } from '@hooks/useLeaving/useLeaving';
import { useMobile } from '@hooks/useMobile/useMobile';
import { useProspect } from '@hooks/useProspect/useProspect';

import Step from '../components/Step/Step';

import BoardOffice from './components/BoardOffice/BoardOffice';
import MandateExpired from './components/MandateExpired/MandateExpired';
import MandateSignature from './components/MandateSignature/MandateSignature';

import boardOfficeSvg from './board-office.svg';
import signatureSvg from './signature.svg';

import './Mandate.scss';

export default function Mandate() {
  const { t } = useTranslation();
  const leaving = useLeaving();
  const prospect = useProspect();
  const isMobile = useMobile();

  const computeCurrentStep = useCallback(() => {
    if (leaving.isExpired()) {
      return <MandateExpired />;
    } else if (prospect.status === PROSPECT_STATUS.CLIENT_TO_VALIDATE) {
      return <BoardOffice />;
    } else {
      return <MandateSignature />;
    }
  }, [leaving]);

  const computeImage = useCallback(() => {
    if (leaving.isExpired()) {
      return;
    } else if (prospect.status === PROSPECT_STATUS.CLIENT_TO_VALIDATE) {
      return boardOfficeSvg;
    } else {
      return signatureSvg;
    }
  }, [leaving]);

  return (
    <Step img={computeImage()}>
      {!leaving.isExpired() && leaving.expirationDate && (
        <Alert
          severity="info"
          icon={
            isMobile ? (
              false
            ) : (
              <Box
                component={FontAwesomeIcon}
                icon={faHourglass as any}
                size="sm"
                my="auto"
                mx={1}
              />
            )
          }
          sx={{ mb: 2, width: '100%' }}
        >
          <AlertTitle>
            {t('mandateExpired.alert.title', {
              date: leaving.expirationDate?.format('DD/MM/YYYY'),
            })}
          </AlertTitle>
          {t('mandateExpired.alert.subtitle')}
        </Alert>
      )}
      {computeCurrentStep()}
    </Step>
  );
}

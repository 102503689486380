import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { routes } from 'src/routes';

export default function useRedirectToLogin() {
  const navigate = useNavigate();

  return useCallback(() => {
    navigate(routes.login);
  }, []);
}

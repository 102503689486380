import moment from 'moment-timezone';

import TermPricing from './TermPricing';

export default class Modality {
  id!: number;
  leavingId!: number;
  earlyPayment!: boolean;
  paidInsurance!: boolean;
  paidCleaning!: boolean;
  paidKey!: boolean;
  paidInventory!: boolean;
  setupFee!: boolean;
  validated!: boolean;
  termsPricings: Array<TermPricing>;
  createdAt!: moment.Moment;
  updatedAt!: moment.Moment;

  constructor(data: Record<string, any>) {
    Object.assign(this, data);

    this.termsPricings = (data.termsPricings || []).map(
      (termPricing: Partial<TermPricing>) => new TermPricing(termPricing),
    );

    this.createdAt = moment(data.createdAt);
    this.updatedAt = moment(data.updatedAt);
  }
}

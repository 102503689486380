import moment from 'moment-timezone';

import {
  INVENTORY_STATUS,
  INVENTORY_TYPE,
  KEY_SETUP_STATUS,
  LEAVING_STATUS,
} from '@enum';

import Commission from './Commission';
import Document from './Document';
import Employee from './Employee';
import { Inventory } from './Inventory';
import KeySetup from './KeySetup';
import Modality from './Modality';

export default class Leaving {
  id!: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  mandateExternalId?: number;
  mandateId?: number;
  keySetups: KeySetup[];
  hostReturnDate?: moment.Moment;
  hostReturnTime?: string;
  hostHasKey?: boolean;
  authorizationDocument: Document;
  departureDocument: Document;
  inventories: Inventory[] = [];
  expectedInventoryInReviewDate?: moment.Moment;
  expectedInventoryOutValidationDate?: moment.Moment;
  inventoryInOperational: Employee;
  rent?: number;
  isCheckinReady!: boolean;
  isCheckoutReady!: boolean;
  status!: string;
  parentId!: number;
  apartmentId!: number;
  signatureDate?: moment.Moment;
  allowance?: number;
  monthlyCharges?: number;
  paymentDay?: number;
  isFakeDate?: boolean;
  accountabilityId?: number;
  commission?: Commission;
  expirationDate: moment.Moment;
  modality?: Modality;

  constructor(data: Record<string, any>) {
    Object.assign(this, data);
    this.startDate = data.startDate && moment(data.startDate);
    this.signatureDate = data.signatureDate && moment(data.signatureDate);
    this.endDate = data.endDate && moment(data.endDate);
    this.hostReturnDate = data.hostReturnDate && moment(data.hostReturnDate);
    this.expectedInventoryInReviewDate =
      data.expectedInventoryInReviewDate &&
      moment(data.expectedInventoryInReviewDate);

    this.keySetups = data.keySetups || [];

    this.expectedInventoryOutValidationDate =
      data.expectedInventoryOutValidationDate &&
      moment(data.expectedInventoryOutValidationDate);

    this.authorizationDocument =
      data.authorizationDocument && new Document(data.authorizationDocument);
    this.departureDocument =
      data.departureDocument && new Document(data.departureDocument);
    this.inventories = (data.inventories || []).map(
      (inventory: Partial<Inventory>) => new Inventory(inventory),
    );
    this.inventoryInOperational =
      data.inventoryInOperational && new Employee(data.inventoryInOperational);
    this.commission = data.commission && new Commission(data.commission);
    this.expirationDate = data.expirationDate && moment(data.expirationDate);
    this.modality = data.modality && new Modality(data.modality);
  }

  isExpired() {
    return (
      this.status === LEAVING_STATUS.EXPIRED ||
      (this.expirationDate && moment(this.expirationDate).isBefore(moment()))
    );
  }

  findInventoryByType(type: string) {
    return this.inventories.find((inventory) => inventory.type === type);
  }

  get isSigned() {
    return this.status === LEAVING_STATUS.SIGNED;
  }

  get isKeySetupStepCompleted() {
    const expectedKeyNumber = this.keySetups.filter(
      (keySetup) => !!keySetup.type,
    ).length;

    return (
      this.keySetups.filter(
        (keySetup) => keySetup.status === KEY_SETUP_STATUS.DEPOSITED,
      ).length === expectedKeyNumber && expectedKeyNumber > 1
    );
  }

  get isInventoriesOutDone() {
    const inventoryOutValidation = this.findInventoryByType(
      INVENTORY_TYPE.OUT_VALIDATION,
    );

    if (inventoryOutValidation) {
      return inventoryOutValidation.status === INVENTORY_STATUS.DONE;
    }

    const inventoryOut = this.findInventoryByType(INVENTORY_TYPE.OUT);

    if (inventoryOut) {
      return inventoryOut.status === INVENTORY_STATUS.DONE;
    }

    return false;
  }

  get isUnSetupDone() {
    return this.isCheckoutReady && this.isInventoriesOutDone;
  }
}

export function sortLeavingByDate(leavingA: Leaving, leavingB: Leaving) {
  return (
    (leavingA.startDate ? moment(leavingA.startDate).unix() : 0) -
    (leavingB.startDate ? moment(leavingB.startDate).unix() : 0)
  );
}

export function getDefaultCurrentLeaving(leavings: Array<Leaving>) {
  const sortedLeavings = [...leavings]
    .filter((leaving: Leaving) =>
      [
        LEAVING_STATUS.INIT,
        LEAVING_STATUS.EXPIRED,
        LEAVING_STATUS.SIGNED,
        LEAVING_STATUS.CANCELED,
      ].includes(leaving.status),
    )
    .sort(sortLeavingByDate);

  const now = moment();

  const inDateLeaving = sortedLeavings.find(
    (leaving) =>
      moment(leaving.startDate).isSameOrBefore(now, 'day') &&
      moment(leaving.endDate).isSameOrAfter(now, 'day'),
  );

  if (inDateLeaving) return inDateLeaving;

  const postLeaving = sortedLeavings.find((leaving) =>
    moment(leaving.startDate).isSameOrAfter(now, 'day'),
  );

  if (postLeaving) return postLeaving;

  return sortedLeavings[sortedLeavings.length - 1];
}

import { Access } from '@models/Access';
import { AccessStep } from '@models/AccessStep';

import { fetchApi } from './fetchApi';

export async function getApartmentAccessSteps(token: string, accessId: number) {
  return fetchApi<AccessStep[]>('GET', `/accesses/${accessId}/steps`, token);
}

export async function patchApartmentAccess(
  token: string,
  accessId: number,
  accessType: string,
  steps: Array<{
    position: number;
    type: string;
    value: string;
  }>,
) {
  return fetchApi<Access>('PATCH', `/accesses/${accessId}`, token, {
    body: {
      type: accessType,
      steps,
    },
  });
}

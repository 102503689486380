import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { routes } from 'src/routes';

import {
  SButton,
  SEditRow,
  SForm,
  SFormChangeProps,
  SFormSubmitProps,
  SInput,
  SText,
} from '@smartrenting/smartomic';

import CONFIG from '@config';

import { useAppContext } from '@contexts/AppContext/AppContext';

import AuthPage from '../components/AuthPage/AuthPage';

import './Login.scss';

export default function Login() {
  const { login } = useAppContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const onSubmit = async (event: FormEvent, { values }: SFormSubmitProps) => {
    setLoading(true);

    try {
      await login(values.email, values.password);
      navigate(routes.root);
    } catch {
      setLoading(false);

      toast.error(t('auth.login.fail'), {
        autoClose: 2000,
      });
    }
  };

  return (
    <AuthPage
      className="Login"
      title={t('auth.login.title')}
      subtitle={t('auth.login.subtitle')}
      form={
        <SForm
          onSubmit={onSubmit}
          initialValues={{
            email: '',
            password: '',
          }}
        >
          {({ validateRules }: SFormChangeProps) => (
            <>
              <SEditRow
                required
                name="email"
                label={t('auth.login.email')}
                validate={validateRules.validateEmail}
              >
                <SInput />
              </SEditRow>
              <SEditRow
                required
                name="password"
                type="password"
                label={t('auth.login.password')}
              >
                <SInput />
              </SEditRow>
              <Link
                to="/auth/forgotten"
                className="ALink"
                data-testid="password-forgotten-link"
              >
                <SText variant="body-title">{t('auth.forgotten.title')}</SText>
              </Link>
              <SButton disabled={loading} variant="secondary" type="submit">
                {t('auth.login.submit')}
              </SButton>
              <div className="Login__footer">
                <SText align="center" variant="body-title">
                  {t('auth.setPassword.title')}
                  <a
                    href={`${CONFIG.FO_URL}/auth/forgotten`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="form-already-submitted"
                  >
                    {t('auth.setPassword.submit')}
                  </a>
                </SText>
                <div className="Login__separator">
                  <div className="Login__separator__line" />
                  <SText variant="body-subtitle" align="center">
                    <strong>{t('common.or')}</strong>
                  </SText>
                </div>
                <SText align="center" variant="body-title">
                  {t('auth.signup.title')}
                  <a
                    href={`${CONFIG.WEBSITE_URL}/formulaire`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="create-account"
                  >
                    {t('auth.signup.submit')}
                  </a>
                </SText>
              </div>
            </>
          )}
        </SForm>
      }
    />
  );
}

export default class User {
  id!: number;
  email!: string;
  firstname!: string;
  lastname!: string;
  gender!: string;
  phone!: string;
  profilePictureId!: number;
  role!: string;
  referralCode!: string;

  constructor(data: Record<string, any>) {
    Object.assign(this, data);
  }

  get initials(): string {
    return `${this.firstname} ${this.lastname}`
      .trim()
      .split(/[\s-]+/)
      .map((el) => el[0])
      .join('')
      .toUpperCase();
  }

  get fullName(): string {
    return `${this.firstname} ${this.lastname}`;
  }
}

import { Alert, AlertTitle, Box } from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import moment from 'moment-timezone';
import QRCode from 'qrcode';
import { routes } from 'src/routes';

import {
  faTriangleExclamation,
  faDownload,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SButton, SSmartLoader, SText } from '@smartrenting/smartomic';

import CONFIG from '@config';

import { INVENTORY_TYPE } from '@enum';

import { mobileAndTabletCheck } from '@utils/tools';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import PdfViewer from '../../components/PdfViewer/PdfViewer';

import Instructions from './components/Instructions';

import './OutToSign.scss';

export default function OutToSign() {
  const navigate = useNavigate();
  const baseUrl = routes.inventoryRedirect.replace(
    ':type',
    INVENTORY_TYPE.OUT_REVIEW,
  );
  const leaving = useLeaving();
  const apartment = useApartment();
  const { t } = useTranslation();

  const {
    state: { token },
  } = useAppContext();

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<Blob>();
  const [dataUrl, setDataUrl] = useState<string>();

  const inventoryOut = leaving.findInventoryByType(INVENTORY_TYPE.OUT);

  const isMobileOrTablet = mobileAndTabletCheck();

  const handlePreview = useCallback(async () => {
    if (inventoryOut?.documentId) {
      const myHeaders = new Headers({ Authorization: token });
      const result = await fetch(
        `${CONFIG.API_V2_URL}/documents/${inventoryOut.documentId}`,
        { headers: myHeaders },
      );

      if (result && result.ok) {
        const file = await result.blob();

        setFile(file);
        setError(false);
      } else {
        setError(true);
      }
    }
  }, [leaving]);

  const handleDownload = useCallback(() => {
    const fileName = `edl-${apartment.code.replace(
      / /g,
      '-',
    )}-${inventoryOut?.typeCode}-${inventoryOut?.id}.pdf`;
    const filePdf = new Blob([file as Blob], {
      type: 'application/pdf',
    });
    const newWindow = window.open() as Window;
    newWindow.location.href = URL.createObjectURL(filePdf);

    if (!isMobileOrTablet) {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(filePdf);
      a.download = fileName;
      a.click();
      a.remove();
    }
  }, [apartment, inventoryOut]);

  const handleRedirectToOodriveSign = useCallback(() => {
    if (inventoryOut?.oodriveSignUrl) {
      const newWindow = window.open() as Window;
      newWindow.location.href = inventoryOut?.oodriveSignUrl;
    }
  }, []);

  useEffect(() => {
    const loadInventoryRedirectUrl = async () => {
      setLoading(true);
      const url = `${CONFIG.FO_URL}${baseUrl}?token=${token}&apartmentId=${apartment.id}`;

      if (!isMobileOrTablet) {
        await QRCode.toDataURL(url, {
          width: 180,
          margin: 5,
        }).then(setDataUrl);
      }

      setLoading(false);
    };

    loadInventoryRedirectUrl();
  }, []);

  useEffect(() => {
    if (token) {
      handlePreview();
    }
  }, [token, leaving.isSigned]);

  return (
    <div className="OutToSign">
      <Alert
        severity="warning"
        icon={
          <Box
            component={FontAwesomeIcon}
            icon={faTriangleExclamation as any}
            size="1x"
            my="auto"
          />
        }
        sx={{ width: '100%' }}
      >
        <AlertTitle color="warning.main">
          {t('dashboard.inventory.outToSign.warning.title', {
            count: Math.max(
              0,
              Math.ceil(
                moment(leaving.endDate)
                  .add(5, 'days')
                  .diff(moment(), 'day', true),
              ),
            ),
          })}
        </AlertTitle>
        {t('dashboard.inventory.outToSign.warning.subtitle')}
      </Alert>
      <div className="OutToSign__content">
        {error && (
          <div>
            <h2>{t('dashboard.inventory.error.title')}</h2>
            <p>
              {t('dashboard.inventory.error.content1')}
              <br />
              {t('dashboard.inventory.error.content2')}
            </p>
            <div>
              <p>{t('dashboard.inventory.error.boxContent')}</p>
              <a
                className="InReviewToSign__box__link"
                href={CONFIG.WEBSITE_URL}
              >
                <span>{CONFIG.WEBSITE_URL}</span>
              </a>
            </div>
          </div>
        )}
        {!error && (
          <>
            <div className="OutToSign__content__left">
              <div className="OutToSign__content__left__title">
                <SText variant="body-subtitle" align="center">
                  <strong>
                    {t('dashboard.inventory.outToSign.signature.title')}
                  </strong>
                </SText>
              </div>
              <div className="OutToSign__content__left__content">
                {file && <PdfViewer file={file} scale={0.7} />}
                <div className="OutToSign__content__left__content__actions">
                  <SButton
                    variant="secondary"
                    disabled={!inventoryOut?.oodriveSignUrl}
                    label={t('dashboard.inventory.sign')}
                    onClick={handleRedirectToOodriveSign}
                  />
                  <SButton
                    variant="secondary"
                    icon={faDownload}
                    disabled={!file}
                    outline
                    onClick={handleDownload}
                  />
                </div>
              </div>
            </div>

            <div className="OutToSign__content__separator">
              <div className="OutToSign__content__separator__line" />
              <SText variant="body-subtitle" align="center">
                <strong>{t('common.or')}</strong>
              </SText>
            </div>
            <div className="OutToSign__content__right">
              <div className="OutToSign__content__right__title">
                <SText variant="body-subtitle" align="center">
                  <strong>
                    {t('dashboard.inventory.outToSign.qr-code.title')}
                  </strong>
                </SText>
              </div>
              <Instructions />
              <div className="OutToSign__content__right__content">
                {isMobileOrTablet && (
                  <SButton
                    className="InventoryIn__button_redirect"
                    onClick={() => navigate(baseUrl)}
                  >
                    {t('common.start')}
                  </SButton>
                )}
                {!isMobileOrTablet && (
                  <div className="OutToSign__content__right__content__qr-code">
                    {loading && <SSmartLoader />}
                    {!loading && <img src={dataUrl} alt="qr-code" />}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

import { Alert, AlertTitle, Box } from '@mui/material';

import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SButton,
  SCard,
  SInput,
  SSelect,
  SSmartLoader,
  SText,
} from '@smartrenting/smartomic';

import { ACCESS_STEP_TYPE, ACCESS_TYPE } from '@enum';

import { AccessStep } from '@models/AccessStep';

import { useAppContext } from '@contexts/AppContext/AppContext';
import { useReferences } from '@contexts/EntitiesContext/EntitiesContext';

import { useApartment } from '@hooks/useApartment/useApartment';

import './AccessCreation.scss';

export type AccessCreationProps = {
  setEdit: (edit: boolean) => void;
};

// TODO: change that shit
const FINAL_ACCESS_STEP = "accédez à l'appartement";

export const AccessCreation = ({ setEdit }: AccessCreationProps) => {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [stepTips, setStepTips] = useState('firstStep');
  const [tipsLocation, setTipsLocation] = useState('');
  const apartment = useApartment();
  const {
    fetchApartmentAccessSteps,
    createApartmentAccess,
    updateApartmentAccess,
  } = useAppContext();
  const initialValue = apartment.accesses.find(
    (access) => access.type === ACCESS_TYPE.FOOT,
  );

  const { accessStepData } = useReferences();

  const accessStepActions = (accessStepData || []).filter(
    (accessStepData) =>
      accessStepData.accessType === ACCESS_TYPE.FOOT &&
      accessStepData.stepType === ACCESS_STEP_TYPE.ACTION,
  );

  const accessStepAccesses = (accessStepData || []).filter(
    (accessStepData) =>
      accessStepData.accessType === ACCESS_TYPE.FOOT &&
      accessStepData.stepType === ACCESS_STEP_TYPE.ACCESS,
  );

  const accessStepLocations = (accessStepData || []).filter(
    (accessStepData) =>
      accessStepData.accessType === ACCESS_TYPE.FOOT &&
      accessStepData.stepType === ACCESS_STEP_TYPE.LOCATION,
  );

  const resizeInput = () => {
    document.querySelectorAll('input').forEach((input) => {
      const hide = document.getElementById('hidden-span');
      const { value, placeholder } = input;
      const { fontSize, fontWeight } = window.getComputedStyle(input);

      if (hide) {
        hide.style.fontSize = fontSize;
        hide.style.fontWeight = fontWeight;
        hide.textContent = value !== '' ? value : placeholder;
        input.style.width = `${hide.offsetWidth}px`;
      }
    });
  };

  useEffect(() => {
    const loadAsync = async () => {
      await loadExistingAccess();
      await initInitalStep();
      await resizeInput();
    };

    loadAsync();
  }, [apartment.id]);

  const initInitalStep = () => {
    if (!Object.values(steps).length && !initialValue) {
      setSteps({
        '0': {
          type: ACCESS_STEP_TYPE.FIXED,
          value: `${t(`access.steps.initial.start.${ACCESS_TYPE.FOOT}`, {
            fullAddress: apartment.getFullAddress(),
          })}`,
        },
        '1': {
          type: ACCESS_STEP_TYPE.ACTION,
          value: null,
        },
        '2': {
          type: ACCESS_STEP_TYPE.CUSTOM,
          value: '',
        },
        '3': {
          type: ACCESS_STEP_TYPE.FIXED,
          value: t('access.steps.initial.with'),
        },
        '4': {
          type: ACCESS_STEP_TYPE.ACCESS,
          value: null,
        },
        '5': {
          type: ACCESS_STEP_TYPE.CUSTOM,
          value: '',
        },
      });
    }
  };

  const RenderInitialSteps = () => {
    if (Object.keys(steps).length < 1) return null;

    const optionsData = generateOptionsData();

    return (
      <div className="AccessCreation__editzone__loop">
        <span id="hidden-span" className="AccessCreation__hide-span" />
        <span>
          {t(`access.steps.initial.start.${ACCESS_TYPE.FOOT}`, {
            fullAddress: `${apartment.getFullAddress()},`,
          })}
        </span>
        <SSelect
          className={`SSelect${stepValue(1) ? '__text' : '__button'}`}
          name="action"
          value={stepValue(1)}
          options={optionsData.accessStepActionsOptions}
          onChange={handleSelectChange(1)}
          autoResize={!!stepValue(1)}
          placeholder="+"
        />
        <SInput
          autoCapitalize="none"
          name="custom"
          value={stepValue(2)}
          onChange={handleInputChange(2)}
          placeholder={t('access.freeText')}
        />
        <span>{t('access.steps.initial.with')}</span>
        <SSelect
          className={`SSelect${stepValue(4) ? '__text' : '__button'}`}
          name="access"
          value={stepValue(4)}
          options={optionsData.accessStepAccessesOptions}
          onChange={handleSelectChange(4)}
          autoResize={!!stepValue(4)}
          placeholder="+"
        />
        <SInput
          autoCapitalize="none"
          name="custom"
          value={stepValue(5)}
          onChange={handleInputChange(5)}
          placeholder={t('access.freeText')}
        />
      </div>
    );
  };

  const renderLoops = () => {
    let loops: any[] = [];
    const maxPosition = Math.max(
      ...Object.keys(steps || {}).map((key) => Number(key)),
    );

    if (maxPosition < 7) {
      return null;
    }

    const optionsData = generateOptionsData();

    for (let pos = 7; pos < maxPosition; pos += 8) {
      const numberOfLoop = (pos - 7) / 8;

      const newLoop = (
        <div key={`loop-${pos}`} className="AccessCreation__editzone__loop">
          <span>
            {t(
              `access.steps.loop.${numberOfLoop % 2 === 0 ? 'start' : 'once'}`,
            )}
          </span>
          <SSelect
            className={`SSelect${stepValue(pos) ? '__text' : '__button'}`}
            name="location"
            value={stepValue(pos)}
            options={optionsData.accessStepLocationsOptions}
            onChange={handleSelectChange(pos)}
            autoResize={!!stepValue(pos)}
            placeholder="+"
          />
          <SInput
            autoCapitalize="none"
            name="custom"
            value={steps[pos + 1].value}
            onChange={handleInputChange(pos + 1)}
            placeholder={t('access.freeText')}
          />
          <span>,</span>
          <SSelect
            className={`SSelect${stepValue(pos + 2) ? '__text' : '__button'}`}
            name="action"
            value={stepValue(pos + 2)}
            options={optionsData.accessStepActionsOptions}
            onChange={handleSelectChange(pos + 2)}
            autoResize={!!stepValue(pos + 2)}
            placeholder="+"
          />
          <SInput
            autoCapitalize="none"
            name="custom"
            value={steps[pos + 3].value}
            onChange={handleInputChange(pos + 3)}
            placeholder={t('access.freeText')}
          />
          <span>{t('access.steps.loop.with')}</span>
          <SSelect
            className={`SSelect${stepValue(pos + 5) ? '__text' : '__button'}`}
            value={stepValue(pos + 5)}
            name="access"
            options={optionsData.accessStepAccessesOptions}
            onChange={handleSelectChange(pos + 5)}
            autoResize={!!stepValue(pos + 5)}
            placeholder="+"
          />
          <SInput
            autoCapitalize="none"
            name="custom"
            value={steps[pos + 6].value}
            onChange={handleInputChange(pos + 6)}
            placeholder={t('access.freeText')}
          />
        </div>
      );

      loops = [...loops, newLoop];
    }

    return loops;
  };

  const handleSelectChange =
    (position: number) => (event: ChangeEvent<HTMLSelectElement>) => {
      let newSteps = {
        ...steps,
        [position.toString()]: {
          type: event.currentTarget.name,
          value: event.currentTarget.value,
        },
      };

      handleStepTips(position, event.currentTarget.value);
      let loop = 0;
      let loopEdited: number | null = null;
      const allPositions = Object.keys(newSteps)
        .map((key) => Number(key))
        .slice(6);

      if (event.currentTarget.value === null) {
        allPositions.forEach((positionLoop, index) => {
          if (index && index % 8 === 0) {
            loop += 1;
          }

          if (positionLoop === position) {
            loopEdited = loop;
          }

          if (position < 6 || (loopEdited !== null && loop > loopEdited)) {
            delete newSteps[positionLoop];
          }
        });
      }

      if (
        event.currentTarget.value === FINAL_ACCESS_STEP &&
        position <=
          Math.max(...Object.keys(newSteps).map((key) => Number(key))) - 1
      ) {
        Object.keys(newSteps).forEach((stepPosition) => {
          if (Number(stepPosition) > position + 4) {
            delete newSteps[stepPosition];
          }
        });
      }

      if (shouldAddLoop(newSteps)) {
        newSteps = addNewLoop(newSteps);
      }

      const isComplete = checkStepsCompletion(newSteps);

      setSteps(newSteps);
      setIsComplete(isComplete);
    };

  const handleInputChange =
    (position: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value, placeholder } = event.currentTarget;
      const hide = document.getElementById('hidden-span');
      const { fontSize, fontWeight } = window.getComputedStyle(event.target);

      if (hide) {
        hide.style.fontSize = fontSize;
        hide.style.fontWeight = fontWeight;
        hide.textContent = value !== '' ? value : placeholder;
        event.currentTarget.style.width = `${hide.offsetWidth}px`;
        setSteps((prevState) => ({
          ...prevState,
          [position.toString()]: {
            type: name,
            value: value,
          },
        }));
      }
    };

  const handleClickSubmit = async () => {
    const formatedSteps = Object.keys(steps).map((key) => ({
      position: Number(key),
      type: steps[key].type,
      value: steps[key].value,
    }));

    setLoading(true);

    (initialValue
      ? updateApartmentAccess(initialValue.id, ACCESS_TYPE.FOOT, formatedSteps)
      : createApartmentAccess(apartment.id, ACCESS_TYPE.FOOT, formatedSteps)
    ).finally(() => {
      setLoading(false);
      setEdit(false);
    });
  };

  const generateOptionsData = () => {
    return {
      accessStepActionsOptions: formatOptions(accessStepActions),
      accessStepAccessesOptions: formatOptions(accessStepAccesses),
      accessStepLocationsOptions: formatOptions(accessStepLocations),
    };
  };

  const formatOptions = (accessSteps: { code: string; accessType: string }[]) =>
    accessSteps.map<{ value: string; label: string; group: string }>(
      (accessStep) => ({
        value: accessStep.code,
        label: accessStep.code,
        group: accessStep.accessType,
      }),
    );

  const checkStepsCompletion = (steps: Record<string, AccessStep>) => {
    const neededSteps = (Object.values(steps) as Partial<AccessStep>[]).filter(
      ({ type }) => type !== 'custom' && type !== 'fixed',
    );
    const isEveryNeededStepFilled = neededSteps.every((step) => step.value);
    const counts = countStepsByType(steps);

    if (counts.isFinalStepSelected) {
      setStepTips('lastStep');
    }

    return counts.isFinalStepSelected && isEveryNeededStepFilled;
  };

  const shouldAddLoop = (steps: Record<string, AccessStep>) => {
    const counts = countStepsByType(steps);
    const neededSteps = (Object.values(steps) as Partial<AccessStep>[]).filter(
      ({ type }) => type !== 'custom' && type !== 'fixed',
    );
    const isEveryNeededStepFilled = neededSteps.every((step) => step.value);

    const isFirstLoop = Object.keys(steps).length <= 6;

    return (
      isEveryNeededStepFilled &&
      counts.action === counts.access &&
      (isFirstLoop || counts.access - 1 === counts.location) &&
      !counts.isFinalStepSelected
    );
  };

  const countStepsByType = (steps: Record<string, AccessStep>) => {
    return Object.keys(steps).reduce(
      (acc, key) => {
        const currentType = steps[key].type;

        acc.isFinalStepSelected =
          acc.isFinalStepSelected ||
          (steps[key].type === ACCESS_STEP_TYPE.ACTION &&
            steps[key].value === FINAL_ACCESS_STEP);

        if (steps[key].value) {
          return { ...acc, [currentType]: acc[currentType] + 1 };
        }

        return { ...acc };
      },
      {
        action: 0,
        access: 0,
        custom: 0,
        fixed: 0,
        isFinalStepSelected: false,
        location: 0,
      } as Record<string, any>,
    );
  };

  const addNewLoop = (steps: Record<string, AccessStep>) => {
    const maxPosition = Math.max(
      ...Object.keys(steps).map((key) => Number(key)),
    );

    const numberOfLoop = (maxPosition - 5) / 8;

    return {
      ...steps,
      [maxPosition + 1]: {
        type: ACCESS_STEP_TYPE.FIXED,
        value: t(
          `access.steps.loop.${numberOfLoop % 2 === 0 ? 'start' : 'once'}`,
        ),
      },
      [maxPosition + 2]: {
        type: ACCESS_STEP_TYPE.LOCATION,
        value: null,
      },
      [maxPosition + 3]: {
        type: ACCESS_STEP_TYPE.CUSTOM,
        value: '',
      },
      [maxPosition + 4]: {
        type: ACCESS_STEP_TYPE.ACTION,
        value: null,
      },
      [maxPosition + 5]: {
        type: ACCESS_STEP_TYPE.CUSTOM,
        value: '',
      },
      [maxPosition + 6]: {
        type: ACCESS_STEP_TYPE.FIXED,
        value: t('access.steps.loop.with'),
      },
      [maxPosition + 7]: {
        type: ACCESS_STEP_TYPE.ACCESS,
        value: null,
      },
      [maxPosition + 8]: {
        type: ACCESS_STEP_TYPE.CUSTOM,
        value: '',
      },
    };
  };

  const handleStepTips = (position: number, location: string) => {
    switch (position) {
      case 1:
        setStepTips('firstAccess');
        break;
      case 4:
        setStepTips('firstLocation');
        break;
      case 7:
        setStepTips('secondStep');
        setTipsLocation(location);
        break;
      default:
        break;
    }

    if (position >= 13) {
      setStepTips('freeText');
    }
  };

  const loadExistingAccess = async () => {
    if (initialValue) {
      setLoading(true);

      const fetchedSteps = await fetchApartmentAccessSteps(initialValue.id);

      const objectSteps = fetchedSteps.reduce((acc: any, step: any) => {
        return {
          ...acc,
          [step.position]: step,
        };
      }, {});

      setSteps(objectSteps);
      setLoading(false);
      setIsComplete(true);
    }
  };

  const stepValue = (position: number) => {
    return (steps[position] && steps[position].value) || '';
  };

  return (
    <div>
      <Alert
        severity="info"
        icon={
          <Box
            component={FontAwesomeIcon}
            icon={faExclamationCircle as any}
            size="1x"
            my="auto"
          />
        }
      >
        <AlertTitle>{t('access.alert.title')}</AlertTitle>
        {t('access.alert.subtitle')}
      </Alert>
      <div className="AccessCreation__title">{t('access.types.foot')}</div>
      <SCard className="AccessCreation">
        <div className="AccessCreation__editzone__container">
          {loading && (
            <div className="AccessCreation__editzone__loader">
              <SSmartLoader />
            </div>
          )}
          {!loading && (
            <>
              {RenderInitialSteps()}
              {renderLoops()}
            </>
          )}
        </div>
        <div className="AccessCreation__tipszone">
          <SCard>
            <SText variant="body-title">{t('access.tips.title')}</SText>
            <SText variant="text">
              {t(`access.tips.${stepTips}`, {
                lieu: tipsLocation,
              })}
            </SText>
          </SCard>
        </div>
      </SCard>
      <SButton
        className="validate-form-button"
        variant="secondary"
        label={t('settings.submit')}
        disabled={loading || !isComplete}
        onClick={handleClickSubmit}
      />
    </div>
  );
};

export default AccessCreation;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { routes } from 'src/routes';

import {
  faUser,
  faWallet,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import {
  AAvatar,
  ASeparator,
  AText,
  SNavItem,
  STitle,
} from '@smartrenting/smartomic';

import { useContact } from '@hooks/useContact/useContact';
import { usePicture } from '@hooks/usePicture/usePicture';

import './SettingsNavigation.scss';

export default function SettingsNavigation() {
  const { t } = useTranslation();
  const contact = useContact();
  const navigate = useNavigate();

  const picture = usePicture(contact?.user?.profilePictureId);

  return (
    <div className="SettingsNavigation">
      <STitle
        className="SettingsNavigation__title"
        title={t('navigation.routes.settings')}
      />
      <ASeparator />
      <div className="SettingsNavigation__profile">
        <AAvatar initials={contact.initials} picture={picture} />
        <div className="SettingsNavigation__avatar">
          <AText>{t('common.hello')}</AText>
          <STitle title={contact.fullName} />
        </div>
      </div>
      <ASeparator className="SettingsNavigation__separator__unset-margin-bottom" />
      <SNavItem
        label={t('navigation.routes.user.title')}
        icon={faUser}
        href={routes.user}
        rightIcon={faChevronRight}
        onClick={navigate}
      />
      <ASeparator className="SettingsNavigation__separator" />
      <SNavItem
        label={t('navigation.routes.rib.title')}
        icon={faWallet}
        rightIcon={faChevronRight}
        href={routes.rib}
        onClick={navigate}
      />
      <ASeparator className="SettingsNavigation__separator" />
      <SNavItem
        label={t('navigation.routes.security.title')}
        icon={faWallet}
        rightIcon={faChevronRight}
        href={routes.security}
        onClick={navigate}
      />
      <ASeparator className="SettingsNavigation__separator" />
    </div>
  );
}

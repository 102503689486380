import { Box } from '@mui/material';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { STitle } from '@smartrenting/smartomic';

import { LEAVING_STATUS } from '@enum';

import { useApartment } from '@hooks/useApartment/useApartment';

import Trip from '../Trip/Trip';

interface DocumentsByLeavingProps {
  display?: boolean;
}

export default function DocumentsByLeaving({
  display,
}: DocumentsByLeavingProps) {
  if (!display) return null;

  const { leavings } = useApartment();
  const { t } = useTranslation();

  const filteredLeavings = useMemo(() => {
    return leavings
      .filter((leaving) => leaving.status !== LEAVING_STATUS.ARCHIVED)
      .sort((leavingA, leavingB) =>
        leavingA.startDate.isBefore(leavingB.startDate) ? -1 : 1,
      );
  }, [leavings]);

  return (
    <>
      <STitle variant="body" title={t('documents.trip.title')} />

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {filteredLeavings.map((leaving, index) => (
          <Trip key={index} leaving={leaving} index={index} />
        ))}
      </Box>
      <STitle variant="body" title={t('documents.list.title')} />
    </>
  );
}

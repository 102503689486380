import { Box, Grid, Typography } from '@mui/material';

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faLockKeyholeOpen,
  faKeySkeletonLeftRight,
} from '@fortawesome/pro-duotone-svg-icons';

import { TERM_PRICING_TYPE } from '@enum';

import Modality from '@models/Modality';

import StepChoice from '../ModalityChoice';
import { useUniqueTermPricingByType } from '../hooks/useTermPricing';

interface StepKeysProps {
  onChoiceChange: (data: Record<string, any>) => void;
  modality: Modality | null;
}

export default function StepKeys({ onChoiceChange, modality }: StepKeysProps) {
  const { t } = useTranslation();
  const termPricing = useUniqueTermPricingByType(TERM_PRICING_TYPE.PAID_KEY);
  const [currentChoice, setCurrentChoice] = useState<boolean>(
    !!modality?.paidKey,
  );

  const handleClick = useCallback(
    (choice: boolean) => () => {
      setCurrentChoice(choice);
      onChoiceChange({ paidKey: choice });
    },
    [],
  );

  return (
    <Box>
      <Typography
        variant="h6"
        align="center"
        fontWeight={500}
        sx={{ mb: { xs: '1rem', sm: '2rem' } }}
      >
        {t('boardOffice.steps.keys.title')}
      </Typography>
      <Grid sx={{ flexGrow: 1 }} container>
        <Grid container justifyContent="center" spacing={{ xs: 2, sm: 4 }}>
          <Grid key="keys-autonomous" item onClick={handleClick(false)}>
            <StepChoice
              title={t('boardOffice.steps.keys.autonomous.title')}
              subtitle={t('boardOffice.steps.keys.autonomous.subtitle')}
              header={t('boardOffice.steps.keys.autonomous.header')}
              icon={faLockKeyholeOpen as IconProp}
              active={!currentChoice}
            />
          </Grid>
          <Grid key="keys-delegated" item onClick={handleClick(true)}>
            <StepChoice
              title={t('boardOffice.steps.keys.delegated.title')}
              subtitle={t('boardOffice.steps.keys.delegated.subtitle')}
              header={t('boardOffice.steps.keys.delegated.header', {
                amount: termPricing.value,
              })}
              icon={faKeySkeletonLeftRight as IconProp}
              active={currentChoice}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

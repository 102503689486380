import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  AText,
  SButton,
  SEquipmentList,
  STitle,
} from '@smartrenting/smartomic';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';

import {
  equipmentsDefault,
  equipmentsOther,
} from '@scopes/Gamification/components/EquipmentsStep/EquipmentsStep';

import './Equipment.scss';

const Equipment: React.FC<{
  edit: boolean;
  setEdit?: (edit: boolean) => void;
}> = ({ edit, setEdit }) => {
  const { t } = useTranslation();
  const { equipments, id } = useApartment();
  const { updateApartment } = useAppContext();

  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [selected, setSelected] = useState<string[]>(
    equipments.filter((e: string) => !equipmentsOther.includes(e as any)),
  );
  const [otherSelected, setOtherSelected] = useState<string[]>(
    equipments.filter((e: string) => !equipmentsDefault.includes(e as any)),
  );

  useEffect(() => {
    setUpdated(false);
    setSelected([
      ...equipments.filter((e: string) => !equipmentsOther.includes(e as any)),
    ]);
    setOtherSelected(
      equipments.filter((e: string) => !equipmentsDefault.includes(e as any)),
    );
  }, [edit]);

  const handleChangeDefault = ({ target }: any) => {
    setUpdated(true);
    setSelected(target.value);
  };
  const handleChangeOther = ({ target }: any) => {
    setUpdated(true);
    setOtherSelected(target.value);
  };

  const handleSubmit = async (equipments: string[]) => {
    setLoading(true);
    try {
      await updateApartment(id, {
        equipments,
      });
    } catch (error) {
      toast.error(t('common.unhandledError'), {
        autoClose: 2000,
      });
    }
    setLoading(false);
    if (setEdit) setEdit(false);
  };

  if (!edit)
    return (
      <div className="EquipmentBlock__view">
        {equipments.map((equipment, index) => {
          return (
            <div key={index} className="EquipmentBlock__element">
              <span className={`household_items-${equipment}`}></span>
              <AText>{t(`equipment.${equipment}`)}</AText>
            </div>
          );
        })}
      </div>
    );

  return (
    <>
      <div className="EquipmentBlock">
        <div className="EquipmentBlock__list">
          <STitle
            variant="page"
            title={t('apartment.info.equipmentsDefault')}
          />
          <SEquipmentList
            name={'equipementDefault'}
            equipments={equipmentsDefault}
            onChange={handleChangeDefault}
            value={selected}
          />
        </div>

        <div className="EquipmentBlock__list">
          <STitle variant="page" title={t('common.other')} />
          <SEquipmentList
            name={'equipementOther'}
            equipments={equipmentsOther}
            onChange={handleChangeOther}
            value={otherSelected}
          />
        </div>
      </div>
      <SButton
        className="validate-form-button"
        disabled={loading || !updated}
        variant="secondary"
        label={t('settings.submit')}
        onClick={handleSubmit.bind(null, [...selected, ...otherSelected])}
      />
    </>
  );
};

export default Equipment;

import { contact } from '@schemas';

import { denormalize } from 'normalizr';

import Contact from '@models/Contact';

import { useAppContext } from '@contexts/AppContext/AppContext';
import { useEntities } from '@contexts/EntitiesContext/EntitiesContext';

export function useContact(): Contact {
  const entities = useEntities();
  const {
    state: { contact: contactId },
  } = useAppContext();

  const selectedContact = denormalize(contactId, contact, entities);

  return selectedContact && new Contact(selectedContact);
}

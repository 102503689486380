import { Alert, AlertTitle, Box } from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment-timezone';

import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CalendarConfirmation, SSmartLoader } from '@smartrenting/smartomic';

import { toastPromise } from '@utils/tools';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';
import { useTranslationFormat } from '@hooks/useTranslationFormat/useTranslationFormat';

import Scheduler, { SchedulerLogoType } from '@components/Scheduler/Scheduler';

import Step from '../components/Step/Step';

import photoShootSvg from './photoShoot.svg';

import './PhotoShoot.scss';

export default function PhotoShoot() {
  const [date, setDate] = useState<moment.Moment>();
  const [loading, setLoading] = useState(false);

  const [timeSlots, setTimeSlots] = useState<
    Record<string, { available: boolean }>
  >({});

  const firstDaySlot = moment().add(1, 'day');
  const [availabilities, setAvailabilities] = useState<{
    start: moment.Moment;
    end: moment.Moment;
    cityId: number;
    packId: number;
  }>({
    start: firstDaySlot,
  } as any);

  const { t } = useTranslation();
  const { t: translationFormat } = useTranslationFormat();
  const apartment = useApartment();

  const leaving = useLeaving();
  const threeDayAfterLeavingSigning = leaving.signatureDate
    ?.clone()
    .add(4, 'day');
  const slotsAreClosed = firstDaySlot.isSameOrAfter(leaving.startDate, 'day');

  const photoContract = apartment.photoContracts[0];

  const {
    createPicthouseOrder,
    fetchPhotoContractDates,
    fetchPhotoContractSlots,
  } = useAppContext();

  const handleSubmit = useCallback(
    (date: moment.Moment) => {
      setLoading(true);
      toastPromise(createPicthouseOrder(photoContract.id, date)).finally(() => {
        setLoading(false);
      });
    },
    [photoContract.id],
  );

  useEffect(() => {
    if (!slotsAreClosed) {
      setLoading(true);
      fetchPhotoContractDates(photoContract.id).then(
        ({
          cityId,
          packId,
          dates,
        }: {
          cityId: number;
          packId: number;
          dates: string[];
        }) => {
          const lastDateIndex = dates.findIndex((dateString: string) => {
            const date = moment(dateString);

            return date.isSameOrAfter(leaving.startDate, 'day');
          });
          const firstDateIndex = dates.findIndex((dateString: string) => {
            const date = moment(dateString);

            return date.isAfter(moment(), 'day');
          });

          const end =
            lastDateIndex !== -1
              ? dates[lastDateIndex - 1]
              : dates[dates.length - 1];

          const computedEnd = moment(end).isAfter(
            moment(dates[firstDateIndex]).add(7, 'days'),
          )
            ? moment(dates[firstDateIndex]).add(7, 'days')
            : moment(end);

          setLoading(false);
          setAvailabilities({
            start: moment(dates[firstDateIndex]),
            end: computedEnd,
            cityId,
            packId,
          });
        },
      );
    }
  }, [photoContract.id, slotsAreClosed]);

  useEffect(() => {
    const { cityId, packId } = availabilities;

    if (availabilities.cityId && availabilities.packId && date) {
      fetchPhotoContractSlots(photoContract.id, date, cityId, packId).then(
        (data) => {
          setTimeSlots(data);
        },
      );
    }
  }, [photoContract.id, date, availabilities.packId, availabilities.cityId]);

  const alreadyStartedSince = Math.ceil(
    leaving.startDate.diff(moment().add(1, 'day'), 'day', true),
  );

  return (
    <Step img={photoShootSvg}>
      {slotsAreClosed && (
        <div className="PhotoShoot__card">
          <Alert
            severity="error"
            icon={
              <Box
                component={FontAwesomeIcon}
                icon={faExclamationCircle as any}
                size="1x"
                my="auto"
              />
            }
            sx={{ mb: 2, width: '100%' }}
          >
            <AlertTitle color="error.main">
              {t('dashboard.photoShoot.alreadyStarted.title', {
                count: alreadyStartedSince,
              })}
            </AlertTitle>
            {t('dashboard.photoShoot.alreadyStarted.subtitle')}
          </Alert>
        </div>
      )}
      {!slotsAreClosed && (
        <>
          <div className="PhotoShoot__card">
            <Alert
              severity="warning"
              icon={
                <Box
                  component={FontAwesomeIcon}
                  icon={faExclamationCircle as any}
                  size="1x"
                  my="auto"
                />
              }
              sx={{ width: '100%' }}
            >
              <AlertTitle color="warning.main">
                {t('dashboard.photoShoot.startSoon.title', {
                  count: Math.max(
                    0,
                    Math.ceil(
                      threeDayAfterLeavingSigning?.diff(
                        firstDaySlot,
                        'day',
                        true,
                      ) || 0,
                    ),
                  ),
                })}
              </AlertTitle>
              {translationFormat('dashboard.photoShoot.startSoon.subtitle')}
            </Alert>
          </div>
          <Scheduler
            type={SchedulerLogoType[SchedulerLogoType.photo]}
            duration={30}
            onChange={null}
          >
            <CalendarConfirmation
              timeSelector
              duration={30}
              title={t('dashboard.photoShoot.form.date')}
              min={availabilities.start}
              max={availabilities.end}
              onChange={(newDate: moment.Moment) => setDate(newDate)}
              timeSlots={Object.keys(timeSlots).filter(
                (slot: string) => timeSlots[slot].available,
              )}
              onSubmit={handleSubmit}
            />
          </Scheduler>
        </>
      )}
      {loading && (
        <div className="PhotoShoot__loader">
          <SSmartLoader />
        </div>
      )}
    </Step>
  );
}

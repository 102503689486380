const WEBSITE_URL = 'https://leazly.fr';
const GOOGLE_API_KEY = 'AIzaSyCAa2q4ij1-8NZsonoGgbMNKrxA-sdeGpQ';
const GTM_ID_DEV = 'GTM-5DR44BK';
const GTM_ID_PROD = 'GTM-MWTGRL2';

const CONFIG = {
  development: {
    ENVIRONMENT: 'development',
    API_V2_URL: 'http://localhost:7006/v2',
    WEBSITE_URL: 'http://localhost:3004',
    FO_URL: 'http://localhost:3001',
    GOOGLE_API_KEY,
    GTM_ID: GTM_ID_DEV,
  },
  test: {
    ENVIRONMENT: 'test',
    API_V2_URL: 'http://localhost:7006/v2',
    FO_URL: 'http://localhost:3001',
    WEBSITE_URL: 'https://www.dev.leazly.fr',
    GOOGLE_API_KEY,
    GTM_ID: undefined,
  },
  staging: {
    ENVIRONMENT: 'staging',
    API_V2_URL: 'https://smart-api-staging.leazly.fr/v2',
    FO_URL: 'https://app-staging.leazly.fr',
    WEBSITE_URL: 'https://www.dev.leazly.fr',
    GOOGLE_API_KEY,
    GTM_ID: GTM_ID_DEV,
  },
  preproduction: {
    ENVIRONMENT: 'preproduction',
    API_V2_URL: 'https://smart-api-preproduction.leazly.fr/v2',
    FO_URL: 'https://app-preproduction.leazly.fr',
    WEBSITE_URL: 'https://www.dev.leazly.fr',
    GOOGLE_API_KEY,
    GTM_ID: GTM_ID_DEV,
  },
  production: {
    ENVIRONMENT: 'production',
    API_V2_URL: 'https://smart-api-production.leazly.fr/v2',
    FO_URL: 'https://app.leazly.fr',
    WEBSITE_URL,
    GOOGLE_API_KEY,
    GTM_ID: GTM_ID_PROD,
  },
};

let config = CONFIG[process.env.NODE_ENV || 'development'];

// If I get an ENVIRONMENT from the idex.html, I use it. If not then I am in dev mode
if (
  global.window &&
  (global.window as any).ENVIRONMENT &&
  (global.window as any).ENVIRONMENT !== '__ENVIRONMENT__'
)
  config = CONFIG[(global.window as any).ENVIRONMENT as 'development'];

// eslint-disable-next-line
export default config;

import BackgroundPicture from './assets/background.png';

import React from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { routes } from 'src/routes';

import Logo from '@components/Logo/Logo';

import Login from '@scopes/Auth/Login/Login';

import Forgotten from './Forgotten/Forgotten';
import Register from './Register/Register';
import Reset from './Reset/Reset';

import './Auth.scss';

function Auth() {
  return (
    <div className="Auth">
      <Logo className="Auth__logoMobile" />
      <div className="Auth__picture">
        <img src={BackgroundPicture}></img>
      </div>
      <div className="Auth__page">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotten" element={<Forgotten />} />
          <Route path="/new-password" element={<Reset />} />
          <Route path="*" element={<Navigate replace to={routes.login} />} />
        </Routes>
      </div>
    </div>
  );
}

export default Auth;

import { HostRent } from '@models/HostRent';

import { fetchApi } from './fetchApi';

export async function getHostRentsWithBills(
  token: string,
  apartmentId: number,
) {
  return fetchApi<HostRent[]>('GET', `/host-rents/bills/${apartmentId}`, token);
}

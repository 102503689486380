import { useEffect, useRef, useState } from 'react';

import { CleaningDuration } from '@models/CleaningDuration';

import { useAppContext } from '@contexts/AppContext/AppContext';

export function useCleaningDuration(area: number) {
  const [cleaningDuration, setCleaningDuration] = useState<
    CleaningDuration | undefined
  >(undefined);
  const { fetchCleaningDurationByArea } = useAppContext();
  const hasFetchedRef = useRef(false);

  useEffect(() => {
    if (!hasFetchedRef.current) {
      hasFetchedRef.current = true;
      fetchCleaningDurationByArea(area).then((res) => {
        setCleaningDuration(res);
      });
    }
  }, [fetchCleaningDurationByArea]);

  return cleaningDuration;
}

import moment from 'moment-timezone';
import { Schemas } from 'src/schemas';

export const classNames = (...classNames: (string | undefined | boolean)[]) =>
  classNames.filter((e) => e).join(' ');

export function isObject(item: any): boolean {
  return (
    item &&
    typeof item === 'object' &&
    !Array.isArray(item) &&
    !moment.isMoment(item)
  );
}

export function mergeDeep(target: any, ...sources: any): any {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }

        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const defaultFunctionParameter = async (...params: any) => {
  void params;
};

export type Actions<T> = {
  type: T;
  payload?: any;
  meta?: any;
  schema?: Schemas;
};

import React from 'react';

import Page from '@components/Page/Page';

export default function Leavings() {
  return (
    <div className="Page__container">
      <Page>Leavings</Page>
    </div>
  );
}

import moment from 'moment-timezone';

import { RefferalStatus } from '@enum';

import Apartment from './Apartment';
import Document from './Document';
import User from './User';

interface Referral {
  id: number;
  amount: number;
  createdAt: moment.Moment;
  status: RefferalStatus;
  referee: User;
}

export default class Contact {
  id!: number;
  user: User;
  apartments: Apartment[] = [];

  birthDate!: string;
  birthTown!: string;
  address!: string;
  iban!: string;
  bic!: string;

  identityDocuments: Document[] = [];
  rib: Document;

  referrals?: Referral[];

  constructor(data: Partial<Contact> | any = {}) {
    Object.assign(this, data);

    this.apartments =
      data.apartments &&
      data.apartments.map(
        (apartment: Partial<Apartment>) => new Apartment(apartment),
      );

    this.identityDocuments =
      data.identityDocuments &&
      data.identityDocuments.map(
        (document: Partial<Document>) => new Document(document),
      );
    this.rib = data.rib && new Document(data.rib);
    this.user = data.user && new User(data.user);
    this.referrals = data.referrals?.map((referral: Referral) => ({
      ...referral,
      createdAt: referral.createdAt && moment(referral.createdAt),
      referee: new User(referral.referee),
    }));
  }

  get fullName() {
    return this.user.fullName;
  }

  get initials() {
    return this.user.initials;
  }
}

import { keySetup } from '@schemas';

import { denormalize } from 'normalizr';

import { APARTMENT_KEY_DENOMINATION } from '@enum';

import KeySetup from '@models/KeySetup';

import { useEntities } from '@contexts/EntitiesContext/EntitiesContext';

import { useLeaving } from '@hooks/useLeaving/useLeaving';

export function useKeySetups() {
  const entities = useEntities();
  const leaving = useLeaving();

  if (!leaving) {
    return {
      guestKeySetup: null,
      cleaningKeySetup: null,
      agencyKeySetup: null,
    };
  }

  const keySetups: KeySetup[] = denormalize(
    leaving.keySetups.map((keySetup: KeySetup) => keySetup.id),
    [keySetup],
    entities,
  );

  const guestKeySetup = keySetups.find(
    (keySetup: KeySetup) =>
      keySetup && keySetup.denomination === APARTMENT_KEY_DENOMINATION.GUEST,
  );

  const cleaningKeySetup = keySetups.find(
    (keySetup: KeySetup) =>
      keySetup && keySetup.denomination === APARTMENT_KEY_DENOMINATION.CLEANING,
  );

  const agencyKeySetup = keySetups.find(
    (keySetup: KeySetup) =>
      keySetup && keySetup.denomination === APARTMENT_KEY_DENOMINATION.AGENCY,
  );

  return {
    guestKeySetup: guestKeySetup,
    cleaningKeySetup: cleaningKeySetup,
    agencyKeySetup: agencyKeySetup,
  };
}

import React, {
  createContext,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';

import { routes } from 'src/routes';

import { defaultFunctionParameter } from '@utils/helpers';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useProspect } from '@hooks/useProspect/useProspect';

export enum GamificationStep {
  type,
  capacity,
  rooms,
  equipments,
  keywords,
  neighborhood,
  photo,
}

interface GamificationInterface {
  handleNext: (cb: () => Promise<any>) => any;
  handlePrev: CallableFunction;
  enable: CallableFunction;
  disable: CallableFunction;
  disabled: boolean;
  loading: boolean;
  onNext: MouseEventHandler;
  step: (typeof GamificationStep)[keyof typeof GamificationStep];
}

const GamificationContext = createContext<GamificationInterface>({
  handleNext: defaultFunctionParameter as any,
  handlePrev: defaultFunctionParameter,
  enable: defaultFunctionParameter,
  disable: defaultFunctionParameter,
  onNext: defaultFunctionParameter,
  disabled: false,
  loading: false,
  step: GamificationStep.type,
});

export const GamificationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const prospect = useProspect();
  const stepInLocalStorage =
    localStorage.getItem(`gamification-${prospect.id}`) || '';
  const { endGamification } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [nextCb, setNextCb] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const locationStep = Object.entries(GamificationStep).find(
    ([key]) => key === location.hash.slice(1),
  )?.[1] as any as number;
  const [step, setStep] = useState<number>(
    parseInt(stepInLocalStorage) || locationStep || GamificationStep.type,
  );

  const onNext = useCallback(async () => {
    setLoading(true);
    try {
      await nextCb.cb();
      setLoading(false);

      if (step === GamificationStep.photo) {
        await endGamification(prospect.id);
        localStorage.removeItem(`gamification-${prospect.id}`);
        navigate(routes.estimation);
      } else {
        setDisabled(true);
        setStep(step + 1);

        if (step !== GamificationStep.keywords) {
          localStorage.setItem(`gamification-${prospect.id}`, `${step + 1}`);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  }, [step, prospect, nextCb]);

  const handleNext = (cb: any) => {
    setNextCb({ cb });
  };

  const handlePrev = useCallback(() => setStep(step - 1), [step]);

  useEffect(() => {
    if (location.hash.slice(1) !== GamificationStep[step]) {
      navigate(location.pathname + `#${GamificationStep[step]}`);
    }
  }, [location, step]);

  return (
    <GamificationContext.Provider
      value={{
        step,
        handleNext,
        handlePrev,
        disabled,
        loading,
        onNext,
        enable: () => setDisabled(false),
        disable: () => setDisabled(true),
      }}
    >
      {children}
    </GamificationContext.Provider>
  );
};

export const useGamification = () => {
  return useContext(GamificationContext);
};

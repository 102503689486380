import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { routes } from 'src/routes';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useProspect } from '@hooks/useProspect/useProspect';

export function useInit() {
  const {
    state: { token, connected },
    setToken,
    setCurrentApartment,
    fetchContact,
    fetchReferences,
    fetchNeighborhoods,
    fetchEvents,
    fetchTermsPricing,
    authenticate,
    resetState,
  } = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearchParams] = useSearchParams();
  const prospect = useProspect();

  useEffect(() => {
    if (connected && token && location.pathname.startsWith('/auth')) {
      navigate(routes.root, { replace: true });
    }
    if (!connected && !token && !location.pathname.startsWith('/auth')) {
      navigate(routes.login, { replace: true });
    }
    if (
      !connected &&
      token &&
      !location.pathname.startsWith(routes.register) &&
      !location.pathname.startsWith(routes.reset)
    ) {
      authenticate();
    }
  }, [connected, token, location.pathname]);

  useEffect(() => {
    const t = search.get('token');

    if (t && t !== token) {
      search.delete('token');
      setSearchParams(search);
      resetState();
      setToken(t);
    }
  }, [token, search]);

  useEffect(() => {
    const apartId = search.get('apartmentId')
      ? Number(search.get('apartmentId'))
      : undefined;

    if (apartId) {
      setCurrentApartment(apartId);
      search.delete('apartmentId');
    }

    if (token && connected) {
      fetchContact(apartId);
      fetchReferences();
      fetchNeighborhoods();
      fetchTermsPricing();
    }
  }, [token, connected]);

  useEffect(() => {
    if (token && connected && prospect?.id) {
      fetchEvents(prospect.id);
    }
  }, [token, prospect?.id, connected]);
}

import { termPricing } from '@schemas';

import { useMemo } from 'react';

import { denormalize } from 'normalizr';

import { TERM_PRICING_TYPE } from '@enum';

import TermPricing from '@models/TermPricing';

import { useEntities } from '@contexts/EntitiesContext/EntitiesContext';

export function useUniqueTermPricingByType(
  termPricingName: (typeof TERM_PRICING_TYPE)[keyof typeof TERM_PRICING_TYPE],
): TermPricing {
  const entities = useEntities();

  const termsPricing = useMemo(
    () =>
      denormalize(Object.keys(entities.termsPricing), [termPricing], entities),
    [entities.termsPricing],
  );

  const foundTermPricing = termsPricing.find((tp: TermPricing) => {
    return tp.type === termPricingName;
  });

  return foundTermPricing && new TermPricing(foundTermPricing);
}

export function useMultipleTermPricingByType(
  termPricingName: (typeof TERM_PRICING_TYPE)[keyof typeof TERM_PRICING_TYPE],
): TermPricing[] {
  const entities = useEntities();

  const termsPricing = useMemo(
    () =>
      denormalize(Object.keys(entities.termsPricing), [termPricing], entities),
    [entities.termsPricing],
  );

  const foundTermPricing = termsPricing.filter((tp: TermPricing) => {
    return tp.type === termPricingName;
  });

  return (
    foundTermPricing &&
    foundTermPricing.map((tp2: TermPricing) => new TermPricing(tp2))
  );
}

export function useTermsPricing(): Array<TermPricing> {
  const entities = useEntities();

  const termsPricing = useMemo(
    () =>
      denormalize(Object.keys(entities.termsPricing), [termPricing], entities),
    [entities.termsPricing],
  );

  return termsPricing.map((tp: TermPricing) => new TermPricing(tp));
}

import moment from 'moment-timezone';

import { APARTMENT_KEY_STATUS } from '@enum';

import { useKeys } from '@hooks/useKeys/useKeys';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

export enum ProspectUnSetupStep {
  hostReturn,
  keyUnSetup,
  inventory,
  finalStep,
}

export const useUnSetupStep = () => {
  const leaving = useLeaving();
  const { guestKey, cleaningKey, agencyKey } = useKeys();
  const isHostReturnStepCompleted =
    leaving.hostHasKey !== null && !!leaving.hostReturnDate;

  if (
    !isHostReturnStepCompleted &&
    moment().isBetween(
      moment(leaving.endDate).subtract(5, 'days').startOf('day'),
      moment(leaving.endDate).endOf('day'),
      undefined,
      '[]',
    )
  ) {
    return ProspectUnSetupStep.hostReturn;
  }

  if (
    [guestKey, cleaningKey, agencyKey].some(
      (key) => key?.status === APARTMENT_KEY_STATUS.STORED,
    )
  ) {
    return ProspectUnSetupStep.keyUnSetup;
  }

  return ProspectUnSetupStep.inventory;
};

import Apartment from '@models/Apartment';
import { RoomCreation } from '@models/Room';

import { fetchApi } from './fetchApi';

export async function postRooms(
  token: string,
  apartmentId: number,
  rooms: RoomCreation[],
) {
  return fetchApi<Apartment>(
    'POST',
    `/apartments/${apartmentId}/rooms`,
    token,
    {
      body: rooms,
    },
  );
}

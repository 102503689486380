import { INVENTORY_STATUS, INVENTORY_TYPE } from '@enum';

import { useLeaving } from '@hooks/useLeaving/useLeaving';

export enum InventoryStep {
  in,
  inReview,
  inReviewScheduled,
  inReviewToSign,
  waitingOut,
  outToSign,
  outDone,
  outValidationToSchedule,
  outValidationScheduled,
  outValidationToSign,
}

export const useInventoryStep = () => {
  const leaving = useLeaving();

  const inventoryInReview = leaving.findInventoryByType(
    INVENTORY_TYPE.IN_REVIEW,
  );

  const inventoryOut = leaving.findInventoryByType(INVENTORY_TYPE.OUT);

  const inventoryOutReview = leaving.findInventoryByType(
    INVENTORY_TYPE.OUT_REVIEW,
  );

  const inventoryOutValidation = leaving.findInventoryByType(
    INVENTORY_TYPE.OUT_VALIDATION,
  );

  if (inventoryOutValidation?.isDone()) {
    return InventoryStep.outDone;
  }

  if (inventoryOutValidation?.status === INVENTORY_STATUS.TO_SIGN) {
    return InventoryStep.outValidationToSign;
  }

  if (
    inventoryOutReview?.isDone() &&
    leaving.expectedInventoryOutValidationDate
  ) {
    return InventoryStep.outValidationScheduled;
  }

  if (inventoryOutReview?.isDone()) {
    return InventoryStep.outValidationToSchedule;
  }

  if (inventoryOut?.status === INVENTORY_STATUS.TO_SIGN) {
    return InventoryStep.outToSign;
  }

  if (inventoryOut?.isDone()) {
    return InventoryStep.outDone;
  }

  if (inventoryInReview?.isDone() || leaving.isCheckinReady) {
    return InventoryStep.waitingOut;
  }

  if (leaving.expectedInventoryInReviewDate && !inventoryInReview) {
    return InventoryStep.inReviewScheduled;
  }

  if (inventoryInReview?.status === INVENTORY_STATUS.TO_SIGN) {
    return InventoryStep.inReviewToSign;
  }

  return InventoryStep.inReview;
};

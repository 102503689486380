import Leaving from '@models/Leaving';

import { fetchApi } from './fetchApi';

export async function patchLeaving(
  token: string,
  id: number,
  data: Partial<Leaving>,
) {
  return fetchApi<Leaving>('PATCH', `/leavings/${id}`, token, {
    body: data,
  });
}

export async function postLeavingInventory(
  token: string,
  id: number,
  type: string,
) {
  return fetchApi<any>('POST', `/leavings/${id}/inventories`, token, {
    body: { type },
  });
}

export async function generateAuthorizationTenantDocument(
  token: string,
  id: number,
) {
  return fetchApi<any>(
    'GET',
    `/leavings/generate-authorization-document/${id}`,
    token,
  );
}

export async function generateMandateForLeaving(token: string, id: number) {
  return fetchApi<any>('POST', `/leavings/${id}/mandate`, token, {
    body: { manualMode: false },
  });
}

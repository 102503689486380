import React, { SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SForm, SFormChangeProps } from '@smartrenting/smartomic';

import { useAppContext } from '@contexts/AppContext/AppContext';
import { useReferences } from '@contexts/EntitiesContext/EntitiesContext';

import { useApartment } from '@hooks/useApartment/useApartment';

import { useGamification } from '../../contexts/GamificationContext';
import GamificationInput from '../GamificationInput/GamificationInput';

import './CapacityStep.scss';

export default function CapacityStep() {
  const { apartmentCategories = [] } = useReferences();
  const { t } = useTranslation();
  const { updateApartment } = useAppContext();

  const { enable, disable, disabled, handleNext } = useGamification();

  const { id, category, capacity, area, floor, door } = useApartment();

  useEffect(() => {
    if (category && capacity && area && floor && door) {
      enable();
      handleNext(() => Promise.resolve());
    }
  }, []);

  const handleChange = (
    event: SyntheticEvent<EventTarget, Event>,
    formProps: SFormChangeProps,
  ) => {
    const { touched = [], values } = formProps;

    const checkingValues = { ...values };
    delete checkingValues['door'];

    if (
      Object.values(checkingValues).every((value) => !!value) &&
      values.capacity > 0
    ) {
      enable();
    } else if (!disabled) {
      disable();
    }

    const modifications = touched.reduce((acc: Record<string, any>, name) => {
      if (name === 'area') {
        values[name] = parseInt(values[name]);
      }
      acc[name] = values[name];

      return acc;
    }, {});

    if (Object.values(modifications).length === 0) return;

    handleNext(() => updateApartment(id, modifications) as any);
  };

  return (
    <div className="CapacityStep">
      <SForm
        onChange={handleChange}
        initialValues={{
          category: category || '',
          capacity: capacity || 0,
          area: area || '',
          floor: floor || '',
          door: door || '',
        }}
      >
        <GamificationInput
          name="category"
          label={t('apartment.form.category')}
          type="select"
          options={apartmentCategories.map((el: string) => ({
            label: t(`apartment.categories.${el}`),
            value: el,
          }))}
          data-testid="gamification-select-category"
        />
        <GamificationInput
          name="capacity"
          label={t('apartment.form.capacity')}
          type="SInputNumber"
        />
        <GamificationInput
          name="area"
          label={t('apartment.form.area')}
          type="number"
          data-testid="gamification-input-area"
        />
        <GamificationInput
          name="floor"
          label={t('apartment.form.floor')}
          type="number"
          data-testid="gamification-input-floor"
        />
        <GamificationInput
          name="door"
          label={t('apartment.form.door')}
          type="text"
          data-testid="gamification-input-door"
        />
      </SForm>
    </div>
  );
}

import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AAvatar,
  ASeparator,
  SButton,
  SDateTimePicker,
  SEditRow,
  SForm,
  SFormSubmitProps,
  SInput,
  SSelect,
  SSmartLoader,
} from '@smartrenting/smartomic';

import { GENDER } from '@enum';

import { toastPromise } from '@utils/tools';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useContact } from '@hooks/useContact/useContact';
import { useMobile } from '@hooks/useMobile/useMobile';
import { usePicture } from '@hooks/usePicture/usePicture';

import userSVG from '../assets/user.svg';

import './User.scss';

export const UserForm = {
  contactInfo: ['birthDate', 'birthTown', 'address'],
  userInfo: ['gender', 'lastname', 'firstname', 'email', 'phone'],
};

export default function User() {
  const [loading, setLoading] = useState(false);
  const contact = useContact();
  const isMobile = useMobile();
  const { updateProfilePicture, updateContact } = useAppContext();
  const { t } = useTranslation();

  const picture = usePicture(contact.user.profilePictureId);

  const handleEditProfilePicture = async (file: File) => {
    const formData = new FormData();

    formData.append('file', file);

    setLoading(true);

    await updateProfilePicture(formData);

    setLoading(false);
  };

  const handleSubmit = async (
    event: FormEvent,
    formProps: SFormSubmitProps,
  ) => {
    const { touched = [], values, reset } = formProps;

    event.preventDefault();
    event.stopPropagation();

    const modifications = touched.reduce(
      (acc: Record<string, any>, name: any) => {
        if (UserForm.userInfo.includes(name)) {
          if (!acc.user) acc.user = {};
          acc.user[name] = values[name];

          return acc;
        } else {
          acc[name] = values[name];
        }

        return acc;
      },
      {},
    );

    if (Object.values(modifications).length === 0) return;

    return toastPromise(updateContact(contact.id, modifications)).then(reset);
  };

  const gendersOptions = Object.values(GENDER).map((gender: string) => {
    return { value: gender, label: t(`settings.user.genders.${gender}`) };
  });

  return (
    <div className="User">
      <SForm
        onSubmit={handleSubmit}
        initialValues={{
          gender: contact.user.gender || '',
          lastname: contact.user.lastname || '',
          firstname: contact.user.firstname || '',
          birthDate: contact.birthDate || '',
          birthTown: contact.birthTown || '',
          email: contact.user.email || '',
          phone: contact.user.phone || '',
          address: contact.address || '',
        }}
      >
        <div className="User__container User__flex-end">
          {isMobile && (
            <div className="User__profile-picture">
              {loading && <SSmartLoader />}
              {!loading && (
                <AAvatar
                  picture={picture}
                  upload
                  initials={contact?.initials}
                  onUpload={handleEditProfilePicture}
                />
              )}
            </div>
          )}
          <SEditRow label={t('settings.user.gender')} name="gender" required>
            <SSelect options={gendersOptions} />
          </SEditRow>
          <SEditRow
            label={t('settings.user.lastname')}
            name="lastname"
            required
          >
            <SInput placeholder={t('settings.user.lastname')} />
          </SEditRow>
          <SEditRow
            label={t('settings.user.firstname')}
            name="firstname"
            required
          >
            <SInput placeholder={t('settings.user.firstname')} />
          </SEditRow>
          <SEditRow label={t('settings.user.birthDate')} name="birthDate">
            <SDateTimePicker />
          </SEditRow>
        </div>
        <div className="User__container User__flex-end">
          {!isMobile && (
            <div className="User__profile-picture">
              {loading && <SSmartLoader />}
              {!loading && (
                <AAvatar
                  picture={picture}
                  upload
                  initials={contact?.initials}
                  onUpload={handleEditProfilePicture}
                />
              )}
            </div>
          )}
          <SEditRow label={t('settings.user.birthTown')} name="birthTown">
            <SInput placeholder={t('settings.user.birthTown')} />
          </SEditRow>
        </div>
        <ASeparator />
        <div className="User__container">
          <SEditRow label={t('settings.user.email')} name="email">
            <SInput disabled />
          </SEditRow>
        </div>
        <div className="User__container">
          <SEditRow label={t('settings.user.phone')} name="phone">
            <SInput placeholder={t('settings.user.phone')} disabled />
          </SEditRow>
        </div>
        <div className="User__row">
          <SEditRow label={t('settings.user.address')} name="address">
            <SInput placeholder={t('settings.user.address')} />
          </SEditRow>
          <SButton
            variant="secondary"
            type="submit"
            label={t('settings.submit')}
          />
        </div>
      </SForm>
      <div className="User__image">
        <img src={userSVG} alt="" />
      </div>
    </div>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import { faLightbulb } from '@fortawesome/pro-solid-svg-icons';
import { SText, STitle } from '@smartrenting/smartomic';

import { ApartmentKey } from '@models/ApartmentKey';
import KeySetup from '@models/KeySetup';

import KeyCafeHelp from './components/KeyCafeHelp';
import KeyNestHelp from './components/KeyNestHelp';

import './KeysHelp.scss';

interface KeysHelpProps {
  processType: 'setup' | 'unsetup';
  keysInfos: ApartmentKey | KeySetup;
  sameTypeDifferentLocation?: boolean;
  differentType?: boolean;
}

export default function KeysHelp({
  processType,
  keysInfos,
  sameTypeDifferentLocation = false,
  differentType = false,
}: KeysHelpProps) {
  const { t } = useTranslation();

  return (
    <div className="KeysHelp">
      <STitle
        title={t(`setup.ready.help.title.${processType}`)}
        icon={faLightbulb}
        variant="body"
      />
      <div className="KeysHelp__content">
        {processType === 'setup' &&
          (differentType ? (
            <SText variant="body-subtitle">
              {t(`setup.ready.help.content.top.same`)}
            </SText>
          ) : (
            <SText variant="body-subtitle">
              {t(`setup.ready.help.content.top.different`)}
            </SText>
          ))}
        {processType === 'unsetup' && differentType && (
          <SText variant="body-subtitle">{t(`unsetup.help.content.top`)}</SText>
        )}
        <div className="KeysHelp__content__keys">
          {differentType && (
            <>
              <SText
                className="KeysHelp__content__keys__title"
                variant="body-title"
              >
                {t(`common.keycafe`)}
              </SText>
              <KeyCafeHelp
                processType={processType}
                keyInfos={keysInfos}
                sameTypeDifferentLocation={true}
              />
              <SText
                className="KeysHelp__content__keys__title"
                variant="body-title"
              >
                {t(`common.keynest`)}
              </SText>
              <KeyNestHelp
                processType={processType}
                keyInfos={keysInfos}
                sameTypeDifferentLocation={true}
              />
            </>
          )}
          {!differentType &&
            (keysInfos.type === 'keycafe' ? (
              <KeyCafeHelp
                processType={processType}
                keyInfos={keysInfos}
                sameTypeDifferentLocation={sameTypeDifferentLocation}
              />
            ) : (
              <KeyNestHelp
                processType={processType}
                keyInfos={keysInfos}
                sameTypeDifferentLocation={sameTypeDifferentLocation}
              />
            ))}
        </div>
        <SText variant="body-subtitle">
          {t(`setup.ready.help.content.bottom`)}
        </SText>
      </div>
    </div>
  );
}

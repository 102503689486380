import React from 'react';

import { RoomType } from '@models/Room';

import RoomCard from '../RoomCard/RoomCard';

import { RoomUpdate, selectRoomByType } from './RoomsHelper';

import './Rooms.scss';

export type RoomsProps = {
  rooms: RoomUpdate[];
  edit: boolean;
  onChange: (rooms: RoomUpdate[]) => void;
};

const Rooms: React.FC<RoomsProps> = ({ rooms, edit, onChange }) => {
  const bedRooms = selectRoomByType(rooms, 'bedroom');
  const livingRooms = selectRoomByType(rooms, 'livingroom');
  const bathRooms = selectRoomByType(rooms, 'bathroom');

  const handleChange = (updatedRooms: RoomUpdate[], type: RoomType) => {
    onChange([
      ...(type === 'bedroom' ? updatedRooms : bedRooms),
      ...(type === 'livingroom' ? updatedRooms : livingRooms),
      ...(type === 'bathroom' ? updatedRooms : bathRooms),
    ]);
  };

  return (
    <div className="room-container">
      <RoomCard
        type="bedroom"
        rooms={bedRooms}
        edit={edit}
        onChange={handleChange}
      />
      <RoomCard
        type="livingroom"
        rooms={livingRooms}
        edit={edit}
        onChange={handleChange}
      />
      <RoomCard
        type="bathroom"
        rooms={bathRooms}
        edit={edit}
        onChange={handleChange}
      />
    </div>
  );
};

export default Rooms;

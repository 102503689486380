import { APARTMENT_KEY_DENOMINATION } from '@enum';

import { ApartmentKey } from '@models/ApartmentKey';

import { useApartment } from '@hooks/useApartment/useApartment';

export function useKeys() {
  const apartment = useApartment();

  if (!apartment) {
    return {
      guestKey: null,
      cleaningKey: null,
      agencyKey: null,
    };
  }

  const keys = apartment.keys;

  const guestKey = keys.find(
    (Key: Partial<ApartmentKey>) =>
      Key?.denomination === APARTMENT_KEY_DENOMINATION.GUEST,
  );

  const cleaningKey = keys.find(
    (Key: Partial<ApartmentKey>) =>
      Key?.denomination === APARTMENT_KEY_DENOMINATION.CLEANING,
  );

  const agencyKey = keys.find(
    (Key: Partial<ApartmentKey>) =>
      Key?.denomination === APARTMENT_KEY_DENOMINATION.AGENCY,
  );

  return {
    guestKey: guestKey,
    cleaningKey: cleaningKey,
    agencyKey: agencyKey,
  };
}

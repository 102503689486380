import { Box, Grid, Typography } from '@mui/material';

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faMoneyBillTransfer,
  faFaceTongueMoney,
} from '@fortawesome/pro-duotone-svg-icons';

import { TERM_PRICING_TYPE } from '@enum';

import Modality from '@models/Modality';

import StepChoice from '../ModalityChoice';
import { useUniqueTermPricingByType } from '../hooks/useTermPricing';

interface StepPaymentProps {
  onChoiceChange: (data: Record<string, any>) => void;
  modality: Modality | null;
}

export default function StepPayment({
  onChoiceChange,
  modality,
}: StepPaymentProps) {
  const { t } = useTranslation();
  const termPricing = useUniqueTermPricingByType(
    TERM_PRICING_TYPE.EARLY_PAYMENT,
  );
  const [currentChoice, setCurrentChoice] = useState<boolean>(
    !!modality?.earlyPayment,
  );

  const handleClick = useCallback(
    (choice: boolean) => () => {
      setCurrentChoice(choice);
      onChoiceChange({ earlyPayment: choice });
    },
    [],
  );

  return (
    <Box>
      <Typography
        variant="h6"
        align="center"
        fontWeight={500}
        sx={{ mb: { xs: '1rem', sm: '2rem' } }}
      >
        {t('boardOffice.steps.payment.title')}
      </Typography>
      <Grid sx={{ flexGrow: 1 }} container>
        <Grid container justifyContent="center" spacing={{ xs: 2, sm: 4 }}>
          <Grid key="payment-classic" item onClick={handleClick(false)}>
            <StepChoice
              title={t('boardOffice.steps.payment.classic.title')}
              subtitle={t('boardOffice.steps.payment.classic.subtitle')}
              header={t('boardOffice.steps.payment.classic.header')}
              icon={faFaceTongueMoney as IconProp}
              active={!currentChoice}
            />
          </Grid>
          <Grid key="payment-express" item onClick={handleClick(true)}>
            <StepChoice
              title={t('boardOffice.steps.payment.express.title')}
              subtitle={t('boardOffice.steps.payment.express.subtitle', {
                percent: termPricing?.value ? termPricing?.value * 100 : 0,
              })}
              header={t('boardOffice.steps.payment.express.header')}
              icon={faMoneyBillTransfer as IconProp}
              active={currentChoice}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

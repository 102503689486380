import { Alert, AlertTitle, Box } from '@mui/material';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SCard, STitle } from '@smartrenting/smartomic';

import { OWNERSHIP, RESIDENCE_TYPE } from '@enum';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useContact } from '@hooks/useContact/useContact';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import Documents from '@components/Documents/Documents';
import documentSvg from '@components/Documents/assets/document.svg';

import AuthorizationTenant from '../../AuthorizationTenant/AuthorizationTenant';
import Step from '../components/Step/Step';

import documentsSvg from './documents.svg';
import documentsCompleteSvg from './documentsComplete.svg';

import './UploadDocuments.scss';

export default function UploadDocuments() {
  const { t } = useTranslation();
  const contact = useContact();
  const apartment = useApartment();
  const leaving = useLeaving();

  const isComplete = useMemo(() => {
    return contact.rib &&
      contact.identityDocuments.length &&
      apartment.proofDocument &&
      (apartment.ownership === OWNERSHIP.OWNER &&
      apartment.residenceType === RESIDENCE_TYPE.PRIMARY
        ? leaving.departureDocument
        : true) &&
      (apartment.ownership === OWNERSHIP.OWNER &&
      apartment.residenceType === RESIDENCE_TYPE.SECONDARY
        ? apartment.usageDocument
        : true)
      ? true
      : false;
  }, [apartment, contact, leaving]);

  return (
    <Step img={isComplete ? documentsCompleteSvg : documentsSvg}>
      {isComplete && (
        <Alert
          severity="success"
          icon={
            <Box
              component={FontAwesomeIcon}
              icon={faCircleCheck as any}
              size="1x"
              my="auto"
            />
          }
          sx={{ mb: 2, width: '100%' }}
        >
          <AlertTitle color="success.main">
            {t('dashboard.documents.complete.title')}
          </AlertTitle>
          {t('dashboard.documents.complete.subtitle')}
        </Alert>
      )}
      <SCard className="UploadDocuments__card">
        <STitle
          variant="body"
          icon={(<img src={documentSvg} />) as unknown as string}
          title={t('documents.folders')}
        />
        <Documents />
      </SCard>
      {apartment.ownership === OWNERSHIP.TENANT &&
        !leaving.authorizationDocument && (
          <AuthorizationTenant callScheduled withFooter />
        )}
    </Step>
  );
}

import React, { MouseEventHandler } from 'react';

import moment from 'moment-timezone';

import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { AIcon, AText } from '@smartrenting/smartomic';

import DocumentModel from '@models/Document';

import { useDownload } from '@hooks/useDownload/useDownload';

import fileCheckSvg from '../../assets/fileCheck.svg';

import './Document.scss';

export default function Document({
  document,
  onDelete,
}: {
  document: DocumentModel;
  onDelete: MouseEventHandler;
}) {
  const handleDownload = useDownload(document.id, document.originalName);

  return (
    <div className="Document" onClick={handleDownload}>
      <div>
        <img src={fileCheckSvg} />
      </div>
      <div>
        <AText>{document.originalName}</AText>
      </div>
      <div>
        <AText>{moment(document.createdAt).format('L')}</AText>
      </div>
      <div>
        <AText>
          {document.originalName.slice(
            document.originalName.lastIndexOf('.') + 1,
          )}
        </AText>
      </div>
      <div>
        <AIcon onClick={onDelete} icon={faTrashCan} height={18} />
      </div>
    </div>
  );
}

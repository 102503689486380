import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AText, SButton } from '@smartrenting/smartomic';

import { RoomCreation } from '@models/Room';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';

import Rooms from './components/Rooms/Rooms';
import {
  roomsToUpdatedRooms,
  RoomUpdate,
} from './components/Rooms/RoomsHelper';

import './LodgingInfo.scss';

const LodgingInfo: React.FC<{
  setEdit?: (edit: boolean) => void;
  edit: boolean;
}> = ({ setEdit, edit }) => {
  const { t } = useTranslation();
  const { capacity, rooms, id } = useApartment();
  const { createRooms } = useAppContext();

  const [stateRooms, setStateRooms] = useState([...roomsToUpdatedRooms(rooms)]);
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRoomEmpty, setIsRoomEmpty] = useState(false);

  const onChange = (rooms: RoomUpdate[]) => {
    setIsRoomEmpty(rooms.some((room) => room.items.length === 0));
    setStateRooms([...rooms]);
    setUpdated(true);
  };

  const handleSubmit = async (rooms: Omit<RoomCreation, 'apartmentId'>[]) => {
    setLoading(true);
    await createRooms(
      id,
      rooms.map((room) => ({
        ...room,
        apartmentId: id,
      })),
    );
    setLoading(false);
    if (setEdit) setEdit(false);
  };

  useEffect(() => {
    setStateRooms([...roomsToUpdatedRooms(rooms)]);
    setUpdated(false);
  }, [edit]);

  return (
    <>
      <div className="half-tag">
        <AText>
          {t('apartment.lodging.capacity', {
            capacity,
            count: Number(capacity),
          })}
        </AText>
      </div>
      <Rooms rooms={stateRooms} edit={edit} onChange={onChange} />
      {edit && (
        <SButton
          className="Lodging validate-form-button"
          disabled={loading || !updated || isRoomEmpty}
          variant="secondary"
          label={t('settings.submit')}
          onClick={handleSubmit.bind(null, stateRooms)}
        />
      )}
    </>
  );
};

export default LodgingInfo;

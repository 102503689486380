import React from 'react';
import { useTranslation } from 'react-i18next';

import { faShoePrints } from '@fortawesome/pro-solid-svg-icons';
import { AIcon } from '@smartrenting/smartomic';

import { ACCESS_TYPE, LANGUAGES } from '@enum';

import { useApartment } from '@hooks/useApartment/useApartment';

import './ApartmentAccess.scss';

export default function ApartmentAccess() {
  const apartment = useApartment();
  const { t } = useTranslation();

  const access = apartment.accesses.find(
    (access) => access.type === ACCESS_TYPE.FOOT,
  );

  if (!access) {
    return <>{t('apartment.accesses.noAccess')}</>;
  }

  return (
    <div className="ApartmentAccess">
      <div className="ApartmentAccess__header">
        <AIcon icon={faShoePrints} height={16} />
        <div>{t(`apartment.accesses.${access.type}`)}</div>
      </div>
      <div className="ApartmentAccess__content">
        {
          access.translation.translations.find(
            (tr) => tr.language === LANGUAGES.FR,
          )?.text
        }
      </div>
    </div>
  );
}

export const routes = {
  root: '/',
  login: '/auth/login',
  forgotten: '/auth/forgotten',
  reset: '/auth/new-password',
  register: '/auth/register',
  dashboard: '/dashboard',
  leavings: '/leavings',
  apartments: '/apartments',
  documents: '/documents',
  messages: '/messages',
  settings: '/settings',
  user: '/settings/user',
  rib: '/settings/rib',
  security: '/settings/security',
  help: '/help',
  gamification: '/start',
  estimation: '/estimation',
  inventoryRedirect: '/inventory/redirect/:type',
  waitingMandate: '/waiting-mandate',
  waintingInventory: '/waiting-inventory',
  referral: '/referral',
  payment: '/payments',
};

import { Alert } from '@mui/material';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Moment } from 'moment-timezone';

import {
  ASeparator,
  SButton,
  SCard,
  SModal,
  SText,
  STitle,
} from '@smartrenting/smartomic';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';

import { DateRangePicker } from '@components/DateRangePicker/DateRangePicker';

import doneLeavingSvg from '../../assets/doneLeaving.svg';

import './DoneLeaving.scss';

export default function DoneLeaving() {
  const { t } = useTranslation();
  const apartment = useApartment();
  const { sendRenewalEmail } = useAppContext();

  const [renewalModal, setRenewalModal] = useState(false);
  const [startDate, setStartDate] = useState<Moment>();
  const [endDate, setEndDate] = useState<Moment>();

  const handleSubmit = async () => {
    if (!startDate || !endDate) {
      return toast.error(t('dashboard.leavings.renewal.error'));
    }

    return sendRenewalEmail(
      startDate.startOf('days'),
      endDate.endOf('days'),
      apartment.id,
    )
      .then(() => {
        setStartDate(undefined);
        setEndDate(undefined);
        setRenewalModal(false);
        toast.success(t('dashboard.leavings.renewal.success'));
      })
      .catch((error) => {
        console.error(error);
        toast.error(t('dashboard.leavings.renewal.error'));
      });
  };

  return (
    <div className="DoneLeaving">
      <ASeparator className="DoneLeaving__ASeparator" />
      <div className="DoneLeaving__content">
        <SCard>
          <SText align="center" variant="subtitle">
            <strong>{t('dashboard.leavings.doneTravel')}</strong>
          </SText>
          <SText align="center" variant="body-subtitle">
            {t('dashboard.leavings.nextTravel')}
          </SText>
          <SButton onClick={setRenewalModal.bind(null, true)}>
            {t('dashboard.leavings.renewal.title')}
          </SButton>
          <img src={doneLeavingSvg} alt="ongoing-svg" />
        </SCard>
      </div>
      <SModal
        isOpen={renewalModal}
        onClose={setRenewalModal.bind(null, false)}
        className="RenewalModal"
      >
        <STitle
          variant="body"
          title={t('dashboard.leavings.renewal.title')}
          subtitle={t('dashboard.leavings.renewal.subtitle')}
        />
        <ASeparator />
        <Alert icon={null} severity="info" sx={{ width: '100%' }}>
          {t('dashboard.leavings.renewal.alert')}
        </Alert>
        <SText>{t('dashboard.leavings.renewal.date-picker.title')}</SText>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <SButton
          className="RenewalModal__submit"
          onClick={handleSubmit}
          disabled={!startDate || !endDate}
        >
          {t('dashboard.leavings.renewal.submit')}
        </SButton>
      </SModal>
    </div>
  );
}

import { TableRow, TableCell, Box } from '@mui/material';

import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HostRent } from '@models/HostRent';

import { TabCellDownloader } from './FileDownloader';

export const BillTableRow = ({ hostRent }: { hostRent: HostRent }) => {
  return (
    <TableRow
      key={hostRent.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      hover
    >
      <TableCell width="50%" scope="row">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            marginRight={1}
            component={FontAwesomeIcon}
            height={20}
            icon={faFilePdf as IconProp}
            color="primary.main"
          />
          {hostRent.bill?.originalName}
        </Box>
      </TableCell>
      <TableCell width="30%" align="center">
        {hostRent.dueDate.format('MMMM YYYY')}
      </TableCell>
      <TabCellDownloader
        documentId={hostRent.bill?.id}
        name={hostRent.bill?.originalName}
      />
    </TableRow>
  );
};

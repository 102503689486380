import React, { useMemo } from 'react';

import { KEY_SETUP_STATUS } from '@enum';

import { useKeySetups } from '@hooks/useKeySetups/useKeySetups';

import KeysMultiLocation from '../component/KeysMultiLocation/KeysMultiLocation';
import KeysNotReady from '../component/KeysNotReady/KeysNotReady';
import KeysWithSameLocation from '../component/KeysWithSameLocation/KeysWithSameLocation';

import './KeySetups.scss';

export default function KeySetups() {
  const { guestKeySetup, cleaningKeySetup, agencyKeySetup } = useKeySetups();

  const noKeys: boolean = useMemo(() => {
    return [guestKeySetup, cleaningKeySetup, agencyKeySetup].every(
      (keysetup) => keysetup?.status === KEY_SETUP_STATUS.INIT,
    );
  }, [guestKeySetup, cleaningKeySetup, agencyKeySetup]);

  const keySetupWithSameType: boolean = useMemo(() => {
    const keySetupWithDefinedType = [
      guestKeySetup,
      cleaningKeySetup,
      agencyKeySetup,
    ].filter((e) => !!e?.type);

    return keySetupWithDefinedType.every(
      (key) =>
        key?.type === keySetupWithDefinedType[0]?.type &&
        key?.locationName === keySetupWithDefinedType[0]?.locationName,
    );
  }, [guestKeySetup, cleaningKeySetup, agencyKeySetup]);

  if (noKeys) {
    return <KeysNotReady processType="setup" />;
  }

  if (keySetupWithSameType) {
    return (
      <KeysWithSameLocation
        guestKey={guestKeySetup}
        agencyKey={agencyKeySetup}
        cleaningKey={cleaningKeySetup}
        processType="setup"
      />
    );
  }

  return (
    <KeysMultiLocation
      processType="setup"
      guestKey={guestKeySetup}
      cleaningKey={cleaningKeySetup}
      agencyKey={agencyKeySetup}
    />
  );
}

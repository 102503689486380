import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Since react-i18next doesn't handle tags in translation, we override the default
 * useTranslation hooks in order to set the content as HTML
 * @returns {useTranslation} a similar object to the react-i18next hooks
 */
export function useTranslationFormat() {
  const translation = useTranslation();

  return {
    ...translation,
    t: function t(...params: Parameters<typeof translation.t>) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: translation.t(...params),
          }}
        />
      );
    },
  };
}

import { Typography } from '@mui/material';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  faPhone,
  faTag,
  faKey,
  faCheckCircle,
  faLock,
} from '@fortawesome/pro-solid-svg-icons';
import { AIcon, SText } from '@smartrenting/smartomic';

import { INVENTORY_STATUS, INVENTORY_TYPE } from '@enum';

import { classNames } from '@utils/helpers';

import { useLeaving } from '@hooks/useLeaving/useLeaving';

import {
  InventoryStep,
  useInventoryStep,
} from '@scopes/Dashboard/components/Steps/Inventory/useInventoryStep';

import { ProspectUnSetupStep } from './useUnSetupStep';

import './UnSetupStepContainer.scss';

const stepIcon = {
  [ProspectUnSetupStep.hostReturn]: faPhone,
  [ProspectUnSetupStep.inventory]: faTag,
  [ProspectUnSetupStep.keyUnSetup]: faKey,
};

export default function UnSetupStepContainer({
  steps,
  initialStep,
  selectedStep,
  onChange,
  children,
}: {
  steps: Exclude<ProspectUnSetupStep, ProspectUnSetupStep.finalStep>[];
  initialStep: ProspectUnSetupStep;
  selectedStep: ProspectUnSetupStep;
  onChange: (step: ProspectUnSetupStep) => void;
  children: React.ReactNode;
}) {
  const { t } = useTranslation();
  const inventoryStep: InventoryStep = useInventoryStep();
  const leaving = useLeaving();

  const inventoryOut = leaving.findInventoryByType(INVENTORY_TYPE.OUT);
  const inventoryOutValidation = leaving.findInventoryByType(
    INVENTORY_TYPE.OUT_VALIDATION,
  );

  const getTitle = useCallback(
    () =>
      selectedStep === ProspectUnSetupStep.inventory
        ? t(
            `dashboard.${ProspectUnSetupStep[selectedStep]}.${InventoryStep[inventoryStep]}.title`,
          )
        : t(
            `dashboard.leavings.step.${ProspectUnSetupStep[selectedStep]}.title`,
          ),
    [selectedStep, inventoryStep],
  );

  const getSubtitle = useCallback(
    () =>
      selectedStep !== ProspectUnSetupStep.inventory ? (
        t(
          `dashboard.leavings.step.${ProspectUnSetupStep[selectedStep]}.subtitle`,
        )
      ) : (
        <>
          <span>
            {t(
              `dashboard.${ProspectUnSetupStep[selectedStep]}.${InventoryStep[inventoryStep]}.subtitle`,
            )}
          </span>
          <span className="UnSetupStepContainer__content__text__subtitle__date">
            {inventoryStep === InventoryStep.outValidationScheduled &&
              leaving.expectedInventoryOutValidationDate?.format('L à LT')}
          </span>
        </>
      ),
    [selectedStep, inventoryStep, leaving.expectedInventoryOutValidationDate],
  );

  return (
    <div className="UnSetupStepContainer">
      <div className="UnSetupStepContainer__steps">
        {steps.map((step) => {
          let isDone = step < initialStep;
          const isSelected = selectedStep === step;
          let isSelectable = false;
          let isLocked = step > initialStep;
          let isActive = initialStep === step;
          let icon = stepIcon[step];

          if (
            initialStep >= ProspectUnSetupStep.keyUnSetup &&
            inventoryOut?.status === INVENTORY_STATUS.TO_SIGN &&
            inventoryOutValidation?.status !== INVENTORY_STATUS.DONE
          ) {
            isLocked = !(step >= ProspectUnSetupStep.keyUnSetup);
            isActive = !(step >= ProspectUnSetupStep.keyUnSetup);
            isSelectable = step >= ProspectUnSetupStep.keyUnSetup;
          }

          if (
            step >= ProspectUnSetupStep.inventory &&
            (inventoryOutValidation?.status === INVENTORY_STATUS.DONE ||
              inventoryOut?.status === INVENTORY_STATUS.DONE)
          ) {
            isDone = true;
            isLocked = false;
            isSelectable = false;
          }

          if (isDone) icon = faCheckCircle;
          if (isLocked) icon = faLock;

          return (
            <div
              className={classNames(
                'UnSetupStepContainer__steps__step',
                isDone && 'UnSetupStepContainer__steps__step--done',
                isLocked && 'UnSetupStepContainer__steps__step--locked',
                isActive && 'UnSetupStepContainer__steps__step--active',
                isSelected && 'UnSetupStepContainer__steps__step--selected',
                isSelectable && 'UnSetupStepContainer__steps__step--selectable',
              )}
              onClick={() => onChange(step)}
              key={ProspectUnSetupStep[step]}
            >
              <AIcon icon={icon} />
              <SText className="UnSetupStepContainer__steps__step__text">
                {t(`dashboard.steps.${ProspectUnSetupStep[step]}`)}
              </SText>
            </div>
          );
        })}
      </div>
      <div className="UnSetupStepContainer__content">
        <div className="UnSetupStepContainer__content__text">
          <Typography variant="h6">{getTitle()}</Typography>
          <Typography textAlign="justify" variant="subtitle2">
            {getSubtitle()}
          </Typography>
        </div>
        <div className="UnSetupStepContainer__content__step">{children}</div>
      </div>
    </div>
  );
}

import { Alert, AlertTitle, Box } from '@mui/material';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ALink, SCard, SText, STitle } from '@smartrenting/smartomic';

import {
  APARTMENT_KEY_STATUS,
  APARTMENT_KEY_TYPE,
  KEY_SETUP_STATUS,
} from '@enum';

import { Tools } from '@utils/tools';

import { ApartmentKey } from '@models/ApartmentKey';
import KeySetup from '@models/KeySetup';

import Map from '@components/Map/Map';

import KeyInfoCard from '../KeyInfoCard/KeyInfoCard';
import KeysHelp from '../KeysHelp/KeysHelp';

import './KeysWithSameLocation.scss';

interface KeysWithSameLocationSetup {
  guestKey?: KeySetup | null | undefined;
  cleaningKey?: KeySetup | null | undefined;
  agencyKey?: KeySetup | null | undefined;
  processType: 'setup';
}

interface KeysWithSameLocationUnsetup {
  guestKey?: ApartmentKey | null | undefined;
  cleaningKey?: ApartmentKey | null | undefined;
  agencyKey?: ApartmentKey | null | undefined;
  processType: 'setup' | 'unsetup';
}

export type KeysWithSameLocationProps =
  | KeysWithSameLocationSetup
  | KeysWithSameLocationUnsetup;

export default function KeysWithSameLocation({
  guestKey,
  cleaningKey,
  agencyKey,
  processType,
}: KeysWithSameLocationProps) {
  const { t } = useTranslation();

  const defaultKey = useMemo(() => {
    const keySetupWithDefinedType = [guestKey, cleaningKey, agencyKey].filter(
      (e) => !!e?.type,
    );

    return keySetupWithDefinedType[0];
  }, []);

  const keysToDisplay = useMemo(() => {
    // In case of setup, we would key setup with ready or deposited status
    if (processType === 'setup') {
      return [guestKey, cleaningKey, agencyKey].filter(
        (e) => !!e?.type && e.status !== KEY_SETUP_STATUS.INIT,
      );
    }

    // In case of unsetup, we would stored apartment key
    return [guestKey, cleaningKey, agencyKey].filter(
      (e) => !!e?.type && e.status === APARTMENT_KEY_STATUS.STORED,
    );
  }, []);

  const handleOpenMaps = () => {
    const locationAddress = defaultKey?.locationAddress;

    if (locationAddress) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          locationAddress,
        )}`,
        '_blank',
      );
    }
  };

  if (!defaultKey) {
    return null;
  }

  return (
    <div className="KeysWithSameLocation">
      <div className="KeysWithSameLocation__alert">
        <Alert
          severity="warning"
          icon={
            <Box
              component={FontAwesomeIcon}
              icon={faExclamationCircle as any}
              size="1x"
              my="auto"
            />
          }
          sx={{ width: '100%' }}
        >
          <AlertTitle color="warning.main">
            {t(`${processType}.ready.alert.title`)}
          </AlertTitle>
          {t(`${processType}.ready.alert.subtitle`)}
        </Alert>
      </div>
      {keysToDisplay.length !== 0 && (
        <>
          <div className="KeysWithSameLocation__subtitle">
            <STitle
              title={t(`${processType}.ready.subtitle`, {
                type: t(`common.${defaultKey?.type}`),
              })}
              variant="body"
            />
          </div>
          <SCard className="KeysWithSameLocation__info">
            <div className="KeysWithSameLocation__info__store">
              <div className="KeysWithSameLocation__info__store__name">
                <SText variant="body-title">{defaultKey?.locationName}</SText>
              </div>
              <div className="KeysWithSameLocation__info__store__location">
                <SText variant="body-subtitle">
                  <ALink onClick={handleOpenMaps}>
                    {defaultKey?.locationAddress}
                  </ALink>
                </SText>
              </div>
            </div>
            <div className="KeysWithSameLocation__info__hours">
              {defaultKey?.locationTimeSchedule &&
                Tools.apartmentKeyLocationOpeningHoursToHumanFormat(
                  defaultKey.locationTimeSchedule as {
                    dayOfWeek: number;
                    startMinuteOfDay: number;
                    endMinuteOfDay: number;
                  }[],
                ).map((schedule: any) => (
                  <div
                    className="KeysPickupDetails__keys__content__days"
                    key={String(schedule)}
                  >
                    {schedule}
                  </div>
                ))}
            </div>
          </SCard>
          <div className="KeysWithSameLocation__content">
            <div className="KeysWithSameLocation__content__keys">
              {keysToDisplay.map((key, index) => {
                return (
                  <SCard
                    key={`${key?.id}-${index}`}
                    className={`KeysWithSameLocation__content${
                      key?.status === KEY_SETUP_STATUS.DEPOSITED
                        ? '--deposited'
                        : '--ready'
                    }`}
                  >
                    <div
                      className={`KeysWithSameLocation__content__keys__key--${key?.denomination}`}
                    />
                    <div className={`KeysWithSameLocation__content__keys__key`}>
                      <div>
                        <SText variant="body-title">
                          {t(`setup.ready.${key?.denomination}`)}
                        </SText>
                        {key?.status === KEY_SETUP_STATUS.DEPOSITED ? (
                          <SText variant="body-subtitle">
                            {t(`setup.ready.deposited`)}
                          </SText>
                        ) : (
                          <SText variant="body-subtitle">
                            {t(`setup.ready.code`)}
                            <span
                              className={`KeysWithSameLocation__content__keys__key__code KeysWithSameLocation__content__keys__key__code--${key?.denomination}`}
                            >
                              {processType === 'unsetup' &&
                              key?.type === APARTMENT_KEY_TYPE.KEYNEST
                                ? (key as ApartmentKey)?.collectionCode
                                : key?.dropOffCode}
                            </span>
                          </SText>
                        )}
                      </div>
                    </div>
                  </SCard>
                );
              })}
            </div>
            <div className="KeysWithSameLocation__content__map">
              <Map
                markerAddress={defaultKey && defaultKey?.locationAddress}
                zoom={15}
              />
            </div>
          </div>
          <KeyInfoCard processType={processType} />
          <div className="KeysWithSameLocation__keys">
            <KeysHelp processType={processType} keysInfos={defaultKey} />
          </div>
        </>
      )}
    </div>
  );
}

import React, { useCallback, useEffect, useState } from 'react';

import { INVENTORY_STATUS, INVENTORY_TYPE, LEAVING_STATUS } from '@enum';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import SetupStepContainer from '../SetupStepContainer/SetupStepContainer';
import {
  ProspectSetupStep,
  useSetupStep,
} from '../SetupStepContainer/useSetupStep';
import HidePhoneStep from '../Steps/HidePhoneStep/HidePhoneStep';
import InventoryStep from '../Steps/Inventory/Inventory';
import KeysStep from '../Steps/Keys/Keys';
import MandateStep from '../Steps/Mandate/Mandate';
import PhoneStep from '../Steps/Phone/Phone';
import PhotoShootStep from '../Steps/PhotoShoot/PhotoShoot';
import RejectedStep from '../Steps/Rejected/Rejected';
import ToRecontactStep from '../Steps/ToRecontact/ToRecontact';
import UploadDocumentsStep from '../Steps/UploadDocuments/UploadDocuments';
import VisitStep from '../Steps/Visit/Visit';

const fullSteps: Exclude<
  ProspectSetupStep,
  ProspectSetupStep.toRecontact | ProspectSetupStep.rejected
>[] = [
  ProspectSetupStep.phone,
  ProspectSetupStep.visit,
  ProspectSetupStep.documents,
  ProspectSetupStep.mandate,
  ProspectSetupStep.photoShoot,
  ProspectSetupStep.inventory,
  ProspectSetupStep.keys,
];

const renewalSteps: Exclude<
  ProspectSetupStep,
  | ProspectSetupStep.phone
  | ProspectSetupStep.visit
  | ProspectSetupStep.documents
  | ProspectSetupStep.toRecontact
  | ProspectSetupStep.rejected
>[] = [
  ProspectSetupStep.mandate,
  ProspectSetupStep.inventory,
  ProspectSetupStep.keys,
];

export default function DashboardSetup() {
  const initialStep = useSetupStep() as ProspectSetupStep;
  const [selectedStep, setSelectedStep] = useState(initialStep);
  const apartment = useApartment();
  const leaving = useLeaving();
  const inventoryInReview = leaving.findInventoryByType(
    INVENTORY_TYPE.IN_REVIEW,
  );

  const handleClickStep = useCallback((step: ProspectSetupStep) => {
    if (
      inventoryInReview?.status === INVENTORY_STATUS.TO_SIGN &&
      selectedStep >= ProspectSetupStep.inventory &&
      [ProspectSetupStep.inventory, ProspectSetupStep.keys].includes(step)
    ) {
      setSelectedStep(step);
    }
  }, []);

  useEffect(() => {
    setSelectedStep(initialStep);
  }, [initialStep]);

  const isRenewal =
    apartment.leavings.some(
      (leav) => leav.status === LEAVING_STATUS.SIGNED && leav.id !== leaving.id,
    ) && !leaving.parentId;
  const steps = isRenewal ? renewalSteps : fullSteps;

  return (
    <>
      {selectedStep !== ProspectSetupStep.rejected &&
        selectedStep !== ProspectSetupStep.toRecontact && (
          <SetupStepContainer
            steps={steps}
            initialStep={initialStep}
            selectedStep={selectedStep}
            onChange={handleClickStep}
          >
            <>
              {selectedStep === ProspectSetupStep.phone && <PhoneStep />}
              {selectedStep === ProspectSetupStep.hidePhone && (
                <HidePhoneStep />
              )}
              {selectedStep === ProspectSetupStep.visit && <VisitStep />}
              {selectedStep === ProspectSetupStep.documents && (
                <UploadDocumentsStep />
              )}
              {selectedStep === ProspectSetupStep.mandate && <MandateStep />}
              {selectedStep === ProspectSetupStep.photoShoot && (
                <PhotoShootStep />
              )}
              {selectedStep === ProspectSetupStep.inventory && (
                <InventoryStep />
              )}
              {selectedStep === ProspectSetupStep.keys && <KeysStep />}
            </>
          </SetupStepContainer>
        )}
      {selectedStep === ProspectSetupStep.toRecontact && <ToRecontactStep />}
      {selectedStep === ProspectSetupStep.rejected && <RejectedStep />}
    </>
  );
}

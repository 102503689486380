import moment from 'moment-timezone';

import Document from './Document';

export class HostRent {
  id!: number;
  createdAt!: string;
  updatedAt!: string;
  month!: number;
  year!: number;
  dueDate!: moment.Moment;
  type!: string;
  amount!: number;
  leavingId!: number;
  apartmentId!: number;

  validatedAt?: string;
  validatedById?: number;
  hostRentSepaId?: number;
  billId?: number;
  bill?: Document | undefined;

  constructor(data: Record<string, any>) {
    Object.assign(this, data);

    this.bill = data.bill ? new Document(data.bill) : undefined;
    this.dueDate = moment(data.dueDate);
  }
}

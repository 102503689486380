import moment from 'moment-timezone';

import { KeywordType } from '@smartrenting/smartomic';

import { LEAVING_STATUS } from '@enum';

import { Access } from './Access';
import Agency from './Agency';
import { ApartmentKey } from './ApartmentKey';
import Document from './Document';
import Employee from './Employee';
import Leaving from './Leaving';
import Neighborhood from './Neighborhood';
import { PhotoContract } from './PhotoContract';
import Prospect from './Prospect';
import { Room } from './Room';

export default class Apartment {
  id!: number;
  ownership!: string;
  residenceType!: string;
  agency?: Agency;
  prospect?: Prospect;
  agencyId?: number;
  longitude?: number;
  latitude?: number;
  address!: string;
  postalCode!: string;
  city!: string;
  housingType?: string;
  equipments: string[] = [];
  capacity?: string;
  category?: string;
  ring?: string;
  floor?: string;
  door?: string;
  mainContact?: string;
  area?: string;
  wifi?: string;
  wifiPassword?: string;
  iban?: string;
  bic?: string;
  neighborhood?: Neighborhood;
  neighborhoodId?: number;
  leavings: Leaving[] = [];
  usageDocument: Document;
  proofDocument: Document;
  receipts: Document[] = [];
  professionalPictures: Document[] = [];
  pictures: Document[] = [];
  keywords: KeywordType[] = [];
  photoContracts: PhotoContract[] = [];
  rooms: Room[] = [];
  sale: Employee;
  customerExperience: Employee;
  keys: ApartmentKey[] = [];
  electricitySupplyLocalization?: string;
  waterSupplyLocalization?: string;
  cleaningDirective?: string;
  wasteInstructions?: string;
  wifiInstructions?: string;
  accesses: Access[] = [];
  code!: string;
  risk!: number;

  constructor(data: Record<string, any>) {
    Object.assign(this, data);

    this.agency = data.agency && new Agency(data.agency);
    this.leavings =
      data.leavings &&
      data.leavings.map((leaving: Partial<Leaving>) => new Leaving(leaving));
    this.prospect = data.prospect && new Prospect(data.prospect);
    this.usageDocument = data.usageDocument && new Document(data.usageDocument);
    this.proofDocument = data.proofDocument && new Document(data.proofDocument);
    this.sale = data.sale && new Employee(data.sale);
    this.customerExperience =
      data.customerExperience && new Employee(data.customerExperience);
    this.receipts =
      data.receipts &&
      data.receipts.map((receipt: Partial<Document>) => new Document(receipt));
    this.professionalPictures = (data.professionalPictures || []).map(
      (picture: Partial<Document>) => new Document(picture),
    );
    this.pictures = (data.pictures || []).map(
      (picture: Partial<Document>) => new Document(picture),
    );
    this.photoContracts = (data.photoContracts || []).map(
      (photContract: Partial<PhotoContract>) => new PhotoContract(photContract),
    );
    this.keywords = data.keywords || [];

    this.rooms = (data.rooms || []).map((room: Room) => room);
  }

  getFullAddress() {
    return `${this.address} ${this.postalCode} ${this.city}`;
  }

  getPicture() {
    const latestPicture = [...this.professionalPictures, ...this.pictures].sort(
      (a, b) => (moment(a.updatedAt).isAfter(b.updatedAt) ? -1 : 1),
    );

    return latestPicture?.[0];
  }

  isRenewal(leavingId: number) {
    return this.leavings.some(
      (leav) => leav.status === LEAVING_STATUS.SIGNED && leav.id !== leavingId,
    );
  }
}

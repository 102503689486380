import React, { useState } from 'react';

import moment, { Moment } from 'moment-timezone';

import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { AIcon, ASeparator } from '@smartrenting/smartomic';

import './DateRangePicker.scss';

export interface DateRangePickerProps {
  startDate?: Moment;
  endDate?: Moment;
  setStartDate: (date?: Moment) => void;
  setEndDate: (date?: Moment) => void;
}

interface CalendarDay {
  day?: number | string;
  value?: Moment;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(moment().month());
  const [isStartDate, setIsStartDate] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const monthDays = (date: Moment): CalendarDay[] => {
    const y = date.get('years');
    const m = date.get('months');
    const firstDay = new Date(y, m, 0).getDay();
    const totalDays = new Date(y, m + 1, 0).getDate();

    const days = [];
    for (let i = 1; i <= totalDays; i++) {
      days.push({ day: i, value: moment([y, m, i]) });
    }
    for (let i = firstDay; i > 0; i--) {
      days.unshift({
        day: undefined,
        value: undefined,
      });
    }

    for (let i = days.length; i % 7 !== 0; i++) {
      days.push({
        day: undefined,
        value: undefined,
      });
    }

    return days;
  };

  const next = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else setMonth(month + 1);
  };

  const prev = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else setMonth(month - 1);
  };

  const handleClick = (date?: Moment) => {
    if (!date) return;
    if (isStartDate) {
      setIsStartDate(false);
      setStartDate(date);
      setEndDate(undefined);
    } else if (startDate && date.isSameOrBefore(startDate)) {
      setStartDate(date);
    } else {
      setIsStartDate(true);
      setEndDate(date);
    }
  };

  const backdropClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(false);
  };

  return (
    <div className="DateRangePicker">
      <div
        className="DateRangePickerInput__container"
        onClick={setIsOpen.bind(null, true)}
      >
        <div className="DateRangePickerInput__input DateRangePickerInput__input--start">
          {startDate ? startDate.format('DD/MM/YYYY') : 'Départ'}
          <AIcon icon={faCalendar} height={20} />
        </div>
        <div className="DateRangePickerInput__input DateRangePickerInput__input--end">
          {endDate ? endDate.format('DD/MM/YYYY') : 'Retour'}
          <AIcon icon={faCalendar} height={20} />
        </div>
      </div>

      {isOpen && (
        <>
          <div className="DateRangePicker__backdrop" onClick={backdropClick} />
          <div className="DateRangePicker__modal">
            <div className="DateRangePicker__container">
              <div className="DateRangePicker__header">
                <AIcon icon={faChevronLeft} height={15} onClick={prev} />
                {moment([year, month, 1]).format('MMMM YYYY')}
                <AIcon
                  className="sm-hidden"
                  icon={faChevronRight}
                  height={15}
                  onClick={next}
                />
              </div>
              <ASeparator />
              <Calendar
                id={'month-' + month}
                handleClick={handleClick}
                days={monthDays(moment([year, month, 1]))}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
            <div className="DateRangePicker__container">
              <div className="DateRangePicker__header">
                <AIcon
                  className="sm-hidden"
                  icon={faChevronLeft}
                  height={15}
                  onClick={prev}
                />
                {moment([year, month, 1]).add(1, 'months').format('MMMM YYYY')}
                <AIcon icon={faChevronRight} height={15} onClick={next} />
              </div>
              <ASeparator />
              <Calendar
                id={
                  'month-' + moment([year, month, 1]).add(1, 'months').month()
                }
                handleClick={handleClick}
                days={monthDays(moment([year, month, 1]).add(1, 'months'))}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Calendar: React.FC<{
  id: string;
  startDate?: Moment;
  endDate?: Moment;
  days: CalendarDay[];
  handleClick: (date: Moment) => void;
}> = ({ id, days, endDate, startDate, handleClick }) => {
  return (
    <div id={id} className="DateRangePicker__content">
      <div className="DateRangePicker__daysRow">
        {Array.apply(null, [...Array(7)])
          .map((_, i) =>
            moment(i, 'e')
              .startOf('week')
              .isoWeekday(i + 1)
              .format('ddd')
              .slice(0, 1)
              .toUpperCase(),
          )
          .map((weekDay, key) => (
            <CalendarDay key={key} day={weekDay} handleClick={handleClick} />
          ))}
      </div>
      {days
        .reduce((rows, day, index) => {
          if (index % 7 === 0) {
            rows.push([]);
          }
          rows[rows.length - 1].push(day);

          return rows;
        }, [] as CalendarDay[][])
        .map((row, key) => (
          <div className="DateRangePicker__daysRow" key={'month-' + key}>
            {row.map(({ day, value }, index) => {
              return (
                <CalendarDay
                  key={'day-' + index}
                  id={'day-' + value?.date()}
                  handleClick={handleClick}
                  day={day}
                  value={value}
                  main={value?.isSame(endDate) || value?.isSame(startDate)}
                  isEnd={
                    value?.isSame(endDate) ||
                    index === 6 ||
                    day === value?.daysInMonth()
                  }
                  isStart={value?.isSame(startDate) || index === 0 || day === 1}
                  inRange={
                    value &&
                    startDate &&
                    endDate &&
                    value.isSameOrAfter(startDate) &&
                    value.isSameOrBefore(endDate)
                  }
                />
              );
            })}
          </div>
        ))}
    </div>
  );
};

const CalendarDay: React.FC<
  CalendarDay & {
    id?: string;
    main?: boolean;
    isStart?: boolean;
    isEnd?: boolean;
    inRange?: boolean;
    handleClick: (date: Moment) => void;
  }
> = ({ id, day, value, isStart, isEnd, inRange, handleClick, main }) => {
  if (!value) {
    return (
      <div className="DateRangePicker__day">
        <div className="circle">{day}</div>
      </div>
    );
  }

  return (
    <div
      id={id}
      onClick={handleClick.bind(null, value)}
      className={`${id} DateRangePicker__day ${isStart ? 'isStart' : ''}
        ${isEnd ? 'isEnd' : ''} ${inRange ? 'inRange' : ''}`}
    >
      <div className={`circle ${main ? 'circle-selected' : ''}`}>{day}</div>
    </div>
  );
};

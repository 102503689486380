export const ActionTypes = {
  SET_TOKEN: 'SET_TOKEN',
  SET_CONNECTED: 'SET_CONNECTED',
  SET_CONTACT: 'SET_CONTACT',
  SET_DOCUMENTS: 'SET_DOCUMENTS',
  SET_CURRENT_APARTMENT: 'SET_CURRENT_APARTMENT',
  SET_CURRENT_LEAVING: 'SET_CURRENT_LEAVING',
  RESET_STATE: 'RESET_STATE',
  SET_SIDEBAR_OPEN: 'SET_SIDEBAR_OPEN',
};

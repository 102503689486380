import React from 'react';
import { useNavigate } from 'react-router';

import { routes } from 'src/routes';

import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { AIcon, STitle } from '@smartrenting/smartomic';

import './GoBackHeader.scss';

type Props = {
  title: string;
  subtitle: string;
};

export default function GoBackHeader({ title, subtitle }: Props) {
  const navigate = useNavigate();

  return (
    <div className="GoBackHeader__header">
      <AIcon
        className="GoBackHeader__icon"
        height={18}
        icon={faArrowLeft}
        onClick={() => navigate(routes.settings)}
      />
      <STitle variant="body" title={title} subtitle={subtitle} />
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';

import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { AIcon, SSmartLoader } from '@smartrenting/smartomic';

import Document from '@models/Document';

import { getImage } from '@api/documents';

import { useAppContext } from '@contexts/AppContext/AppContext';

interface ImageProps {
  document?: Document;
  parameters?: Parameters<typeof getImage>[2];
}

function Image({ document, parameters }: ImageProps) {
  const {
    state: { token },
  } = useAppContext();
  const [file, setFile] = useState<string>('');
  const documentRef = useRef(document);
  useEffect(() => {
    const abortController = new AbortController();

    if (documentRef.current?.id !== document?.id) {
      documentRef.current = document;
      setFile('');
    }

    if (token && !file && document) {
      getImage(token, document.id, parameters, abortController.signal)
        .then((blob: Blob) => {
          setFile(URL.createObjectURL(blob));
        })
        .catch((err: DOMException) => {
          if (err.code === DOMException.ABORT_ERR) {
            return;
          }

          console.error(err);
        });
    }

    return () => {
      abortController.abort();
    };
  }, [token, file, document]);

  if (!document) {
    return <AIcon className="Image--icon" icon={faHome} />;
  }

  if (!file) {
    return (
      <div className="Image Image--loading">
        <SSmartLoader />
      </div>
    );
  }

  return <img className="Image" src={file} />;
}

export default React.memo(
  Image,
  (prevProps: ImageProps, nextProps: ImageProps) => {
    if (
      prevProps.document &&
      nextProps.document &&
      prevProps.document.id === nextProps.document.id
    ) {
      return true;
    }

    return false;
  },
);

import { leavings } from '@schemas';

import { useMemo } from 'react';

import { denormalize } from 'normalizr';

import Leaving from '@models/Leaving';

import { useAppContext } from '@contexts/AppContext/AppContext';
import { useEntities } from '@contexts/EntitiesContext/EntitiesContext';

export function useLeaving(): Leaving {
  const entities = useEntities();
  const {
    state: { currentLeaving },
  } = useAppContext();
  const leavingFromState = useMemo(
    () => denormalize(currentLeaving, leavings, entities),
    [currentLeaving, entities.leavings],
  );

  return useMemo(
    () => leavingFromState && new Leaving(leavingFromState),
    [leavingFromState],
  );
}

import { Box, Grid, Typography } from '@mui/material';

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSparkles, faBroom } from '@fortawesome/pro-duotone-svg-icons';

import { TERM_PRICING_TYPE } from '@enum';

import Modality from '@models/Modality';

import { useApartment } from '@hooks/useApartment/useApartment';

import StepChoice from '../ModalityChoice';
import { computeCleaningAmount } from '../hooks/useModalityMapper';
import { useUniqueTermPricingByType } from '../hooks/useTermPricing';

interface StepCleaningProps {
  onChoiceChange: (data: Record<string, any>) => void;
  modality: Modality | null;
}

export default function StepCleaning({
  onChoiceChange,
  modality,
}: StepCleaningProps) {
  const { t } = useTranslation();
  const apartment = useApartment();
  const [currentChoice, setCurrentChoice] = useState<boolean>(
    !!modality?.paidCleaning,
  );

  const termPricing = useUniqueTermPricingByType(
    TERM_PRICING_TYPE.PAID_CLEANING,
  );

  const handleClick = useCallback(
    (choice: boolean) => () => {
      setCurrentChoice(choice);
      onChoiceChange({ paidCleaning: choice });
    },
    [],
  );

  const computeCleaningPrice = computeCleaningAmount(
    termPricing,
    Number(apartment.area),
  );

  return (
    <Box>
      <Typography
        variant="h6"
        align="center"
        fontWeight={500}
        sx={{ mb: { xs: '1rem', sm: '2rem' } }}
      >
        {t('boardOffice.steps.cleaning.title')}
      </Typography>
      <Grid sx={{ flexGrow: 1 }} container>
        <Grid container justifyContent="center" spacing={{ xs: 2, sm: 4 }}>
          <Grid key="cleaning-autonomous" item onClick={handleClick(false)}>
            <StepChoice
              title={t('boardOffice.steps.cleaning.autonomous.title')}
              subtitle={t('boardOffice.steps.cleaning.autonomous.subtitle')}
              header={t('boardOffice.steps.cleaning.autonomous.header')}
              icon={faBroom as IconProp}
              active={!currentChoice}
            />
          </Grid>
          <Grid key="cleaning-hotel" item onClick={handleClick(true)}>
            <StepChoice
              title={t('boardOffice.steps.cleaning.hotel.title')}
              subtitle={t('boardOffice.steps.cleaning.hotel.subtitle')}
              header={t('boardOffice.steps.cleaning.hotel.header', {
                amount: computeCleaningPrice || '...',
              })}
              icon={faSparkles as IconProp}
              active={currentChoice}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

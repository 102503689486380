import '@locales/i18n';
import { ThemeProvider } from '@mui/material';
import theme from '@styles/theme';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { pdfjs } from 'react-pdf';

import CONFIG from '@config';

import GoogleTagManager from '@utils/gtm/gtm';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

if (CONFIG.GTM_ID) GoogleTagManager.initialize(CONFIG.GTM_ID);

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

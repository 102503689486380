import React from 'react';

import { SSmartLoader } from '@smartrenting/smartomic';

import './AppLoader.scss';

export function AppLoader() {
  return (
    <div className="AppLoader">
      <SSmartLoader />
    </div>
  );
}

import moment from 'moment-timezone';

export default class Document {
  id!: number;
  name!: string;
  originalName!: string;
  url!: string;
  createdAt!: moment.Moment;
  updatedAt!: moment.Moment;

  constructor(data: Partial<Document>) {
    Object.assign(this, data);

    this.createdAt = moment(data.createdAt);
    this.updatedAt = moment(data.updatedAt);
  }
}

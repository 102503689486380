import { Card, CardContent, CardHeader, Typography } from '@mui/material';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment-timezone';

import { Badge } from '@smartrenting/smartomic';

import { LEAVING_STATUS } from '@enum';

import Leaving from '@models/Leaving';

import DocumentDownloader, {
  DocumentDownloaderProps,
} from '../DocumentDownloader/DocumentDownloader';

interface TripProps {
  leaving: Leaving;
  index: number;
}

export default function Trip({ leaving, index }: TripProps) {
  const { t } = useTranslation();

  const computedBadgeProps = useMemo(() => {
    if (leaving.status === LEAVING_STATUS.INIT) {
      return { color: 'red', label: t('documents.chip.to-sign') };
    }

    if (leaving.startDate.isAfter(moment())) {
      return { color: 'blue', label: t('documents.chip.futur') };
    }

    if (leaving.endDate.isBefore(moment())) {
      return { color: 'green', label: t('documents.chip.past') };
    }

    return { color: 'yellow', label: t('documents.chip.ongoing') };
  }, [leaving.status, leaving.startDate, leaving.endDate]);

  const downloadableDocuments = useMemo(() => {
    const documents: Array<DocumentDownloaderProps['document']> = [];

    if (leaving.mandateId) {
      documents.push({
        documentId: leaving.mandateId,
        name:
          leaving.status === LEAVING_STATUS.SIGNED
            ? t('documents.trip.name.mandate-signed')
            : t('documents.trip.name.mandate-to-sign'),
      });
    }

    if (leaving.inventories?.length) {
      leaving.inventories.forEach((inventory) => {
        if (inventory.documentId) {
          documents.push({
            documentId: inventory.documentId,
            name: t(`documents.trip.name.${inventory.type}`),
          });
        }
      });
    }

    if (leaving.accountabilityId) {
      documents.push({
        documentId: leaving.accountabilityId,
        name: t('documents.trip.name.accountability'),
      });
    }

    return documents;
  }, [leaving]);

  return (
    <Card
      key={leaving.id}
      sx={{
        marginBottom: 5,
        minWidth: { md: '30%', sm: '40%', xs: '100%' },
        maxWidth: { md: '30%', sm: '40%', xs: '100%' },
        marginRight: 4,
      }}
    >
      <CardHeader
        title={
          <Typography sx={{ fontWeight: 600 }} color="text.primary">
            {`${t('documents.trip.subtitle')} n°${index + 1}`}
          </Typography>
        }
        subheader={
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {`${leaving.startDate.format(
              'DD/MM/YYYY',
            )} - ${leaving.endDate.format('DD/MM/YYYY')}`}
          </Typography>
        }
        action={
          <Badge
            label={computedBadgeProps.label}
            color={computedBadgeProps.color as any}
          />
        }
      />
      <CardContent>
        {downloadableDocuments.length ? (
          downloadableDocuments.map((doc, index) => (
            <DocumentDownloader key={index} document={doc} />
          ))
        ) : (
          <Typography color="text.secondary" variant="body2">
            {t('documents.trip.noDocuments')}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  faTrash,
  faAngleDown,
  faAngleRight,
  faShower,
  faBath,
  faToilet,
  faBedFront,
  faBedBunk,
  faBed,
  faCouch,
} from '@fortawesome/pro-solid-svg-icons';
import { AIcon } from '@smartrenting/smartomic';

import { ROOM_ITEM_BY_ROOM, ROOM_ITEM_TYPE } from '@enum';

import RoomInput from '../RoomInput/RoomInput';
import { RoomEditProps } from '../Rooms/RoomsHelper';

import './RoomEdit.scss';

const RoomEdit: React.FC<RoomEditProps> = ({
  type,
  index,
  items,
  onChange,
  handleRemove,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const iconMap = {
    shower: faShower,
    bath: faBath,
    toilets: faToilet,
    'bed-140': faBedFront,
    'bed-160': faBedFront,
    'bed-180': faBedFront,
    'bed-bunk': faBedBunk,
    'bed-solo': faBed,
    'bed-couch': faCouch,
  } as const;

  const itemsCounter = items.reduce<
    Partial<
      Record<(typeof ROOM_ITEM_TYPE)[keyof typeof ROOM_ITEM_TYPE], number>
    >
  >((counter, type) => {
    const a = counter[type];
    counter[type] = a ? a + 1 : 1;

    return counter;
  }, {});

  const subtitle = (
    Object.entries(itemsCounter) as Array<
      [(typeof ROOM_ITEM_TYPE)[keyof typeof ROOM_ITEM_TYPE], number]
    >
  ).map(([type, count]) => t(`room-item.${type}`, { count }));

  const handleChange = (
    item: (typeof ROOM_ITEM_TYPE)[keyof typeof ROOM_ITEM_TYPE],
    number: number,
  ) => {
    onChange(
      {
        type,
        items: [
          ...items.filter((i) => i !== item),
          ...Array.from({ length: number }, () => item),
        ],
      },
      index,
    );
  };

  return (
    <div className="room-edit">
      <div className="room-edit-header">
        <AIcon
          icon={faTrash}
          height={10}
          onClick={handleRemove.bind(null, index)}
        />
        <div className="room-edit-title">
          {t(`room.${type}`)} {index}
        </div>
        {!open && <div className="room-edit-info">{subtitle.join(', ')}</div>}
        <AIcon
          icon={open ? faAngleDown : faAngleRight}
          height={15}
          onClick={setOpen.bind(null, !open)}
        />
      </div>
      {open && (
        <div className="room-edit-body">
          {ROOM_ITEM_BY_ROOM[type].map((item) => (
            <RoomInput
              key={item}
              item={item}
              number={itemsCounter[item] || 0}
              icon={iconMap[item]}
              onChange={handleChange}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default RoomEdit;

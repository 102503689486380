import confett from './assets/confett.png';
import imageTitle from './assets/imageTitle.png';
import noReferee from './assets/noReferee.png';
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  Divider,
  Fade,
  Grid,
  Link,
  Modal,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import themeMUI from '@styles/theme';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faWhatsapp,
  faFacebookMessenger,
} from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope,
  faSackDollar,
  faHourglassClock,
  faLightbulbOn,
  faUserPlus,
} from '@fortawesome/pro-duotone-svg-icons';
import { faLink, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import config from '@config';

import { REFERRAL_STATUS } from '@enum';

import { hexToRgba } from '@utils/colors';

import { useContact } from '@hooks/useContact/useContact';
import { usePicture } from '@hooks/usePicture/usePicture';

import { MessengerBox, SemiButton } from './styled';

export default function Referral() {
  const { t } = useTranslation();
  const contact = useContact();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [openModal, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const share = () => {
    if (!navigator?.share) {
      return;
    }

    navigator
      .share({
        url: `${config.WEBSITE_URL}?referralCode=${contact.user.referralCode}`,
        title: t('referral.shareText.subject'),
        text: [
          t('referral.shareText.1'),
          `${config.WEBSITE_URL}?referralCode=${contact.user.referralCode}`,
          t('referral.shareText.2'),
          contact.user.firstname,
          '\n\n',
        ].join(''),
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Stack
      width="100%"
      sx={(theme) => ({
        background: `linear-gradient(176deg, ${hexToRgba(
          theme.palette.primary.light,
          50,
        )}, #fff)`,
        overflowX: { xs: 'auto', sm: 'hidden' },
      })}
      pl={{ xs: 0, lg: 33 }}
      height="100%"
    >
      <Grid
        item
        xs={0}
        md={0}
        pt="2px"
        position="relative"
        display={{ xs: 'none', sm: 'initial' }}
      >
        <Box position="absolute" right={150} top={4} zIndex={0}>
          <Box
            component="img"
            src={imageTitle}
            width={275}
            sx={{
              background: `radial-gradient(#fff, transparent, transparent)`,
            }}
          />
        </Box>
      </Grid>
      <Grid container marginRight={{ xs: 0, sm: 6 }}>
        <Grid
          item
          xs={12}
          sm={6}
          pt={{ xs: 6, sm: 3 }}
          pl={{ xs: 0.5, sm: 2 }}
          pr={{ xs: 0.5, sm: 0 }}
        >
          <Typography
            variant="h2"
            color="primary"
            textAlign={{ xs: 'center', sm: 'start' }}
            fontWeight={{ xs: 900, sm: 700 }}
          >
            {t('referral.title')}
          </Typography>
          <Typography
            variant="h4"
            fontWeight={{ xs: 400, sm: 500 }}
            pb={{ xs: 2.5, sm: 1 }}
            mt={{ xs: '-4px', sm: -1 }}
            textAlign={{ xs: 'center', sm: 'start' }}
          >
            {t('referral.subtitle')}
          </Typography>
          <Stack flexDirection="row" justifyContent="center" px={2}>
            <Button
              variant="contained"
              sx={{ display: { sm: 'none' }, borderRadius: 2.5 }}
              onClick={share}
              fullWidth
            >
              <Box
                py={1}
                mr={2}
                component={FontAwesomeIcon}
                icon={faUserPlus as IconProp}
                height={22}
                color="white"
              />
              <Typography color="white" variant="subtitle1" fontWeight={600}>
                {t('referral.invit')}
              </Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Box
        px={2}
        zIndex={1}
        position="relative"
        display={{ xs: 'none', sm: 'initial' }}
        marginRight={{ xs: 0, sm: '40px' }}
      >
        <Typography variant="body1" color="grey.700" pl={2} pb={0.5}>
          {t('referral.shareLink')}
        </Typography>
        <Stack
          boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.15)"
          p={2.5}
          bgcolor="white"
          borderRadius={4}
          flexDirection="row"
        >
          <Stack
            flexGrow={1}
            border={1}
            borderColor="grey.300"
            alignItems="center"
            sx={{
              borderRight: 0,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            }}
            flexDirection="row"
          >
            <Typography
              variant="subtitle2"
              fontWeight={500}
              px={1.5}
              flexGrow={1}
            >
              {`${config.WEBSITE_URL}?referralCode=${contact.user.referralCode}`}
            </Typography>
            <Stack
              mr={2}
              height={28}
              width={28}
              border={1}
              borderRadius={50}
              borderColor="secondary.main"
            >
              <Box
                m="auto"
                component={FontAwesomeIcon}
                icon={faLink as IconProp}
                height={16}
                color="secondary.main"
                cursor="pointer"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${config.WEBSITE_URL}?referralCode=${contact.user.referralCode}`,
                  );
                  toast.success(t('referral.successClip'), {
                    autoClose: 1000,
                  });
                }}
              />
            </Stack>
          </Stack>
          <SemiButton
            variant="contained"
            href={`mailto:?subject=${encodeURIComponent(
              t('referral.shareText.subject'),
            )}&body=${encodeURIComponent(
              [
                t('referral.shareText.1'),
                `${config.WEBSITE_URL}?referralCode=${contact.user.referralCode}`,
                t('referral.shareText.2'),
                contact.user.firstname,
              ].join(''),
            )}`}
          >
            <FontAwesomeIcon icon={faEnvelope as IconProp} color="white" />
            <Typography variant="body1" color="white" fontWeight={500} pl={1.5}>
              {t('referral.sendEmail')}
            </Typography>
          </SemiButton>
          <Stack
            component={Link}
            href={`https://web.whatsapp.com/send?text=${encodeURI(
              [
                t('referral.shareText.1'),
                `${config.WEBSITE_URL}?referralCode=${contact.user.referralCode}`,
                t('referral.shareText.2'),
                contact.user.firstname,
              ].join(''),
            )}`}
            target="_blank"
            mx={2}
            ml={3}
            height={40}
            width={40}
            borderRadius={50}
            bgcolor="#25D366"
            alignSelf="center"
            sx={{
              cursor: 'pointer',
              ':hover': {
                '> *': {
                  height: 26,
                },
              },
            }}
          >
            <Box
              m="auto"
              component={FontAwesomeIcon}
              icon={faWhatsapp as IconProp}
              height={24}
              color="white"
              sx={{
                MozTransition: 'height .5s ease',
                WebkitTransition: 'height .5s ease',
                OTransition: 'height .5s ease',
                transition: 'height .5s ease',
              }}
            />
          </Stack>
          <svg width="0" height="0">
            <radialGradient id="rg" r="150%" cx="30%" cy="107%">
              <stop stopColor="#0695ff" offset="0" />
              <stop stopColor="#a334fa" offset="0.5" />
              <stop stopColor="#ff6968" offset="0.8" />
              <stop stopColor="#ff6968" offset="1" />
            </radialGradient>
          </svg>
          <Stack
            component={Link}
            href={`https://www.facebook.com/dialog/send?app_id=287062147276759&link=${encodeURI(
              `${config.WEBSITE_URL}?referralCode=${contact.user.referralCode}`,
            )}&redirect_uri=${encodeURI(config.FO_URL)}`}
            target="_blank"
            mr={1}
            height={40}
            width={40}
            borderRadius={50}
            bgcolor="white"
            alignSelf="center"
            boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.15)"
            sx={{
              cursor: 'pointer',
              ':hover': {
                '> *': {
                  height: 26,
                },
              },
            }}
          >
            <MessengerBox icon={faFacebookMessenger as IconProp} />
          </Stack>
        </Stack>
      </Box>
      <Stack
        flexDirection={{
          xs: contact.referrals?.length ? 'column' : 'column-reverse',
          sm: 'column',
        }}
        gap={5.5}
        py={5}
        marginRight={{ xs: 0, sm: '2px' }}
        maxHeight={{ xs: 'none', sm: '260px' }}
      >
        <Stack
          px={2}
          width="100%"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'center', sm: 'initial' }}
        >
          {contact.referrals?.length ? (
            <Box
              bgcolor="white"
              boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.15)"
              borderRadius={5}
              position="relative"
              mr={{ xs: 0, sm: '40px' }}
              mb={{ xs: 6, sm: 0 }}
            >
              <Box
                component="img"
                position="absolute"
                src={confett}
                top="0"
                left="50%"
                width="80%"
                sx={{ transform: 'translate(-50%, +5%)' }}
              />
              <Stack
                width={277}
                maxHeight={'250px'}
                alignItems="center"
                pt={9}
                justifyContent="center"
              >
                <Box
                  component={FontAwesomeIcon}
                  icon={faSackDollar as IconProp}
                  height={28}
                />
                <Typography
                  variant="subtitle2"
                  fontSize={{ xs: '24px', sm: '1.125rem' }}
                  fontWeight={{ xs: '500', sm: '600' }}
                  marginTop="8px"
                >
                  {t('referral.gain')}
                </Typography>
                <Typography
                  variant="h2"
                  lineHeight={1}
                  fontSize={{ xs: '50px', sm: '3rem' }}
                  color={{
                    xs: themeMUI.palette.primary.main,
                    sm: themeMUI.palette.secondary.main,
                  }}
                >
                  {contact.referrals?.reduce(
                    (prev, current) =>
                      current.status === 'to-pay' || current.status === 'payed'
                        ? prev + current.amount
                        : prev,
                    0,
                  ) || 0}
                  €
                </Typography>
                <Typography
                  variant="body1"
                  color={{
                    xs: themeMUI.palette.secondary.main,
                    sm: themeMUI.palette.primary.main,
                  }}
                  fontSize={{ xs: '20px', sm: '1rem' }}
                  fontWeight={{ xs: '500', sm: '600' }}
                  pb={4}
                  pt={3}
                >
                  <Box
                    component={FontAwesomeIcon}
                    icon={faHourglassClock as IconProp}
                    height={{ xs: 20, sm: 15 }}
                    mr={{ xs: 1.5, sm: 1 }}
                  />
                  {contact.referrals?.reduce(
                    (prev, current) =>
                      current.status === 'pending'
                        ? prev + current.amount
                        : prev,
                    0,
                  ) || 0}
                  € {t('referral.waiting')}
                </Typography>
              </Stack>
            </Box>
          ) : null}
          <Box
            width="100%"
            display={{
              xs: contact?.referrals?.length ? 'initial' : 'none',
              sm: 'none',
            }}
          >
            <Typography variant="subtitle2" fontWeight={600} pb={2}>
              {t('referral.referee')}
            </Typography>
          </Box>
          <Stack
            p={{ xs: 2.5, sm: 3 }}
            flexGrow={1}
            mr={{ xs: 0, sm: '40px' }}
            bgcolor="white"
            width={{ xs: '100%', sm: 'fit-content' }}
            boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.15)"
            borderRadius={5}
            position="relative"
            maxHeight={{ xs: 'none', sm: '250px' }}
          >
            {contact.referrals?.length ? (
              <>
                <Stack
                  width="100%"
                  justifyContent="space-evenly"
                  flexDirection={{ xs: 'column-reverse', sm: 'row' }}
                  height="100%"
                >
                  <Stack
                    mr={{ sm: 3 }}
                    maxHeight={{ xs: 'none', sm: '226px' }}
                    overflow={{ xs: 'visible', sm: 'auto' }}
                    width="100%"
                  >
                    <Typography
                      variant="subtitle2"
                      pb={2}
                      fontWeight={600}
                      display={{ xs: 'none', sm: 'initial' }}
                      alignSelf={'center'}
                    >
                      {t('referral.referee')}
                    </Typography>
                    <Stack
                      maxHeight={{ xs: 'none', sm: '185px' }}
                      overflow={{ xs: 'visible', sm: 'auto' }}
                      flexDirection={{ xs: 'column-reverse', sm: 'column' }}
                    >
                      {contact.referrals
                        ?.filter(
                          (referral) =>
                            referral.status === REFERRAL_STATUS.TO_PAY ||
                            referral.status === REFERRAL_STATUS.PAYED,
                        )
                        .map((referral, i) => {
                          const picture = usePicture(
                            referral.referee.profilePictureId,
                          );

                          return (
                            <React.Fragment key={'payed-' + i}>
                              <Stack
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-evenly"
                                width="100%"
                                marginBottom={2}
                              >
                                <Avatar
                                  alt={referral.referee.fullName}
                                  src={picture}
                                >
                                  {referral.referee.initials}
                                </Avatar>
                                <Box pl={1} overflow="hidden">
                                  <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    textOverflow="ellipsis"
                                    noWrap
                                  >
                                    {referral.referee.firstname.charAt(0) +
                                      '. ' +
                                      referral.referee.lastname}
                                  </Typography>
                                  <Typography variant="body2" color="grey.500">
                                    {referral.createdAt.format('DD/MM/YYYY')}
                                  </Typography>
                                </Box>
                                <Box
                                  component={Chip}
                                  label={`+${referral.amount}€`}
                                  sx={{
                                    bgcolor: 'mint.main',
                                    color: 'grass.main',
                                    px: 1.5,
                                    ml: 'auto',
                                  }}
                                />
                              </Stack>
                              <Divider
                                sx={{
                                  mb: 2,
                                  display: { xs: 'block', sm: 'none' },
                                }}
                              />
                            </React.Fragment>
                          );
                        })}
                    </Stack>
                  </Stack>
                  <Divider
                    sx={{
                      height: '100%',
                      display: { xs: 'none', sm: 'block' },
                      color: 'grey.300',
                      bgcolor: 'grey.300',
                    }}
                    orientation="vertical"
                    color="black"
                  ></Divider>
                  <Stack ml={{ sm: 3 }} width="100%">
                    <Typography
                      alignSelf={'center'}
                      fontWeight={600}
                      variant="subtitle2"
                      pb={2}
                      display={{ xs: 'none', sm: 'initial' }}
                    >
                      {t('referral.waiting-referee')}
                    </Typography>
                    <Box
                      maxHeight={{ xs: 'none', sm: '185px' }}
                      overflow={{ xs: 'visible', sm: 'auto' }}
                    >
                      {contact.referrals
                        ?.filter(
                          (referral) =>
                            referral.status === REFERRAL_STATUS.PENDING,
                        )
                        .map((referral, i, array) => {
                          const picture = usePicture(
                            referral.referee.profilePictureId,
                          );

                          return (
                            <React.Fragment key={'pending-' + i}>
                              <Stack
                                flexDirection="row"
                                alignItems="center"
                                marginBottom={2}
                              >
                                <Avatar
                                  alt={referral.referee.fullName}
                                  src={picture}
                                >
                                  {referral.referee.initials}
                                </Avatar>
                                <Box pl={1} overflow="hidden">
                                  <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    textOverflow="ellipsis"
                                    noWrap
                                  >
                                    {referral.referee.firstname.charAt(0) +
                                      '. ' +
                                      referral.referee.lastname}
                                  </Typography>
                                  <Typography variant="body2" color="grey.500">
                                    {referral.createdAt.format('DD/MM/YYYY')}
                                  </Typography>
                                </Box>
                                <Box
                                  component={Chip}
                                  label="en cours"
                                  sx={{
                                    bgcolor: 'primary.light',
                                    color: 'primary.main',
                                    px: 1.5,
                                    ml: 'auto',
                                  }}
                                />
                              </Stack>
                              <Divider
                                sx={{
                                  mb: 2,
                                  display: {
                                    xs: i < array.length - 1 ? 'block' : 'none',
                                    sm: 'none',
                                  },
                                }}
                              />
                            </React.Fragment>
                          );
                        })}
                    </Box>
                  </Stack>
                </Stack>
              </>
            ) : (
              <Stack
                flexDirection="column"
                alignContent="center"
                justifyItems="center"
                justifyContent="center"
                alignItems="center"
              >
                <Box component="img" src={noReferee} width={125} />
                <Typography
                  variant="subtitle2"
                  fontWeight={600}
                  marginTop={0.5}
                  marginBottom={1}
                >
                  {t('referral.noReferee.title')}
                </Typography>
                <Typography variant="body2" whiteSpace="pre-line">
                  {t('referral.noReferee.text')}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Box
          pt={{ xs: 2.5, sm: 1 }}
          px={2.5}
          mx={{ xs: 2.5, sm: 0 }}
          pb={{ xs: 2.5, sm: 5 }}
          mt={{ x: 2, sm: -3 }}
          bgcolor={{ xs: 'white', sm: 'transparent' }}
          borderRadius={{ xs: 5, sm: 0 }}
          boxShadow={{ xs: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)', sm: 'none' }}
          display={{
            xs: contact.referrals?.length ? 'none' : 'initial',
            sm: 'initial',
          }}
        >
          <Typography
            variant="h6"
            pb={{ xs: 1.5, sm: 2 }}
            fontSize={{ xs: '18px', sm: '22px' }}
          >
            <Box
              component={FontAwesomeIcon}
              icon={faLightbulbOn as IconProp}
              height={{ xs: 18, sm: 22 }}
              mr={2}
            />
            {t('referral.tips.title')}
          </Typography>
          <Stack
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent="space-around"
            pr={{ xs: 0, sm: 8 }}
            ml={{ xs: 0, sm: -4 }}
          >
            <Stack
              alignItems="center"
              width={{ sm: 180 }}
              flexDirection={{ xs: 'row', sm: 'column' }}
            >
              <Typography
                component={Stack}
                height={{ xs: 22, sm: 44 }}
                width={{ xs: 22, sm: 44 }}
                borderRadius={50}
                bgcolor={(theme) => hexToRgba(theme.palette.primary.main, 35)}
                color="white"
                variant="h6"
                textAlign="center"
                justifyContent="center"
                lineHeight={1}
                mb={{ xs: 0, sm: 2 }}
                mr={{ xs: 2.5, sm: 0 }}
              >
                1
              </Typography>
              <Typography
                fontSize={{ xs: 12, sm: 14 }}
                fontWeight={500}
                textAlign="center"
                whiteSpace={{ sm: 'pre-line' }}
              >
                {t('referral.tips.1')}
              </Typography>
            </Stack>
            <Box flexGrow={1} height={{ xs: 24, sm: 0 }} position="relative">
              <Box
                height="100%"
                position="absolute"
                sx={(theme) => ({
                  borderTop: { xs: 0, sm: 2 },
                  borderLeft: { xs: 2, sm: 0 },
                  transform: { xs: 'none', sm: 'translate(-50%, 0%)' },
                  borderImageSource: `linear-gradient(180deg, ${hexToRgba(
                    theme.palette.primary.main,
                    35,
                  )}, ${hexToRgba(theme.palette.primary.main, 60)})`,
                  borderImageSlice: 1,
                })}
                width={{ xs: '100%', sm: 'calc(100% + 180px - 44px)' }}
                left={{ xs: 10, sm: '50%' }}
                top={{ xs: 0, sm: 22 }}
              />
            </Box>
            <Stack
              alignItems="center"
              width={{ sm: 180 }}
              flexDirection={{ xs: 'row', sm: 'column' }}
            >
              <Typography
                component={Stack}
                height={{ xs: 22, sm: 44 }}
                width={{ xs: 22, sm: 44 }}
                borderRadius={50}
                bgcolor={(theme) => hexToRgba(theme.palette.primary.main, 60)}
                color="white"
                variant="h6"
                textAlign="center"
                justifyContent="center"
                lineHeight={1}
                mb={{ xs: 0, sm: 2 }}
                mr={{ xs: 2.5, sm: 0 }}
              >
                2
              </Typography>
              <Typography
                fontSize={{ xs: 12, sm: 14 }}
                fontWeight={500}
                textAlign="center"
                whiteSpace={{ sm: 'pre-line' }}
              >
                {t('referral.tips.2')}
              </Typography>
            </Stack>
            <Box flexGrow={1} height={{ xs: 24, sm: 0 }} position="relative">
              <Box
                height={{ xs: 'calc(100% + 0px)', sm: '100%' }}
                position="absolute"
                sx={(theme) => ({
                  borderTop: { xs: 0, sm: 2 },
                  borderLeft: { xs: 2, sm: 0 },
                  transform: { xs: 'none', sm: 'translate(-50%, 0%)' },
                  borderImageSource: `linear-gradient(180deg, ${hexToRgba(
                    theme.palette.primary.main,
                    60,
                  )}, ${theme.palette.primary.main})`,
                  borderImageSlice: 1,
                })}
                width={{ xs: '100%', sm: 'calc(100% + 180px - 44px)' }}
                left={{ xs: 10, sm: '50%' }}
                top={{ xs: 0, sm: 22 }}
              />
            </Box>
            <Stack
              alignItems="center"
              width={{ sm: 180 }}
              position="relative"
              flexDirection={{ xs: 'row', sm: 'column' }}
            >
              <Box
                component="img"
                src={confett}
                position="absolute"
                width={120}
                top={-50}
                display={{ xs: 'none', sm: 'initial' }}
              />
              <Typography
                component={Stack}
                height={{ xs: 22, sm: 44 }}
                width={{ xs: 22, sm: 44 }}
                borderRadius={50}
                bgcolor="primary.main"
                color="white"
                variant="h6"
                textAlign="center"
                justifyContent="center"
                lineHeight={1}
                mb={{ xs: 0, sm: 2 }}
                mr={{ xs: 2.5, sm: 0 }}
              >
                3
              </Typography>
              <Typography
                fontSize={{ xs: 12, sm: 14 }}
                fontWeight={500}
                textAlign="center"
                whiteSpace={{ sm: 'pre-line' }}
              >
                {t('referral.tips.3.normal')}
                <Typography
                  component="span"
                  fontWeight={600}
                  color="primary"
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  {t('referral.tips.3.strong')}
                </Typography>
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: 'none',
                    display: { xs: 'none', sm: 'initial' },
                    mb: 5,
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Stack flexDirection="row">
                    <Typography sx={{ p: 0.5 }} fontWeight={900} variant="h6">
                      {t('referral.tips.star')}
                    </Typography>
                    <Typography sx={{ py: 1 }} width={170} fontWeight={500}>
                      {t('referral.tips.secret')}
                    </Typography>
                  </Stack>
                </Popover>
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection="row">
            <Typography
              sx={{ p: 0.5 }}
              mt={2}
              fontWeight={900}
              variant="h6"
              display={{ xs: 'initial', sm: 'none' }}
            >
              {t('referral.tips.star')}
            </Typography>
            <Typography
              display={{ xs: 'initial', sm: 'none' }}
              fontSize={8}
              mt={3}
            >
              {t('referral.tips.secret')}
            </Typography>
          </Stack>
        </Box>
        <Box
          display={{
            xs: contact.referrals?.length ? 'initial' : 'none',
            sm: 'none',
          }}
        >
          <Link onClick={handleOpen}>
            <Typography
              variant="body1"
              sx={{ textDecoration: 'underline' }}
              textAlign="center"
            >
              <Box
                component={FontAwesomeIcon}
                icon={faLightbulbOn as IconProp}
                height={14}
                mr={0.5}
              />
              {t('referral.tips.title')}
            </Typography>
          </Link>
        </Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openModal}>
            <Box
              p={2}
              bgcolor="white"
              borderRadius={5}
              boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.15)"
              position="absolute"
              top="50%"
              left="50%"
              sx={{
                transform: 'translate(-50%, -50%)',
              }}
              width="90%"
            >
              <Typography variant="h6" pb={1.5}>
                <Stack flexDirection="row" width="100%" position="relative">
                  <Box
                    component={FontAwesomeIcon}
                    icon={faLightbulbOn as IconProp}
                    height={{ xs: 16, sm: 22 }}
                    mt="2px"
                    mr={1.5}
                  />
                  <Typography variant="h6" pb={1.5}>
                    {t('referral.tips.title')}
                  </Typography>
                  <Box
                    onClick={handleClose}
                    component={FontAwesomeIcon}
                    icon={faXmark as IconProp}
                    color="gray"
                    height={22}
                    position="absolute"
                    right={0}
                    top={-4}
                  />
                </Stack>
              </Typography>
              <Stack
                flexDirection="column"
                justifyContent="space-around"
                pb={1.5}
              >
                <Stack
                  alignItems="center"
                  width={{ sm: 180 }}
                  flexDirection={{ xs: 'row', sm: 'column' }}
                >
                  <Typography
                    component={Stack}
                    height={{ xs: 22, sm: 44 }}
                    width={{ xs: 22, sm: 44 }}
                    borderRadius={50}
                    bgcolor={(theme) =>
                      hexToRgba(theme.palette.primary.main, 35)
                    }
                    color="white"
                    variant="h6"
                    textAlign="center"
                    justifyContent="center"
                    lineHeight={1}
                    mb={{ xs: 0, sm: 2 }}
                    mr={{ xs: 2.5, sm: 0 }}
                  >
                    1
                  </Typography>
                  <Typography
                    fontSize={{ xs: 12, sm: 18 }}
                    fontWeight={300}
                    textAlign="center"
                    whiteSpace={{ sm: 'pre-line' }}
                  >
                    {t('referral.tips.1')}
                  </Typography>
                </Stack>
                <Box
                  flexGrow={1}
                  height={{ xs: 24, sm: 0 }}
                  position="relative"
                >
                  <Box
                    height="100%"
                    position="absolute"
                    sx={(theme) => ({
                      borderTop: { xs: 0, sm: 2 },
                      borderLeft: { xs: 2, sm: 0 },
                      transform: { xs: 'none', sm: 'translate(-50%, 0%)' },
                      borderImageSource: `linear-gradient(180deg, ${hexToRgba(
                        theme.palette.primary.main,
                        35,
                      )}, ${hexToRgba(theme.palette.primary.main, 60)})`,
                      borderImageSlice: 1,
                    })}
                    width={{ xs: '100%', sm: 'calc(100% + 180px - 44px)' }}
                    left={{ xs: 10, sm: '50%' }}
                    top={{ xs: 0, sm: 22 }}
                  />
                </Box>
                <Stack
                  alignItems="center"
                  width={{ sm: 180 }}
                  flexDirection={{ xs: 'row', sm: 'column' }}
                >
                  <Typography
                    component={Stack}
                    height={{ xs: 22, sm: 44 }}
                    width={{ xs: 22, sm: 44 }}
                    borderRadius={50}
                    bgcolor={(theme) =>
                      hexToRgba(theme.palette.primary.main, 60)
                    }
                    color="white"
                    variant="h6"
                    textAlign="center"
                    justifyContent="center"
                    lineHeight={1}
                    mb={{ xs: 0, sm: 2 }}
                    mr={{ xs: 2.5, sm: 0 }}
                  >
                    2
                  </Typography>
                  <Typography
                    fontSize={{ xs: 12, sm: 18 }}
                    fontWeight={300}
                    textAlign="center"
                    whiteSpace={{ sm: 'pre-line' }}
                  >
                    {t('referral.tips.2')}
                  </Typography>
                </Stack>
                <Box
                  flexGrow={1}
                  height={{ xs: 24, sm: 0 }}
                  position="relative"
                >
                  <Box
                    height={{ xs: 'calc(100% + 0px)', sm: '100%' }}
                    position="absolute"
                    sx={(theme) => ({
                      borderTop: { xs: 0, sm: 2 },
                      borderLeft: { xs: 2, sm: 0 },
                      transform: { xs: 'none', sm: 'translate(-50%, 0%)' },
                      borderImageSource: `linear-gradient(180deg, ${hexToRgba(
                        theme.palette.primary.main,
                        60,
                      )}, ${theme.palette.primary.main})`,
                      borderImageSlice: 1,
                    })}
                    width={{ xs: '100%', sm: 'calc(100% + 180px - 44px)' }}
                    left={{ xs: 10, sm: '50%' }}
                    top={{ xs: 0, sm: 22 }}
                  />
                </Box>
                <Stack
                  alignItems="center"
                  width={{ sm: 180 }}
                  position="relative"
                  flexDirection={{ xs: 'row', sm: 'column' }}
                >
                  <Box
                    component="img"
                    src={confett}
                    position="absolute"
                    width={120}
                    top={-50}
                    display={{ xs: 'none', sm: 'initial' }}
                  />
                  <Typography
                    component={Stack}
                    height={{ xs: 22, sm: 44 }}
                    width={{ xs: 22, sm: 44 }}
                    borderRadius={50}
                    bgcolor="primary.main"
                    color="white"
                    variant="h6"
                    textAlign="center"
                    justifyContent="center"
                    lineHeight={1}
                    mb={{ xs: 0, sm: 2 }}
                    mr={{ xs: 2.5, sm: 0 }}
                  >
                    3
                  </Typography>
                  <Typography
                    fontSize={{ xs: 12, sm: 18 }}
                    fontWeight={300}
                    textAlign="center"
                    whiteSpace={{ sm: 'pre-line' }}
                  >
                    {t('referral.tips.3.normal')}
                    <Typography
                      component="span"
                      fontWeight={600}
                      color="primary"
                    >
                      {t('referral.tips.3.strong')}
                    </Typography>
                  </Typography>
                </Stack>
              </Stack>
              <Stack flexDirection="row">
                <Typography
                  sx={{ p: 0.5 }}
                  fontWeight={900}
                  variant="h6"
                  display={{ xs: 'initial', sm: 'none' }}
                >
                  {t('referral.tips.star')}
                </Typography>
                <Typography
                  display={{ xs: 'initial', sm: 'none' }}
                  fontSize={8}
                  marginTop={1}
                >
                  {t('referral.tips.secret')}
                </Typography>
              </Stack>
            </Box>
          </Fade>
        </Modal>
      </Stack>
    </Stack>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ASeparator, SCard, SText } from '@smartrenting/smartomic';

import { useLeaving } from '@hooks/useLeaving/useLeaving';
import { useTranslationFormat } from '@hooks/useTranslationFormat/useTranslationFormat';

import ongoingSvg from '../../assets/ongoingLeaving.svg';

import './OngoingLeaving.scss';

export default function OngoingLeaving() {
  const { t } = useTranslation();
  const leaving = useLeaving();
  const { t: translationFormat } = useTranslationFormat();

  return (
    <div className="OngoingLeaving">
      <ASeparator className="OngoingLeaving__ASeparator" />
      <div className="OngoingLeaving__content">
        <SCard>
          <SText variant="subtitle" align="center">
            <strong>{t('dashboard.leavings.goodTravel')}</strong>
          </SText>
          <img src={ongoingSvg} alt="ongoing-svg" />
          <SText variant="body-subtitle" align="center">
            {translationFormat('dashboard.leavings.ongoing', {
              startDate: leaving.startDate?.format('L'),
              endDate: leaving.endDate?.format('L'),
            } as any)}
          </SText>
        </SCard>
      </div>
    </div>
  );
}

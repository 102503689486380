import { Card } from '@mui/material';

import React, {
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Moment } from 'moment-timezone';

import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { AIcon, SSmartLoader, SText, STitle } from '@smartrenting/smartomic';

import { DOCUMENT } from '@enum';

import { classNames } from '@utils/helpers';

import Apartment from '@models/Apartment';
import Contact from '@models/Contact';
import DocumentModel from '@models/Document';
import Leaving from '@models/Leaving';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useContact } from '@hooks/useContact/useContact';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import Document from './components/Document/Document';
import DocumentsByLeaving from './components/DocumentsByLeaving/DocumentsByLeaving';
import { useDocumentsList } from './useDocumentsList';

import documentCheckSvg from './assets/documentCheck.svg';
import documentCrossSvg from './assets/documentCross.svg';
import documentOptionalSvg from './assets/documentOptional.svg';
import fileUploadSvg from './assets/fileUpload.svg';

import './Documents.scss';

function getDocumentName(document: string, ownership: string) {
  if (document === DOCUMENT.PROOF) {
    return document + '.' + ownership;
  }

  return document;
}

function getDocumentCount(
  document: string,
  {
    apartment,
    leaving,
    contact,
  }: {
    apartment: Apartment;
    leaving: Leaving;
    contact: Contact;
  },
) {
  switch (document) {
    case DOCUMENT.ID: {
      return contact.identityDocuments.length;
    }
    case DOCUMENT.RIB: {
      return contact.rib ? 1 : 0;
    }
    case DOCUMENT.AUTHORIZATION: {
      return leaving.authorizationDocument ? 1 : 0;
    }
    case DOCUMENT.DEPARTURE: {
      return leaving.departureDocument ? 1 : 0;
    }
    case DOCUMENT.PROOF: {
      return apartment.proofDocument ? 1 : 0;
    }
    case DOCUMENT.USAGE: {
      return apartment.usageDocument ? 1 : 0;
    }
    case DOCUMENT.RECEIPTS: {
      return apartment.receipts.length;
    }
  }
}

function getDocuments(
  document: string,
  {
    apartment,
    leaving,
    contact,
  }: {
    apartment: Apartment;
    leaving: Leaving;
    contact: Contact;
  },
) {
  switch (document) {
    case DOCUMENT.ID: {
      return [contact.identityDocuments].flat();
    }
    case DOCUMENT.RIB: {
      return contact.rib ? [contact.rib] : [];
    }
    case DOCUMENT.AUTHORIZATION: {
      return leaving.authorizationDocument
        ? [leaving.authorizationDocument]
        : [];
    }
    case DOCUMENT.DEPARTURE: {
      return leaving.departureDocument ? [leaving.departureDocument] : [];
    }
    case DOCUMENT.PROOF: {
      return apartment.proofDocument ? [apartment.proofDocument] : [];
    }
    case DOCUMENT.USAGE: {
      return apartment.usageDocument ? [apartment.usageDocument] : [];
    }
    case DOCUMENT.RECEIPTS: {
      return [apartment.receipts].flat();
    }
  }
}

const getIcon = (count: number, documentName: string, nbDays: number) => {
  const optionalDocuments = [
    DOCUMENT.RECEIPTS,
    DOCUMENT.AUTHORIZATION,
    ...(nbDays <= 120 ? [DOCUMENT.DEPARTURE] : []),
  ];

  if (optionalDocuments.includes(documentName as any)) {
    return documentOptionalSvg;
  }

  if (count > 0) {
    return documentCheckSvg;
  }

  return documentCrossSvg;
};

interface DocumentsProps {
  displayDetails?: boolean;
}

export default function Documents({ displayDetails }: DocumentsProps) {
  const documentsList = useDocumentsList();
  const { t } = useTranslation();
  const { createDocument, removeDocument } = useAppContext();
  const contact = useContact();
  const leaving = useLeaving();
  const apartment = useApartment();
  const { ownership } = apartment;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] =
    useState<(typeof DOCUMENT)[keyof typeof DOCUMENT]>();

  const handleSubmit = async (documentName: string, file: File) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    await createDocument(documentName, formData);
  };

  const onDrop = useCallback(
    async (acceptedFiles: Array<File>) => {
      return Promise.all(
        acceptedFiles.map((file: File) => {
          handleSubmit(selected as string, file);
        }),
      ).then(() => setLoading(false));
    },
    [selected],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
      'application/pdf': [],
    },
  });

  const handleDeleteDocument = useCallback(
    (name: string, document: DocumentModel): MouseEventHandler =>
      (event) => {
        event.stopPropagation();
        removeDocument(name, document.id);
      },
    [],
  );

  const fetchedDocuments = useMemo(() => {
    if (selected) {
      return getDocuments(selected, {
        apartment,
        contact,
        leaving,
      }) as DocumentModel[];
    }

    return [];
  }, [selected, documentsList]);

  return (
    <div className="Documents">
      <DocumentsByLeaving display={displayDetails} />
      <Card>
        {!selected && (
          <>
            <div className="Documents__header Documents__header--folder">
              <div />
              <div>
                <SText variant="body-subtitle">
                  {t('documents.list.name')}
                </SText>
              </div>
              <div>
                <SText variant="body-subtitle">
                  {t('documents.list.number')}
                </SText>
              </div>
              <div>
                <SText variant="body-subtitle">
                  {t('documents.list.type')}
                </SText>
              </div>
            </div>
            <div className="Documents__body">
              {documentsList.map((documentName) => {
                const count = getDocumentCount(documentName, {
                  apartment,
                  contact,
                  leaving,
                });

                const name = t(
                  `documents.document.${getDocumentName(
                    documentName,
                    ownership,
                  )}`,
                );

                const documents = getDocuments(documentName, {
                  leaving,
                  apartment,
                  contact,
                }) as DocumentModel[];

                return (
                  <div
                    key={documentName}
                    onClick={() => setSelected(documentName)}
                    className="Documents__body__row Documents__body__row--folder"
                  >
                    <div>
                      <img
                        src={getIcon(
                          count as number,
                          documentName,
                          leaving.endDate.diff(leaving.startDate, 'days'),
                        )}
                      ></img>
                    </div>
                    <div>
                      <STitle
                        variant="body"
                        title={name}
                        subtitle={documents
                          .reduce<Moment | null>(
                            (acc, document) =>
                              document.createdAt.isAfter(acc)
                                ? document.createdAt
                                : acc,
                            documents[0]?.createdAt,
                          )
                          ?.format?.('L')}
                      />
                    </div>
                    <div>
                      <SText variant="body-subtitle">{count}</SText>{' '}
                    </div>
                    <div>
                      <SText variant="body-subtitle">
                        {[
                          ...new Set(
                            documents.map((document) =>
                              document.originalName.slice(
                                document.originalName.lastIndexOf('.') + 1,
                              ),
                            ),
                          ),
                        ].join(',')}
                      </SText>{' '}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {selected && (
          <>
            <div className="Documents__header">
              <div>
                <AIcon
                  icon={faChevronLeft}
                  onClick={() => setSelected(undefined)}
                />
                <SText variant="body-title">
                  {t(
                    `documents.document.${getDocumentName(
                      selected,
                      ownership,
                    )}`,
                  )}
                </SText>
              </div>
              <div />
              <div />
            </div>
            <div className="Documents__body">
              <div
                className={classNames(
                  'Documents__body__dropzone',
                  isDragActive && 'Documents__body__dropzone--active',
                )}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <img src={fileUploadSvg}></img>
                <SText
                  className="Documents__body__dropzone__click"
                  variant="body-subtitle"
                >
                  {t('documents.list.click')}
                </SText>
                <SText
                  className="Documents__body__dropzone__drop"
                  variant="body-subtitle"
                >
                  {t('documents.list.drop')}
                </SText>
              </div>
              <div className="Documents__header">
                <div />
                <div>
                  <SText variant="body-subtitle">
                    {t('documents.list.name')}
                  </SText>
                </div>
                <div>
                  <SText variant="body-subtitle">
                    {t('documents.list.date')}
                  </SText>
                </div>
                <div>
                  <SText variant="body-subtitle">
                    {t('documents.list.type')}
                  </SText>
                </div>
                <div />
                <div />
              </div>
              {fetchedDocuments.length ? (
                fetchedDocuments.map((document) => {
                  return (
                    <Document
                      key={document.id}
                      document={document}
                      onDelete={handleDeleteDocument(selected, document)}
                    />
                  );
                })
              ) : (
                <div className="Documents__body__waitDocuments">
                  {t('common.waitDocuments')}
                </div>
              )}
              {loading && (
                <div className="Documents__body__row--loading">
                  <SSmartLoader />
                </div>
              )}
            </div>
          </>
        )}
      </Card>
    </div>
  );
}

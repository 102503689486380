import React from 'react';

import { classNames } from '@utils/helpers';

import './Page.scss';

export default function Page({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLProps<HTMLDivElement>, HTMLDivElement> & {
  className?: React.DetailedHTMLProps<
    React.HTMLProps<HTMLDivElement>,
    HTMLDivElement
  >['className'];
}) {
  return (
    <div className={classNames('Page', className)} {...props}>
      {children}
    </div>
  );
}

import React, { useEffect } from 'react';

import { useKeywordsContext } from '@scopes/Gamification/context/KeywordsContext';
import { useGamification } from '@scopes/Gamification/contexts/GamificationContext';

import Keywords from '../Keywords/Keywords';

import './KeywordsStep.scss';

export default function KeywordsStep() {
  const { enable, handleNext, disable } = useGamification();
  const { validSteps } = useKeywordsContext();

  const validationCondition = validSteps.includes('keywords');

  useEffect(() => {
    if (validationCondition) {
      enable();
    } else {
      disable();
    }
    handleNext(() => Promise.resolve());
  }, [validationCondition]);

  return (
    <div className="KeywordsStep">
      <Keywords categories={['apartment', 'building']} step="keywords" />
    </div>
  );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';

import { useMobile } from '@hooks/useMobile/useMobile';

import './PdfViewer.scss';

interface PdfViewerProps {
  file: Blob;
  scale?: number;
}

export default function PdfViewer({ file, scale }: PdfViewerProps) {
  const [numPages, setNumPages] = useState(0);
  const isMobile = useMobile();
  const { t } = useTranslation();

  return (
    <div className="PdfViewer">
      <Document
        file={file}
        loading={t('common.loading')}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            width={isMobile ? 280 : 600}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={scale || 1}
          />
        ))}
      </Document>
    </div>
  );
}

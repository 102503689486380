import { Box, AlertTitle, Alert } from '@mui/material';

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import moment from 'moment-timezone';
import { routes } from 'src/routes';
import { RentEstimationType } from 'src/types/RentEstimation';

import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SButton, SText } from '@smartrenting/smartomic';

import { OWNERSHIP } from '@enum';

import { useAppContext } from '@contexts/AppContext/AppContext';

import { useApartment } from '@hooks/useApartment/useApartment';
import { useLeaving } from '@hooks/useLeaving/useLeaving';

import Page from '@components/Page/Page';

import estimationSvg from './estimation.svg';

import './Estimation.scss';

export default function Estimation() {
  const { fetchRentEstimation } = useAppContext();
  const [rentEstimation, setRentEstimation] = useState<RentEstimationType>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { rent, startDate, endDate } = useLeaving();
  const { id, ownership } = useApartment();

  const isOwner = ownership === OWNERSHIP.OWNER;
  const isTenant = ownership === OWNERSHIP.TENANT;

  useEffect(() => {
    fetchRentEstimation(id).then((result) => {
      setRentEstimation({
        ...result,
        ref_price: Math.round(result.ref_price / 50) * 50,
        max_price: Math.round(result.max_price / 50) * 50,
      });
    });
  }, []);

  const isOlympicGamesDates = useMemo(() => {
    const startDateJO = moment('2024-07-26');
    const endDateJO = moment('2024-08-11');

    const startDate2MonthsBeforeJO = startDateJO.clone().subtract(2, 'months');
    const endDate2MonthsAfterJO = endDateJO.clone().add(2, 'months');

    const startsDuring2MonthsBeforeJO = startDate.isBetween(
      startDate2MonthsBeforeJO,
      endDateJO,
      null,
      '[]',
    );
    const endsDuring2MonthsAfterJO = endDate.isBetween(
      startDateJO,
      endDate2MonthsAfterJO,
      null,
      '[]',
    );
    const overlapsWithJO =
      startDate.isBefore(endDateJO) && endDate.isAfter(startDateJO);

    return (
      startsDuring2MonthsBeforeJO && endsDuring2MonthsAfterJO && overlapsWithJO
    );
  }, [startDate, endDate]);

  return (
    <Page className="Estimation">
      <SText
        variant="title"
        className="Estimation__type Estimation__text--color"
      >
        {t(`estimation.${ownership}.title`)}
      </SText>
      <SText variant="body-title" className="Estimation__gain">
        {isTenant && rent && t('estimation.tenant.subtitle', { rent })}
        {isTenant && !rent && t('estimation.tenant.subtitle_1')}
        {isOwner && !isOlympicGamesDates && t('estimation.owner.subtitle')}
      </SText>
      {isTenant && rent && (
        <div className="Estimation__rent Estimation__text--color">
          <SText variant="title" className="Estimation__rent__value">
            {t('estimation.tenant.amount', { rent: rent.toLocaleString() })}
          </SText>
          <span>{t('estimation.month')}</span>
        </div>
      )}
      {isOlympicGamesDates && isOwner && (
        <Alert
          severity="info"
          icon={
            <Box
              component={FontAwesomeIcon}
              icon={faExclamationCircle as any}
              size="1x"
              my="auto"
            />
          }
          sx={{ width: '100%' }}
        >
          <AlertTitle>{t('estimation.owner.JO_title')}</AlertTitle>
          {t('estimation.owner.JO_subtitle')}
        </Alert>
      )}
      {!isOlympicGamesDates && isOwner && rentEstimation && (
        <div className="Estimation__rent Estimation__text--color">
          <SText variant="title" className="Estimation__rent__value">
            {t('estimation.owner.amount', {
              ref_price: rentEstimation.ref_price.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              }),
              max_price: rentEstimation.max_price.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              }),
            })}
          </SText>
          <span>{t('estimation.month')}</span>
        </div>
      )}
      <div className="Estimation--bottom">
        <SButton
          onClick={() => navigate(routes.root)}
          label={
            isOlympicGamesDates && isOwner
              ? t('estimation.contact')
              : t('estimation.next')
          }
          data-testid="gamification-estimation-next"
        />
        <img src={estimationSvg} alt="fireworks" />
      </div>
    </Page>
  );
}

import { Box, Typography } from '@mui/material';

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faPhone,
  faHouse,
  faFileLines,
  faFileSignature,
  faCamera,
  faTags,
  faKeySkeletonLeftRight,
  faLock,
} from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SText } from '@smartrenting/smartomic';

import { INVENTORY_STATUS, INVENTORY_TYPE, PROSPECT_STATUS } from '@enum';

import { classNames } from '@utils/helpers';

import { useLeaving } from '@hooks/useLeaving/useLeaving';
import { useProspect } from '@hooks/useProspect/useProspect';

import {
  InventoryStep,
  useInventoryStep,
} from '../Steps/Inventory/useInventoryStep';

import { ProspectSetupStep } from './useSetupStep';

import './SetupStepContainer.scss';

const stepIcon = {
  [ProspectSetupStep.phone]: faPhone,
  [ProspectSetupStep.hidePhone]: faPhone,
  [ProspectSetupStep.visit]: faHouse,
  [ProspectSetupStep.documents]: faFileLines,
  [ProspectSetupStep.mandate]: faFileSignature,
  [ProspectSetupStep.photoShoot]: faCamera,
  [ProspectSetupStep.inventory]: faTags,
  [ProspectSetupStep.keys]: faKeySkeletonLeftRight,
};

export default function SetupStepContainer({
  steps,
  initialStep,
  selectedStep,
  onChange,
  children,
}: {
  steps: Exclude<
    ProspectSetupStep,
    ProspectSetupStep.toRecontact | ProspectSetupStep.rejected
  >[];
  initialStep: ProspectSetupStep;
  selectedStep: ProspectSetupStep;
  onChange: (step: ProspectSetupStep) => void;
  children: React.ReactNode;
}) {
  const { t } = useTranslation();
  const { status } = useProspect();
  const isPhoneCall = status === PROSPECT_STATUS.PHONE_CALL;
  const inventoryStep = useInventoryStep();
  const leaving = useLeaving();
  const inventoryInReview = leaving.findInventoryByType(
    INVENTORY_TYPE.IN_REVIEW,
  );

  const getTitle = useCallback(
    () =>
      selectedStep === ProspectSetupStep.inventory
        ? t(
            `dashboard.${ProspectSetupStep[selectedStep]}.${InventoryStep[inventoryStep]}.title`,
          )
        : t(`dashboard.${ProspectSetupStep[selectedStep]}.title`),
    [selectedStep],
  );

  const getSubtitle = useCallback(
    () =>
      selectedStep === ProspectSetupStep.inventory ? (
        <>
          <span>
            {t(
              `dashboard.${ProspectSetupStep[selectedStep]}.${InventoryStep[inventoryStep]}.subtitle`,
            )}
          </span>
        </>
      ) : isPhoneCall ? (
        t(`dashboard.${ProspectSetupStep[selectedStep]}.subtitle_1`)
      ) : (
        t(`dashboard.${ProspectSetupStep[selectedStep]}.subtitle`)
      ),
    [selectedStep, isPhoneCall],
  );

  return (
    <div className="SetupStepContainer">
      <div className="SetupStepContainer__steps">
        {steps.map((step) => {
          const isDone = step < initialStep;
          const isSelected = selectedStep === step;
          const isSelectable =
            inventoryInReview?.status === INVENTORY_STATUS.TO_SIGN &&
            step >= ProspectSetupStep.inventory;
          let isLocked = step > initialStep;
          let isActive = initialStep === step;
          let icon = stepIcon[step];

          if (
            initialStep >= ProspectSetupStep.inventory &&
            inventoryInReview?.status === INVENTORY_STATUS.TO_SIGN
          ) {
            isLocked = !(step >= ProspectSetupStep.inventory);
            isActive = !(step >= ProspectSetupStep.inventory);
          }
          if (isDone) icon = faCheckCircle;
          if (isLocked) icon = faLock;

          return (
            <div
              className={classNames(
                'SetupStepContainer__steps__step',
                isDone && 'SetupStepContainer__steps__step--done',
                isLocked && 'SetupStepContainer__steps__step--locked',
                isActive && 'SetupStepContainer__steps__step--active',
                isSelected && 'SetupStepContainer__steps__step--selected',
                isSelectable && 'SetupStepContainer__steps__step--selectable',
              )}
              onClick={() => onChange(step)}
              key={ProspectSetupStep[step]}
            >
              <Box
                component={FontAwesomeIcon}
                icon={icon as IconProp}
                sx={{
                  height: '20px',
                  marginRight: '1rem',
                }}
              />
              <SText className="UnSetupStepContainer__steps__step__text">
                {t(`dashboard.steps.${ProspectSetupStep[step]}`)}
              </SText>
            </div>
          );
        })}
      </div>
      <div className="SetupStepContainer__content">
        <div className="SetupStepContainer__content__text">
          <Typography variant="h6">{getTitle()}</Typography>
          <Typography textAlign="justify" variant="subtitle2">
            {getSubtitle()}
          </Typography>
        </div>
        <div className="SetupStepContainer__content__step">{children}</div>
      </div>
    </div>
  );
}

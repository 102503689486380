import { fetchApi } from './fetchApi';

export async function getImage(
  token: string,
  id: number,
  params: {
    h?: number;
    w?: number;
    q?: number;
    crop?: string;
    auto?: string;
    fit?: string;
  } = {},
  abortSignal?: AbortSignal,
) {
  return fetchApi<Blob>(
    'GET',
    `/documents/${id}/image`,
    token,
    {
      query: params,
    },
    abortSignal,
  );
}

import { Typography } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

import DocumentsComponent from '@components/Documents/Documents';
import Page from '@components/Page/Page';

import './Documents.scss';

export default function Documents() {
  const { t } = useTranslation();

  return (
    <div className="Page__container">
      <Page className="Documents">
        <Typography variant="h5">{t('documents.title')}</Typography>
        <Typography mb={2.5}>{t('documents.subtitle')}</Typography>
        <DocumentsComponent displayDetails={true} />
      </Page>
    </div>
  );
}
